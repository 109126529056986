import React from "react";

function ActionButton({
  children,
  icon,
  color,
  onClick=()=>{},
  disabled=false,
}) {
  function handleClick() {
    if (disabled) {
      return;
    }
    onClick();
  }

  function getColor() {
    if (color) {
      return color;
    }
    return "bg-white";
  }

  return (
    <div 
      onClick={handleClick}
      className={`rounded-xl self-stretch flex flex-1 ${getColor()} overflow-hidden ${disabled && "opacity-50"}`
      }>
      <button className={
        `text-black text-xl flex flex-1 justify-center items-center font-bold ${disabled ? "cursor-not-allowed" : "hover:bg-blue-300"}`}
      >
        {icon && <div className={children ? "mr-2" : ""}>{icon}</div>}
        {children && children}
      </button>
    </div>
  );
}

export default ActionButton;
