import React, { useState, useEffect } from 'react';
import { searchVariant, selectVariant } from 'actions/pos/variantActions';
import { createFlashMessageRequest } from 'actions/alertActions';
import { addToCart, getCart, removeFromCart, createCart } from 'actions/pos/cartActions';
import { connect } from 'react-redux';
import { Input, ProductItem, ProductItemSkeleton } from './components';
import PricePanel from './pricePanel';

function CartPanel(props) {
    const [input, setInput] = useState('');
    const [lineItems, setLineItems] = useState([]);
    const [lineItemIdLoading, setLineItemIdLoading] = useState(null);
    const [showProductSkeleton, setShowProductSkeleton] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);

    useEffect(() => {
        if (triggerSearch) {
            search();
            setTriggerSearch(false);
        }
    }, [triggerSearch, props.cart]);

    useEffect(() => {
        if (!props.cart.data && lineItems.length) {
            setLineItems([]);
        }
    }, [props.cart.loaded]);

    async function handleSearch() {
        if (input === '') return;
        if (!props.cart.isLoaded) {
            await findOrCreateCart(props.client.selectedClientId);
        }
        setTriggerSearch(true);
    }

    async function search() {
        let inputText = input.slice(0, 12);
        setInput('');
        setShowProductSkeleton(true);
        // TODO: Add to cart using barcode directly and skip searchVariant
        let variant = await props.searchVariant({ barcode: inputText });
        let isValid = validateVariant(variant);
        if (!isValid) {
            setShowProductSkeleton(false);
            return;
        }
        await props.addToCart({ variant_id: variant[0].id, quantity: 1 });
        handleSelectVariant(variant[0].id);
    }

    function validateVariant(variant) {
        if (!variant || !variant.length) {
            props.createFlashMessageRequest({
                closeTime: 1500,
                variant: 'warning',
                message: `No se encontró el producto con el código de barras seleccionado`,
            });
            return false;
        }
        let attributes = variant[0].attributes;

        if (!attributes.in_stock && !attributes.purchasable) {
            props.createFlashMessageRequest({
                closeTime: 1500,
                variant: 'warning',
                message: `El producto ${attributes.name} (${attributes.options_text}) no está disponible`,
            });
            return false;
        }

        return true;
    }

    async function handleRemoveFromCart(id) {
        setLineItemIdLoading(id);
        props.removeFromCart(id);
        let cartLineItems = props.cart.data.attributes.line_items.data.filter((item) => item.id !== id);
        if (!cartLineItems.length) {
            props.selectVariant(null);
            return;
        }
        handleSelectVariant(cartLineItems[cartLineItems.length - 1].attributes.variant_id);
    }

    async function handleSelectVariant(id) {
        if (id === props.variant.selectedVariantId) return;
        props.selectVariant(id);
    }

    async function findOrCreateCart(user_id) {
        let cart = await props.getCart({ user_id });

        if (cart?.status === 404) {
            cart = await props.createCart({ user_id });
        }

        if (cart?.attributes) {
            sessionStorage.setItem(
                `orderToken`,
                JSON.stringify({
                    token: cart.attributes.token,
                    order_id: cart.id,
                    user_id: cart.attributes.user_id,
                })
            );
        }
    }

    useEffect(async () => {
        if (props.client.loaded && props.client.data) {
            setShowProductSkeleton(true);
            findOrCreateCart(props.client.selectedClientId);
        }
    }, [props.client]);

    useEffect(() => {
        if (props.cart.isLoading) {
            setShowProductSkeleton(true);
        }
        if (props.cart.loaded && props.cart.data) {
            setLineItemIdLoading(null);
            setShowProductSkeleton(false);
            let cartLineItems = props.cart.data.attributes.line_items.data;
            setLineItems(cartLineItems);
            if (!props.variant.selectedVariantId && cartLineItems.length) {
                handleSelectVariant(cartLineItems[cartLineItems.length - 1].attributes.variant_id);
            }
        }
        if (!!props.cart.error) {
            setShowProductSkeleton(false);
        }
    }, [props.cart]);

    return (
        <div className="flex flex-grow">
            <div className="rounded-xl flex flex-column flex-1 m-2 p-2 drop-shadow-lg" style={{ backgroundColor: 'hsl(217, 32%, 28%)' }}>
                <div className="flex flex-1 flex-column justify-start items-start">
                    <div className="flex flex-column w-full text-white text-2xl font-light pb-3">
                        <Input placeholder="Escribe o escanea un producto" style="p-3 text-center" value={input} onChange={(val) => setInput(val)} onPressEnter={handleSearch} />
                    </div>
                    <div className="w-full mx-2 overflow-y-scroll h-0 flex-auto rounded-xl" style={{ backgroundColor: 'hsl(217, 36%, 22%)' }}>
                        {' '}
                        {!!lineItems.length && lineItems.map((item) => <ProductItem key={item.id} id={item.id} attributes={item.attributes} onRemove={handleRemoveFromCart} onSelect={handleSelectVariant} selected={props.variant.selectedVariantId === item.attributes.variant_id} isLoading={lineItemIdLoading === item.id} />)}
                        {showProductSkeleton && <ProductItemSkeleton />}
                    </div>
                </div>
                <PricePanel />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.posCart,
    variant: state.posVariant,
    client: state.posClient,
});

const mapDispatchToProps = {
    searchVariant,
    selectVariant,
    addToCart,
    getCart,
    createCart,
    removeFromCart,
    createFlashMessageRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartPanel);
