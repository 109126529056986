import axios from 'axios';
import { simpleRequestBuilder } from 'utils/actions/requests';

import {
    API_BASE_URL,
    ADD_ORDER,
    INDEX_ORDER,
    INDEX_DISTRIBUTOR_ORDER, // needs to be updated
} from './types';

import { dispatchAction } from '../utils/actions/common';

export const AllClientsOrdersFromUser = (user_id) => {
    //TODO: should update action, route and structure of returned data
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/users/distributor/orders.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        }).then((res) => {
            let clients = { data: { orders: JSON.parse(res.data.orders) || [] } };
            return dispatchAction(dispatch, clients, INDEX_DISTRIBUTOR_ORDER);
        });
    };
};

export const getOrders = (params) => async (dispatch) => {
    let data = await simpleRequestBuilder('GET', API_BASE_URL + `/es/app/api/v1/orders.js`, params);
    return dispatch({
        type: INDEX_ORDER,
        payload: { orders: data },
    });
};

export const getOrderData = (order_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/orders/${order_id}`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        }).then((res) => {
            //catch error

            let order = { data: { order: res.data.data || [] } };

            return dispatchAction(dispatch, order, ADD_ORDER);
        });
    };
};
