import axios from 'axios';

import { API_BASE_URL, SHOW_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT, UPDATE_STORE_CREDIT } from './types';

import { dispatchAction } from 'utils/actions/common';

export const getUserBankInformation = (user_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/bank_account/user_id/${user_id}`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user_id },
            json: true,
        }).then((res) => {
            let bank_account = { data: JSON.parse(res.data.bank_account) };
            if (!bank_account.data) {
                bank_account.data = {};
            }
            return dispatchAction(dispatch, bank_account, SHOW_BANK_ACCOUNT);
        });
    };
};

export const updateSelectedCreditCard = (user_id, card_id, attr) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/bank_account/user_id/${user_id}/update_selected_card`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: { user_id, card_id, attribute: attr },
            json: true,
        }).then((res) => {
            let bank_account = { data: JSON.parse(res.data.bank_account) };
            return dispatchAction(dispatch, bank_account, UPDATE_BANK_ACCOUNT);
        });
    };
};

export const transferStoreCredit = (user_id, amount, type = 'client') => {
    // sender is current user, receiver is user_id

    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/bank_account/add_store_credit.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { receiver_id: user_id, amount, type },
            json: true,
        }).then((res) => {
            if (res.data.status === 400) {
                return res;
            }
            let data = buildCreditDataFromRes(res);

            return dispatchAction(dispatch, data, UPDATE_STORE_CREDIT);
        });
    };
};

export const withdrawStoreCredit = (user_id, amount, type = 'client') => {
    // receiver is current user, receiver is current_user
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/bank_account/retrieve_store_credit.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { sender_id: user_id, amount, type },
            json: true,
        })
            .then((res) => {
                if (res.data.status === 400) {
                    return res;
                }
                let data = buildCreditDataFromRes(res, -1, 'sender');
                return dispatchAction(dispatch, data, UPDATE_STORE_CREDIT);
            })
            .catch((err) => {
                console.log(err);
                return err;
            });
    };
};

export const findUserBank = ({ bankAccounts, userId }) => {
    if (!bankAccounts?.length || !userId) {
        return null;
    }

    return bankAccounts.find((bank) => bank.user_id === userId);
};

// private functions

function buildCreditDataFromRes(res, multiplier = 1, party = 'receiver') {
    return {
        data: {
            user_id: res.data[party + '_credit'][0].credit.user_id,
            used_amount: res.data[party + '_credit'] && Math.abs(parseInt(res.data[party + '_credit'].reduce((prev, curr) => prev + curr.used_amount, 0))) * multiplier,
            type: res.data[party + '_credit'][0].type,
        },
    };
}
