import React, {useEffect} from 'react';
import { Redirect} from 'react-router-dom';

const DistributorIndex = (props) => {
  //redirect to profile

  return (
    <div>
      <Redirect to={
      {
        pathname: "/distributor/clients",
        state: {
          from: props.location
        }
      }
      }/> 
    </div>
  )
}

export default DistributorIndex;
