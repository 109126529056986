import React, {useState} from 'react';
import { DefaultModal } from '../../../components/Modal';
import { BasicTextArea, Title, BasicCheckbox } from '../../../components/Form';

const ModalCandidateRejection = (props) => {
  const [data, setData] = useState({ rejectMessage: "", notifyCandidate: false });
  
  const handleMessageChange = (e) => {
    setData({...data, rejectMessage: e.target.value});
  }
  
  const handleNotifyChange = (e) => {
    setData({...data, notifyCandidate: !data.notifyCandidate});
  }

  const handleRejection = () => {
    props.rejectCandidate({user: props.candidate, ...data})
  }

  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      title={`Rechazar registro de ${props.candidate.first_name} ${props.candidate.last_name}`}
      saveButtonText={"Rechazar Candidato"}
      onSave={handleRejection}
    >
      <Title>Razón de rechazo</Title>
      <BasicTextArea
        id="rejectMessage"
        title="Escribe un mensaje (Opcional)"
        value={data.rejectMessage}
        onChange={handleMessageChange}
      />
      <BasicCheckbox
        id="notifyCandidate"
        checked={data.notifyCandidate}
        onChange={handleNotifyChange}
      >Enviar razón como correo al candidato?</BasicCheckbox>
    </DefaultModal>
  )
}

export default ModalCandidateRejection;