import axios from 'axios';

import { API_BASE_URL, GET_STATEMENT_ACCOUNT } from './types';

export const getStatementAccount = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/scheduled_payments/statement_account',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: params,
            json: true,
        })
            .then((response) => {
                const newResponse = { ...response };
                newResponse.data.Data = response.data.Data.map((r) => ({ ...r, id: r.user_id }));

                dispatch({
                    type: GET_STATEMENT_ACCOUNT,
                    payload: newResponse.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
