import React from 'react';
import {Tab, TabGroup} from 'components/Navs';
import {
  HeaderContainer,
  HeaderContent,
  UserInfoWrapper,
  TabOptions,
  UserInfoTitle,
} from './style';

const DistributorHeader = (props) => {
  return (
  <HeaderContainer>
    <HeaderContent>
      <UserInfoWrapper>
        <UserInfoTitle>
          {props.userRank && props.userRank.attributes.user.attributes.full_name}
        </UserInfoTitle>
      </UserInfoWrapper>
      <TabOptions>
        <TabGroup
          hideLine={true}
          hoverColor={"#bdbdbd"}
          style={{marginBottom: 0,}}
        >
          {Object.entries(props.TABS).map(([k, v]) => {
            let isDisabled = props.disabledTabs && k.indexOf(props.disabledTabs) >= 0;
            return (
              <Tab
                id={k}
                active={props.selectedTab}
                onClick={isDisabled ? () =>{} : props.setSelectedTab}
                disabled={isDisabled}
              >
                {v}
              </Tab>
            )
          })}
        </TabGroup>
      </TabOptions>
    </HeaderContent>
  </HeaderContainer>

  )
}

export default DistributorHeader;
