import React, {useState, useEffect} from 'react';
import { LocalShippingOutlined } from '@mui/icons-material';
import {ActionButton} from "./";
import OrderListerModal from "../modals/orderListerModal";

const OrderListerActionButton = ({show, setShow}) => {
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    setShowList(show);
  }, [show]);

  useEffect(() => {
    setShow(showList);
  }, [showList]);

  return (
    <>
      <ActionButton
        onClick={() => setShowList(true)}
        icon={<LocalShippingOutlined fontSize="large"/>}
      />
      <OrderListerModal
        show={showList}
        onClose={() => setShowList(false)}
      />
    </>
  )
}

export default OrderListerActionButton;
