import React from 'react';
import { MenuItem, MenuDivider } from '../../../components/Sidebar';
import { faStore, faAddressCard, faCog, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../../actions/types';

const DistributorInactiveMenu = (props) => {
    return (
        <div>
            <MenuItem {...props} text="Pagos" icon={faCreditCard} link="/distributor/payments" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Tienda" icon={faStore} link={API_BASE_URL} isExternal={true} selectedColor="#2B91A7" />
            <MenuDivider />
            <MenuItem {...props} text="Configuración" icon={faCog} link="/distributor/config" selectedColor="#2B91A7" />
        </div>
    );
};

export default DistributorInactiveMenu;
