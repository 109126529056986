import React from 'react'
import { RowRadio, BasicInput, BasicCheckbox } from '../../../../components/Form';
import RegisteredCard from './RegisteredCard';

import {
  ModalAddPaymentMethodCodeExpirationContainer,
  PaymentDetailsSectionWrapper
} from './style';

const CardPaymentDetailsForm = (props) => {
  return (
    <PaymentDetailsSectionWrapper>
      {props.creditCardList &&
        props.creditCardList.map((card) => {
          return (
            <RegisteredCard
              id={card.id}
              type={card.cc_type}
              attributes={card.attributes}
              onClick={props.handleCardSelection}
              selected={props.formData.selected_card_id}
            />
          )
        })
      }
      <RowRadio
        id={0}
        selected={props.formData.selected_card_id}
        title="Agregar nueva tarjeta"
        onChange={props.handleCardSelection}
      />
      { !props.formData.selected_card_id &&
        <div>
          <BasicInput
            title={"Nombre"}
            placeholder={props.data.first_name+" "+props.data.last_name}
            id="name"
            onChange={props.handleNewCardFormChange}
            value={props.formData.new_card_form.name}
          />
          <BasicInput 
            title={"Número de tarjeta"}
            placeholder={"16 digitos"}
            id="cardNumber"
            onChange={props.handleNewCardFormChange}
            value={props.formData.new_card_form.cardNumber}
          />
          <ModalAddPaymentMethodCodeExpirationContainer>
            <BasicInput 
              title={"Vencimiento"}
              placeholder="MM/YYYY"
              id="expiration"
              value={props.formData.new_card_form.expiration}
              onChange={props.handleNewCardFormChange}
            />
            <BasicInput 
              title={"Código de Tarjeta"} 
              placeholder="CVV"
              id="code"
              value={props.formData.new_card_form.code}
              onChange={props.handleNewCardFormChange}

            />
          </ModalAddPaymentMethodCodeExpirationContainer>
          <BasicCheckbox
            id="default"
            label="Establecer como predeterminada"
            onChange={props.handleNewCardSetDefaultChange}
            checked={props.formData.new_card_form.default}
          />
        </div>
      }
    </PaymentDetailsSectionWrapper>
  )
}

export default CardPaymentDetailsForm;
