import {
  POS_SELECT_CLIENT,
  POS_SET_CLIENT,
  POS_SET_CLIENT_NETWORK,
  POS_UNSELECT_CLIENT,
  POS_UPDATE_CLIENT,
  POS_CREATE_CLIENT
} from '../../actions/types';

import reducerHandler from 'reducers/reducerHandler';

const initialState = {
  data: [],
  network: [],
  selectedClientId: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case POS_SET_CLIENT.loading:
    case POS_SET_CLIENT.success:
    case POS_SET_CLIENT.error:
      return reducerHandler(state, action, POS_SET_CLIENT);
    case POS_SET_CLIENT_NETWORK:
      return {
        ...state,
        network: action.payload,
      };
    case POS_SELECT_CLIENT:
      return {
        ...state,
        selectedClientId: action.payload,
      };
    case POS_UNSELECT_CLIENT:
      return {
        ...state,
        isLoading: false,
        loaded: false,
        selectedClientId: null,
      };
    default:
      return state;
  }
}
