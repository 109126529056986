import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { MainWrapper, Page } from 'components/Body';
import { BasicCheckbox, BasicInput } from 'components/Form';
import { Text } from 'components/Texts';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import Swal from 'sweetalert2';
import { ChangePasswordModal } from 'components/Modal';
import { updateUserRequest, deleteUserRequest, logoutUserRequest } from 'actions/userActions';

const SettingsPage = (props) => {
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        telephone: '',
        birthdate: '',
    });

    const [passForm, setPassForm] = useState({
        password: '',
        password_confirmation: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false);

    const [fields, setFields] = useState({
        first_name: {
            label: 'Nombre',
            type: 'text',
            placeholder: 'Nombre',
        },
        last_name: {
            label: 'Apellido',
            type: 'text',
            placeholder: 'Apellido',
        },
        email: {
            label: 'Correo',
            type: 'email',
            placeholder: 'Correo',
        },
        telephone: {
            label: 'Teléfono',
            type: 'phone',
            placeholder: 'Teléfono',
            maxLength: 10,
            isPhoneNumber: true,
        },
        birthdate: {
            label: 'Fecha de nacimiento',
            type: 'date',
            placeholder: 'Fecha de nacimiento',
        },
    });

    const [passFields, setPassFields] = useState({
        password: {
            label: 'Contraseña',
            type: 'password',
            placeholder: '',
        },
        password_confirmation: {
            label: 'Confirmar Contraseña',
            type: 'password',
            placeholder: '',
            hide: true,
        },
    });
    const [edit, setEdit] = useState(false);
    const [changePassword, setChangePassword] = useState(false);

    useEffect(() => {
        clearFields();
    }, []);

    function handleInputChange(e) {
        let newValue = e.target.value;
        if (e.target.name === 'telephone') {
            var regex = /[!"#$%&/]/g;

            newValue = e.target.value.replace(/[a-zA-Z]/g, '').replace(regex, '');

            if (newValue.length > 10) {
                newValue = newValue.slice(0, 10);
            }

            const onlyNumbers = /^\d*$/;
            const disabled = newValue.length < 10 && !onlyNumbers.test(newValue);
            if (disabled) {
                setIsDisabledSaveButton(disabled);
                return;
            }
        }

        setForm({
            ...form,
            [e.target.name]: newValue,
        });
    }

    function handleInputPassChange(e) {
        setPassForm({
            ...passForm,
            [e.target.name]: e.target.value,
        });
    }

    function handleClear() {
        clearFields();
        setChangePassword(false);
        setEdit(false);
    }

    function clearFields() {
        let newForm = { ...form };
        Object.keys(form).forEach((key) => {
            let val = props.user.data.attributes[key];
            if (val) {
                newForm[key] = val;
            }
        });
        setForm(newForm);
        setPassForm({ password: '', password_confirmation: '' });
    }

    function handleEditingMode() {
        if (edit) {
            let userForm = { ...form };
            if (changePassword) userForm = { ...userForm, ...passForm };
            props.updateUserRequest(userForm);
            clearFields();
        } else {
            setEdit(!edit);
        }
    }

    function handleDelete() {
        Swal.fire({
            show: true,
            title: '¿Deseas borrar tu cuenta permanentemente?',
            text: 'Al confirmar ésta operación, se eliminará toda tu informacion y no será posible recuperarla.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrar',
            confirmButtonColor: '#379824',
            cancelButtonText: 'Regresar',
        }).then((result) => {
            if (result.isConfirmed) {
                props.deleteUserRequest(props.user.data.id).then((res) => {
                    if (res) {
                        props.logoutUserRequest();
                    }
                });
            }
        });
    }

    function handleChangePassword() {
        if (edit) setChangePassword(!changePassword);
    }

    return (
        <Page title="Configuración de la cuenta">
            <MainWrapper
                title="Perfil"
                headerRight={
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            sx={{
                                color: 'rgb(138, 153, 181)',
                                border: '1px solid rgb(138, 153, 181)',
                            }}
                            startIcon={edit ? <SaveIcon /> : <EditIcon />}
                            onClick={handleEditingMode}
                            disabled={isDisabledSaveButton}
                        >
                            {edit ? 'Guardar' : 'Editar'}
                        </Button>

                        {edit && (
                            <Button variant="outlined" color="error" style={{ marginLeft: '10px' }} startIcon={<ClearIcon />} onClick={() => handleClear()}>
                                Cancelar
                            </Button>
                        )}
                    </div>
                }
            >
                {Object.entries(form).map(([key, value]) => {
                    return (
                        <div className="flex py-2 px-1 flex-row">
                            <Text className="p-3 basis-1/4 font-semibold">{!fields[key].hide && fields[key].label}</Text>
                            <div className="basis-3/4 h-4">{edit && (!fields[key].disabled || !fields[key].hide) ? <BasicInput label={fields[key].label} name={key} type={fields[key].type} placeholder={fields[key].placeholder} value={value} onChange={handleInputChange} /> : <Text className="p-3 text-white">{value}</Text>}</div>
                        </div>
                    );
                })}
                <div className="flex flex-row justify-between pt-2">
                    <Text className="p-3 basis-1/4 font-semibold">¿Cambiar contraseña?</Text>
                    <BasicCheckbox checked={changePassword} disabled={!edit} onChange={handleChangePassword}></BasicCheckbox>
                </div>
                {Object.entries(passForm).map(([key, value]) => {
                    return edit && changePassword ? (
                        <div className="flex py-2 px-1 flex-row">
                            <Text className="p-3 basis-1/4 font-semibold">{!passFields[key].hide && passFields[key].label}</Text>
                            <div className="basis-3/4 h-4">
                                <BasicInput label={passFields[key].label} name={key} type={passFields[key].type} placeholder={passFields[key].placeholder} value={value} onChange={handleInputPassChange} showPassword={passFields[key].type === 'password' ? showPassword : showConfirmPassword} onClickShowPassword={() => (passFields[key].type === 'password' ? setShowPassword(!showPassword) : setShowConfirmPassword(!showConfirmPassword))} />
                            </div>
                        </div>
                    ) : (
                        <></>
                    );
                })}
                <div className="flex flex-row justify-between pt-5">
                    <div>
                        <Text className="text-red-500">¿Deseas borrar tu cuenta?</Text>
                        <Text className="text-white">Esta acción es irreversible y se perderán los datos de tu cuenta</Text>
                    </div>
                    <div>
                        <Button variant="outlined" color="error" startIcon={<DeleteOutlineIcon />} onClick={() => handleDelete()}>
                            Borrar Cuenta
                        </Button>
                    </div>
                </div>
            </MainWrapper>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    updateUserRequest,
    deleteUserRequest,
    logoutUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
