import { 
  SHOW_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT,
  UPDATE_STORE_CREDIT,
} from '../actions/types';

const initialState = {
  bank_accounts: [],
}

export default function(state = initialState, action) {
  switch(action.type) {
    case SHOW_BANK_ACCOUNT:
      return {...state, bank_accounts: [...state.bank_accounts, action.payload]}
    case UPDATE_BANK_ACCOUNT:
      return {
        ...state,
        bank_accounts: updatedBankAccountsObj(state, action.payload)
      }
    case UPDATE_STORE_CREDIT: {
      let bankAccount = state.bank_accounts.find(bacc => bacc.user_id === action.payload.user_id)
      if (!bankAccount) { return state }
      let updatedBankAcc = updateBankAccountAmounts(bankAccount, action.payload)
      return {
        ...state,
        bank_accounts: updatedBankAccountsObj(state, updatedBankAcc)
      }
    }
    default:
      return state;
  }
}

function getPayloadCredit(action) {
  return action.payload.credit[0].credit;
}

function updateBankAccountAmounts(bankAccount, data) {
  return {
    ...bankAccount,
    [data.type+"_store_credit_amount"]: parseFloat(bankAccount[data.type+"_store_credit_amount"]) + parseFloat(data.used_amount),
        [data.type+"_store_credit_remaining"]: parseFloat(bankAccount[data.type+"_store_credit_remaining"]) + parseFloat(data.used_amount), 
      }
}

function updatedBankAccountsObj (state, newObj ) {
  return [
    ...state.bank_accounts.filter(match => match.id !== newObj.id),
    newObj
  ]
}
