function Panel({children}) {
  return (
      <div className="basis-7/12 rounded-xl flex flex-col flex-1 m-2 p-2"
        style={{backgroundColor: "hsl(217, 32%, 28%)"}}
      >
        {children}
      </div>
  )
}

export default Panel;
