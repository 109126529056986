import Styled from 'styled-components';

export const DistributorCreditContainer = Styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-flow: row wrap;
`;

export const CreditDataContainer = Styled.div`
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 18px;
  font-weight: 700;
`;

export const CreditDataMetrics = Styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  padding-bottom: 40px;
`;

export const CreditDataBar = Styled.div`
  width: 100%;
  margin: auto 10%;
`;

export const MetricHeader = Styled.div`

`;

export const CreditActions = Styled.div`
  width: 80%;
  float: right;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  gap: 10px;
`;

export const CreditOptionsContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding: 0 4px;
  flex-grow: 1;
  width: 100%;
`;

export const TwoFieldsContainer = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  div {
    flex-basis: 45%;
    min-width: 200px;
  }
`
