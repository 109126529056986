import React, { useEffect, useState } from 'react';
import {
  faHome,
 } from '@fortawesome/free-solid-svg-icons'
import {APP_TITLE} from '../../../actions/types';
import {connect} from 'react-redux';
import {Sidebar} from '../../../components/Sidebar';
import CandidateMenu from './candidate';
import DistributorMenu from './distributor';
import DistributorInactiveMenu from './distributor_inactive';
import AdminMenu from './admin';
import CashierMenu from './cashier';


const SideBar = (props) => {
  let defaultMenuItemProps = {
    text: "Inicio",
    icon: faHome,
  }

  return (
    <>
      { props.user.isLoggedIn && props.isOpen &&
        <Sidebar title={APP_TITLE}>
            <>
              {props.user.data.attributes.role === "candidate" && <CandidateMenu {...defaultMenuItemProps}/>}
              {props.user.data.attributes.role === "distributor" && <DistributorMenu {...defaultMenuItemProps}/>}
              {props.user.data.attributes.role === "distributor_inactive" && <DistributorInactiveMenu {...defaultMenuItemProps}/>}
              {props.user.data.attributes.role === "admin" && <AdminMenu {...defaultMenuItemProps}/>}
              {props.user.data.attributes.role === "cashier" && <CashierMenu {...defaultMenuItemProps}/>}
            </>
        </Sidebar>
      }
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  isOpen: state.leftSidebarReducer.isOpen,
})

export default connect(mapStateToProps, {})(SideBar);
