export function validateLaterThanToday(strDate) {
  if(!strDate){
    return false;
  }

  let date = new Date(strDate);
  let today = new Date();

  if (today > date) {
    return false
  }

  return true;
}

export function isValidDate(strDate) {
  let date = new Date(strDate);
  return date instanceof Date && !isNaN(date);
}

export function isEarlierThan(strDate, latestDate) {
  let latest = new Date(latestDate);
  let date = new Date(strDate);

  if (!isValidDate(strDate)) {
    return false;
  }

  return date < latest;
}
