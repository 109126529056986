import Styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Modal, Button} from 'react-bootstrap';

export const ViewCandidateInfoButtonIcon = Styled(FontAwesomeIcon)`
  cursor: pointer;
  margin: 5px;
  width: 100%;
  &:hover {
    color: ${props => props.theme.text.color.formTitle};
  }
`;

export const ModalFooter = Styled(Modal.Footer)`
  background-color: ${props => props.theme.bg.modal};
  color: ${props => props.theme.text.color.panel};
  border-top: 1px solid ${props => props.theme.color.modalBorder};
  display: flex;
  justify-content: space-between;
`;

export const ActionButtonContainer = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AcceptButton = Styled(Button)`
  background-color: ${props => props.theme.bg.alertSuccess};
  border-color: ${props => props.theme.bg.success};
  color: ${props => props.theme.text.color.alertSuccess};

  &:hover {
    background-color: ${props => props.theme.bg.success};
    border-color: ${props => props.theme.bg.success};
    color: ${props => props.theme.text.color.success};
  }

  &.active {
    background-color: ${props => props.theme.bg.alertSuccess};
    border-color: ${props => props.theme.bg.success};
    color: ${props => props.theme.text.color.alertSuccess};
  }
`;

export const RejectButton = Styled(Button)`
  margin-right: 10px;
  background-color: ${props => props.theme.bg.alertDanger};
  border-color: ${props => props.theme.bg.danger};
  color: ${props => props.theme.text.color.alertDanger};

  &:hover {
    background-color: ${props => props.theme.bg.danger};
    border-color: ${props => props.theme.bg.danger};
    color: ${props => props.theme.text.color.success};
  }

  &.active {
    background-color: ${props => props.theme.bg.alertSuccess};
    border-color: ${props => props.theme.bg.success};
    color: ${props => props.theme.text.color.alertSuccess};
  }
`;

export const CloseButton = Styled(Button)`
  background-color: ${props => props.theme.bg.alertSecondary};
  border-color: ${props => props.theme.color.alertSecondaryBorder};
  color: ${props => props.theme.text.color.alertSecondary};
  &:hover {
    background-color: ${props => props.theme.bg.cancel};
    border-color: ${props => props.theme.bg.cancel};
    opacity: 0.9;
  }
`;

export const EditButton = Styled.button`
  background-color: ${props => props.theme.bg.buttonDefault};
  color: ${props => props.theme.text.color.label};
  border: ${props => props.theme.form.color.inputBorder};
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 15px;
  margin-bottom: 13px;
  width: 70px;
  height: 40px;
  align-self: flex-end;
  border-box: box-sizing;

  &:hover {
    background-color: ${props => props.theme.bg.buttonDefaultHover};
  }
`;

export const SummaryActionContainer = Styled.div`
  display: flex;
`;

export const SummaryInputContainer = Styled.div`
  flex-grow: 2;
`;

export const ImageFile = Styled.div`
  background-image: url("${props => props.url}");
  background-size: 100%;
  height: 85%;
  border: 1px solid black;
  border-radius: 10px;
  background-repeat: no-repeat;
`;

export const ImageContainer = Styled.div`
  width: 50%;
  height: 280px;
`;

export const ImageTitle = Styled.div`

`;

export const ImageName = Styled.div`
  width: 100%;
  text-align: center;
`;

export const RegisterDocumentAttribute = Styled.div`
  display: flex;
  padding: 10px 0px;
  border-bottom: 1px solid rgba(255,255,255,0.12);
  cursor: pointer;
`;

export const SearchFieldContainer = Styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  div {
    width: 35%;
    max-width: 440px;
  }
  @media (max-width: 768px) {
    div {
      width: 100%;
    }
  }
`;

export const MembershipSummaryActionContainer = Styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;

`;

export const MembershipDataWrapper = Styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryActionHeader = Styled.div`
  font-size: 20px;
  color: #bdd1f8;
  font-weight: 500;
  margin-bottom: 15px;
`;

export const SummaryActionItem = Styled.div`

`;

export const SummaryActionItemTitle = Styled.div`
  font-weight: 400;
  color: ${props => props.theme.text.color.light};
  font-size: 14px;
  display: block;
`;
