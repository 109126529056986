import React, {useState, useEffect} from 'react';
import { DefaultModal } from '../../../components/Modal';
import {ModalFooter, AcceptButton, RejectButton, ActionButtonContainer, CloseButton} from './style'
import { Tab, TabGroup } from '../../../components/Navs';
import RegistrationInformationTab from './registrationInformationTab';
import SendMessageTab from './sendMessageTab';
import SummaryTab from './summaryTab';
import ModalCandidateAcceptance from './modalCandidateAcceptance';
import ModalCandidateRejection from './modalCandidateRejection';
import Swal from 'sweetalert2';

const ModalCandidateView = (props) => {
  const [selectedTab, setSelectedTab] = useState("summary");
  const [isAccepting, setIsAccepting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [credit, setCredit] = useState();
  const [candidate, setCandidate] = useState()

  useEffect(() => {
    if (props.selectedCandidateId) {
      setCandidate(getCandidateById(props.selectedCandidateId))
    }
  }, [props.selectedCandidateId])

  const getCandidateById = (candidate_id) => {
    if (!candidate_id) {
      return null;
    }
    return props.candidates?.find(cand =>  cand.id === candidate_id)
  }

  const TabTitles = {
    "summary": "Datos de cuenta",
    "register_documents": "Documentos de Registro",
    "send_message": "Enviar mensaje",
  }

  const getFullName = () => {
    if (!candidate) {
      return ""
    } else {
      return candidate.first_name + " " + candidate.last_name;
    }
  }

  const handleCandidateRejection = () => {
    setIsRejecting(true);
  }

  const handleCandidateAcceptance = () => {
    setIsAccepting(true);
  }
  const handleCreditAmountChange = (e) => {
    setCredit(e.target.value)
  }

  const onCandidateAccept = () => {
    props.setIsLoading(true);
    return props.acceptCandidate({user: candidate, credit})
    .then((res) => {
      if (res.role === "distributor") {
        props.setIsLoading(false);
        Swal.fire(
          'Aceptado!',
          'El candidato ha sido aceptado exitosamente.',
          'success'
        )
        setIsAccepting(false);
        props.toggleModal();
      }
    })
  }

  const onCandidateReject = (data) => {
    return props.rejectCandidate(data)
    .then((res) => {
      if (res.role === "rejected_candidate") {
        //props.createFlashMessageRequest({
          //closeTime: 4000,
          //variant: "warning",
          //message: `El registro del candidato ${props.candidate.first_name} ${props.candidate.last_name} ha sido rechazado.`
        //})
        Swal.fire(
          'Rechazado!',
          `El registro del candidato ${candidate.first_name} ${candidate.last_name} ha sido rechazado.`,
          'info'
        )
        setIsRejecting(false);
        props.toggleModal();
      }
      return res;
    })
  }

  useEffect(() => {
    if(credit) {
      setCredit();
    }
  }, [isAccepting])

  const customFooter = () => {
    return (
      <ModalFooter>
        <CloseButton
          onClick={props.toggleModal}
        >Cerrar</CloseButton>
        <ActionButtonContainer>
          <RejectButton onClick={handleCandidateRejection}>Rechazar Registro</RejectButton>
          <AcceptButton onClick={handleCandidateAcceptance}>Aceptar Candidato</AcceptButton>
        </ActionButtonContainer>
      </ModalFooter>
    )
  }

  return (
    <>
      {(!isAccepting && !isRejecting) && !!candidate && (
          <DefaultModal
            show={props.show}
            onHide={props.toggleModal}
            onSave={() => {}}
            title={getFullName()}
            customFooter={customFooter}
          >
            <TabGroup>
              {Object.entries(TabTitles).map(([id, title]) => (
                <Tab
                  id={id}
                  onClick={setSelectedTab}
                  active={selectedTab}
                >{title}</Tab>
              ))}
            </TabGroup>

            {selectedTab === "summary" &&
              <SummaryTab
                selectedCandidateId={props.selectedCandidateId}
              />
            }
            {selectedTab === "register_documents" &&
              <RegistrationInformationTab
                candidate={candidate}
                registerDocument={props.registerDocument}
              />
            }
            {selectedTab === "send_message" &&
              <SendMessageTab 
                candidate={candidate}
                onSendMessage={props.onSendMessage}
              />
            }
          </DefaultModal>
        )
      }      
      {isAccepting && 
        <ModalCandidateAcceptance
          candidate={candidate}
          onChange={handleCreditAmountChange}
          onSave={onCandidateAccept}
          value={credit}
          show={isAccepting}
          toggleModal={() => setIsAccepting(!isAccepting)}
        />
      }
      {isRejecting && 
        <ModalCandidateRejection 
          candidate={candidate}
          show={isRejecting}
          rejectCandidate={onCandidateReject}
          toggleModal={() => setIsRejecting(!isRejecting)}
        />
      }
    </>
  )
}

export default ModalCandidateView;
