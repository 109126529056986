import React from "react";
import { Title } from 'components/Texts';
import {ChangesList} from './style';
import TotalItem from './total_item';

const MonthlyTotals = (props) => {
  return (
    <div>
      <Title>
        Totales del mes 
      </Title>

      <ChangesList>
        <TotalItem title="Distribuidores">32</TotalItem>
        <TotalItem title="Prendas">429</TotalItem>
        <TotalItem title="Pedidos">109</TotalItem>
      </ChangesList>
      <ChangesList>
        <TotalItem title="Distribuidores">32</TotalItem>
        <TotalItem title="Prendas">429</TotalItem>
        <TotalItem title="Pedidos">109</TotalItem>
      </ChangesList>
    </div>
  )
}

export default MonthlyTotals;
