import React, { useState, useEffect } from 'react';
import { DefaultModal } from '../../../components/Modal';
import ClientSearchForm from './clientSearchForm';
import ClientSearchResults from './clientSearchResults';

const initValues = {
    first_name: '',
    last_name: '',
    email: '',
    telephone: '',
};

const ClientSearchModal = ({ onClose, onHide, show, onSave, onSelect, selectedClientId, searchResults, isLoading, selectAllClient = false, ...props }) => {
    const [data, setData] = useState(initValues);

    const PAGE_DATA = {
        search: {
            title: 'Buscar',
            saveButtonText: 'Buscar',
            onSave: () => onSave(data),
            onClose: () => handleCloseHide('close'),
            closeButtonText: null,
        },
        results: {
            title: 'Resultados',
            saveButtonText: 'Seleccionar',
            onSave: null,
            closeButtonText: 'Regresar',
            onClose: () => props.setPage('search'),
        },
    };

    function handleChange(e) {
        setData({
            ...data,
            [e.target.id]: e.target.value,
        });
    }

    const handleCloseHide = (flag) => {
        setData(initValues);

        if (flag === 'hide') {
            onHide();
        } else {
            onClose();
        }
    };

    const handleSelect = (client) => {
        setData(initValues);
        props.setPage('search');

        onSelect(client);
    };

    return (
        <DefaultModal show={show} onHide={() => handleCloseHide('hide')} title={PAGE_DATA[props.page].title} onSave={PAGE_DATA[props.page].onSave} onClose={PAGE_DATA[props.page].onClose} saveButtonText={PAGE_DATA[props.page].saveButtonText} closeButtonText={PAGE_DATA[props.page].closeButtonText} isLoading={isLoading}>
            {props.page === 'search' && <ClientSearchForm data={data} onChange={handleChange} />}
            {props.page === 'results' && <ClientSearchResults results={searchResults} selected={selectedClientId} onSelect={handleSelect} selectAllClient={selectAllClient} />}
        </DefaultModal>
    );
};

export default ClientSearchModal;
