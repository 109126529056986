import axios from 'axios';

import { API_BASE_URL, GET_CUBIT_ORDERS, GET_CUBIT_LINE_ITEMS, GET_CUBIT_ITEMS_CLIENTS } from './types';

export const getReportsOrders = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/cubit_orders/all_cubit_orders',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params,
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_CUBIT_ORDERS,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getReportsLineItems = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/cubit_line_items/all_cubit_line_items',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params,
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_CUBIT_LINE_ITEMS,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getReportsItemsClient = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/cubit_items_clients/all_cubit_items_client',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params,
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_CUBIT_ITEMS_CLIENTS,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};
