import { POS_GET_PAYMENT_METHODS, POS_ADD_PAYMENT, POS_CLEAR_PAYMENTS, POS_REMOVE_PAYMENT, POS_SET_PAYMENTMETHODSELECTED, POS_SET_PAYMENTCREDITMETHODSELECTED } from 'actions/types';

import reducerHandler from '../reducerHandler';

const initialState = {
    paymentMethods: [],
    data: [],
    paymentMethodSelected: null,
    paymentCreditMethodSelected: null,
    isLoading: false,
    loaded: false,
    error: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POS_ADD_PAYMENT.loading:
        case POS_ADD_PAYMENT.success:
        case POS_ADD_PAYMENT.error:
            action.replace = true;
            return reducerHandler(state, action, POS_ADD_PAYMENT);
        case POS_REMOVE_PAYMENT.loading:
        case POS_REMOVE_PAYMENT.success:
        case POS_REMOVE_PAYMENT.error:
            action.replace = true;
            return reducerHandler(state, action, POS_REMOVE_PAYMENT);
        case POS_GET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.payload,
            };
        case POS_CLEAR_PAYMENTS:
            return initialState;
        case POS_SET_PAYMENTMETHODSELECTED:
            return {
                ...state,
                paymentMethodSelected: action.payload,
            };
        case POS_SET_PAYMENTCREDITMETHODSELECTED:
            return {
                ...state,
                paymentCreditMethodSelected: action.payload,
            };
        default:
            return state;
    }
}
