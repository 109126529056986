import { INDEX_DISTRIBUTOR_SOURCES, CREATE_DISTRIBUTOR_SOURCE, DELETE_DISTRIBUTOR_SOURCE, UPDATE_DEFAULT_CREDIT_CARD, CLEAR_CREDIT_CARDS } from '../actions/types';

const initialState = [];

export default function (state = initialState, action) {
    switch (action.type) {
        case INDEX_DISTRIBUTOR_SOURCES:
            return action.payload.data;
        case CREATE_DISTRIBUTOR_SOURCE:
            return [...updateCreditCards(state, action.payload)];
        case DELETE_DISTRIBUTOR_SOURCE:
            return [...state.filter((card) => card.id !== action.payload.id)];
        case UPDATE_DEFAULT_CREDIT_CARD:
            return [...updateCreditCards(state, action.payload)];
        case UPDATE_DEFAULT_CREDIT_CARD:
            return [...updateCreditCards(state, action.payload)];
        case CLEAR_CREDIT_CARDS:
            return [];
        default:
            return state;
    }
}

const updateCreditCards = (credit_cards, payload) => {
    if (payload.attributes.default) {
        credit_cards = updateCreditCardsToSecondary(credit_cards);
    }

    return credit_cards
        .filter((card) => card.id !== payload.id)
        .concat(payload)
        .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
};

const updateCreditCardsToSecondary = (credit_cards) => {
    return credit_cards.map((card) => {
        return {
            ...card,
            attributes: {
                ...card.attributes,
                default: false,
            },
        };
    });
};
