import {faCheck, faExclamationTriangle, faTimes, faClock, faShoppingCart} from '@fortawesome/free-solid-svg-icons';

export const dateFormat = (strDate) => {
  if (!strDate) {
    return null;
  }

  let date = new Date(strDate);
  let formattedDate = date.toLocaleDateString('es-MX')

  return formattedDate;
}

export const timeFormat = (strDate, twelveHrFormat=false) => {
  if (!strDate) {
    return null;
  }

  let date = new Date(strDate);
  let hour = date.getHours();
  let timeSegment = '';

  if (twelveHrFormat) {
    timeSegment = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;
  }

  return `${hour}:${date.getMinutes()} ${timeSegment}`;
}

export const setupTableDataRows = (data) => {
  if (!data.length) {
    return [];
  }

  return data.map((row) => { return {id: row.id, ...(row.attributes || row)}})
}


export const setupMUIDataGridColumns = (rows, HEADERS, IGNORE_HEADERS=[]) => {
    if (!rows.length) {
      return [];
    }

    let dataCols = rows[0].attributes ? Object.keys(rows[0].attributes) : Object.keys(rows[0])
    let sortedCols = []

    //sorts by order in which keys appear in the TABLE_HEADERS constant
    Object.keys(HEADERS)
      .forEach((id, i) => {
        if ((dataCols.includes(id) && !Object.values(IGNORE_HEADERS).includes(id)) || HEADERS[id].isCustom) {
          sortedCols.push(id)
        }
      })

    let formatCols = sortedCols.map((id) => {
      if (HEADERS[id].hidden) {
        return null;
      }

      return HEADERS[id];
    }).filter(Boolean)

    return formatCols;
  }


export const variantStates = {
  pending: {
    icon: faExclamationTriangle,
    text: "Pendiente",
    actionText: "posponer",
    variant: "pending",
  },
  complete: {
    icon: faCheck,
    text: "Completado",
    actionText: "completar",
    variant: "success",
  },
  completed: {
    icon: faCheck,
    text: "Completado",
    actionText: "completar",
    variant: "success",
  },
  paid: {
    icon: faCheck,
    text: "Pagado",
    actionText: "pagar",
    variant: "success",
  },
  approved: {
    icon: faCheck,
    text: "Aprobada",
    actionText: "aprobar",
    variant: "success",
  },
  balance_due: {
    icon: faExclamationTriangle,
    text: "Saldo debido",
    variant: "pending",
  },
  credit_owed: {
    icon: faExclamationTriangle,
    text: "Crédito debido",
    variant: "pending",
  },
  scheduled: {
    icon: faClock,
    text: "Programado",
    actionText: "programar",
    variant: "secondary",
  },
  cart: {
    icon: faShoppingCart,
    text: "En Carrito",
    variant: "secondary",
  },
  checkout: {
    icon: faShoppingCart,
    text: "En proceso...",
    variant: "secondary",
  },
  processing: {
    icon: faClock,
    text: "En proceso...",
    variant: "secondary",
  },
  processing: {
    icon: faTimes,
    text: "Cancelada",
    actionText: "cancelar",
    variant: "secondary",
  },
  delayed: {
    icon: faTimes,
    variant: 'danger',
    text: 'Retrasado',
    actionText: "retrasar",
  },
  failed: {
    icon: faTimes,
    text: "Fallido",
    actionText: "fallar",
    variant: "danger"
  },
  rejected: {
    icon: faTimes,
    text: "Rechazada",
    actionText: "rechazar",
    variant: "danger"
  },
  default: {
    icon: faClock,
    text: "Sin Información",
    actionText: "Sin Información",
    variant: "secondary"
  }
}


