import React from 'react';
import {
  RankCardContainer,
  RankCardLockIcon,
  RankCardHeader,
  RankCardHeaderContent,
  RankCardBody,
  RankCardBodyWrapper,
  RankCardList,
  RankCardListItem,
  RankCardListBullet,
  RankCardBodyTitle,
} from './style';

import { faCheck, faLockKeyhole } from '@fortawesome/free-solid-svg-icons';

const RankCard = (props) => {
  const level_complete = props.userRank.attributes && props.userRank.attributes.current_level.attributes.level >= props.rankLevel?.attributes.level;
  return (
    <RankCardContainer>
      <RankCardHeader color={props.color}>
        <RankCardHeaderContent>
          {props.name}
        </RankCardHeaderContent>
        <RankCardLockIcon style={{color: "yellow"}}>
          {!level_complete && "lock_outlined"}
        </RankCardLockIcon>
      </RankCardHeader>
      <RankCardBodyWrapper>
        <RankCardBody>
          <RankCardBodyTitle color={props.color}>Requerimientos</RankCardBodyTitle>
          <RankCardList>
            { !!props.requirements && props.requirements.map(req => {
              return (
                <RankCardListItem>
                  <RankCardListBullet icon={faCheck} color={props.color}/>
                  <b>{req.name}</b>: {req.description}
                </RankCardListItem>
              )
            })}
            { !props.requirements &&
                <RankCardListItem>
                  No hay ningun requerimiento.
                </RankCardListItem>
            }
          </RankCardList>
          <RankCardBodyTitle color={props.color}>Beneficios</RankCardBodyTitle>
          <RankCardList>
            { !!props.benefits && props.benefits.map(benefit => {
              return (
                <RankCardListItem>
                  <RankCardListBullet icon={faCheck} color={props.color}/>
                  {benefit.description}
                </RankCardListItem>
              )
            }) }
          </RankCardList>

          <RankCardBodyTitle color={props.color}>Premios Especiales</RankCardBodyTitle>
          <RankCardList>
            { !!props.prices && props.prices.map((price) => {
              return (
                <RankCardListItem>
                  <RankCardListBullet icon={faCheck} color={props.color}/>
                  <b>{price.name}</b>: {price.description}
                </RankCardListItem>
              )
            }) }
          </RankCardList>
        </RankCardBody>
      </RankCardBodyWrapper>
    </RankCardContainer>
  )
}

export default RankCard;
