import React from 'react';
import { TabPage, TabContentSection, ChatItem, LoadMore } from '../../../components/RightSidebar';

const ChatPage = (props) => {
  return (
    <TabPage title="Chat">
      { props.chats.map((obj)=>{
        let group = Object.keys(obj)[0];
        return (
          <TabContentSection title={group}>
            {obj[group].map(chat => {
              let userId = Object.keys(chat)[0];
              return <ChatItem chatInfo={chat[userId]} toggleConversationOpen={props.toggleConversationOpen}/>
            })}
          </TabContentSection>
        )
      })}
      <LoadMore/>
    </TabPage>
  )
}

export default ChatPage;