import React from 'react';
import { DefaultModal } from '../../../components/Modal';
import { BasicInput, Title } from '../../../components/Form';

export const ModalCandidateAcceptance = (props) => {
  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      onSave={props.onSave}
      saveButtonText={"Confirmar"}
      title={`Confirmación de ${props.candidate.first_name} ${props.candidate.last_name}`}
    >
      <Title>Crédito de distribuidor</Title>
      <BasicInput
        title="Escribe la candidad a entregar de crédito inicial."
        placeholder={"E.g. 50000"}
        name="credit"
        value={props.value}
        onChange={props.onChange}
      />

    </DefaultModal>
  )
}

export default ModalCandidateAcceptance;