import React, {useState, useEffect} from 'react';
import {BasicInput} from '../../../components/Form';

const ClientSearchForm = ({data, onChange, ...props}) => {
  return (
    <>
      <div className='flex flex-row w-full flex-wrap space-between'>
        <div className='sm:pr-2 w-full sm:w-1/2'>
          <BasicInput
            id={"first_name"}
            label={"Nombre"}
            showLabel={true}
            value={data.first_name}
            onChange={onChange}
            placeholder={"Ej. Juan"}
          />
        </div>
        <div className='w-full sm:pl-2 sm:w-1/2'>
          <BasicInput
            id={"last_name"}
            label={"Apellido"}
            showLabel={true}
            value={data.last_name}
            onChange={onChange}
            placeholder={"Ej. Perez"}
          />
        </div>
      </div>
      <div className='flex flex-row w-full flex-wrap space-between'>
        <div className='sm:pr-2 w-full sm:w-1/2'>
          <BasicInput
            id={"email"}
            label={"Correo electrónico"}
            showLabel={true}
            value={data.email}
            onChange={onChange}
            placeholder={"Ej. usuario@hotmail.com"}
          />
        </div>
        <div className='w-full sm:pl-2 sm:w-1/2'>
          <BasicInput
            id={"telephone"}
            label={"Teléfono"}
            showLabel={true}
            value={data.telephone}
            onChange={onChange}
            placeholder={"Ej. 781-123-4567"}
          />
        </div>
      </div>
    </>
  )
}

export default ClientSearchForm
