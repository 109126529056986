import React, { Component } from 'react';
import styled from 'styled-components';
import {StyledNav} from './style';

const Title = styled.h1`
  color: ${props => props.theme.text.color.default};
`;

class DefaultHeader extends Component {
  render() {
    return (
      <StyledNav>
        {this.props.children}
      </StyledNav>
    );
  }
}

export default DefaultHeader;