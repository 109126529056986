import React, { useState, useEffect } from 'react';
import { UsersContainer, RankStatusHeader, RankStatusVerticalLine, RankStatusTitle, RankStatusDescription, RankStatusTitleContainer, RankStatusHeaderTitle, BodyContainer, SectionTitle } from './style';
import { RankCard } from 'components/Organizers';
import { PagedCarousel } from 'components/Carousel';
import { ActionSpinner, CircularProgressSpinner } from 'components/Spinners';

const ProfileViewer = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [nextLevelReqs, setNextLevelReqs] = useState([]);

    useEffect(() => {
        if (props.userRank.attributes) {
            setIsLoading(false);
            setNextLevelReqs(props.userRank.attributes.next_level?.attributes.requirements);
        }
    }, [props.userRank]);

    return (
        <UsersContainer>
            <RankStatusHeader>
                {props.userRank.attributes ? (
                    <>
                        <RankStatusVerticalLine color={props.RANKINFO[props.userRank.attributes?.state].color} />
                        <RankStatusTitle color={props.RANKINFO[props.userRank.attributes.state].color}> Estás en rango {props.RANKINFO[props.userRank.attributes?.state].name} </RankStatusTitle>
                        <RankStatusDescription>Siguiente nivel: {props.RANKINFO[props.userRank.attributes.next_level?.attributes.name].name}</RankStatusDescription>
                    </>
                ) : (
                    <CircularProgressSpinner />
                )}
            </RankStatusHeader>

            <BodyContainer>
                <RankStatusHeader>
                    {props.userRank.attributes ? (
                        <>
                            <RankStatusTitleContainer>
                                <RankStatusHeaderTitle color={props.RANKINFO[props.userRank.attributes?.state].color}>
                                    <RankStatusTitle color={'white'}>Tu red</RankStatusTitle>
                                </RankStatusHeaderTitle>
                                <RankStatusDescription>Lideres: {props.userRank.attributes.leader_amount}</RankStatusDescription>
                                <RankStatusDescription>Emprendedores: {props.userRank.attributes.entrepreneur_amount}</RankStatusDescription>
                            </RankStatusTitleContainer>
                        </>
                    ) : (
                        <CircularProgressSpinner />
                    )}
                </RankStatusHeader>

                <RankStatusHeader>
                    {props.userRank.attributes ? (
                        <>
                            <RankStatusTitleContainer>
                                <RankStatusHeaderTitle color={props.RANKINFO[props.userRank.attributes.next_level?.attributes.name].color}>
                                    <RankStatusTitle color={'white'}>Siguiente nivel: {props.RANKINFO[props.userRank.attributes.next_level?.attributes.name].name}</RankStatusTitle>
                                </RankStatusHeaderTitle>
                                <RankStatusDescription>Lideres: {nextLevelReqs.find((e) => e.category == 'leader')?.quantity}</RankStatusDescription>
                                <RankStatusDescription>Emprendedores: {nextLevelReqs.find((e) => e.category == 'entrepreneur')?.quantity}</RankStatusDescription>
                            </RankStatusTitleContainer>
                        </>
                    ) : (
                        <CircularProgressSpinner />
                    )}
                </RankStatusHeader>
            </BodyContainer>

            <div>
                <SectionTitle> Tus beneficios </SectionTitle>
                {!!props.rank_levels.length && !!props.userRank.attributes ? (
                    <PagedCarousel itemWidth={417}>
                        {props.rank_levels.map((rank_level) => {
                            let rank_info = props.RANKINFO[rank_level.attributes.name];
                            return <RankCard name={rank_info.name} color={rank_info.color} rankLevel={rank_level} requirements={rank_info.requirements} benefits={rank_info.benefits} prices={rank_info.prices} userRank={props.userRank} />;
                        })}
                    </PagedCarousel>
                ) : (
                    <CircularProgressSpinner />
                )}
            </div>
        </UsersContainer>
    );
};
export default ProfileViewer;
