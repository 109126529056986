import React, { useState, useEffect } from 'react';
import { completeCheckout, setStoreDefaultShippingAddress, nextCheckout } from 'actions/pos/cartActions';
import { unselectCart, advanceCheckout, AddCredit, updateCart, updatePaymentPlan } from 'actions/pos/cartActions';
import { unselectVariant } from 'actions/pos/variantActions';
import { createSimplePayment } from 'actions/pos/paymentActions';
import { clearPayments } from 'actions/pos/paymentActions';
import { connect } from 'react-redux';
import { DefaultModal } from 'components/Modal';
import { CircularProgressSpinner } from 'components/Spinners';
import { DoneAll } from '@material-ui/icons';
import { unselectClient } from 'actions/pos/clientActions';

const ConfirmPurchaseModal = ({ cart, payments, PAYMENT_METHODS, paymentMethodSelected, paymentCreditMethodSelected, createSimplePayment, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    useEffect(() => {
        if (cart.data && cart.data.attributes.state === 'complete') {
            setShowThankYou(true);
        }

        if (!cart.loaded && !cart.isLoading) {
            setShowThankYou(false);
            setIsLoading(false);
        }
    }, [cart.data]);

    async function onSave() {
        if (cart.isLoading) return;

        if (showThankYou) {
            props.unselectCart();
            props.unselectVariant();
            props.clearPayments();
            props.unselectClient();
            props.onClose();
            return;
        }

        let addCashCardMethod = (!paymentCreditMethodSelected && paymentMethodSelected) || (paymentCreditMethodSelected?.plan?.upfront_percentage !== '' && paymentCreditMethodSelected?.plan?.upfront_percentage !== '0.0');

        let currentCart = cart.data;
        setIsLoading(true);

        await props.nextCheckout().then((r) => (currentCart = r));

        await props.setStoreDefaultShippingAddress();

        await props.nextCheckout().then((r) => (currentCart = r));

        if (paymentCreditMethodSelected) {
            let toPay = currentCart.attributes.item_total;
            if (paymentCreditMethodSelected?.plan?.upfront_percentage) {
                toPay = currentCart.attributes.item_total - currentCart.attributes.item_total * parseFloat(paymentCreditMethodSelected?.plan?.upfront_percentage);
            }
            await props
                .AddCredit({
                    credit_type: paymentCreditMethodSelected.type,
                    amount: toPay,
                })
                .then(async (r) => {
                    currentCart = r;
                    if (paymentCreditMethodSelected.type !== 'store') {
                        await props.updateCart({
                            amount: currentCart?.attributes?.total,
                            payment_plan_id: paymentCreditMethodSelected.plan.id,
                            credit_type: paymentCreditMethodSelected.type,
                        });

                        //patch plan
                        await props.updatePaymentPlan({
                            payment_plan_id: paymentCreditMethodSelected.plan.id,
                        });
                    }
                });
        }

        if (paymentMethodSelected) {
            const { id } = paymentMethodSelected.type;
            let toPay = currentCart.attributes.item_total;

            if (addCashCardMethod && (paymentCreditMethodSelected?.type === 'client' || paymentCreditMethodSelected?.notEnoughtCredit)) {
                if (paymentCreditMethodSelected?.plan?.upfront_percentage && paymentCreditMethodSelected?.plan?.upfront_percentage !== '0.0') {
                    toPay = currentCart.attributes.item_total * parseFloat(paymentCreditMethodSelected.plan.upfront_percentage);
                }
            }

            if (paymentMethodSelected?.type && paymentMethodSelected?.cardId == null && toPay > 0) {
                await createSimplePayment({
                    payment_method_id: id,
                    amount: toPay,
                });
            } else {
                await createSimplePayment({
                    payment_method_id: id,
                    source_id: paymentMethodSelected.cardId,
                    amount: toPay,
                });
            }
        }

        await props.completeCheckout();

        setIsLoading(false);
    }

    function renderProcessingPurchase() {
        return (
            <div className="flex flex-column justify-center">
                <CircularProgressSpinner />
                <div className="flex text-xl font-bold mb-3 items-center justify-center mt-4">Procesando compra...</div>
            </div>
        );
    }

    function renderThankYou() {
        return (
            <div className="flex flex-column justify-center items-center">
                <div className="w-16 h-16">
                    <div className="bg-green-500 flex flex-1 h-full w-full bg-green border-2 border-white rounded-full justify-center items-center">
                        <DoneAll sx={{ color: 'white' }} fontSize="large" />
                    </div>
                </div>
                <div className="flex text-xl font-bold mb-1 items-center justify-center mt-4">Gracias por tu compra!</div>
                <div className="flex text-lg mb-2 items-center justify-center mb-4">Tu pedido se ha procesado correctamente.</div>
                <div className="flex flex-column text-lg items-center justify-center mb-2">
                    Tu numero de pedido es:
                    <div className="font-bold text-xl">{cart.data && cart.data.attributes.number}</div>
                </div>
                <div>Recibirás un correo electrónico con los detalles de tu compra.</div>
            </div>
        );
    }

    function renderConfirmationMessage() {
        return (
            <div>
                <div className="text-xl font-bold mb-3">Estas seguro de realizar la compra?</div>
                <div>Al confirmar, no podras modificar el pedido y se procesará el cobro de la compra.</div>
            </div>
        );
    }

    return (
        <DefaultModal show={props.show} title="Confirmar Compra" onClose={props.onClose} onHide={props.onClose} onSave={onSave} disabledSaveButton={isLoading} saveButtonText={showThankYou ? 'Continuar' : 'Confirmar'}>
            <div className="flex flex-col text-gray-100 py-4 px-6">{showThankYou ? renderThankYou() : isLoading ? renderProcessingPurchase() : renderConfirmationMessage()}</div>
        </DefaultModal>
    );
};

const mapStateToProps = (state) => ({
    client: state.posClient,
    cart: state.posCart,
    payments: state.posPayment,
    paymentPlans: state.paymentPlan,
});

const mapDispatchToProps = {
    completeCheckout,
    setStoreDefaultShippingAddress,
    createSimplePayment,
    unselectCart,
    unselectVariant,
    clearPayments,
    AddCredit,
    advanceCheckout,
    updateCart,
    updatePaymentPlan,
    nextCheckout,
    unselectClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPurchaseModal);
