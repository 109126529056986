/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { ActionButton, PrintActionButton, OrderListerActionButton } from './components';
import { ShoppingCartCheckoutOutlined, Clear, Settings, Archive, Search, DeleteOutline, ContentCopy } from '@mui/icons-material';
import Skeleton from '@mui/material/Skeleton';
import { connect } from 'react-redux';
import ConfirmPurchaseModal from './modals/ConfirmPurchaseModal';
import { updateAutofocus } from 'actions/pos/cartActions';
import { formatDate } from 'utils/formatters';

function ActionsPanel({ cart, paymentMethodSelected, paymentCreditMethodSelected, ...props }) {
    const [isLoading, setIsLoading] = useState(false);
    const [showConfirmPurchaseModal, setShowConfirmPurchaseModal] = useState(false);
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [showOrderListerModal, setShowOrderListerModal] = useState(false);
    const [disableBuyButton, setDisableButButton] = useState(true);

    useEffect(() => {
        if (showConfirmPurchaseModal && cart.autoFocus) {
            props.updateAutofocus(false);
        } else {
            props.updateAutofocus(true);
        }
    }, [showConfirmPurchaseModal]);

    useEffect(() => {
        if (cart.isLoading) {
            setIsLoading(true);
        } else if (cart.data !== null) {
            setIsLoading(false);
        }
    }, [cart]);

    useEffect(() => {
        if (paymentCreditMethodSelected && paymentCreditMethodSelected?.type) {
            if (paymentCreditMethodSelected?.type === 'store') {
                setDisableButButton(!paymentMethodSelected && paymentCreditMethodSelected.notEnoughtCredit);
            } else {
                if (paymentCreditMethodSelected.plan?.upfront_percentage === '0.0') {
                    setDisableButButton(false);
                } else if (paymentCreditMethodSelected?.type === 'payroll') {
                    setDisableButButton(false);
                } else {
                    setDisableButButton(!paymentMethodSelected && paymentCreditMethodSelected?.type);
                }
            }
        } else {
            setDisableButButton(!(cart?.data?.total > 0) && !paymentMethodSelected);
        }
    }, [paymentMethodSelected, paymentCreditMethodSelected]);

    function handleCloseConfirmPurchaseModal() {
        setShowConfirmPurchaseModal(false);

        if (cart.data && cart.data.attributes.state === 'complete') {
            setShowPrintModal(true);
        }
    }

    return (
        <div className="w-48 flex flex-column items-end mr-3 ml-1">
            <div className="self-stretch flex flex-col flex-1 h-16 p-2">
                {isLoading ? (
                    <div className="h-4 w-28 mb-2">
                        <Skeleton
                            sx={{
                                backgroundColor: 'rgb(70, 90, 124)',
                            }}
                            variant="rectangular"
                            width="100%"
                            height="100%"
                        />
                    </div>
                ) : (
                    <div className="font-bold text-white">{cart.loaded ? `Pedido #${cart?.data?.attributes?.number}` : 'No se ha seleccionado un pedido'}</div>
                )}
                {!!cart.data && cart.data.attributes.state === 'complete' && cart.data?.attributes?.completed_at && (
                    <div
                        style={{
                            border: '2px solid white',
                            boxShadow: '0 0 3px 2px #48abe0',
                        }}
                        className="mt-2 w-full rounded-lg bg-blue-700 p-3 border-2 border-blue-300"
                    >
                        <div className="text-white font-bold">Este pedido ha sido completado el {formatDate(cart.data?.attributes?.completed_at)}</div>
                    </div>
                )}
            </div>
            <div className="self-stretch flex flex-row flex-wrap m-2">
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <OrderListerActionButton show={showOrderListerModal} setShow={setShowOrderListerModal} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <ActionButton icon={<Search fontSize="large" />} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <PrintActionButton show={showPrintModal} setShow={setShowPrintModal} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <ActionButton icon={<Settings fontSize="large" />} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <ActionButton icon={<ContentCopy fontSize="large" />} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <ActionButton color="bg-red-500" icon={<Clear sx={{ color: 'white' }} fontSize="large" />} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <ActionButton icon={<Archive fontSize="large" />} />
                </div>
                <div className="p-1 flex justify-center items-center basis-1/3 aspect-square">
                    <ActionButton icon={<DeleteOutline fontSize="large" />} />
                </div>
            </div>
            <div className="self-stretch flex h-16 m-2 w-full">
                <ActionButton icon={<ShoppingCartCheckoutOutlined fontSize="large" />} onClick={() => setShowConfirmPurchaseModal(true)} disabled={disableBuyButton}>
                    COMPRAR
                </ActionButton>
            </div>
            <ConfirmPurchaseModal show={showConfirmPurchaseModal} onClose={handleCloseConfirmPurchaseModal} paymentMethodSelected={paymentMethodSelected} paymentCreditMethodSelected={paymentCreditMethodSelected} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.posCart,
        paymentMethodSelected: state.posPayment.paymentMethodSelected,
        paymentCreditMethodSelected: state.posPayment.paymentCreditMethodSelected,
    };
};

const mapDispatchToProps = {
    updateAutofocus,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsPanel);
