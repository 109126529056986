import React from 'react';
import {TabGroupContainer} from './style';

const TabGroup = (props) => {
  return (
    <TabGroupContainer
      variant="tabs" 
      defaultActiveKey={props.defaultActiveKey}
      id={"uncontrolled-tab-example"}
      hideLine={props.hideLine}
      hoverColor={props.hoverColor}
      style={props.style}
    >
      {props.children}
    </TabGroupContainer>
  )
}

export default TabGroup;
