import React, { useState, useEffect, useRef } from 'react';
import { AddCircleOutlined } from '@mui/icons-material';

export default function FilterButton({ name, children, onClose = () => {}, onRemove = () => {}, onConfirm = () => {}, disabled = false, resultText }) {
    const [isOpen, setIsOpen] = useState(false);
    const popupRef = useRef < HTMLDivElement > null;

    useEffect(() => {
        const handleClickOutside = (event) => {
            let isMui = event.target?.classList[0]?.indexOf('Mui') >= 0;
            if (!isMui && popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false);
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [popupRef]);

    function handleOnConfirm() {
        setIsOpen(false);
        onConfirm();
    }

    function getButtonStyle() {
        if (disabled) {
            return 'w-full bg-gray-600 text-gray-300 font-bold py-2 px-4 rounded-lg cursor-not-allowed';
        }
        return 'w-full bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg';
    }

    function handleCirclePress() {
        if (!!resultText?.length) {
            onRemove();
        } else {
            setIsOpen(true);
        }
    }

    return (
        <div className="mr-2">
            <div className="flex flex-row border-2 border-dashed border-gray-600 py-1 px-2 rounded-full hover:bg-gray-800 select-none cursor-pointer">
                <div className="mr-2" onClick={handleCirclePress}>
                    <AddCircleOutlined
                        sx={{
                            transform: `rotate(${!!resultText?.length ? 45 : 0}deg)`,
                            '&:hover': {
                                color: 'white',
                            },
                        }}
                    />
                </div>
                <div onClick={() => setIsOpen(!isOpen)} className="flex flex-row">
                    <div className="font-semibold text-base">{name}</div>
                    {!!resultText?.length && <div className="border-l-2 ml-1 pl-1 border-gray-500 font-semibold text-indigo-400 text-base">{resultText}</div>}
                </div>
            </div>
            {isOpen && (
                <div ref={popupRef} className="absolute z-20 w-72 h-fit bg-gray-800 mt-2 drop-shadow-lg rounded-lg shadow-xl border-2 border-gray-600">
                    <div className="absolute bg-gray-800 w-10 aspect-square rotate-45 ml-3 -mt-1 border-t-2 border-l-2 border-gray-600"></div>
                    <div className="relative w-full h-full bg-gray-800 rounded-lg p-2">
                        <div className="flex flex-1 flex-col h-full">
                            <div className="font-bold text-white">Filtrar por {name}</div>
                            <div className="flex flex-col flex-1 mt-2">{children}</div>
                            <div className="mt-2 w-full">
                                <button disabled={disabled} onClick={handleOnConfirm} className={getButtonStyle()}>
                                    Aplicar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
