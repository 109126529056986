import Styled, {css} from 'styled-components';
import {Nav} from 'react-bootstrap';
import {mobileScreenSize} from 'utils/screen';

export const TabsContainer = Styled(Nav)`
  border-bottom-color: rgba(255, 255, 255, 0.12);
`;

export const TabItemContainer = Styled(Nav.Item)`
  border-color: rgba(255, 255, 255, 0.12);
  border-top-left: 1px solid rgba(255, 255, 255, 0.12);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #222e4c;
  &:active {
    background-color: #222e4c;
  }

  @media (max-width: ${mobileScreenSize}px) {
    border-right: 2px solid #36415c;
    border-bottom: 1px solid #36415c;
  }

`;

export const TabLinkContainer = Styled(Nav.Link)`
  color: white;
  font-size: 20px;
  background-color: #192231;
  
  &:hover {
    color: white;
    font-weight: 500;
  }

  ${props => props.active && ActiveTab};

  @media (max-width: ${mobileScreenSize}px) {
    padding: 10px 6px;
    font-size: 14px;
  }

`;

const ActiveTab = css`
  background-color: #5893df !important;
  color: white !important;
  font-weight: 500;
  border-color: 1px solid rgba(255,255,255,0.12);
`;
