import React from 'react';

const PaypalPaymentDetailsForm = (props) => {
  return (
    <div>
      Pago utilizando paypal
    </div>
  )
}

export default PaypalPaymentDetailsForm;