import React from 'react';

const Text = ({children, style, className=""}) => {
  return (
    <div
      className={"flex items-center" + " " + className}
      style={style}
    >
      { children }
    </div>
  )
}

export default Text;
