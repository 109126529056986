import React from 'react';
import Moment from 'react-moment';
import {TabPageWrapper,
  TabPageHeader,
  ClientInfoTabFieldTitle,
  ClientInfoTabFieldValue,
  ClientInfoRowContainer,
  ClientInfoTabFieldContainer} from '../style';

const ClientInfoTab = (props) => {
  let dateLocale = new Date(props.client.created_at).toLocaleString().split(",");
  let dateArray = dateLocale && dateLocale[0].split("/");
  let birthdateArray = props.client.birthdate && props.client.birthdate.split('-');
  return (
    <TabPageWrapper>
      <TabPageHeader>Información del cliente</TabPageHeader>
      <div style={{display: 'flex', flexDirection: 'column'}}>
        <ClientInfoRowContainer>
          <ClientInfoTabFieldContainer>
            <ClientInfoTabFieldTitle>Nombre</ClientInfoTabFieldTitle>
            <ClientInfoTabFieldValue>{props.client.first_name +" "+ props.client.last_name}</ClientInfoTabFieldValue>
          </ClientInfoTabFieldContainer>
          <ClientInfoTabFieldContainer>
            <ClientInfoTabFieldTitle>Correo Electrónico</ClientInfoTabFieldTitle>
            <ClientInfoTabFieldValue>{props.client.email}</ClientInfoTabFieldValue>
          </ClientInfoTabFieldContainer>
        </ClientInfoRowContainer>
        <ClientInfoRowContainer>
          <ClientInfoTabFieldContainer>
            <ClientInfoTabFieldTitle>Fecha de registro</ClientInfoTabFieldTitle>
            <ClientInfoTabFieldValue>
              <Moment 
                date={props.client.created_at} 
                fromNow 
                locale="es-mx"
              />{" - "}
              {dateArray[1]+"/"+dateArray[0]+"/"+dateArray[2]}
            </ClientInfoTabFieldValue>
          </ClientInfoTabFieldContainer>
          <ClientInfoTabFieldContainer>
          <ClientInfoTabFieldTitle>Edad</ClientInfoTabFieldTitle>
            <ClientInfoTabFieldValue>
              {birthdateArray ? (
                <>
                  <Moment
                    date={props.client.birthdate} 
                    fromNow
                    ago
                    locale="es-mx"
                  />{" - "}
                  {birthdateArray[2]+"/"+birthdateArray[1]+"/"+birthdateArray[0]}
                </>
              ) : (
                "No especificado"
              )}
            </ClientInfoTabFieldValue>
          </ClientInfoTabFieldContainer>
        </ClientInfoRowContainer>
        <ClientInfoRowContainer>
          <ClientInfoTabFieldContainer>
            <ClientInfoTabFieldTitle>Género</ClientInfoTabFieldTitle>
            <ClientInfoTabFieldValue>{props.client.gender || "No especificado"}</ClientInfoTabFieldValue>
          </ClientInfoTabFieldContainer>
          <ClientInfoTabFieldContainer>
            <ClientInfoTabFieldTitle>Teléfono</ClientInfoTabFieldTitle>
            <ClientInfoTabFieldValue>{props.client.telephone || "No especificado"}</ClientInfoTabFieldValue>
          </ClientInfoTabFieldContainer>
        </ClientInfoRowContainer>
      </div>
    </TabPageWrapper>
  )
}

export default ClientInfoTab;
