import React, {useState} from 'react';
import {BasicInput} from '../../../components/Form'
import {SubmitButton} from '../../../components/Button'
import { AuthHeader} from '../style';
import ActionLinks from '../action_links';

const ForgotPage = (props) => {
  const [email, setEmail] = useState("");
  const onSubmitButtonClick = (email) => {
    props.handlePasswordReset(email)
    setEmail("");
  }
  return (
    <div>
      <AuthHeader>¿Olvidaste tu contraseña?</AuthHeader>
      <BasicInput
        title="Correo Electrónico"
        onChange={(e) => setEmail(e.target.value)}
        name="email"
        value={email}
        style={{float: 'left'}}
        placeholder="usuario@dominio.com"
      />
      <SubmitButton
        onClick={() => onSubmitButtonClick(email)}
      >
        Restablecer Contraseña
      </SubmitButton>
      <ActionLinks
        setUsePage={props.setUsePage}
        usePage={props.usePage}
      />
    </div>
  )
}

export default ForgotPage;
