import {
  ADD_PAYMENT_PLAN,
  ADD_PAYMENT_PLANS_LIST,
  DELETE_PAYMENT_PLAN
} from 'actions/types';

const initialState = {
  list: {},
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ADD_PAYMENT_PLANS_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...action.payload
        }
      }
    case ADD_PAYMENT_PLAN:
      return {
        ...state,
        list: {
          ...state.list,
          [action.payload.user_id]: [ 
            ...state.list[action.payload.user_id],
            action.payload.payment_plan
          ]
        }
      }
    case DELETE_PAYMENT_PLAN:
      const {payment_plan_id, user_id} = action.payload;
      return {
        ...state,
        list: {
          ...state.list,
          [user_id]: state.list[user_id]
            .filter(plan => plan.id !== parseInt(payment_plan_id)),
        }
      }
    default:
      return state;
  }
}
