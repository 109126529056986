import axios from 'axios';
import { API_BASE_URL, ADMIN_SPREE_TOKEN, INDEX_DISTRIBUTOR_CLIENT, INDEX_DISTRIBUTOR_NETWORK, INDEX_DISTRIBUTOR_MONEY, INDEX_DISTRIBUTOR_ORDER, INDEX_DISTRIBUTOR_ADDRESSES, CREATE_FLASH_MESSAGE, CREATE_DISTRIBUTOR_CLIENT, CREATE_DISTRIBUTOR_NETWORK, CREATE_DISTRIBUTOR_PAYMENT, CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT, CREATE_DISTRIBUTOR_ADDRESS, UPDATE_DISTRIBUTOR_PAYMENT } from './types';

import { dispatchAction } from '../utils/actions/common';

export const distributorCreateNewAddress = (address) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return (dispatch) => {
        return createAccountAddress(address, token).then((res) => {
            dispatchAction(dispatch, res, CREATE_DISTRIBUTOR_ADDRESS);
            return res;
        });
    };
};

const createAccountAddress = (address, token) => {
    return axios({
        method: 'post',
        url: API_BASE_URL + '/api/v2/storefront/account/addresses.js',
        headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
        },
        data: { address },
    });
};

export const distributorCreateNewOrderPayment = (payment) => {
    return (dispatch) => {
        return axios({
            method: 'post',
            url: API_BASE_URL + `/api/v1/orders/${payment.order}/payments`,
            headers: {
                'X-Spree-Token': ADMIN_SPREE_TOKEN,
            },
            data: {
                payment: {
                    payment_method_id: 1, //fixed to StripeGateway
                    source_type: payment.source_type,
                    source_id: payment.source_id, //Stored Card's id
                    amount: payment.amount,
                },
            },
        })
            .then((res) => {
                if (res.status !== 201) {
                    dispatch({
                        type: CREATE_FLASH_MESSAGE,
                        payload: {
                            closeTime: 1500,
                            variant: 'warning',
                            message: 'Se ha producido un error y no se ha creado el pago.',
                        },
                    });
                }
                dispatchAction(dispatch, res, CREATE_DISTRIBUTOR_PAYMENT);
                return res;
            })
            .then((res) => {
                let payment_num = res.data.number;
                return axios({
                    method: 'PUT',
                    url: API_BASE_URL + `/api/v1/orders/${payment.order}/payments/${payment_num}/purchase`,
                    headers: {
                        'X-Spree-Token': ADMIN_SPREE_TOKEN,
                    },
                });
            })
            .then((res) => {
                if (res.status !== 200) {
                    dispatch({
                        type: CREATE_FLASH_MESSAGE,
                        payload: {
                            closeTime: 1500,
                            variant: 'warning',
                            message: 'No se ha podido cobrar el pago a la tarjeta seleccionada.',
                        },
                    });
                }
                dispatch({
                    type: CREATE_FLASH_MESSAGE,
                    payload: {
                        closeTime: 1500,
                        variant: 'success',
                        message: `Se ha efectuado exitosamente un pago por la cantidad de ${res.data.display_amount}.`,
                    },
                });
                dispatchAction(dispatch, res, UPDATE_DISTRIBUTOR_PAYMENT);
            });
    };
};

export const distributorListAdresses = () => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'get',
            url: API_BASE_URL + '/api/v2/storefront/account/addresses.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        }).then((res) => {
            if (res.status !== 200) {
                return res;
            }
            let addresses = res.data.data;
            return dispatch({
                type: INDEX_DISTRIBUTOR_ADDRESSES,
                payload: addresses,
            });
        });
    };
};

export const distributorNetworkCreate = (data) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/users/distributor/networks/create.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user: data },
            json: true,
        }).then((res) => {
            if (res.data.status === 400) {
                return res.data;
            }
            let distributor = {
                data: {
                    distributor: JSON.parse(res.data.distributor) || [],
                    status: res.status,
                },
            };
            return dispatchAction(dispatch, distributor, CREATE_DISTRIBUTOR_NETWORK);
        });
    };
};

export const distributorNetworkIndex = () => {
    return async (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        const res = await axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/users/distributor/networks.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        });
        let distributors = { data: { distributors: res.data.data || [] } };
        return dispatchAction(dispatch, distributors, INDEX_DISTRIBUTOR_NETWORK);
    };
};

export const createDistributorClient = (data, params) => {
    return async (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/clients.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: params,
            data: { user: data },
            json: true,
        })
            .then((res) => {
                let data = { data: { user: JSON.parse(res.data.user) || [] } };
                dispatchAction(dispatch, data, CREATE_DISTRIBUTOR_CLIENT);
                return res;
            })
            .catch((e) => {
                console.log(e);
                return e.response;
            });
    };
};

export const indexDistributorClient = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/clients',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
            params: params,
        })
            .then((res) => {
                return dispatch({ type: INDEX_DISTRIBUTOR_CLIENT, payload: { data: { clients: res.data.data || [] }, meta: res.data.meta } });
            })
            .catch((e) => {
                return e.response;
            });
    };
};

export const indexDistributorMoney = () => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/users/distributor/money.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        }).then((res) => {
            let clients = {
                data: {
                    comissions: JSON.parse(res.data.comissions) || [],
                    scheduledPayments: JSON.parse(res.data.scheduled_payments) || [],
                },
            };
            return dispatchAction(dispatch, clients, INDEX_DISTRIBUTOR_MONEY);
        });
    };
};

export const indexDistributorClientOrders = () => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/users/distributor/orders.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        }).then((res) => {
            let clients = { data: { orders: JSON.parse(res.data.orders) || [] } };
            return dispatchAction(dispatch, clients, INDEX_DISTRIBUTOR_ORDER);
        });
    };
};

export const distributorTransferClientStoreCredit = (client, amount) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/clients/add_store_credit_to_client.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { client, amount },
            json: true,
        }).then((res) => {
            if (res.data.status === 400) {
                return res;
            }
            let credit = {
                data: {
                    credit: res.data.receiver_credit || [],
                    used_amount: parseInt(res.data.sender_credit.reduce((prev, curr) => prev + curr.used_amount, 0)),
                },
            };
            return dispatchAction(dispatch, credit, CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT);
        });
    };
};

export const distributorWithdrawClientStoreCredit = (client, amount) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'POST',
            url: API_BASE_URL + '/es/app/api/v1/clients/retireve_store_credit_from_client.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { client, amount },
            json: true,
        }).then((res) => {
            if (res.data.status === 400) {
                return res;
            }
            let credit = {
                data: {
                    credit: res.data.sender_credit || [],
                    used_amount: parseInt(res.data.receiver_credit.reduce((prev, curr) => prev + curr.used_amount, 0)),
                },
            };
            return dispatchAction(dispatch, credit, CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT);
        });
    };
};
