import React, {useState, useEffect} from 'react';
import { faTrashAlt, faTimes, faPenAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import {connect} from 'react-redux';
import Swal from 'sweetalert2';

import {SubscriptionActionBtn} from './style';

import {moneyFormatter} from 'utils/formatters';
import { SweetAlert } from 'components/Alert';
import {DataTable, DataTbody, DataThead, Datum, Row, ColoredDatum} from 'components/Table';
import { Title, BasicInputLabel} from 'components/Form';

import AddMembershipModal from './addMembershipModal';
import {
  paySubscription,
  fetchMembershipByUserID,
} from '../../../../actions/subscriptionActions'

import {
  createCreditCard,
  ListCreditCards,
} from '../../../../actions/creditCardActions'

const SubscriptionPage = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [membershipFetched, setMembershipFetched] = useState(false);
  const [membershipData, setMembershipData] = useState({state: {}});
  const [creditCardList, setCreditCardList] = useState([]);
  const [sweetAlertData, setSweetAlertData] = useState({});

  useEffect(() => {
    //process starts
    setMembershipFetched(false);
    setIsLoading(true);
    props.fetchMembershipByUserID(props.selectedDistributor[0])
      .then((res) => {
        if (res.data.membership === "null") {
          setIsLoading(false);
          Swal.fire(
            'Error',
            "La membresía del Distribuidor seleccionado no existe.",
            'error'
          )
        }
      })
  }, [props.selectedDistributor]);

  useEffect(() => {
    // response received
    if (props.subscription.memberships.length) {
      let selectedDistributorMembership = props.subscription.memberships.find(memb => memb.user_id === props.selectedDistributor[0])
      setMembershipData({...selectedDistributorMembership, state: {}})
    }
  }, [props.subscription])

  useEffect(() => {
    // data is set, membership selected
    if(membershipData.id) {
      setMembershipFetched(true);
    }
  }, [membershipData])

  useEffect(() => {
    // Add state data to membership
    if(membershipFetched) {
      let state = {}
      switch(membershipData.state) {
        case "active":
          state = {
            color: "success",
            text: "Membresia Activa"
          }
          break;
        case "inactive":
          state = {
            color: "danger",
            text: "Membresia Inactiva"
          }
          break;
        default:
          state = {
            color: "Secondary",
            text: "Indefinido"
          }
          break;
      }
      setMembershipData({
        ...membershipData,
        state
      })
      setIsLoading(false);
    }
  }, [membershipFetched])

  useEffect(() => {
    if (isModalOpen && props.selectedDistributor) {
      setCreditCardList([]);
      props.ListCreditCards(props.selectedDistributor[0])
      .then(res => {
        if (res.data.length > 0) {
          setCreditCardList(res.data);
        }
      })
    }
  }, [isModalOpen])

  useEffect(() => {
    setSweetAlertData({
      config: {
        title: "Cargando...",
        text: "Por favor espera unos momentos...",
      },
      show: isLoading,
      isLoading: true
    })
  }, [isLoading])


  const handleMembershipCreate = async (subscription_obj) => {
    let new_card = {};
    if (subscription_obj.payment_method === "card" && subscription_obj.selected_card_id === 0) {
      new_card = await props.createCreditCard({
        credit_card: subscription_obj.new_card_form,
        user_id: props.data.id
      });
      let formatted_new_card = {id: new_card.id, ...new_card.attributes}
      setCreditCardList([...creditCardList, formatted_new_card]);
    }
    let subscription = await props.paySubscription({
      user_id: props.selectedDistributor[0],
      subscription_data: subscription_obj
    });

    if (subscription.status === 200) {
      Swal.fire(
        'Subscripcion activada exitosamente!',
        subscription.data?.message,
        'success'
      )
    }

    if (subscription.status >= 400){
      Swal.fire(
        'Error',
        subscription.data?.message ||  "Error al crear la subscripcion.",
        'error'
      )
    }
    // setIsModalOpen(false);
  }

  return (
    <div>
      <SweetAlert {...sweetAlertData} />
      {!isLoading &&
        <div>
          <Title>Estado de membresia</Title>
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginBottom: "10px", paddingBottom: "10px"}}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", width: "30%", paddingBottom: "20px"}}>
              <BasicInputLabel>Estado Actual</BasicInputLabel>
              <ColoredDatum variant={membershipData.state?.color}>{membershipData.state?.text}</ColoredDatum>
            </div>
            <div style={{display: "flex", justifyContent: "space-around", alignItems: "center", width: "30%"}}>
              <BasicInputLabel>Membresias compradas</BasicInputLabel>
              <div style={{fontSize: "18px"}}>{membershipData.subscriptions?.length}</div>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={() => setIsModalOpen(true)}
              >Agregar membresia </Button>
            </div>
          </div>
          <DataTable>
            <DataThead>
              <Row>
                <Datum>Paquete</Datum>
                <Datum>Precio</Datum>
                <Datum>Fecha de Compra</Datum>
                <Datum>Inicio</Datum>
                <Datum>Expira</Datum>
                <Datum>Acciones</Datum>
              </Row>
            </DataThead>
            <DataTbody style={{height: "200px"}}>
              {membershipData.subscriptions?.filter(sub => sub.state === "active")
              .map(subs => {
                return (
                  <Row id={subs.id}>
                    <Datum>Paquete</Datum>
                    <Datum>{moneyFormatter(subs.payment_amount)}</Datum>
                    <Datum>{subs.payment_date}</Datum>
                    <Datum>{subs.start_date}</Datum>
                    <Datum>{subs.expiration_date}</Datum>
                    <Datum>
                      <SubscriptionActionBtn icon={faTrashAlt}/>
                      <SubscriptionActionBtn icon={faPencilAlt}/>
                    </Datum>
                  </Row>
                )
              })}
            </DataTbody>
          </DataTable>
        <Title>Membresías compradas</Title>
        <DataTable>
            <DataThead>
              <Row>
                <Datum>Paquete</Datum>
                <Datum>Precio</Datum>
                <Datum>Fecha de Compra</Datum>
                <Datum>Inicio</Datum>
                <Datum>Expira</Datum>
                <Datum>Acciones</Datum>
              </Row>
            </DataThead>
            <DataTbody>
              {membershipData.subscriptions?.map(subs => {
                return (
                  <Row id={subs.id}>
                    <Datum>Paquete</Datum>
                    <Datum>{moneyFormatter(subs.payment_amount)}</Datum>
                    <Datum>{subs.payment_date}</Datum>
                    <Datum>{subs.start_date}</Datum>
                    <Datum>{subs.expiration_date}</Datum>
                    <Datum>
                      <SubscriptionActionBtn icon={faTrashAlt}/>
                      <SubscriptionActionBtn icon={faPencilAlt}/>
                    </Datum>
                  </Row>
                )
              })}
            </DataTbody>
          </DataTable>
          <AddMembershipModal
            show={isModalOpen}
            toggleModal={() => setIsModalOpen(!isModalOpen)}
            data={props.data}
            subscription={props.subscription}
            onSave={handleMembershipCreate}
            creditCardList={creditCardList}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state) => ({
  membership: state.subscription.membership,
  subscription: state.subscription,
})

const mapDispatchToProps = {
  paySubscription,
  createCreditCard,
  ListCreditCards,
  fetchMembershipByUserID,
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
