import React, {useState} from "react";
import TextField from '@mui/material/TextField';
import { alpha, styled } from '@mui/material/styles';

const RedditTextField = styled((props) => (
  <TextField
    InputProps={{ disableUnderline: true }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    fontSize: 18,
    fontWeight: 500,
    borderRadius: 4,
    color: "white",
    backgroundColor: 'transparent',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&.Mui-focused': {
      backgroundColor: "rgb(49, 66, 94)",
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
    '& label.Mui-focused': {
      color: "blue",
    },
  },
}));


const MuiTextField = ({
  value,
  onChange,
  label,
  name="",
  type,
  onFocus=() => {},
}) => {
  return (
    <RedditTextField
      label={label}
      value={value}
      type={type}
      id="reddit-input"
      name={name}
      variant="filled"
      onChange={onChange}
      style={{ 
        width: "100%",
      }}
      InputLabelProps={{
        style: { color: '#fff' },
      }}
      onFocus={onFocus}
    />
  )
}

export default MuiTextField;
