import React, {useState} from 'react';
import {BasicInput, BasicTextArea, MoneyInput} from '../../../Form';
import {ColoredDatum, Datum} from '../../../Table';

import {
  ModalTabContainer,
  ModalDataColumn,
  ModalDataRow,
} from '../style';

import ColumnDatum from './ColumnDatum';

import {dateFormat} from '../../../../utils/common';

const GeneralTab = (props) => {
  const [data, setData] = useState(props.order);

  const handleInputChange = () => {

  }
  return (
    <ModalTabContainer>
      <ModalDataRow>
        <ColumnDatum
          value={data.attributes.number}
          title='Numero de orden'
        />
        { data.attributes.user &&
          <ColumnDatum
            value={`${data.attributes.user.first_name} ${data.attributes.user.last_name}`}
            title='Nombre del cliente'
          />
        }
        <ColumnDatum
          value={dateFormat(data.attributes.completed_at) || "No se ha completado"}
          title='Fecha de compra (DD/MM/AAAA)'
        />
      </ModalDataRow>

      <ModalDataRow>
        <ColumnDatum
          value={data.attributes.item_count}
          title='productos vendidos'
        />

        <ColumnDatum
          value={data.attributes.display_item_total + " " + data.attributes.currency}
          title='Total de venta'
        />

        <ColumnDatum
          value={data.distributor_discount_code}
          status={!data.distributor_discount_code && "default"}
          title='código de distribuidor'
        />
      </ModalDataRow>

      <ModalDataRow>
        <ColumnDatum
          status={data.attributes.state || "default"}
          title='Estado del Pedido'
        />

        <ColumnDatum
          status={data.attributes.shipment_state || "default"}
          title='Estado del Envio'
        />

        <ColumnDatum
          status={data.attributes.payment_state || "default"}
          title='Estado de Pago'
        />
      
      </ModalDataRow>

      {!!data.canceler_id &&
        <ModalDataRow>
          <ColumnDatum
            value={data.canceler_id}
            status={data.canceler_id || "default"}
            title='Cancelado por'
          />

           <ColumnDatum
            value={data.canceled_at}
            status={data.canceled_at || "default"}
            title='Fecha de cancelación'
          />
        </ModalDataRow>
      }

    <div style={{paddingTop: "20px"}}>
      <BasicInput
        id={"email"}
        value={data.attributes.email}
        onChange={handleInputChange}
        title={"correo electrónico"}
      />
 
      <BasicTextArea
        id={"special_instructions"}
        value={data.attributes.special_instructions || "Sin Especificar"}
        onChange={handleInputChange}
        title={"Instrucciones Especiales"}
      />
    </div>
    </ModalTabContainer>
  )
}

export default GeneralTab
