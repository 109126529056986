import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import SummaryTabField from './summaryTabField';
import SummaryTabMembership from './summaryTabMembership';
import {
  adminUpdateCandidate
} from '../../../actions/adminActions';
import {
  SummaryActionHeader,
} from './style';

const SummaryTab = (props) => {
  const formFields = [
    {id: "email", title: "Correo Electrónico"},
    {id: "first_name", title: "Nombre"},
    {id: "last_name", title: "Apellidos"},
    {id: "gender", title: "Género"},
    {id: "telephone", title: "Teléfono"},
    {id: "birthdate", title: "Fecha de nacimiento", options: {type: "date"}},
  ];
  const [editingField, setEditingField] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState();
  const [candidate, setCandidate] = useState(false);

  useEffect(() => {
    if (!!candidate) {
      setData({...candidate});
    }
  }, [candidate])

  useEffect(() => {
    if (props.selectedCandidateId) {
      setCandidate(getCandidateById(props.selectedCandidateId))
    }
  }, [props.candidates])

  useEffect(() => {
    setIsSaving(false);
  }, [editingField])

  const getCandidateById = (candidate_id) => {
    if (!candidate_id) {
      return null;
    }
    return props.candidates?.find(cand =>  cand.id === candidate_id)
  }

  const handleFieldChange = (e) => {
    let id = e.target.name;
    let val = e.target.value;
    setData({...data, [id]: val})
  }

  const handleFieldSave = (e) => {
    setIsSaving(true)
    props.adminUpdateCandidate(data).then(() => {
      // show ALERT if data couldnt be saved
      setIsSaving(false);
      setEditingField();
    })
  }

  return (
    <div>
      {!!candidate &&
        <>
          <SummaryTabMembership candidate={candidate}/>
          <SummaryActionHeader>
            Información:
          </SummaryActionHeader>
          {
            data && formFields.map(field => {
              return (
                <SummaryTabField
                  id={field.id}
                  title={field.title}
                  data={data}
                  disabled={editingField !== field.id}
                  onClick={() => setEditingField(field.id)}
                  onChange={handleFieldChange}
                  onSave={handleFieldSave}
                  isSaving={isSaving}
                  {...field.options}
               />
              )
            })
          }
        </>
      }
      
    </div>
  )
}

const mapStateToProps = state => ({
  candidates: state.admin.candidates,

})

const mapDispatchToProps = {
  adminUpdateCandidate
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTab);
