import Styled, {css} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DistributorDetailsContainer = Styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-flow: row wrap;
  div {
    min-width: 40%;
  }
`;

export const TableContainerWrapper = Styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  padding-left: 180px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  
  ${props => props.minimized ? animateShrink : animateGrow}
`;

const animateGrow = css`
  height: 80%;
  transition: height 0.5s ease-out;
`;

const animateShrink = css`
  height: 20%;
  transition: height 0.5s ease-out;
`;

export const TableToggleBar = Styled.div`
  height: 10px;
  background-color: rgb(225, 235, 245);
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: -4px;
  margin-bottom: -5px;
`;

export const TableToggleButton = Styled.div`
  padding: 15px;
  margin-bottom: 20px;
  padding-bottom: 0;
  background-color: white;
  border-top-right-radius: 25%;
  border-top-left-radius: 25%;
  margin-top: -30px;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
`;

export const TableToggleIcon = Styled(FontAwesomeIcon)`
  font-size: 18px;
`;