import React from 'react';
import {TabsContainer} from './style';

const Tabs = (props) => {
  return (
    <TabsContainer 
      variant="tabs"
      defaultActiveKey={props.defaultActiveKey}
    >
      {props.children}
    </TabsContainer>
  )
}

export default Tabs;