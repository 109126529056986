import {
  GET_COMMISSION_PAYMENTS_LEVEL_1,
  GET_COMMISSION_PAYMENTS_LEVEL_2,
  GET_COMMISSION_RECORDS,
} from '../actions/types';

const initialState = {
  list: [],
  meta: {},
  links: {},
}

export default function comissionsRecordsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMMISSION_RECORDS:
      return {
        ...state,
        list: action.payload.data,
        meta: action.payload.meta,
        links: action.payload.links,
      }
    case GET_COMMISSION_PAYMENTS_LEVEL_1:
      return {
        ...state,
        comission_payments: {
          list: action.payload.data,
          meta: action.payload.meta,
          links: action.payload.links,
        }
      }
    case GET_COMMISSION_PAYMENTS_LEVEL_2:
      if(action.payload)
      {
        return {
            ...state,
            comission_integrations: {
              list: action.payload.data,
              meta: action.payload.meta,
              links: action.payload.links,
          }
        }
      }
      return {
        ...state,
        comission_integrations: null
      }
      
    default:
      return state
  }
}
