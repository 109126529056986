import React, { useEffect, useState } from 'react';

import { Switch, Box } from '@mui/material';

import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { Button } from '@mui/material';
import { ActionSpinner } from '../../../../components/Spinners';
import { FormFlashMessage } from '../../../../components/Alert';

import RegisteredPaymentMethod from '../registeredPaymentMethod';

import { RenewalSection, MembershipConfiguration, MembershipConfigurationWrapper, SmallTitle, SectionTitle, SectionText, SectionSwitch, PlansContent, CustomSlider, PaymentInputContainer, PageSubtitleContainer } from './style';

import { AddPaymentMethodButton, AddPaymentMethodContainer } from '../style';

import { toggleAutomaticMembershipRenewal, updateMembershipPaymentSource } from '../../../../actions/subscriptionActions';

import { updateDefaultCreditCard, deleteCreditCard } from '../../../../actions/creditCardActions';
import { BasicInput } from 'components/Form';

const MembershipSettings = (props) => {
    const [isAutomaticRenewal, setIsAutomaticRenewal] = useState(false);
    const [creditCards, setCreditCards] = useState(props.credit_cards);
    const [chargeCard, setChargeCard] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isRenewalLoading, setIsRenewalLoading] = useState(false);

    useEffect(() => {
        if (props.credit_cards.length) {
            setCreditCards(props.credit_cards);
        }
    }, [props.credit_cards]);

    useEffect(() => {
        if (props.membership.spree_plan_id && props.membership.state == 'active') {
            props.setSelectedSubscriptionId(props.membership.spree_plan_id);
        } else if (isMembershipPaid()) {
            let paid_subscription = props.membership.subscriptions.find((sub) => sub.state === 'paid' || sub.state === 'active');
            props.setSelectedSubscriptionId(paid_subscription?.plan_id);
        }

        if (props.membership.id) {
            let isSubscriptionType = !!props.membership.subscription_type && props.membership.subscription_type === 'subscription';
            setIsAutomaticRenewal(isSubscriptionType);
            setIsRenewalLoading(false);
            if (isLoading) {
                setIsLoading(false);
            }
        }
    }, [props.membership]);

    useEffect(() => {
        if (creditCards.length && props.membership.id) {
            let source_charge_card = props.membership.spree_payment_source_id;
            if (source_charge_card !== parseInt(chargeCard?.id)) {
                setChargeCard(creditCards.find((card) => card.id == source_charge_card));
                props.setSelectedChargeCardId(source_charge_card);
            }
        }
    }, [creditCards, props.membership]);

    useEffect(() => {
        if (chargeCard && isLoading) {
            setIsLoading(false);
        }
    }, [chargeCard]);

    const isMembershipActive = () => {
        return props.membership.state === 'active';
    };

    const isMembershipPaid = () => {
        return isMembershipActive() || (props.membership.subscriptions && props.membership.subscriptions.some((sub) => sub.state === 'paid'));
    };

    const handleSubscriptionSelect = (plan_id) => {
        if (!isMembershipPaid()) {
            props.setSelectedSubscriptionId(plan_id);
        }
    };

    const handleToggleRenewal = (e) => {
        const isChecked = e.target.checked;
        if (isAutomaticRenewal) {
            Swal.fire({
                show: true,
                title: '¿Cancelar la renovación automática?',
                text: '¿Está seguro que desea cancelar la suscripción a la renovación automática mensual de su membresía Unique Brother?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancelar',
                cancelButtonText: 'No cambiar',
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsRenewalLoading(true);
                    props.toggleAutomaticMembershipRenewal(props.membership.id, isChecked);
                }
            });
        } else {
            setIsRenewalLoading(true);
            props.toggleAutomaticMembershipRenewal(props.membership.id, isChecked);
        }
    };

    const handleSelectChargeCardId = (id) => {
        props.setSelectedChargeCardId(id);
    };

    const getExpirationDay = () => {
        return new Date(props.membership?.updated_at).getDate();
    };

    const getCardStates = (card) => {
        let states = [];
        if (card.attributes.default) states.push('primary');
        if (chargeCard && card.id == chargeCard.id) states.push('charge');

        return states;
    };

    const toggleNewCardModal = () => {
        props.setIsModalAddPaymentMethodOpen(true);
    };

    const isSubscribed = (plan_id) => {
        return !!props.membership.subscriptions.find((subs) => subs?.plan_id === plan_id && (subs.state === 'active' || subs.state === 'paid'));
    };

    function handleSliderChange(e) {
        let val = parseInt(e.target.value);
        if (val <= 0) {
            val = 0;
        }

        props.setPaymentAmount(val);
    }

    function valueText(value) {
        return `${value} MXN`;
    }

    return (
        <MembershipConfigurationWrapper>
            <SmallTitle>Registro de membresía</SmallTitle>
            <MembershipConfiguration>
                <RenewalSection>
                    <SectionSwitch>
                        {isRenewalLoading && <ActionSpinner />}
                        <Switch checked={isAutomaticRenewal} onChange={handleToggleRenewal} inputProps={{ 'aria-label': 'controlled' }} />
                    </SectionSwitch>
                    <SectionTitle>La renovación automática está {isAutomaticRenewal ? 'activada' : 'desactivada'}</SectionTitle>
                    <SectionText>{isAutomaticRenewal ? `Esta subscripción se renovará automáticamente alrededor del día ${getExpirationDay()} de todos los meses. Se le facturará de forma automática una vez que se renueve.` : 'Una vez expirada la membresía, no se renovará de nuevo y perderá los derechos de distribuidor hasta activarla de nuevo de forma manual. '}</SectionText>
                </RenewalSection>
            </MembershipConfiguration>

            <SmallTitle>Selecciona una tarjeta</SmallTitle>
            {!creditCards.length && <FormFlashMessage variant="danger">No encontramos ninguna tarjeta registrada, es necesario agregar una tarjeta bancaria para continuar con tu suscripción.</FormFlashMessage>}

            <MembershipConfiguration>
                {!isLoading && (
                    <RenewalSection>
                        <SectionTitle>{!!chargeCard ? `Se utilizará la tarjeta de crédito ${chargeCard.attributes.cc_type} con terminación ${chargeCard.attributes.last_digits}` : <>Por favor selecciona una tarjeta.</>}</SectionTitle>

                        <SectionText>
                            {!!chargeCard ? <>Selecciona la tarjeta de crédito a utilizar para pagar por su membresía</> : <>No se ha seleccionado ninguna tarjeta</>}. Si deseas utilizar una tarjeta diferente{' '}
                            <Button variant="text" onClick={toggleNewCardModal}>
                                Registra una aquí
                            </Button>
                            .
                        </SectionText>

                        {!!creditCards.length &&
                            creditCards.map((card) => {
                                if (!!chargeCard && chargeCard.id === card.id) {
                                    return <RegisteredPaymentMethod id={card.id} type={card.type} attributes={card.attributes} selectable={true} setIsLoading={props.setIsLoading} isChecked={!!chargeCard && chargeCard.id === card.id} states={getCardStates(card)} />;
                                }
                            })}

                        {!!creditCards.length && (
                            <AddPaymentMethodContainer>
                                <AddPaymentMethodButton onClick={() => props.setIsModalUpdateChargeCardOpen({ isOpen: true, screen: 'charge' })}>Cambiar Selección</AddPaymentMethodButton>
                            </AddPaymentMethodContainer>
                        )}
                    </RenewalSection>
                )}
            </MembershipConfiguration>

            <SmallTitle>Selecciona el pago inicial</SmallTitle>

            <SectionText>
                <PageSubtitleContainer>
                    <>
                        <b>Nota: </b>
                    </>
                    <div>Al hacer el pago de tu membresía inicial, nosotros te entregamos el doble en puntos de crédito.</div>
                </PageSubtitleContainer>
            </SectionText>

            <PaymentInputContainer>
                <BasicInput title="Tu pagas: " value={props.paymentAmount} onChange={handleSliderChange} style={{ fontSize: '16px', fontWeight: '600' }} inputStyle={{ fontSize: '18px', padding: '20px' }} />
            </PaymentInputContainer>
            <PaymentInputContainer>
                <BasicInput title="Recibes en puntos de crédito: " value={(props.paymentAmount * 1.35).toFixed(2)} style={{ fontSize: '16px', fontWeight: '600' }} inputStyle={{ fontSize: '18px', padding: '20px' }} />
            </PaymentInputContainer>

            <PlansContent>
                <Box sx={{ width: '100%' }}>
                    <CustomSlider arial-label="Pago" marks={marks} defaultValue={2500} min={2500} max={15000} value={props.paymentAmount} getArialValueText={valueText} onChange={handleSliderChange} color="secondary" />
                </Box>
            </PlansContent>
        </MembershipConfigurationWrapper>
    );
};

const marks = [
    {
        value: 2500,
        label: '2500 MXN',
    },
    {
        value: 7500,
        label: '7500 MXN',
    },
    {
        value: 15000,
        label: '15000 MXN',
    },
];

const mapStateToProps = (state) => ({
    membership: state.subscription.membership,
    plans: state.subscription.plans,
    credit_cards: state.creditCard,
});

const mapDispatchToProps = {
    toggleAutomaticMembershipRenewal,
    updateMembershipPaymentSource,
    updateDefaultCreditCard,
    deleteCreditCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MembershipSettings);
