import React from 'react';
import {TabContainer, TabText} from './style';

const Tab = (props) => {
  return (
    <TabContainer
      active={props.active === props.id}
      disabled={props.disabled}
      onClick={props.onClick && (() => props.onClick(props.id))}
      style={props.style}
    >
      <TabText
        disabled={props.disabled}
      >
        {props.children}
      </TabText>
    </TabContainer>
  )
}

export default Tab;
