import React, { useState } from 'react';
import Avatar from './avatar';

const ClientItem = ({ attributes, selected, disabled, selectAllClient, client, ...props }) => {
    const [hover, setHover] = useState(false);

    const { name } = attributes;

    function getImageUrl() {
        //const url = "https://picsum.photos/200/300";
        const url = `https://ui-avatars.com/api/?name=+${attributes.first_name}+${attributes.last_name}`;
        return url;
    }


    function getButtonColor() {
        if (disabled) {
            return 'hsl(217, 36%, 22%)';
        } else if (selected) {
            return '#1D4ED8';
        } else if (hover) {
            return 'white';
        } else {
            return 'rgb(70, 90, 124)';
        }
    }

    return (
        <div className="flex flex-row w-full text-white rounded-xl h-24 mb-2 overflow-hidden bg-gray-800">
            <div className="flex aspect-square mr-1 p-2">
                <Avatar url={getImageUrl()} />
            </div>

            <div className="flex flex-1 flex-row justify-between items-center py-2 pr-3">
                <div className="h-full justify-between">
                    <div className="flex flex-row items-end justify-start">
                        <div className="items-start text-xl font-bold">{`${attributes.first_name} ${attributes.last_name}`}</div>
                        <div className="ml-2 items-start font-light text-sm text-gray-400 mb-0.5">{attributes.years_old && `${attributes.years_old} años`}</div>
                    </div>
                    <div className="items-start font-light text-sm text-gray-300">{attributes.email}</div>
                    <div className="items-start font-light text-sm text-gray-300">{attributes.telephone}</div>
                </div>
                <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => (selectAllClient ? props.onSelect(attributes) : props.onSelect(attributes.id))} style={{ backgroundColor: getButtonColor() }} className={`cursor-pointer select-none flex flex-row justify-center items-center p-3 rounded-3xl w-32 hover:bg-white  ${!selected && 'hover:text-gray-800'}`}>
                    <div className={`text-sm font-bold text-center`}>{selected ? props.selectedText || 'Seleccionado' : props.selectText || 'Seleccionar'}</div>
                </div>
            </div>
        </div>
    );
};

export default ClientItem;
