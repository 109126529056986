import React, {useState, useEffect} from 'react';
import {DefaultModal} from '../../components/Modal';
import {BasicFileUpload, Title} from '../../components/Form';
import {Link} from '../../components/Button';
import { faFileContract, faFileImport, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const ModalDocuments = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if(Object.keys(props.data).length) {
      setData({...data, ...props.data});
    }
  }, [props.data])

  const openDocument = (document) => {
    const documents = {
      pagare: 'PAGARE.pdf',
      contrato: 'CONTRATO.pdf',
    }

    window.open(`${process.env.REACT_APP_PUBLIC_BUCKET}${documents[document]}`)
  }

  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      title={"Documentos a firmar para activación de cuenta"}
      onSave={props.toggleModal}
    >
      <p>Por favor imprimir los documenos en este apartado, firmarlos y subir el escaneado de dichos documentos.</p>
      <Title icon={faFileImport}>Pagaré</Title>
      <div>Puedes descargar el pagaré desde <Link onClick={() => openDocument("pagare")} style={{color: "#007eff"}}>este link</Link>.</div>
      <BasicFileUpload
        text="Subir Pagaré"
        icon={ faFileImport }
        successIcon={faCheckCircle}
        isLoading={props.isLoading}
        onChange={(e) => props.handleFileUploadChange(e, "note")}
        upload={props.formData}
        display={props.data.note}
        id="note"
        data={data}
      />
      <Title icon={faFileContract}>Contrato Firmado</Title>
      <div>Puedes descargar el contrato desde <Link onClick={() => openDocument("contrato")} style={{color: "#007eff"}}>este link</Link>.</div>
      <BasicFileUpload
        text="Subir Contrato firmado"
        onChange={(e) => props.handleFileUploadChange(e, "contract")}
        icon={faFileContract}
        isLoading={props.isLoading}
        successIcon={faCheckCircle}
        upload={props.formData}
        display={props.data.contract}
        id="contract"
        data={data}
      />
    </DefaultModal>
  )
}

export default ModalDocuments;