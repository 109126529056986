import Styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { mobileScreenSize } from 'utils/screen';

export const PageTitle = Styled.h3`
  border-color: #899bca;
  font-size: 2rem;
  font-weight: 300;
  color: ${props => props.theme.text.color.default};
  margin-bottom: 24px;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
`;

export const InnerPage = Styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5px 30px 30px;
  box-shadow: inset 9px 0 10px -9px rgba(0,0,0,.08);
  @media (max-width: ${mobileScreenSize}px) {
    padding: 5px;
    padding-bottom: 0;
  }
`;

export const PageHeader = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
  height: 36px;
  @media (max-width: ${mobileScreenSize}px) {
    margin-top: 0;
    margin-bottom: 8px;
  }

`;

export const Panel = Styled.div`
  background-color: #24344d;
  border-radius: 10px;
  margin-bottom: 24px;
  padding: 25px;
  color: ${props => props.theme.text.color.panel};
  box-shadow: 0 1px 2px rgba(0,0,0,.15), 0 -1px 0 rgba(0,0,0,.02);

  @media (max-width: ${mobileScreenSize}px) {
    padding: 5px;
  }
`;

export const PanelTitle = Styled.div`
  padding: 0 0 21px;
  font-size: 18px;
  color: ${props => props.theme.text.color.light};
  font-weight: 500;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mobileScreenSize}px) {
    padding-top: 14px;
  }
`;

export const PanelSubtitle = Styled.h4`
  font-size: 14px;
  color: ${props => props.theme.text.color.panel};
  font-weight: 300;
  margin-top: 5px;
  float: left;
  width: 100%;
`

export const PanelTitleButton = Styled(Button)`
`;

export const PanelTitleText = Styled.span`
`;

export const PanelHeaderSearchContainer = Styled.div`
  margin: 0;
  height: 36px;
  min-width: 260px;
  width: 25%;
  box-sizing: border-box;
  input {
    margin: 0;
  }
  div {
    margin: 0;
  }
`;
