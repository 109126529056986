const labels = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
export const dataLineChart = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: labels.map(() => 500),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

export const optionsLineChart = {
    plugins: {
    legend: {
      position: 'right',
      labels: {
        color:'white'
      }
    },
  }, 
    scales: {
     y: {
        ticks: { color: 'white', beginAtZero: true }
      },
      x: {
        ticks: { color: 'white', beginAtZero: true }
      }
    },
    tooltip: {
      titleColor: 'white'
    }
  };


export const getCurrentMonthAndDays = ( ) => {
  const currentDate = new Date(); 

  const currenyDay = currentDate.getDate();

  const days = [];

  for (let dia = 1; dia < currenyDay; dia++) {
    days.push(dia);
  }

  return days;
}


export const createLabelsChart = (from, to, data = []) => {
  let start = new Date(from);
  let end = new Date(to);

  if(data.length > 0) {
  const dates = data.filter(d => d.attributes.completed_at).map(d => new Date(d.attributes.completed_at));

    start = new Date(Math.min(...dates));
    end = new Date(Math.max(...dates));

  }
  const dias = [];

  let startDate = start;
  while (startDate <= end) {
    dias.push(startDate.getDate()); 
    startDate.setDate(startDate.getDate() + 1);
  }

  return dias;
}

export const createDataChart = (data) => {
  const dataF = {};

  for (const order of data) {
    if(!order.attributes.completed_at) continue;
     const date = new Date(order.attributes.completed_at).toISOString().split('T')[0]; 

      if (!dataF[date]) { 
        dataF[date] = 0;
      }

      dataF[date]++;
  }

  const dates = Object.keys(dataF).map(d => new Date(d));

  const dateMin = new Date(Math.min(...dates));
  const dateMax = new Date(Math.max(...dates));

  const currentDate = new Date(dateMin);
  const res = [];

  while (currentDate <= dateMax) {
    const date = currentDate.toISOString().split('T')[0];
    res.push(dataF[date] || 0);
    currentDate.setDate(currentDate.getDate() + 1); 
  }

  return res;
}

export const getDataSet = (label, data, borderColor = 'rgb(255, 99, 132)', backgroundColor = 'rgba(255, 99, 132, 0.5)') =>  ({
      label,
      data: createDataChart(data),
      borderColor,
      backgroundColor,
    })
