import React from 'react';
import { MenuItem, MenuDivider } from '../../../components/Sidebar';
import { faStore, faShoppingCart, faUsers, faMoneyBill, faAddressCard, faCog, faProjectDiagram, faCreditCard, faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../../actions/types';

const DistributorMenu = (props) => {
    return (
        <div>
            <MenuItem {...props} text="Multinivel" icon={faNetworkWired} link="/multinivel" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Mis Clientes" icon={faUsers} link="/distributor/clients" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Mi Red" icon={faProjectDiagram} link="/distributor/network" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Mi Dinero" icon={faMoneyBill} link="/money" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Pagos" icon={faCreditCard} link="/distributor/payments" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Pedidos" icon={faShoppingCart} link="/distributor/orders" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Tienda" icon={faStore} link={API_BASE_URL} isExternal={true} selectedColor="#2B91A7" />
            <MenuDivider />
            <MenuItem {...props} text="Configuración" icon={faCog} link="/distributor/config" selectedColor="#2B91A7" />
        </div>
    );
};

export default DistributorMenu;
