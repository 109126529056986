import React, { useState, useEffect } from 'react';
import { DefaultModal } from '../../../components/Modal';
import CardDetailsForm from './cardDetailsForm';
import BillingAddressForm from './billingAddressForm';
import Swal from 'sweetalert2';

const ModalAddPaymentMethod = (props) => {
    const [selectedMethod, setSelectedMethod] = useState('credit_card');
    const [selectedAddress, setSelectedAddress] = useState('new_address');
    const [canGoNext, setCanGoNext] = useState(false);
    const [CurrentPage, setCurrentPage] = useState('card_details');

    const [addressData, setAddressData] = useState({
        country: {},
        state: {},
        zipcode: '',
        city: '',
        street2: '',
        street1: '',
    });

    const [data, setData] = useState({
        cardNumber: '',
        expiration: '',
        code: '',
        name: '',
        default: false,
    });

    useEffect(() => {
        setCanGoNext(validateCanGoNext());
    }, [data]);

    useEffect(() => {
        if (props.addressList.length > 0) {
            setSelectedAddress(props.addressList[0].id);
        }
    }, [props.addressList]);

    const validateCanGoNext = () => {
        //refactor needed
        return Object.entries(data)
            .filter(([key, val]) => key !== 'default')
            .every((val) => !!val[1]);
    };

    const handleCardNumberChange = (e) => {
        const { id, value } = e.target;
        if (value.length <= 19) {
            let newVal = value
                .replace(/[^\d.-]/g, '')
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1-')
                .slice(0, 19);
            setData({
                ...data,
                [id]: newVal,
            });
        }
    };

    const handleCardExpireChange = (e) => {
        const { id, value } = e.target;
        const newVal = value
            .replace(/[^\d.-]/g, '')
            .replace(/(.{2})/, '$1/')
            .slice(0, 7);
        setData({
            ...data,
            [id]: newVal,
        });
    };

    const handleCodeChange = (e) => {
        const { id, value } = e.target;
        const newVal = value.replace(/[^\d.-]/g, '');
        setData({
            ...data,
            [id]: newVal,
        });
    };

    const handleNameChange = (e) => {
        const { id, value } = e.target;
        setData({
            ...data,
            [id]: value,
        });
    };

    const handleAddressInputChange = (e) => {
        let id = e.target.name;
        let val = e.target.value;
        setAddressData({ ...addressData, [id]: val });
    };

    const handleCountrySelectChange = (e) => {
        let name = e.target.name;
        let id = e.target.selectedOptions[0].id;
        let value = e.target.value;
        let iso = '';

        if (value === 'null') {
            if (name === 'country') {
                setAddressData({
                    ...addressData,
                    country: {},
                    state: {},
                });
            }
            return;
            return true;
        }

        if (name === 'country') {
            iso = Object.values(props.countries).find((e) => e.id === id).attributes.iso;
        } else if (name === 'state') {
            let states = Object.values(Object.values(props.countries).find((e) => e.id === addressData.country.id).relationships.states);
            iso = states.find((e) => e.id.toString() == id).abbr;
        }

        setAddressData({ ...addressData, [name]: { value, id, iso } });
    };

    const handleSaveButtonText = () => {
        if (CurrentPage === 'card_details') {
            return 'Siguente';
        } else if (CurrentPage === 'address_details') {
            return 'Guardar';
        }
    };

    const handleCloseButtonText = () => {
        if (CurrentPage === 'card_details') {
            return 'Cerrar';
        } else if (CurrentPage === 'address_details') {
            return 'Regresar';
        }
    };

    const handleOnSave = async () => {
        if (!canGoNext) return;

        if (CurrentPage === 'card_details') {
            setCurrentPage('address_details');
        } else if (CurrentPage === 'address_details') {
            props.setIsLoading(true);
            let response_address = {};

            let source_data = {
                source_type: selectedMethod,
            };
            let address_id;

            if (!parseInt(selectedAddress)) {
                let address_data = {
                    firstname: props.user.attributes.first_name,
                    lastname: props.user.attributes.last_name,
                    phone: props.user.attributes.telephone,
                    state_name: addressData.state.iso,
                    country_iso: addressData.country.iso,
                    address1: addressData.street1,
                    address2: addressData.street2,
                    city: addressData.city,
                    zipcode: addressData.zipcode,
                };

                response_address = await props.distributorCreateNewAddress(address_data);

                if (response_address.data) {
                    address_id = response_address.data.data.id;
                }
            } else {
                address_id = selectedAddress;
            }
            source_data = {
                ...source_data,
                [selectedMethod]: {
                    ...data,
                    address_id: address_id,
                },
            };

            props
                .createCreditCard(source_data)
                .then((res) => {
                    props.setIsLoading(false);
                    if (res.status % 400 < 100 || res.status % 500 < 100) {
                        // resetModalData();
                        Swal.fire('Error!', res.error, 'error');
                    } else {
                        resetModalData();
                        Swal.fire({
                            show: true,
                            text: 'Se ha registrado tu tarjeta',
                            icon: 'success',
                            title: 'Bien hecho!',
                            confirmButtonText: 'Aceptar',
                        });
                    }
                })
                .catch((err) => {
                    props.setIsLoading(false);
                    Swal.fire('Error!', 'No se ha podido registrar la tarjeta seleccionada', 'error');
                });
        }
    };

    const resetModalData = () => {
        props.toggleModal();
        setCurrentPage('card_details');
        setAddressData({
            country: {},
            state: {},
            zipcode: '',
            city: '',
            street2: '',
            street1: '',
        });

        setData({
            cardNumber: '',
            expiration: '',
            code: '',
            name: '',
            default: false,
        });
    };

    const setSourceDataAddress = (source_data, id, address_created) => {
        source_data['address'] = { id, address_created };
        return source_data;
    };

    const handleOnHide = () => {
        if (CurrentPage === 'card_details') {
            props.toggleModal();
        } else if (CurrentPage === 'address_details') {
            setCurrentPage('card_details');
        }
    };

    const handleDefaultToggle = () => {
        setData({ ...data, default: !data.default });
    };

    return (
        <DefaultModal show={props.show} onHide={handleOnHide} onSave={handleOnSave} title={'Registra una tarjeta'} saveButtonText={handleSaveButtonText()} disabledSaveButton={!canGoNext} closeButtonText={handleCloseButtonText()}>
            {CurrentPage === 'card_details' && <CardDetailsForm handleCardExpireChange={handleCardExpireChange} handleNameChange={handleNameChange} handleCodeChange={handleCodeChange} handleCardNumberChange={handleCardNumberChange} handleDefaultToggle={handleDefaultToggle} selectedMethod={selectedMethod} setSelectedMethod={setSelectedMethod} user={props.user} data={data} />}
            {CurrentPage === 'address_details' && <BillingAddressForm user={props.user} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} handleAddressInputChange={handleAddressInputChange} handleCountrySelectChange={handleCountrySelectChange} addressList={props.addressList} countries={props.countries} addressData={addressData} getCountry={props.getCountry} />}
        </DefaultModal>
    );
};

export default ModalAddPaymentMethod;
