import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Swal from 'sweetalert2';
import { BasicInput } from 'components/Form';
import { DefaultModal } from 'components/Modal';
import { CircularProgressSpinner } from 'components/Spinners';
import { faIdBadge } from '@fortawesome/free-solid-svg-icons';

import { isUserAdmin, getUserFromId, removeUserStoreCredits } from 'actions/userActions';

import { getUserBankInformation, findUserBank, withdrawStoreCredit, transferStoreCredit } from 'actions/bankActions';

const CreditTransferViewer = (props) => {
    const [userBank, setUserBank] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [transferValue, setTransferValue] = useState(0);
    const [transferOption, setTransferOption] = useState(props.transferOption || 'transfer');
    const [sweetAlertData, setSweetAlertData] = useState({});

    useEffect(() => {
        setIsLoading(true);
        if (!props.bank_accounts.length) {
            props.getUserBankInformation(props.userId);
        } else {
            let isBankPresent = !!runFindUserBank();

            isBankPresent ? updateUserBank() : props.getUserBankInformation(props.userId);
        }
    }, []);

    useEffect(() => {
        if (!userBank.id && !!runFindUserBank()) {
            updateUserBank();
        }
    }, [props.bank_accounts]);

    useEffect(() => {
        if (userBank.id) {
            setIsLoading(false);
        }
    }, [userBank]);

    useEffect(() => {
        if (props.setIsLoading) {
            props.setIsLoading(isLoading);
        }
    }, [isLoading]);

    useEffect(() => {
        props.transferOption && setTransferOption(props.transferOption);
    }, [props.transferOption]);

    const runFindUserBank = () => {
        return findUserBank({
            bankAccounts: props.bank_accounts,
            userId: props.userId,
        });
    };

    const updateUserBank = () => {
        let currentUserBank = runFindUserBank();
        setUserBank(currentUserBank);
    };

    const getHeaderText = () => {
        return TextTemplate['title'][transferOption];
    };

    const handleInputChange = (e) => {
        var reg = /^\d+$/;
        if (reg.test(e.target.value)) {
            setTransferValue(e.target.value);
        }
    };

    const onConfirmTransfer = async () => {
        if (!(transferValue > 0)) return;
        setIsLoading(true);

        if (transferOption === 'transfer') {
            await handleCreditTransfer();
        } else if (transferOption === 'withdraw') {
            await handleCreditWithdraw();
        }
        setTransferValue('');
        //setTransferOption(false)
        setIsLoading(false);
        props.setShow(false);
    };

    const handleCreditTransfer = () => {
        let client = getUserFromState();
        return props
            .transferStoreCredit(props.userId, transferValue, client.role)
            .then((res) => {
                if (res && res.data && res.data.status === 400) {
                    Swal.fire('Error!', res.data.errors[0].message, 'error');
                    return false;
                }

                if (!isUserAdmin(props.user)) {
                    props.removeUserStoreCredits(-res.used_amount);
                }

                Swal.fire('Transferencia Exitosa!', `Se ha transferido exitosamente a ${client.first_name} ${client.last_name} por la cantidad de ${transferValue} pesos.`, 'success');
                return res;
            })
            .catch((e) => {
                Swal.fire('Error!', e.message, 'error');
            });
    };

    const handleCreditWithdraw = () => {
        let client = getUserFromState();
        return props
            .withdrawStoreCredit(props.userId, transferValue, client.role)
            .then((res) => {
                if (res && res.data && res.data.status === 400) {
                    Swal.fire('Error!', res.data.errors.message, 'error');
                    return false;
                }

                let client = getUserFromState();
                if (!isUserAdmin(props.user)) {
                    props.removeUserStoreCredits(-res.used_amount);
                }

                Swal.fire('Devolución Exitosa!', `Se han devuelto exitosamente ${transferValue} pesos del crédito de ${client.first_name} ${client.last_name} a tu cuenta.`, 'success');
                return res;
            })
            .catch((e) => {
                Swal.fire('Error!', e.message, 'error');
            });
    };

    const getUserFromState = () => {
        let client;
        client = getUserFromId(props.distributors, props.userId);
        client = !!client ? client : getUserFromId(props.clients, props.userId);
        return client;
    };

    return (
        <DefaultModal show={props.show} onHide={() => props.setShow(false)} onSave={onConfirmTransfer} title={getHeaderText()} saveButtonText={'Confirmar movimiento'}>
            <div>
                <BasicInput icon={faIdBadge} id={props.transferOption} placeholder={TextTemplate['InputPlaceholder'][transferOption]} value={props.transferValue} onChange={(e) => handleInputChange(e)} />
            </div>
        </DefaultModal>
    );
};

const TextTemplate = {
    title: {
        transfer: 'Envia crédito a la cuenta del usuario.',
        withdraw: 'Retira el crédito sin uso del usuario.',
    },
    InputPlaceholder: {
        transfer: '¿Cual es la cantidad que deseas transferir?',
        withdraw: '¿Cuanto crédito deseas recuperar de la linea del usuario?',
    },
};

const mapStateToProps = (state) => ({
    bank_accounts: state.bank.bank_accounts,
    clients: state.distributor.clients,
    distributors: state.admin.distributors,
    user: state.user,
});

const mapdispatchtoprops = {
    getUserBankInformation,
    transferStoreCredit,
    withdrawStoreCredit,
    removeUserStoreCredits,
};

export default connect(mapStateToProps, mapdispatchtoprops)(CreditTransferViewer);
