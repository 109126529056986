import Styled, {css} from 'styled-components';
import { mobileScreenSize } from "utils/screen";

export const MainContainer = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  min-height: 90vh;
  background-color: #f5f5f5;
`;

export const ChartBody = Styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 15px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const RowContainer = Styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
`;

export const DashboardItem = Styled.div`
  background-color: white;
  margin: 15px;
  border-radius: 15px;
  padding: 15px;
  height: 100%;
  flex-grow: 1;
  min-width: 224px;
`;

export const MonthlyChangesContainer = Styled.div`
  flex-basis: 65%;
  margin-bottom: 15px;
  flex-grow: 1;
`;

export const MonthlyTotalsContainer = Styled.div`
  flex-basis: 35%;
  margin-bottom: 15px;
  flex-grow: 1;
`;

export const RankingsContainer = Styled.div`
  flex-basis: 35%;
  margin-bottom: 15px;
  flex-grow: 1;
`;

export const ChartContainer = Styled.div`
  flex-basis: 65%;
  margin-bottom: 15px;
  flex-grow: 1;
`;

