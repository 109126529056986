import React from 'react';
import { connect } from 'react-redux';

import { toggleRightSidebarOpen } from '../../actions/rightSideBarActions';
import { toggleLeftSidebarOpen } from '../../actions/leftSideBarActions';
import { logoutUserRequest } from '../../actions/userActions';

import { HeaderBtnLi, HeaderBtnA, HeaderBtnFa } from './style';
import { DropdownButton } from '../Button';
import { DropdownItem, DropdownDivider, DropdownMenu, DropdownNotificationItem } from '../Dropdown';

class HeaderButton extends React.Component {
    constructor() {
        super();
        this.renderSelectedMenu = this.renderSelectedMenu.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.redirectTo = this.redirectTo.bind(this);
    }

    handleLogout() {
        this.props.logoutUserRequest();
    }

    redirectTo(route) {
        return window.location.replace(route || this.props.to);
    }

    renderSelectedMenu(buttonType) {
        typeof buttonType === 'string' && (buttonType = buttonType.toLowerCase());
        switch (buttonType) {
            case 'profile':
                return (
                    <DropdownButton title="Profile" icon={this.props.icon}>
                        <DropdownMenu>
                            <DropdownItem key="4" onClick={() => this.redirectTo('/account/settings')}>
                                Configuración de cuenta
                            </DropdownItem>
                            <DropdownItem key="5" onClick={this.handleLogout}>
                                Cerrar Sesión
                            </DropdownItem>
                        </DropdownMenu>
                    </DropdownButton>
                );
            case 'notifications':
                return (
                    <DropdownButton title="Notifications" icon={this.props.icon}>
                        <DropdownMenu size="medium">
                            <DropdownNotificationItem key="1" type="announcement">
                                Bienvenido a Unique Brother!
                            </DropdownNotificationItem>
                        </DropdownMenu>
                    </DropdownButton>
                );
            case 'rightsidebar':
                return (
                    <HeaderBtnA onClick={() => {} /*this.props.toggleRightSidebarOpen*/}>
                        <HeaderBtnFa icon={this.props.icon} />
                    </HeaderBtnA>
                );
            case 'toggleleftsidebar':
                return (
                    <HeaderBtnA onClick={this.props.toggleLeftSidebarOpen}>
                        <HeaderBtnFa icon={this.props.icon} />
                    </HeaderBtnA>
                );
            case 'link':
                return (
                    <HeaderBtnA onClick={this.redirectTo}>
                        <HeaderBtnFa icon={this.props.icon} />
                    </HeaderBtnA>
                );
            case 'label':
                return (
                    <DropdownButton text={this.props.text} title={this.props.title} style={this.props.style}>
                        <DropdownMenu size="medium">
                            <DropdownNotificationItem key="1" type={this.props.notificationType}>
                                {this.props.dropdownText}
                            </DropdownNotificationItem>
                        </DropdownMenu>
                    </DropdownButton>
                );
            default:
                return (
                    <HeaderBtnA onClick={() => {}}>
                        <HeaderBtnFa icon={this.props.icon} />
                    </HeaderBtnA>
                );
        }
    }

    render() {
        return <HeaderBtnLi>{this.renderSelectedMenu(this.props.type)}</HeaderBtnLi>;
    }
}

const mapStateToProps = (state) => {
    return {
        isOpen: state.rightSidebarReducer.isOpen,
    };
};

const mapDispatchToProps = {
    toggleRightSidebarOpen,
    logoutUserRequest,
    toggleLeftSidebarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderButton);
