import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ProgressContainer} from './style'

const CircularProgressSpinner = (props) => {
  return (
    <ProgressContainer>
      <CircularProgress color={props.color}/>
    </ProgressContainer>
  )
}

export default CircularProgressSpinner;