import Styled from 'styled-components';
import Alert from 'react-bootstrap/Alert';
import { mobileScreenSize } from 'utils/screen';

export const AlertContainer = Styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 100;
  position: relative;
`;

export const StyledAlert = Styled(Alert)`
  width: 100%;
  display: block;
  margin-bottom: 3px !important;
  margin-right: 3px;
`;

export const StyledInformation = Styled(Alert)`
  display: flex;
  z-index: 10;
  display: inline-block;
  background-color: ${(props) => props.theme.bg.error};
  color: ${(props) => props.theme.text.color.error};
  border: 2px solid ${(props) => props.theme.form.color.error};
  min-width: 300px;
  padding-right: 15px;
  width: 100%;
  margin-top: 10px;

  li {
    text-decoration: none;
    list-style-type: circle;
    text-align: left;
    font-size: 14px;
  }

  @media (max-width: ${mobileScreenSize}px) {
    right: 0;
    top: -180px;
  }
`;

export const NoHeightContainer = Styled.div`
  height: 0;

  @media (max-width: ${mobileScreenSize}px) {
    display: none;
  }

`;

export const ContainerPointer = Styled.div`
  background-color: ${(props) => props.theme.bg.error};
  width: 35px;
  height: 35px;
  transform: rotate(45deg);
  border-radius: 3px;
  right: 33px;
  top: -2px;
  z-index: -2;
  position: relative;
  border-left: 1px solid ${(props) => props.theme.form.color.error};
  border-bottom: 1px solid ${(props) => props.theme.form.color.error};  
`;
