import React, { useState } from 'react';
import { DefaultModal } from '../../../components/Modal';
import { Title, BasicInput, BasicSelect } from '../../../components/Form';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

export const ModalDistributorCreate = (props) => {
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        telephone: '',
        birthdate: '',
        gender: '',
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setData({
            ...data,
            [id]: value,
        });
    };

    const resetFields = () => {
        let keys = Object.keys(data);
        let newData = {};
        keys.forEach((key) => {
            newData[key] = '';
        });
        setData(newData);
    };

    const handleCreateNewClient = async () => {
        await props.onCreate(data);
        await props.toggleModal();
        await resetFields();
    };

    return (
        <DefaultModal show={props.show} onHide={props.toggleModal} onSave={handleCreateNewClient} title={'Registra a un nuevo distribuidor'}>
            <Title icon={faUserCircle}>Información Personal</Title>
            <BasicInput id={'first_name'} value={data.first_name} placeholder={'Escribe aquí el nombre de tu distribuidor...'} onChange={(e) => handleInputChange(e)} title={'Nombre'} />
            <BasicInput id={'last_name'} value={data.last_name} placeholder={'Escribe aquí los apellidos de tu distribuidor...'} onChange={(e) => handleInputChange(e)} title={'Apellidos'} />
            <BasicInput id={'email'} value={data.email} placeholder={'Escribe aquí... Ej: usuario@dominio.com'} onChange={(e) => handleInputChange(e)} title={'Correo Electrónico'} />
            <BasicInput id={'password'} value={data.password} placeholder={'Escribe aquí una contraseña temporal. (Se actualizará al iniciar sesión)'} onChange={(e) => handleInputChange(e)} title={'Contraseña temporal'} />
            <BasicInput id={'telephone'} value={data.telephone} placeholder={'Escribe aquí... Ej: 8711234567'} onChange={(e) => handleInputChange(e)} title={'Teléfono'} type={'number'} />
            <BasicInput id={'birthdate'} value={data.birthdate} placeholder={'dd-mm-yyyy'} onChange={(e) => handleInputChange(e)} title={'Fecha de nacimiento'} type={'date'} max={'2030-12-31'} />
            <BasicSelect id={'gender'} value={data.gender} placeholder={'Escribe aquí... Ej: 8711234567'} onChange={(e) => handleInputChange(e)} title={'Género'} type={'number'}>
                <option id={'male'}>Masculino</option>
                <option id={'female'}>Femenino</option>
            </BasicSelect>
        </DefaultModal>
    );
};

export default ModalDistributorCreate;
