import Styled, {css} from 'styled-components';
import { styled } from '@mui/material/styles';
import { PaginationItem, TableContainer } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {mobileScreenSize} from 'utils/screen';

function getColorByType(type, props) {
  if(!type) return props.odd ? "#293851" : "#24344d";

  switch(type) {
    case 'success':
      return props.theme.bg.successRow;
    case 'danger':
      return props.theme.bg.dangerRow;
    case 'secondary':
      return props.theme.bg.secondary;
    case 'warning':
      return props.theme.bg.warning;
    case 'pending':
      return props.theme.bg.warning;
    case 'info':
      return props.theme.bg.info;
    case 'active':
      return props.theme.bg.successRow;
    default:
      return props.theme.bg.successRow;
  }
}

export const Table = Styled.table`
 max-width: 100%;
 margin: 0 auto;
 clear: both;
 border-collapse: separate;
 border-spacing: 0;
 padding-bottom: 10px;
 table-layout: fixed;
 border-collapse: collapse;
`;

export const TableWrapper = Styled.div`
  max-width: inherit;
  overflow-x: auto;
`;

export const Thead = Styled.thead`
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  tr {
    display: block;
  }
`;

export const Tbody = Styled.tbody`
  display: block;
  width: 100%;
  overflow: auto;
  height: 65vh;
  vertical-align: middle;
  border-color: inherit;
`;

export const Tr = Styled.tr`
  display: table-row;
  vertical-align: inherit;
  border: 1px;
  border-bottom: rgb(128, 128, 128);
  border-top: rgb(128, 128, 128);
  background-color: ${props => getColorByType(props.type, props) };
  color: rgba(225,235,245,.9);
  &:hover {
    // background-color: ${props => !props.header && "#293851" };
    opacity: 0.8;
  };
`;

export const Th = Styled.th`
  cursor: pointer;
  min-width: ${props => props.width || "120"}px;
  border-bottom: 1px solid rgb(46,63,91);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 8px;
  padding-left: 0px;
  text-align: left;
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  width: ${props => props.width || "200"}px;

  @media (max-width: ${mobileScreenSize}px) {
    width: ${props => props.width/2 || "100"}px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const Td = Styled.td`
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 8px;
  padding-left: 8px;
  border-bottom: 1px solid rgb(46,63,91);
  width: ${props => props.width || "200"}px;
  word-break: break-all;
  &:hover {
    color: white;
  };
`;

export const SortIcon = Styled(FontAwesomeIcon)`
  margin-right: 10px;
  margin-left: 10px;
`;

export const ShowMore = Styled.button`
  margin: 0;
  padding: 2px;
  background-color: inherit;
  border: 0;
  border-radius: 10px;
  text-align: center !important;
  width: 100%;
  font-size: 14px;
  text-align: left;
  color: #999999;
  &:hover {
    /*background-image: linear-gradient( #293851, #3e4b62, #293851);*/
    background-color: #3e4b62;
    color: white;
    cursor: pointer;
  }
  &:focus {
    outline:none;
  }
`

export const ColoredDatumWrapper = Styled.div`
  padding: 1px 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  min-width: 90px;
  max-width: 200px;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-self: center;
  ${props => props.variant === "success" && SuccessDatum};
  ${props => props.variant === "primary" && PrimaryDatum};
  ${props => props.variant === "secondary" && SecondaryDatum};
  ${props => props.variant === "warning" && WarningDatum};
  ${props => props.variant === "pending" && WarningDatum};
  ${props => props.variant === "danger" && DangerDatum};
`;

const SuccessDatum = css`
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  color: #0e6245;
`;

const PrimaryDatum = css`
  background-color: #cce5ff;
  border: 1px solid #b8daff;
  color: #004085;
`;

const SecondaryDatum = css`
  background-color: #e2e3e5;
  border: 1px solid #d6d8db;
  color: #383d41;
`;

const WarningDatum = css`
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
`;

const DangerDatum = css`
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
`;

export const ColoredDatumIcon = Styled(FontAwesomeIcon)`
  margin-left: 5px;
  font-size: 12px;
  height: 15px;
`;


export const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  width: "100%",
  height: "70vh",
  color: "white",
  backgroundColor: "#24344d",
  border: "1px solid rgb(46,63,91)",
  '& .MuiDataGrid-columnsContainer': {
    border: "1px solid rgb(46,63,91)",
  },

  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: "1px solid rgb(46,63,91)",
  },

  '& .MuiDataGrid-root': {
    border: "1px solid rgb(46,63,91)",
  },
  '& .MuiDataGrid-overlay': {
    backgroundColor: "#24344d",
  },
  '& .MuiButtonBase-root': {
    color: "white",
  }

}))
;

export const StyledPagination = styled(Pagination)(({theme}) => ({
  color: "white",
}))

export const StyledPaginationItem = styled(PaginationItem)(({theme}) => ({
  color: "white",
  borderColor: "rgb(46,63,91)",
}))
