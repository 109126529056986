import React, {useEffect, useState} from 'react';

import {Title, BasicInput, RowRadio} from '../../../components/Form';
import {DefaultModal} from '../../../components/Modal';

const ModalAddDepositAccount = (props) => {

  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      onSave={props.onSave}
      title={"Agrega una cuenta de deposito"}
    >
      <Title>Selecciona el pedido a pagar</Title>
    </DefaultModal>
  )

}

export default ModalAddDepositAccount;
