import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { getOrderData } from 'actions/orderActions';
import { getUserPaymentPlans } from 'actions/paymentPlanActions';
import { DefaultModal } from 'components/Modal';
import ReactToPrint from 'react-to-print';
import { Ticket } from '../components';
import { CircularProgressSpinner } from 'components/Spinners';

const TicketModal = ({ show, onClose, ...props }) => {
    const [orderId, setOrderId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(false);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(false);
    const ticketRef = useRef();

    useEffect(() => {
        if (props.cart.data) {
            setOrderId(parseInt(props.cart.data.id));
        } else if (props.cart.isLoading) {
            setOrderId(false);
        }
    }, [props.cart]);

    useEffect(() => {
        setSelectedPaymentPlan(false);
        setSelectedOrder(false);
        if (orderId) {
            setIsLoading(true);
            getOrSetSelectedOrder();
        }
    }, [orderId]);

    useEffect(() => {
        getOrSetSelectedOrder();
    }, [props.orders]);

    useEffect(() => {
        if (!!orderId && props.cart.data?.id !== orderId) {
            props.getOrderData(orderId);
        }
    }, [props.cart.data]);

    useEffect(() => {
        if (selectedOrder) {
            if (!selectedOrder.attributes.payment_plan_id) {
                setIsLoading(false);
            }
            getOrSetUserPaymentPlan();
        }
    }, [selectedOrder]);

    useEffect(() => {
        if (selectedOrder && props.paymentPlans[selectedOrder.attributes.user.id]) {
            getOrSetUserPaymentPlan();
        }
    }, [props.paymentPlans]);

    useEffect(() => {
        if (selectedPaymentPlan && selectedOrder.attributes.credit_type_used !== 'store') {
            setIsLoading(false);
        }
    }, [selectedPaymentPlan]);

    const getOrSetSelectedOrder = () => {
        let foundOrder;
        if (props.orders.length) {
            foundOrder = findSelectedOrder();
            setSelectedOrder(foundOrder);
        }

        if (!foundOrder) {
            props.getOrderData(orderId);
        }
    };

    const getOrSetUserPaymentPlan = () => {
        if (selectedOrder.attributes.payment_plan_id) {
            const userPlans = props.paymentPlans[selectedOrder.attributes.user.id];
            if (!userPlans) {
                props.getUserPaymentPlans(selectedOrder.attributes.user.id);
            } else {
                setSelectedPaymentPlan(userPlans.find((plan) => plan.id === selectedOrder.attributes.payment_plan_id));
            }
        }
    };

    const findSelectedOrder = () => {
        return props.orders.find((order) => parseInt(order.id) === orderId);
    };

    function handleOnBeforeGetContent() {
        setIsLoading(true);
    }

    function handleOnAfterPrint() {
        setIsLoading(false);
    }

    function renderLoading() {
        return (
            <div className="flex flex-column justify-center">
                <CircularProgressSpinner />
                <div className="flex text-xl font-bold mb-3 items-center justify-center mt-4">Obteniendo datos de compra...</div>
            </div>
        );
    }

    function renderOrderNotComplete() {
        return (
            <div>
                <div className="text-xl font-bold mb-3">No se ha completado el pedido</div>
                <div>Por favor, completa el pedido para poder imprimir el ticket</div>
            </div>
        );
    }

    function renderNotSelected() {
        return (
            <div>
                <div className="text-xl font-bold mb-3">No se ha seleccionado ningún pedido</div>
                <div>Por favor, selecciona un pedido para poder imprimir el ticket</div>
            </div>
        );
    }

    function renderTicket() {
        return (
            <div className="flex justify-around">
                <div></div>
                <Ticket order={selectedOrder} paymentPlan={selectedPaymentPlan} ref={ticketRef} />
                <div className="">
                    <ReactToPrint trigger={() => <button className="btn btn-primary">Imprimir Ticket</button>} content={() => ticketRef.current} />
                </div>
            </div>
        );
    }

    function renderContent() {
        if (isLoading) return renderLoading();
        if (!selectedOrder || !orderId) return renderNotSelected();

        //if (findSelectedOrder() && findSelectedOrder().attributes.state !== "complete") {
        //return renderOrderNotComplete();
        //}

        return renderTicket();
    }

    return (
        <DefaultModal show={show} title="Ticket" onClose={onClose} onHide={onClose} isLoading={isLoading}>
            {renderContent()}
        </DefaultModal>
    );
};

const mapStateToProps = (state) => ({
    orders: state.order.index,
    paymentPlans: state.paymentPlan.list,
    cart: state.posCart,
});

const mapDispatchToProps = {
    getOrderData,
    getUserPaymentPlans,
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketModal);
