import { EXPORT_COMISSIONS } from '../actions/types';

const initialState = {
    list: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case EXPORT_COMISSIONS:
            return { ...state, list: action.payload };
        default:
            return state;
    }
}
