import React from 'react';
import { DefaultModal } from '../../../components/Modal';
import { ImageFile } from './style';

const ModalImageViewer = (props) => {
  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      title={props.image && props.image.name}
      size={"xl"}
    >
      <div style={{height: "85vh"}}>
        <ImageFile
          url={props.image && props.image.url}
        />
      </div>
    </DefaultModal>
  )
}

export default ModalImageViewer;