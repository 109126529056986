import React from 'react';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {faCheck, faDotCircle, faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {ColoredDatum} from '../../../../components/Table';
import { RowRadio} from '../../../../components/Form';


import {
  PaymentMethodInformation,
  PaymentMethodCardDetails,
  PaymentMethodType,
  PaymentMethodNumberDetails,
  PaymentMethodNumbers,
  PaymentMethodExpires,
  PaymentMethodTypeIcon,
} from './style';


const RegisteredPaymentMethod = (props) => {
  const statusObj = {
    primary: {
      type: 'primary',
      text: 'Por Defecto',
      icon: faCheck,
    },
    secondary: {
      type: 'secondary',
      text: 'Secundaria',
      icon: faDotCircle,
    },
    pending: {
      type: 'warning',
      text: 'Por verificar',
      icon: faInfoCircle,
    }
  }

  const getCardState = () => {
    if (props.attributes.default) {
      return "primary"
    } else {
      return "secondary"
    }
  }


  let selectedStatus = statusObj[getCardState()];

  return (
    <PaymentMethodInformation onClick={() => props.onClick(props.id)}>
      <PaymentMethodCardDetails>
        <PaymentMethodType>
          <PaymentMethodTypeIcon icon={faCreditCard} />
        </PaymentMethodType>
        <PaymentMethodNumberDetails>
          <PaymentMethodNumbers>
            {props.attributes.cc_type} * {props.attributes.last_digits}
          </PaymentMethodNumbers>
          <PaymentMethodExpires>
            Expira {props.attributes.month}/{props.attributes.year}
          </PaymentMethodExpires>
        </PaymentMethodNumberDetails>
          <ColoredDatum
            variant={selectedStatus.type}
            icon={selectedStatus.icon}
            style={{width: "120px"}}
            wrapperStyle={{borderBottom: 0}}
          >
            {selectedStatus.text}
          </ColoredDatum>
      </PaymentMethodCardDetails>
      <RowRadio
        id={props.id}
        selected={props.selected}
      />
    </PaymentMethodInformation>
  )
}

export default RegisteredPaymentMethod;