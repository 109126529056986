import React from 'react';
import {Page, MainWrapper} from '../../../components/Body';
import {connect} from 'react-redux';

const DistributorProfile = (props) => {
  let userAttrss = props.user.data.attributes
  return (
    <Page title={userAttrss.first_name +" "+ userAttrss.last_name}>
      <MainWrapper
        title="General"
        actionButton={false}
        showSearch={false}
        onActionClick={() => {}}
      ></MainWrapper>
    </Page>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  distributor: state.distributor
})

export default connect(mapStateToProps)(DistributorProfile);
