import {
  CREATE_COUNTRIES,
  INDEX_COUNTRIES,
  UPDATE_COUNTRY,
} from '../actions/types';

const initialState = {
  countries: {},
}

export default function(state = initialState, action) {
  switch(action.type) {
    case INDEX_COUNTRIES:
      return {...state, countries: {...action.payload}}
    case CREATE_COUNTRIES:
      return {...state, countries: Object.values(state.countries).concat(action.payload)}
    case UPDATE_COUNTRY:
      if (!action.payload || action.payload.states.length === 0) {
        return state
      }
      return {
        ...state,
        countries: {
          ...Object.values(state.countries).filter(country => country.id !== action.payload.country_id),
          ...updateStatesOfCountry(state, action.payload)
        }
      }
    default:
      return state;
  }
}

const updateStatesOfCountry = (state, payload) => {
  let selectedCountry = Object.values(state.countries)
  .find(country => country.id === payload.country_id);

  return [{
    ...selectedCountry,
    relationships: {
      ...selectedCountry.relationships,
      states: {...payload.states}
    }
  }];
}