import React, { useState, useEffect } from 'react';
import { MainWrapper } from '../../../components/Body';
import { Tab, TabGroup } from '../../../components/Navs';
import NetworkPage from './networkPage';
import SubscriptionPage from './subscriptionPage';
import SummaryPage from './SummaryPage';
import CreditPage from './creditPage';

const DistributorDetails = (props) => {
    const TabTitles = {
        summary: 'General',
        credit: 'Crédito',
        subscription: 'Suscripción',
        network: 'Red Multinivel',
    };

    const [selectedTab, setSelectedTab] = useState('summary');

    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        birthdate: '',
        created_at: '',
        telephone: '',
        gender: '',
        distributor_discount_code: '',
        subscription_active: '',
        selected_plan: null,
    });

    useEffect(() => {
        if (props.distributorData['id']) {
            setData(props.distributorData);
        }
    }, [props.distributorData]);

    useEffect(() => {
        getSelectedTabData();
    }, [selectedTab, props.selectedDistributor]);

    const getSelectedTabData = () => {
        if (!props.selectedDistributor.length) {
            return true;
        }
        let dataFetcher;
        switch (selectedTab) {
            case 'summary':
                dataFetcher = props.fetchSummaryData;
                break;
            case 'credit':
                dataFetcher = () => {};
                break;
            case 'subscription':
                dataFetcher = props.fetchSubscriptionData;
                props.fetchPlans();
                break;
            case 'network':
                dataFetcher = props.fetchNetworkData;
                break;
            default:
                dataFetcher = () => {};
                break;
        }
        dataFetcher(props.selectedDistributor[0]);
    };

    const handleInputChange = (e) => {};

    const commonProps = {
        data,
        isLoading: props.isLoading,
        selectedDistributor: props.selectedDistributor,
        setIsLoading: props.setIsLoading,
    };

    return (
        <MainWrapper title="Información del distribuidor" style={{ paddingBottom: '200px' }}>
            <TabGroup>
                {Object.entries(TabTitles).map(([id, title]) => (
                    <Tab id={id} onClick={setSelectedTab} active={selectedTab}>
                        {title}
                    </Tab>
                ))}
            </TabGroup>
            {/* Every click on tab should fetch & reload data */}
            {selectedTab === 'summary' && <SummaryPage handleInputChange={handleInputChange} {...commonProps} />}
            {selectedTab === 'credit' && <CreditPage {...commonProps} />}
            {selectedTab === 'subscription' && <SubscriptionPage {...commonProps} />}
            {selectedTab === 'network' && <NetworkPage showMultiLevel={props.showMultiLevel} network={props.network} {...commonProps} />}
        </MainWrapper>
    );
};

export default DistributorDetails;
