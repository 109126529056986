import React from 'react';
import {Table, TableWrapper} from './style'
import {CircularProgressSpinner} from '../Spinners';

const DataTable = (props) => {
  return (
    <TableWrapper>
      <Table>
        {props.isLoading && <CircularProgressSpinner color="white">Loading...</CircularProgressSpinner>}
        {!props.isLoading && !props.children.length &&
          <div style={{paddingTop: "25px"}}>
            <center>
              Aun no se ha creado ningun registro.
            </center>
          </div>
        }
        {props.children}
      </Table>
    </TableWrapper>
  )
}

export default DataTable;
