import {
  INDEX_ADMIN_CANDIDATE,
  REGISTER_DOCUMENT_ADMIN_CANDIDATE,
  CLEAR_REGISTER_DOCUMENT_ADMIN_CANDIDATE,
  ADMIN_UPDATE_CANDIDATE,
  ADMIN_REJECT_CANDIDATE,
  ADMIN_ACCEPT_CANDIDATE,
  INDEX_ADMIN_DISTRIBUTOR,
  UPDATE_ADMIN_DISTRIBUTOR,
  ADMIN_FETCH_USER_MEMBERSHIP
} from '../actions/types';

const initialState = {
  candidates: [],
  distributors: [],
  register_document: {},
}

export default function(state= initialState, action) {
  switch(action.type) {
    case INDEX_ADMIN_CANDIDATE:
      return {
        ...state,
        candidates: [...action.payload]
      }
    case INDEX_ADMIN_DISTRIBUTOR:
      return {
        ...state,
        distributors: [...action.payload]
      }
    case REGISTER_DOCUMENT_ADMIN_CANDIDATE:
      return {
        ...state,
        register_document: action.payload
      }
    case CLEAR_REGISTER_DOCUMENT_ADMIN_CANDIDATE: 
      return {
        ...state,
        register_document: {}
      }
    case ADMIN_ACCEPT_CANDIDATE:
      return {
        ...state,
        candidates: [
          ...state.candidates.filter(match => match.id !== action.payload.id),
        ]
      }
    case ADMIN_REJECT_CANDIDATE:
      return {
        ...state,
        candidates: [
          ...state.candidates.filter(match => match.id !== action.payload.id),
          {...action.payload, register_document: {status: "rejected"}}
        ]
      }
    case ADMIN_UPDATE_CANDIDATE:
      return {
        ...state,
        candidates: [
          ...state.candidates.filter(match => match.id !== action.payload.id),
          {
            ...state.candidates.filter(match => match.id === action.payload.id)[0],
            ...action.payload
          }
        ]
      }
    case UPDATE_ADMIN_DISTRIBUTOR:
      return {
        ...state,
        distributors: [
          ...state.distributors.filter(match => match.id !== action.payload.id),
         {
            ...state.distributors.filter(match => match.id === action.payload.id)[0],
            ...action.payload
          }
        ]
      }
    default:
      return state;
  }
}

