import { FETCH_ALL_UNIT_OF_MEASUREMENTS } from '../actions/types';

const initialState = {
  FETCH_ALL_UNIT_OF_MEASUREMENTS: [],
  unitOfMeasurement: {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_ALL_UNIT_OF_MEASUREMENTS:
      return {
        ...state,
        FETCH_ALL_UNIT_OF_MEASUREMENTS: action.payload 
      }
    default:
      return state;
  }
}