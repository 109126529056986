import React from 'react';
import { MenuItem, MenuDivider } from '../../../components/Sidebar';
import { faStore, faUsers, faCog, faChartLine, faUserCheck, faMoneyBill, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../../actions/types';

const AdminMenu = (props) => {
    return (
        <div>
            <MenuItem {...props} text="Dashboard" icon={faChartLine} link="/dashboard" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Candidatos" icon={faUsers} link="/admin/candidates" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Distribuidores" icon={faUserCheck} link="/admin/distributors" selectedColor="#2B91A7" />
            <MenuItem {...props} text="Dinero" icon={faMoneyBill} link="/money" selectedColor="#2B91A7" />
            {/* <MenuItem {...props} text="Pedidos" icon={faShoppingCart} link="" selectedColor="#2B91A7"/> */}
            <MenuItem {...props} text="Punto de Venta" icon={faShoppingCart} link="/pos" selectedColor="#2B91A7" />
            <MenuDivider />
            <MenuItem {...props} text="Tienda" icon={faStore} link={API_BASE_URL} isExternal={true} selectedColor="#2B91A7" />
            <MenuItem {...props} text="Configuración" icon={faCog} link="/distributor/config" selectedColor="#2B91A7" />
        </div>
    );
};

export default AdminMenu;
