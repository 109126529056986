import React from 'react';
import Datum from './Datum';
import {ColoredDatumWrapper, ColoredDatumIcon} from './style';

const ColoredDatum = (props) => {

  const getIconStatus = () => {
    if(props.icon) return props.icon;

  }

  return (
    <Datum style={props.wrapperStyle}>
      <ColoredDatumWrapper variant={props.variant} style={props.style}>
        {props.children}
        <ColoredDatumIcon icon={getIconStatus()}/>
      </ColoredDatumWrapper>
    </Datum>
  )
}

export default ColoredDatum;
