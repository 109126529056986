import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CircularProgressSpinner } from 'components/Spinners';
import { RadioButton } from '../components';
import { updateCart } from 'actions/pos/cartActions';
import { clearPayments } from 'actions/pos/paymentActions';
import { createPayment, deletePayment } from 'actions/pos/paymentActions';

const UserCardsList = ({ creditCards, client, selectedCreditCardId, setSelectedCreditCardId, selectedPaymentMethodId, ...props }) => {
    const [cardPayment, setCardPayment] = useState(null);

    useEffect(() => {
        if (creditCards.length > 0) {
            setSelectedCreditCardId(creditCards[0].id);
        }
    }, [creditCards]);

    useEffect(() => {
        if (props.payments.loaded && props.payments.data?.length) {
            let payment = getCheckoutPayment();
            if (payment) {
                setCardPayment(payment);
            } else if (cardPayment) {
                setCardPayment(null);
            }
        }
    }, [props.payments.data, selectedPaymentMethodId]);

    function getCheckoutPayment() {
        if (!props.payments.data) return null;
        return props.payments.data.find((payment) => payment.attributes.payment_method_id === parseInt(selectedPaymentMethodId) && payment.attributes.state === 'checkout');
    }

    return (
        <div className="flex flex-column">
            {creditCards.map((creditCard, i) => {
                let { cc_type, last_digits, year, month } = creditCard.attributes;
                return <RadioButton key={creditCard.id + i} checked={selectedCreditCardId === creditCard.id} label={`${cc_type.toUpperCase()} **** ${last_digits} Expira ${month}/${year}`} value={creditCard.id} onClick={() => setSelectedCreditCardId(creditCard.id)} />;
            })}

            {!creditCards.length && <span style={{ textAlign: 'center', color: 'white' }}>No hay tarjetas guardadas</span>}
        </div>
    );
};

const mapStateToProps = (state) => ({
    client: state.posClient,
    creditCards: state.creditCard,
    cart: state.posCart,
    payments: state.posPayment,
});

const mapDispatchToProps = {
    updateCart,
    createPayment,
    deletePayment,
    clearPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserCardsList);
