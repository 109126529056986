import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { DefaultModal } from './';
import { Title, BasicInput } from '../Form';

import { changeUserPassword } from '../../actions/userActions';

const ChangePasswordModal = (props) => {
    const [show, setShow] = useState(false);
    const [inputData, setInputData] = useState({ confirm_password: '', current_password: '', new_password: '' });
    const [formValid, setFormValid] = useState(false);

    useEffect(() => {
        if (props.user.isLoggedIn) {
            setShow(props.user.data.attributes.change_password_needed);
        }
    }, [props.user]);

    useEffect(() => {
        let validator = [];
        validator.push(inputData.confirm_password === inputData.new_password);
        validator.push(inputData.new_password.length > 4);
        validator.push(inputData.current_password !== inputData.new_password);
        setFormValid(validator.every(Boolean));
    }, [inputData]);

    const handleSave = () => {
        if (formValid) {
            props.changeUserPassword({ user_id: props.user.data?.id, ...inputData }).then((res) => {
                let alert_info = {
                    404: {
                        message: res.error,
                        header: 'error',
                        state: 'error',
                    },
                    200: {
                        message: 'Has cambiado tu contraseña, ahora tu cuenta es mas segura.',
                        header: 'Actualizado!',
                        state: 'success',
                    },
                };

                Swal.fire(alert_info[res.status].header, alert_info[res.status].message, alert_info[res.status].state);
            });
        }
    };

    const handleInputChange = (e) => {
        let val = e.target.value;
        let id = e.target.id;
        setInputData({ ...inputData, [id]: val });
    };

    return (
        <div>
            <DefaultModal show={show} onHide={() => setShow(false)} onSave={handleSave} title="Cambia tu contraseña" disabledSaveButton={!formValid}>
                <Title>Por seguridad, te recomendamos que cambies tu contraseña.</Title>
                <BasicInput id={'new_password'} value={inputData.new_password} placeholder={'Escribe tu nueva contraseña...'} onChange={handleInputChange} title={'Nueva contraseña'} />

                <BasicInput id={'confirm_password'} value={inputData.confirm_password} placeholder={'Confirma tu nueva contraseña...'} onChange={handleInputChange} title={'Confirma tu nueva contraseña'} />
                <div style={{ paddingTop: '15px', borderTop: '1px solid rgba(255,255,255,0.12)' }}>
                    <BasicInput id={'current_password'} value={inputData.current_password} placeholder={'Escribe aquí tu contraseña actual...'} onChange={handleInputChange} title={'Contraseña actual'} />
                </div>
            </DefaultModal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    changeUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
