import React from 'react';
import { PersonInfoWrapper, PersonName, PersonDetail, ReportPersonContainer, VertLine } from './style';

const ReportPerson = (props) => {
    return (
        <>
            <ReportPersonContainer active={props.active === 'true'} isChildren={props.isChildren} onClick={props.onClick}>
                <PersonInfoWrapper>
                    <PersonName>{props.name}</PersonName>
                    <PersonDetail>{props.detail}</PersonDetail>
                </PersonInfoWrapper>
                {/*  <ProfileImage>
                    <ProfilePicture />
                </ProfileImage>*/}
            </ReportPersonContainer>
            {props.showLine && <VertLine />}
        </>
    );
};

export default ReportPerson;
