import React, { Component } from 'react';
import { connect } from 'react-redux';

import { SettingsSidebar, Header, HeaderTabs } from '../../../components/RightSidebar';

import SettingsPage from './SettingsPage.js';
import ChatPage from './ChatPage';
import ConversationPage from './ConversationPage';

import { toggleRightSidebarOpen } from '../../../actions/rightSideBarActions';

class RightSidebar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeHeaderTab: 'chat',
            conversationOpen: false,
            conversationOpenInfo: {},
            chatWriteValue: '',
            selectedConversationMessages: {
                user9865: [
                    {
                        32123: {
                            type: 'sent',
                            dateTime: '02/06/2019 5:59PM',
                            content: 'Como andas?',
                        },
                    },
                    {
                        32123: {
                            type: 'received',
                            dateTime: '02/06/2019 6:02PM',
                            content: 'Muy bien y tu',
                        },
                    },
                    {
                        32123: {
                            type: 'received',
                            dateTime: '02/06/2019 6:02PM',
                            content: 'Muy bien y tu',
                        },
                    },
                ],
            },
            chats: [
                {
                    Recent: [
                        {
                            user9865: {
                                avatarUrl: 'http url',
                                name: 'David',
                                lastMessage: 'Hello There!',
                                status: 'Available',
                                lastSeen: '2h ago',
                                lastMessageDate: '16:20',
                            },
                        },
                        {
                            user9865: {
                                avatarUrl: 'http url',
                                name: 'Bob',
                                status: 'Away',
                                lastSeen: '2h ago',
                                lastMessage: 'Whats new?!',
                                lastMessageDate: '16:20',
                            },
                        },
                    ],
                },
                {
                    Older: [
                        {
                            user9865: {
                                avatarUrl: 'http url',
                                name: 'Tom',
                                status: 'Away',
                                lastSeen: '2h ago',
                                lastMessage: 'Hello There!',
                                lastMessageDate: '16:20',
                            },
                        },
                        {
                            user9865: {
                                avatarUrl: 'http url',
                                name: 'David',
                                status: 'Busy',
                                lastSeen: '2h ago',
                                lastMessage: 'Hello There!',
                                lastMessageDate: '16:20',
                                isActive: true,
                            },
                        },
                        {
                            user9865: {
                                avatarUrl: 'http url',
                                name: 'David',
                                status: 'Disconnected',
                                lastSeen: '2h ago',
                                lastMessage: 'Hello There!',
                                lastMessageDate: '16:20',
                            },
                        },
                    ],
                },
            ],
            switches: [
                //refactor to query db for switches
                {
                    General: [
                        {
                            Notifications: {
                                checked: true,
                            },
                        },
                        { 'Activity Logs': {} },
                        {
                            Messaging: {
                                checked: true,
                            },
                        },
                        {
                            'Automatic Updates': {
                                disabled: true,
                            },
                        },
                    ],
                },
                {
                    Account: [
                        {
                            Chat: {
                                checked: true,
                            },
                        },
                        {
                            'Public Mode': {},
                        },
                        {
                            'Admin Mode': {},
                        },
                        {
                            'Incognito Mode': {},
                        },
                    ],
                },
            ],
        };

        this.handleActiveHeader = this.handleActiveHeader.bind(this);
        this.toggleIsOpen = this.toggleIsOpen.bind(this);
        this.toggleSetting = this.toggleSetting.bind(this);
        this.toggleConversationOpen = this.toggleConversationOpen.bind(this);
        this.doSendMessage = this.doSendMessage.bind(this);
        this.handleChatWriteChange = this.handleChatWriteChange.bind(this);

        this.conversationMessagesEnd = React.createRef();
    }

    toggleIsOpen() {
        this.props.toggleRightSidebarOpen();
    }

    toggleConversationOpen(chatInfo) {
        this.setState({
            conversationOpen: !this.state.conversationOpen,
            conversationOpenInfo: chatInfo,
        });
    }

    handleActiveHeader(e) {
        this.setState({
            activeHeaderTab: e.currentTarget.children[0].text,
        });
    }

    toggleSetting(e) {
        console.log(e);
        //dispatch action selected
    }

    doSendMessage(e) {
        if (e.key === 'Enter') {
            let msgList = this.state.selectedConversationMessages;
            let newMsg = {
                32123: {
                    type: 'sent',
                    dateTime: '02/06/2019 6:02PM',
                    content: e.target.value,
                },
            };
            msgList[Object.keys(msgList)[0]].push(newMsg);
            this.setState({ chatWriteValue: '', selectedConversationMessages: msgList }, () => {
                this.conversationScrollToBottom();
            });
            //send value to api
            //then
        }
    }

    conversationScrollToBottom() {
        this.conversationMessagesEnd.current.scrollIntoView({ behavior: 'smooth' });
    }

    handleChatWriteChange(e) {
        this.setState({ chatWriteValue: e.target.value });
    }

    render() {
        return (
            <>
                <ConversationPage conversation={this.state.selectedConversationMessages} isOpen={this.state.conversationOpen} toggleIsOpen={this.toggleConversationOpen} conversationOpenInfo={this.state.conversationOpenInfo} doSendMessage={this.doSendMessage} chatWriteChange={this.handleChatWriteChange} chatWriteValue={this.state.chatWriteValue} messagesEndRef={this.conversationMessagesEnd} />
                {!this.state.conversationOpen && (
                    <SettingsSidebar isOpen={this.props.isOpen}>
                        <Header onClick={this.toggleIsOpen}>
                            <HeaderTabs active={this.state.activeHeaderTab} onClick={this.handleActiveHeader}>
                                chat
                            </HeaderTabs>
                            <HeaderTabs active={this.state.activeHeaderTab} onClick={this.handleActiveHeader}>
                                settings
                            </HeaderTabs>
                        </Header>
                        {this.state.activeHeaderTab == 'chat' && <ChatPage chats={this.state.chats} toggleConversationOpen={this.toggleConversationOpen} />}
                        {this.state.activeHeaderTab == 'settings' && <SettingsPage switches={this.state.switches} onClick={this.toggleSetting} />}
                    </SettingsSidebar>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isOpen: state.rightSidebarReducer.isOpen,
    };
};

export default connect(mapStateToProps, { toggleRightSidebarOpen })(RightSidebar);
