import axios from 'axios';

import { API_BASE_URL, SHOW_MULTI_LEVEL } from './types';

import { dispatchAction } from '../utils/actions/common';

export const showMultiLevel = (user_id = null) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/account/multi_level/${user_id}`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        }).then((res) => {
            return dispatchAction(dispatch, res, SHOW_MULTI_LEVEL);
        });
    };
};

export const addToNetwork = (params) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));

    return axios({
        method: 'PATCH',
        url: API_BASE_URL + `/es/app/api/v1/users/account/assign_distributor`,
        headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
        },
        params: params,
        json: true,
    })
        .then((res) => {})
        .catch(console.log);
};
