import React, {useState, useEffect} from 'react';
import {Print} from '@mui/icons-material';
import {ActionButton} from "./";
import TicketModal from '../modals/ticketModal';

const PrintActionButton = ({show, setShow}) => {
  const [showPrint, setShowPrint] = useState(false);

  useEffect(() => {
    setShowPrint(show);
  }, [show])

  useEffect(() => {
    setShow(showPrint);
  }, [showPrint])

  return (
    <>
      <ActionButton 
        onClick={() => setShowPrint(true)}
        icon={<Print fontSize="large"/>}
      />
      <TicketModal
        show={showPrint}
        onClose={() => setShowPrint(false)}
      />
    </>
  )
}

export default PrintActionButton;
