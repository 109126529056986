import { CREATE_CATEGORY, DELETE_CATEGORY, FETCH_ALL_CATEGORIES } from '../actions/types';

const initialState = {
  FETCH_ALL_CATEGORIES: [],
  category:   {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case CREATE_CATEGORY:
      return {...state, FETCH_ALL_CATEGORIES: handleCategoriesRequest(state, action)}
    case FETCH_ALL_CATEGORIES:
      return {...state, FETCH_ALL_CATEGORIES: {...state.FETCH_ALL_CATEGORIES}}
    case DELETE_CATEGORY:
      return {...state, FETCH_ALL_CATEGORIES: handleCategoriesRequest(state, action)}
    default:
      return state;
  }
}

const handleCategoriesRequest = (state=[], action) => {
  switch(action.type) {
    case CREATE_CATEGORY:
      return [
        ...state.FETCH_ALL_CATEGORIES,
        {
          ...action.payload
        }
      ];
    case DELETE_CATEGORY:
      return state.filter(category => category.id !== action.id)
    default:
      return state;
  }
}