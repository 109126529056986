import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

const MuiDatePicker = ({ label, onChange, value = null }) => {
    function getValue() {
        if (typeof value === 'string') {
            return moment(value, 'DD/MM/YYYY');
        }
        return value;
    }
    return (
        <DatePicker
            label={label}
            onChange={onChange}
            value={getValue()}
            format="DD/MM/YYYY"
            slotProps={{
                textField: {
                    sx: {
                        '& .MuiInputLabel-outlined': {
                            color: 'white',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& .MuiOutlinedInput-input': {
                                color: 'white',
                            },
                            '& fieldset': {
                                borderColor: '#93C5FD',
                                color: '#fff',
                            },
                            '& Mui-focused fieldset': {
                                borderColor: '#60A5FA',
                                borderWidth: '2px',
                            },
                            '&:hover fieldset': {
                                borderColor: '#3B82F6',
                                borderWidth: '2px',
                            },
                            '& .MuiSvgIcon-root': {
                                color: 'white',
                            },
                        },
                    },
                },
            }}
        />
    );
};

export default MuiDatePicker;
