import { requestBuilder, simpleRequestBuilder } from 'utils/actions/requests';

import { API_BASE_URL, POS_GET_PAYMENT_METHODS, POS_ADD_PAYMENT, POS_CLEAR_PAYMENTS, POS_REMOVE_PAYMENT, POS_SET_PAYMENTMETHODSELECTED, POS_SET_PAYMENTCREDITMETHODSELECTED } from '../types';

const STOREFRONT_API_URL = API_BASE_URL + '/es/api/v2/storefront/checkout/';

export const getPaymentMethods = (params) => async (dispatch) => {
    let data = await simpleRequestBuilder('get', STOREFRONT_API_URL + `payment_methods`, params);

    return dispatch({
        type: POS_GET_PAYMENT_METHODS,
        payload: data,
    });
};

export const getPayments = (params) => async (dispatch) => {
    return requestBuilder('GET', STOREFRONT_API_URL + `list_payments`, params, dispatch, POS_ADD_PAYMENT);
};

export const createPayment = (params) => async (dispatch) => {
    return requestBuilder('POST', STOREFRONT_API_URL + `create_payment`, params, dispatch, POS_ADD_PAYMENT);
};

export const createSimplePayment = (params) => async (dispatch) => {
    return simpleRequestBuilder('POST', STOREFRONT_API_URL + `create_payment`, params);
};

export const deletePayment = (params) => async (dispatch) => {
    return requestBuilder('DELETE', STOREFRONT_API_URL + `remove_payment`, params, dispatch, POS_REMOVE_PAYMENT);
};

export const clearPayments = () => async (dispatch) => {
    return dispatch({
        type: POS_CLEAR_PAYMENTS,
    });
};

export const setPaymentMethodForBuy = (method) => async (dispatch) => {
    return dispatch({
        type: POS_SET_PAYMENTMETHODSELECTED,
        payload: method,
    });
};

export const setPaymentCreditMethodForBuy = (method) => async (dispatch) => {
    return dispatch({
        type: POS_SET_PAYMENTCREDITMETHODSELECTED,
        payload: method,
    });
};
