export const getStartDateWithIntervals = (interval, count, intervalCount, dateTime) => {
    let newDate = new Date(dateTime);

    switch (interval) {
        case 'day':
            return newDate.setDate(newDate.getDate() + count * intervalCount);
        case 'week':
            return newDate.setDate(newDate.getDate() + count * 7 * intervalCount);
        case 'month':
            return newDate.setDate(newDate.getDate() + count * 30 * intervalCount);
        case 'year':
        default:
            return newDate.setFullYear(newDate.getFullYear() + count);
    }
};

export const getIntervalToStart = (interval, time) => {
    /* let intervalToStart = '';
    switch (interval) {
        case 'day':
            intervalToStart = time;
            if (intervalToStart.Contains('.')) {
                intervalToStart = (Convert.ToInt32(intervalToStart.Split('.')[0]) + 1).ToString();
            } else {
                intervalToStart = '0';
            }
            return intervalToStart;
        case 'week':
            intervalToStart = (time / 7).ToString();
            if (intervalToStart.Contains('.')) {
                intervalToStart = (Convert.ToInt32(intervalToStart.Split('.')[0]) + 1).ToString();
            } else {
                intervalToStart = '0';
            }
            return intervalToStart;
        case 'month':
            intervalToStart = (time / 30).ToString();
            if (intervalToStart.Contains('.')) {
                intervalToStart = (Convert.ToInt32(intervalToStart.Split('.')[0]) + 1).ToString();
            } else {
                intervalToStart = '0';
            }
            return intervalToStart;
        case 'year':
        default:
            intervalToStart = (time / 365).ToString();
            if (intervalToStart.Contains('.')) {
                intervalToStart = (Convert.ToInt32(intervalToStart.Split('.')[0]) + 1).ToString();
            } else {
                intervalToStart = '0';
            }
            return intervalToStart;
    }*/
};
