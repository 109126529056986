import { POS_GET_CART, POS_ADD_TO_CART, POS_REMOVE_FROM_CART, POS_CLEAR_CART, POS_UPDATE_CART_AUTOFOCUS, POS_UNSELECT_CART } from '../../actions/types';

import reducerHandler from '../reducerHandler';

const initialState = {
    data: null,
    isLoading: false,
    loaded: false,
    error: null,
    autoFocus: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case POS_ADD_TO_CART.loading:
        case POS_ADD_TO_CART.success:
        case POS_ADD_TO_CART.error:
            return reducerHandler(state, action, POS_ADD_TO_CART);

        case POS_GET_CART.loading:
        case POS_GET_CART.success:
        case POS_GET_CART.error:
            return reducerHandler(state, action, POS_GET_CART);

        case POS_REMOVE_FROM_CART.loading:
        case POS_REMOVE_FROM_CART.success:
        case POS_REMOVE_FROM_CART.error:
            return reducerHandler(state, action, POS_REMOVE_FROM_CART);
        case POS_UPDATE_CART_AUTOFOCUS:
            return {
                ...state,
                autoFocus: action.payload,
            };
        case POS_UNSELECT_CART:
            return initialState;
        default:
            return state;
    }
}
