import {
  GET_SCHEDULED_PAYMENTS,
} from '../actions/types';

const initialState = {
  list: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SCHEDULED_PAYMENTS:
      return {
        ...state,
        list: action.payload.data,
        meta: action.payload.meta,
        links: action.payload.links,
      }
    default:
      return state;
  }
}
