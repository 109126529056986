import axios from 'axios'
import {
  API_BASE_URL,
  UPLOAD_OFFICIAL_ID, 
  UPDATE_CURP,
  UPDATE_REGISTER_DOCUMENTS_STATUS,
  UPDATE_REGISTER_DOCUMENTS} from './types';
import {dispatchAction} from '../utils/actions/common';

export const uploadOfficialIdentityFile = (data) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'POST',
      url: API_BASE_URL+'/es/app/api/v1/users/candidate/setup/official_identity.js',
      headers: {
        'X-User-Email': data.credentials.email,
        'X-User-Token': token.access_token,
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: data.data,
      json: true
    }).then((res) => {
      return dispatchAction(dispatch, res, UPLOAD_OFFICIAL_ID);
    })
  }
}

export const requestFiles = () => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
        url: API_BASE_URL+'/es/app/api/v1/users/candidate/setup/request_register_files.js',
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      return dispatchAction(dispatch, res, UPDATE_REGISTER_DOCUMENTS);
    })
  }
} 

export const requestRegisterDocumentStatus = () => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+'/es/app/api/v1/users/candidate/setup/register_document_status.js',
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      return dispatchAction(dispatch, res, UPDATE_REGISTER_DOCUMENTS_STATUS)
    })
  }
}

export const updateCurp = (val) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'POST',
      url: API_BASE_URL+'/es/app/api/v1/users/candidate/setup/update_curp.js',
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {register_document: {curp: val}},
      json: true
    }).then(res => {
      return dispatchAction(dispatch, res, UPDATE_CURP)
    })
  }
}