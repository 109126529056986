import {
  SHOW_MULTI_LEVEL
} from '../actions/types';

const initialState = {
  networks: [],
}

export default function(state= initialState, action) {
  switch (action.type) {
    case SHOW_MULTI_LEVEL:
      return {
        ...state,
        networks: addOrUpdatePayload(state, action)
      }
    default:
      return state;
  }
}

const addOrUpdatePayload = (state, action) => {
  let action_id = action.payload.user_id;
  let networks = [
    ...state.networks.map(
      network => network.user_id === action_id ? formattedPayload(action.payload) : network
    ),
  ]
  if (!networks.find(network => network.user_id === action_id)) {
    networks = [
      ...networks,
      formattedPayload(action.payload),
    ]
  }
  return networks;
}

const formattedPayload = (payload) => {
  return {
    ...payload,
    distributors: JSON.parse(payload.distributors)
  }
}