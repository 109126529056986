import Styled, { css } from 'styled-components';
import { mobileScreenSize } from 'utils/screen';

export const DirectReportsContainer = Styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  
  padding-top: 1.2rem;
  border-top: thin solid #bdbdbd;

   @media (max-width: ${mobileScreenSize}px) {
  min-width: unset;
  }
`;

export const HeaderContainer = Styled.div`
  box-sizing: border-box;
  flex: none;
  padding: 0;
  white-space: nowrap;
  border-bottom: thin solid #bdbdbd;
`;

export const HeaderContent = Styled.div`
  padding: 0.5rem 1.2rem 0rem 2rem;
  display: flex;
  align-items: stretch;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  @media (max-width: ${mobileScreenSize}px) {

  }
`;

export const UserInfoWrapper = Styled.div`
  margin: -0.3rem 0 0;
  width: auto;
  max-width: calc(100% - 32rem);
  min-width: min-content;
  height: min-content;
`;

export const UserInfoTitle = Styled.div`
  max-width: 29rem;
  width: auto;
  overflow: hidden;
  margin-left: 1.2rem;
  white-space: nowrap;
  display: inline-block;
  vertical-align: top;
  font-weight: 700;
  font-size: 1.8rem;
  margin: 0.5rem 0 0;
  line-height: 1.333;
  text-overflow: elipsis;
  @media (max-width: ${mobileScreenSize}px) {
    margin-left: 0;
  }
`;

export const TabOptions = Styled.div`
  height: min-content;
  margin: 0 0 0 4rem;
  display: flex;
  @media (max-width: ${mobileScreenSize}px) {
    margin-left: 0;
  }
`;

export const SearchContainer = Styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 6.8rem;
  justify-content: flex-end;
  margin-top: 0.1rem;
  z-index: 1;
  height: 3.2rem;
  @media (max-width: ${mobileScreenSize}px) {
    justify-content: flex-start;
  }
`;

export const MainContainer = Styled.div`
  position: relative;
  width: 100%;
  background-color: #f5f5f5;
  min-height: 100vh;
`;

export const UsersContainer = Styled.div`
  padding: 0 3.2rem;
  @media (max-width: ${mobileScreenSize}px) {
    padding: 1rem;
  }

`;

export const PeopleSearchBox = Styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  width: 20rem;
  height: 2.2rem;
`;

export const PeopleSearchInput = Styled.input`
  display: flex;
  flex: 0 0 auto;
  border: 0;
  padding: 0.6rem 1.2rem;
  height: 100%;
  &:focus {
    outline: thin;
  }
`;

export const BodyContainer = Styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-evenly;
  @media (max-width: ${mobileScreenSize}px) {
    flex-flow: column;
  }
`;

export const RankStatusHeaderTitle = Styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: ${(props) => props.color || '#20C261'};
`;

export const RankStatusHeader = Styled.div`
  padding: 24px;
  position: relative;
  margin-top: 24px;
  margin-right: 24px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 10%);
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
`;

export const RankStatusVerticalLine = Styled.div`
  background-color: ${(props) => props.color || '#20C261'};
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  width: 4px;
`;

export const RankStatusTitleContainer = Styled.div`
  margin-top: 30px;
  width: 100%;
  @media (max-width: ${mobileScreenSize}px) {
    min-width: 100%;
  }

`;

export const RankStatusTitle = Styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.17;
  color: ${(props) => props.color || '#20C261'};

`;
export const RankStatusDescription = Styled.div`
  color: black;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  padding-top: 15px;
`;

export const SectionTitle = Styled.div`
  color: #333;
  font-size: 20px;
  font-wieght: 600;
  line-height: 1;
  margin-top: 48px;
`;
