import { onlyNumbers } from 'utils/formatters';
import { validateLaterThanToday, isValidDate, isEarlierThan } from './dates';

export const validateFields = (props) => {
    if (!props.validators.required && !!props.value === false) {
        return { validInput: true };
    }
    let validators = Object.entries(props.validators);
    let errorList = validators.map((validatorObj) => {
        let [key, val] = validatorObj;
        switch (key) {
            case 'required':
                if (val) {
                    return {
                        error: 'Este campo no puede estar en blanco',
                        showAlert: !props.value.length,
                        type: 'required',
                        valid: !!props.value.length,
                    };
                }
            case 'selectionRequired':
                if (val) {
                    let isValid = props.value.length > 0;
                    if (props.selectedIndex === 0) isValid = false;
                    return {
                        error: 'Debes seleccionar una opción de la lista',
                        showAlert: !isValid,
                        type: 'selectionRequired',
                        valid: isValid,
                    };
                }
            case 'length':
                let validationRes = {
                    type: 'length',
                    valid: true,
                    showAlert: false,
                };
                if (typeof val === 'object') {
                    if (val.min && val.min >= props.value.length) {
                        validationRes = {
                            ...validationRes,
                            error: `Este campo debe ser mayor a ${val.min} caracteres.`,
                            showAlert: true,
                            valid: false,
                        };
                    }
                    if (val.max && val.max < props.value.length) {
                        validationRes = {
                            ...validationRes,
                            error: `Este campo debe ser menor a ${val.max} caracteres.`,
                            showAlert: true,
                            valid: false,
                        };
                    }
                } else if (typeof val === 'number') {
                    if (val < props.value.length) {
                        validationRes = {
                            ...validationRes,
                            error: `Este campo debe ser menor a ${val.max} caracteres.`,
                            showAlert: true,
                            valid: false,
                        };
                    }
                }
                return validationRes;
            case 'isPhoneNumber':
                let isPhoneNumber = {
                    type: 'isPhoneNumber',
                    valid: true,
                    showAlert: false,
                };
                if (props.value && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(props.value)) {
                    isPhoneNumber = {
                        type: 'isPhoneNumber',
                        valid: false,
                        showAlert: true,
                        error: `Este campo debe ser un numero de teléfono valido.`,
                    };
                }
                return isPhoneNumber;
            case 'isNumber':
                let isNumberRes = {
                    type: 'isNumber',
                    valid: true,
                    showAlert: false,
                };
                if (props.value && !/^[-+]?[0-9]+(?:,[0-9]{3})*(?:\.[0-9]+)?$/.test(props.value)) {
                    isNumberRes = {
                        ...isNumberRes,
                        error: `Este campo solo acepta numeros`,
                        showAlert: true,
                        valid: false,
                    };
                }
                return isNumberRes;
            case 'greaterThan': {
                let greaterThan = {
                    type: 'greaterThan',
                    valid: true,
                    showAlert: false,
                };
                if (parseFloat(props.value) <= val) {
                    greaterThan = {
                        ...greaterThan,
                        error: `Debe ser un numero mayor a ${val}`,
                        showAlert: true,
                        valid: false,
                    };
                }
                return greaterThan;
            }
            case 'isPositive': {
                let isPositiveRes = {
                    type: 'isPositive',
                    valid: true,
                    showAlert: false,
                };
                if (!/^[+]?[0-9]+(?:,[0-9]{3})*(?:\.[0-9]+)?$/.test(props.value)) {
                    isPositiveRes = {
                        ...isPositiveRes,
                        error: `Solo se permiten números positivos`,
                        showAlert: true,
                        valid: false,
                    };
                }
                return isPositiveRes;
            }
            case 'isEmail': {
                let isEmailRes = {
                    type: 'isEmail',
                    valid: true,
                    showAlert: true,
                };
                if (!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(props.value)) {
                    isEmailRes = {
                        valid: false,
                        showAlert: false,
                        error: `Por favor introduce un correo electrónico valido`,
                    };
                }
                return isEmailRes;
            }
            case 'isDate': {
                let isDateRes = {
                    type: 'isDate',
                    valid: true,
                    showAlert: true,
                };

                if (!isValidDate(props.value)) {
                    isDateRes = {
                        valid: false,
                        showAlert: false,
                        error: `Por favor introduce una fécha valida`,
                    };
                }

                return isDateRes;
            }
            case 'equals': {
                let isValid = props.value === val;
                return {
                    error: 'Confirmación de contraseña invalida.',
                    showAlert: !isValid,
                    type: 'passwordConfirmation',
                    valid: isValid,
                };
            }
            case 'after_today': {
                let isValid = validateLaterThanToday(props.value);
                return {
                    error: 'La fecha seleccionada debe ser después de hoy.',
                    showAlert: !isValid,
                    type: 'after_today',
                    valid: isValid,
                };
            }
            case 'before_date': {
                let isValid = isEarlierThan(props.value, val);
                return {
                    error: 'La fecha seleccionada es muy reciente.',
                    showAlert: !isValid,
                    type: 'before_date',
                    valid: isValid,
                };
            }
            default:
                return;
        }
    });
    errorList['validInput'] = errorList.filter(Boolean).every((x) => x.valid);
    return errorList;
};
