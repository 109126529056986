import React from 'react';
import { ActionButtonsWrapper, ButtonsContainer, PersonCardContainer, PersonName, PersonDetail, PersonCardInfoWrapper, VertLine } from './style';
import { ActionButton } from 'components/Button';

import { faEnvelope, faUser, faPhone } from '@fortawesome/free-solid-svg-icons';

const PersonCard = (props) => {
    return (
        <>
            <PersonCardContainer active={props.active === 'true'} onClick={props.onClick}>
                <PersonCardInfoWrapper>
                    <PersonName>{props.name}</PersonName>
                    <PersonDetail>{props.detail}</PersonDetail>
                    <PersonDetail>{props.detail2}</PersonDetail>
                    <PersonDetail>{props.detail3}</PersonDetail>
                </PersonCardInfoWrapper>
                {/*<PersonCardProfileImage>
                    <ProfilePicture size={'5.3rem'} />
                </PersonCardProfileImage>*/}
                <ActionButtonsWrapper>
                    <ButtonsContainer>
                        <ActionButton icon={faEnvelope} />
                        <ActionButton icon={faUser} />
                        <ActionButton icon={faPhone} />
                    </ButtonsContainer>
                </ActionButtonsWrapper>
            </PersonCardContainer>
            {props.showLine && <VertLine />}
        </>
    );
};

export default PersonCard;
