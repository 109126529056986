import React from 'react';
import { ModalContainer, ModalHeader, ModalTitle, ModalBody, ModalFooter, CloseButton, SaveButton } from './style';
import { CircularProgressSpinner } from 'components/Spinners';

const DefaultModal = (props) => {
    return (
        <ModalContainer {...props} size={props.size || 'lg'} aria-labelledby="contained-modal-title-vcenter" centered>
            <ModalHeader closeButton>
                <ModalTitle id="contained-modal-title-vcenter">{props.title}</ModalTitle>
            </ModalHeader>
            <ModalBody>{props.show && props.children}</ModalBody>
            {props.customFooter && props.customFooter()}
            {!props.customFooter && (
                <ModalFooter>
                    {props.isLoading ? (
                        <CircularProgressSpinner />
                    ) : (
                        <>
                            <CloseButton variant="secondary" onClick={props.onClose || props.onHide}>
                                {props.closeButtonText || 'Cerrar'}
                            </CloseButton>
                            {!!props.onSave && (
                                <SaveButton disabled={props.disabledSaveButton} onClick={props.onSave}>
                                    {props.saveButtonText || 'Guardar cambios'}
                                </SaveButton>
                            )}
                        </>
                    )}
                </ModalFooter>
            )}
        </ModalContainer>
    );
};

export default DefaultModal;
