import React, { useState, forwardRef } from 'react';
import { dateFormat, timeFormat, variantStates } from 'utils/common';
import QRCode from 'react-qr-code';
import UniqueLogo from 'assets/images/Uniquelogo.png';
import moment from 'moment';

const Ticket = forwardRef(({ order, paymentPlan, ...props }, ref) => {

    function displayName(name) {
        if (name.length > 19) {
            return name.substring(0, 19) + '...';
        } else {
            return name;
        }
    }

    function scheduledPayments() {
        if (!order.attributes.scheduled_payments) return [];
        return order.attributes.scheduled_payments;
    }

    function getLastScheduledPayment() {
        return scheduledPayments()[scheduledPayments().length - 1];
    }

    function getNextScheduledPayment() {
        return scheduledPayments().find((payment) => payment.state !== 'complete' && new Date() < new Date(payment.due_date));
    }

    return (
        <div ref={ref} className="flex flex-column items-start justify-start w-80 border-2 border-black bg-white px-4 py-8 drop-shadow-lg shadow-lg rounded-lg text-black">
            <div className="flex w-full items-center justify-center">
                <img src={UniqueLogo} height="40" width="40" alt="logo" style={{ marginBottom: '5px', marginRight: '4px' }} />
                <div className="font-bold pb-2 text-lg">UNIQUE BROTHER</div>
            </div>

            <div className="flex flex-column justify-center items-center w-full mb-2">
                <div className="">Av Hidalgo entre Treviño y Falcón</div>
                <div>Primitivo Centro</div>
                <div>27000 Torreón, Coah.</div>
                <div>TEL. 871 712 8070</div>
            </div>

            <div className="flex w-full">
                <div className="">{dateFormat(order.attributes.completed_at)}</div>
                <div className="ml-2">{timeFormat(order.attributes.completed_at, true)}</div>
            </div>

            <div className="flex">
                <div className="">Pedido:</div>
                <div className="ml-2">{order.attributes.number}</div>
            </div>

            <div className="w-full border-t-2 border-black pt-2 my-2">
                <div className="text-xl mb-2">Artículos ({order.attributes.item_count})</div>

                {order.attributes.line_items.data.map((item, index) => (
                    <div className="flex flex-column mb-1 w-full">
                        <div className="flex w-full">
                            <div className="">{item.attributes.quantity}x</div>
                            <div className="ml-1 flex-1">{displayName(item.attributes.name)}</div>
                            <div className="ml-1">
                                {item.attributes.price} {item.attributes.currency}
                            </div>
                        </div>
                        <div className="flex">
                            * <div className="">{item.attributes.options_text}</div>
                        </div>
                    </div>
                ))}
            </div>

            {parseInt(order.attributes.total_applied_store_credit) > 0 && (
                <>
                    <div className="w-full text-xl border-t-2 border-black pt-2">Financiamiento</div>

                    <div className="flex flex-column w-full mb-3">
                        <div className="flex">
                            <div className="">Plan: </div>
                            <div className="ml-2">{paymentPlan.name}</div>
                        </div>
                        {parseInt(order.attributes.total_applied_store_credit) > 0 && (
                            <div className="flex">
                                <div className="">Crédito usado: </div>
                                <div className="ml-2">
                                    $ {order.attributes.total_applied_store_credit} {order.attributes.currency}
                                </div>
                            </div>
                        )}
                        <div className="flex justify-between w-full">
                            <div>
                                <div className="">Pagos</div>
                                <div className="">{paymentPlan.total_intervals}</div>
                            </div>
                            <div>
                                {!!getNextScheduledPayment() && (
                                    <>
                                        <div className="">Próximo</div>
                                        <div className="">{dateFormat(getNextScheduledPayment()?.due_date)}</div>
                                    </>
                                )}
                            </div>
                            <div>
                                {!!getNextScheduledPayment() && (
                                    <>
                                        <div className="">Último</div>
                                        <div className="">{dateFormat(getLastScheduledPayment()?.due_date)}</div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

            {!!order.attributes.payment_plan_id && !!order.attributes.scheduled_payments.length && (
                <div className="w-full mb-3">
                    <div className="text-xl">Plan de Pagos</div>
                    <div className="flex justify-between w-full">
                        <div className="">Estado</div>
                        <div className="">Fecha</div>
                        <div className="">cantidad</div>
                    </div>

                    <div className="flex flex-column w-full">
                        {order.attributes.scheduled_payments.map((payment, index) => (
                            <div className="flex flex-row w-full justify-between">
                                <div className="text-sm">{index + 1}</div>
                                <div className="text-sm">{variantStates[payment.state].text}</div>
                                <div className="text-sm">{moment(payment.due_date).format('DD/MM/YYYY')}</div>
                                <div className="text-sm">
                                    $ {payment.amount} {order.attributes.currency}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {parseFloat(order.attributes.benefit_total) > 0 && (
                <div className="flex w-full justify-between border-t-2 border-black pt-2">
                    <div className="text-xl">DESCUENTO</div>
                    <div className="text-xl">
                        - $ {order.attributes.benefit_total} {order.attributes.currency}
                    </div>
                </div>
            )}

            {parseInt(order.attributes.ship_total) > 0 && (
                <div className="flex w-full justify-between">
                    <div className="text-xl">ENVÍO</div>
                    <div className="text-xl">
                        {order.attributes.ship_total} {order.attributes.currency}
                    </div>
                </div>
            )}

            <div className="flex w-full justify-between mb-2">
                <div className="text-xl">TOTAL</div>
                <div className="text-xl">
                    {order.attributes.display_item_total} {order.attributes.currency}
                </div>
            </div>

            <div className="flex flex-column w-full items-center justify-center mb-2">
                <div className="">*** IVA INCLUIDO EN EL PRECIO ***</div>
                <div className="">*** GRACIAS POR SU COMPRA ***</div>
            </div>

            <div className="flex w-full items-center justify-center mb-2">
                <QRCode value={`https://www.uniquebrother.com/orders/${order.attributes.number}`} size={128} />
            </div>

            <div className="flex w-full justify-center">
                <div className="text-lg">WWW.UNIQUEBROTHER.COM</div>
            </div>
        </div>
    );
});

export default Ticket;
