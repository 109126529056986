import React, { useState, useEffect } from 'react';
import {
  MembershipSummaryActionContainer,
  MembershipDataWrapper,
  SummaryActionHeader,
  SummaryActionItem,
  SummaryActionItemTitle,
} from './style';

const SummaryTabMembership = (props) => {
  const states = {
    active: "Activa",
    paid: "Pagada",
    inactive: "Inactiva",
    expired: "Expirada",
    one_time: "Manual",
    subscription: "Automatica"
  }
  const [membership, setMembership] = useState();
  const [paidSubscription, setPaidSubscription] = useState();

  useEffect(() => {
    setMembership(props.candidate?.membership)
  }, [props.candidate])

  useEffect(() => {
    if (membership) {
      setPaidSubscription(getPaidSubscription())
    }
  }, [membership])

  const getPaidSubscription = () => {
    return membership.subscriptions.find(sub => sub.state === "paid")
  }

  const getStateText = (state) => {
    return states[state] || "No hay información"
  }

  return (
    <>
      {!membership ? 
        <div>cargando...</div> : 

        <MembershipSummaryActionContainer>
          <SummaryActionHeader>
            Membresía:
          </SummaryActionHeader>
          <MembershipDataWrapper>
            <SummaryActionItem>
              <SummaryActionItemTitle>
                Renovación:
              </SummaryActionItemTitle>
              <div>{getStateText(membership.subscription_type)}</div>
            </SummaryActionItem>

            <SummaryActionItem>
              <SummaryActionItemTitle>
                Plan:
              </SummaryActionItemTitle>
              <div>{paidSubscription?.plan_id}</div>
            </SummaryActionItem>

            <SummaryActionItem>
              <SummaryActionItemTitle>
                Suscripción:
              </SummaryActionItemTitle>
              <div>{getStateText(paidSubscription?.state)}</div>
            </SummaryActionItem>

            <SummaryActionItem>
              <SummaryActionItemTitle>
                Fecha de Actividad:
              </SummaryActionItemTitle>
              <MembershipDataWrapper>
                {paidSubscription?.purchase_date ? 
                  <div>
                    <div>Fecha de pago: {paidSubscription.payment_date}</div>
                  </div> :
                  <div> No ha iniciado. </div>
                }
                
              </MembershipDataWrapper>
            </SummaryActionItem>
          </MembershipDataWrapper>
        </MembershipSummaryActionContainer>
      }
    </>
  )
}

export default SummaryTabMembership;
