import React, { useState } from 'react';
import { DataTable, DataTbody, DataThead, Header, Row, Datum, MomentDatum, ColoredDatum } from '../../../../components/Table';
import { TabPageWrapper, TabPageHeader, TabPageNumber, TabPageTitle, TabPageSummary } from '../style';
import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';

const PaymentTab = (props) => {
    const statusObj = {
        paid: {
            type: 'success',
            text: 'Pagado',
            icon: faCheck,
        },
        balance_due: {
            type: 'warning',
            text: 'Pendiente',
            icon: faExclamationTriangle,
        },
        scheduled: {
            type: 'warning',
            text: 'Pendiente',
            icon: faExclamationTriangle,
        },
        delayed: {
            type: 'danger',
            text: 'Retrasado',
            icon: faTimes,
        },
        failed: {
            type: 'danger',
            text: 'Fallido',
            icon: faTimes,
        },
    };

    const _renderScheduledPaymentsDuePaid = (flag) => {
        let sum = 0;
        if (props.client.orders.length) {
            let orders = props.client.orders;

            orders.forEach((order) => {
                sum +=
                    flag === 'due'
                        ? order.scheduled_payments.reduce((acc, cv) => {
                              return parseFloat(acc) + parseFloat(cv.amount) - parseFloat(cv.amount_paid);
                          }, 0)
                        : order.scheduled_payments.reduce((acc, cv) => {
                              return parseFloat(acc) + parseFloat(cv.amount_paid);
                          }, 0);
            });
        }

        return sum.toFixed(2);
    };

    return (
        <TabPageWrapper>
            <TabPageHeader>Pagos del cliente</TabPageHeader>
            <TabPageSummary>
                <div>
                    <TabPageTitle>Total debido</TabPageTitle>
                    <TabPageNumber>$ {_renderScheduledPaymentsDuePaid('due')} MXN</TabPageNumber>
                </div>
                <div>
                    <TabPageTitle>Total pagado</TabPageTitle>
                    <TabPageNumber>$ {_renderScheduledPaymentsDuePaid('paid')} MXN</TabPageNumber>
                </div>
            </TabPageSummary>
        </TabPageWrapper>
    );
};

export default PaymentTab;
