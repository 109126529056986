import Styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { mobileScreenSize } from "utils/screen";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const TabPageWrapper = Styled.div`
  min-height: 500px;
  position: relative;
  color: rgba(255,255,255);
`;

export const TabPageHeader = Styled.h4`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const TabPageTitle = Styled.div`
  text-align: left;
  font-size: 14px;
`;

export const TabPageNumber = Styled.div`
  font-weight: 600;
  font-size: 24px;
  text-align: left;
  padding-right: 10px;
`;

export const TabPageSummary = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  border-bottom: 1px solid rgb(46,63,91);
  justify-content: space-between;
  background-color: #2b3b64;
  margin-bottom: 15px;
  padding: 18px;
  border-radius: 10px;
  @media (max-width: ${mobileScreenSize}px) {
    flex-direction: column;
  }
`;

export const TabPagePlansList = Styled.div`
  display: flex;
  flex-direction: row;
  flex: 4;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const TabPagePlansConfirm = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const TabPageActionBtn = Styled(Button)`
  position: absolute;
  right: 15px;
  top: 30px;
  background-color: ${props => props.isActive ? "#B8D4E3" : "#2ec5d3"};
  border-color: ${props => props.isActive ? "white" : "#2ec5d3"};

  &:hover {
    background-color: ${props => props.isActive ? "#B8D4E3" : "#2ec5d3"};
    border-color: ${props => props.isActive ? "white" : "#2ec5d3"};  
    opacity: 0.6;
  }

  &:focus {
    background-color: ${props => props.isActive ? "#B8D4E3" : "#2ec5d3"};
    border-color: ${props => props.isActive ? "white" : "#2ec5d3"};  
    opacity: 0.6;
  }
`;

export const ActionBtn = Styled(Button)`
  background-color: #71B48D;
  color: white;
  border-color: #71B48D;
  width: 100%;
`;

export const ViewClientInfoButtonIcon = Styled(FontAwesomeIcon)`

`;

export const ViewClientInfoButton = Styled.button`
  border-radius: 15px;
  width: 50px;
`;

export const ClientInfoTabFieldTitle = Styled.div`
  color: ${props => props.theme.text.color.default};
  font-weight: 500;
`;

export const ClientInfoTabFieldValue = Styled.div`
  color: ${props => props.theme.text.color.value};
  margin-top: 10px;
  padding: 5px;
  padding-left: 10px;
  background-color: ${props => props.theme.bg.default};
  border: 1px solid ${props => props.theme.bg.border};
  opacity: 0.9;
  border-radius: 5px;
`;

export const ClientInfoTabFieldContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  marginBottom: 10px;

`;

export const ClientInfoRowContainer = Styled.div`
    display: flex;
    flexDirection: row;
    width: 100%;
    flexWrap: wrap;
    @media (max-width: ${mobileScreenSize}px) {
      flex-direction: column;
    }
`;

