import React from 'react';
import { TabItemContainer, TabLinkContainer } from './style';

const TabItem = (props) => {
  return (
    <TabItemContainer
      onClick={() => props.onClick(props.eventKey)}
    >
      <TabLinkContainer
        href={props.href}
        eventKey={props.eventKey}
        active={props.active}
      >
        {props.children}
      </TabLinkContainer>
    </TabItemContainer>
  )
}

export default TabItem;