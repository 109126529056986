import React, {useRef, useEffect, useState, useCallback} from "react";
import { connect } from "react-redux";

function Input({children,
  onClick=() =>{},
  placeholder="",
  style="",
  focusOnKeyDown=false,
  value="",
  onChange=() =>{},
  onPressEnter=() =>{},
  ...props
}) {
  const inputRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const focusInput = useCallback(() => {
    inputRef.current.focus();
  }, []);

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      onPressEnter();
    }
  }

  function removeEventListener() {
    window.removeEventListener("keydown", focusInput);
  }

  function addEventListener() {
    window.addEventListener("keydown", focusInput);
  }

  function handleChange(e) {
    onChange(e.target.value);
  }

  useEffect(() => {
    if (inputRef.current && focusOnKeyDown) {
      addEventListener();
      setLoaded(true);
      return () => {
        removeEventListener();
      };
    }
  }, [inputRef]);

  useEffect(() => {
    if (loaded) {
      if (props.cart.autoFocus) {
        addEventListener();
      } else {
        removeEventListener();
      }
    }
  }, [props.cart.autoFocus]);


  return (
    <input
      type="text"
      ref={inputRef}
      className={"rounded-xl flex flex-column flex-1 m-2 drop-shadow-lg " + style}
      style={{backgroundColor: "#24344D"}}
      onClick={onClick}
      placeholder={placeholder}
      onKeyDown={handleKeyDown}
      value={value}
      onChange={handleChange}
    />
  );
}

const mapStateToProps = (state) => ({
  cart: state.posCart,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Input);
