import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getComissionsPayments, getComissionsIntegrations, clearComissionsIntegrations } from 'actions/comissionsRecordsActions';
import Button from '@mui/material/Button';
import { dateFormatter, moneyFormatter } from 'utils/formatters';
import { variantStates } from 'utils/common';
import { DefaultModal } from '../';
import { CircularProgressSpinner } from 'components/Spinners';
import { ColoredDatum, MuiDataTable } from 'components/Table';
import { UnfoldMore, ArrowBack } from '@mui/icons-material';

const COMISSIONS_PAYMENTS = ['id', 'total_amount', 'status', 'user_name', 'created_at'];

const COMISSIONS_PAYMENTS_INTEGRATIONS = ['commission_amount', 'order_number', 'user_name', 'order_amount'];

const ComissionsModal = (props) => {
    const { comissionId, getComissionsPayments, getComissionsIntegrations, comissionPayments, comissionIntegrations, clearComissionsIntegrations } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (comissionId) {
            getComissionsPayments(comissionId);
        }
    }, [comissionId]);

    useEffect(() => {
        if (comissionPayments) {
            setData(comissionPayments);
            setIsLoading(false);
        }
    }, [comissionPayments]);

    useEffect(() => {
        if (comissionIntegrations) {
            setData(comissionIntegrations);
            setIsLoading(false);
        }
    }, [comissionIntegrations]);

    const getHeaderText = () => {
        if (isLoading) {
            return 'Cargando...';
        }
        return `Comisiones por cliente`;
    };

    const handlePageChange = (event, value) => setCurrentPage(value);

    const getCIntegrations = (id) => {
        setIsLoading(true);
        getComissionsIntegrations(id);
    };

    const goBack = () => {
        clearComissionsIntegrations();
        setData(comissionPayments);
    };

    const TABLE_HEADERS = {
        id: {
            field: 'id',
            headerName: 'Ver más',
            width: 130,
            renderCell: (params) => {
                return (
                    <Button onClick={() => getCIntegrations(params.row.id)} variant="outlined" endIcon={<UnfoldMore />}>
                        Abrir
                    </Button>
                );
            },
        },
        total_amount: {
            field: 'total_amount',
            headerName: 'Total (MXN)',
            width: 130,
            isCustom: true,
            renderCell: (p) => moneyFormatter(p.value),
        },
        status: {
            field: 'status',
            headerName: 'Estado',
            width: 180,
            isCustom: true,
            renderCell: (p) => (
                <ColoredDatum variant={variantStates[p.value].variant} icon={variantStates[p.value].icon}>
                    {variantStates[p.value].text}
                </ColoredDatum>
            ),
        },
        created_at: {
            field: 'created_at',
            headerName: 'Fecha de creación',
            width: 180,
            isCustom: true,
            renderCell: (p) => dateFormatter(p.value),
        },
        commission_amount: {
            field: 'commission_amount',
            headerName: 'Total comision (MXN)',
            width: 130,
            isCustom: true,
            renderCell: (p) => moneyFormatter(p.value),
        },
        order_amount: {
            field: 'order_amount',
            headerName: 'Total orden (MXN)',
            width: 130,
            isCustom: true,
            renderCell: (p) => moneyFormatter(p.value),
        },
        order_number: {
            field: 'order_number',
            headerName: 'pedido',
            width: 180,
        },
        user_name: {
            field: 'user_name',
            headerName: 'Nombre del usuario',
            width: 200,
        },
    };

    const getTableHeaders = () => {
        let headers = [];
        if (comissionIntegrations) {
            headers = COMISSIONS_PAYMENTS_INTEGRATIONS;
        } else {
            headers = COMISSIONS_PAYMENTS;
        }

        let entries = Object.entries(TABLE_HEADERS).filter(([key, value]) => headers.includes(key));

        return Object.fromEntries(entries);
    };

    return (
        <DefaultModal show={props.show} onHide={props.toggleModal} title={getHeaderText()}>
            {isLoading ? (
                <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <CircularProgressSpinner />
                </div>
            ) : (
                <>
                    {comissionIntegrations && <ArrowBack style={{ marginBottom: 10 }} onClick={() => goBack()} role="button" />}
                    <MuiDataTable data={data.list} table_headers={getTableHeaders()} isLoading={isLoading} pageSize={data.meta?.count} currentPage={currentPage} totalPages={data.meta?.total_pages} onPageChange={handlePageChange} />
                </>
            )}
        </DefaultModal>
    );
};

const mapStateToProps = (state) => ({
    comissionPayments: state.comissionRecords.comission_payments,
    comissionIntegrations: state.comissionRecords.comission_integrations,
});

const mapDispatchToProps = {
    getComissionsPayments,
    getComissionsIntegrations,
    clearComissionsIntegrations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComissionsModal);
