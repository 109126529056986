import React from 'react';
import {InnerPage, PageHeader, PageTitle} from './style';

const Page = (props) => {
  return (
    <InnerPage>
      <PageHeader>
        {props.title && 
          <PageTitle>
            {props.title}
          </PageTitle>
        }
      </PageHeader>
      {props.children}
    </InnerPage>
  );
};

export default Page;
