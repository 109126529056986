import React, { useState } from 'react';
import { TabPageWrapper, TabPageHeader, TabPageSummary, TabPageTitle, TabPageNumber } from '../style';
import { DataTable, DataTbody, DataThead, Datum, Header, MomentDatum, Row, ColoredDatum } from '../../../../components/Table';
import { faCheck, faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '@mui/material/Button';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const OrderTab = (props) => {
    let orders = props.client.orders;

    const statusObj = {
        paid: {
            type: 'success',
            text: 'Pagado',
            icon: faCheck,
        },
        balance_due: {
            type: 'warning',
            text: 'Pendiente',
            icon: faExclamationTriangle,
        },
        delayed: {
            type: 'danger',
            text: 'Retrasado',
            icon: faTimes,
        },
        ready: {
            text: 'Preparando paquete',
        },
        complete: {
            text: 'Orden completada',
        },
        canceled: {
            text: 'Cancelada',
        },
        pending: {
            text: 'Pendiente',
        },
        shipped: {
            text: 'Enviado',
        },
        cart: {
            text: 'Pedido en carrito',
        },
        address: {
            text: 'Carrito movido a checkout',
        },
        delivery: {
            text: 'Se ha registrado una dirección',
        },
        payment: {
            text: 'Se ha seleccionado una opcion de entrega',
        },
        confirm: {
            text: 'Se ha seleccionado un metodo de pago',
        },
        resumed: {
            text: 'Orden reanudada.',
        },
        null: {
            text: 'Sin Estado',
        },
    };

    return (
        <TabPageWrapper>
            <TabPageHeader>Órdenes del cliente</TabPageHeader>
            <TabPageSummary>
                <div>
                    <TabPageTitle>Total de Ordenes</TabPageTitle>
                    <TabPageNumber>{props.client.orders.length}</TabPageNumber>
                </div>
                <div>
                    <TabPageTitle>Pagadas</TabPageTitle>
                    <TabPageNumber>{props.client.orders.filter((order) => order.payment_state === 'paid').length}</TabPageNumber>
                </div>
                <div>
                    <TabPageTitle>Pendientes</TabPageTitle>
                    <TabPageNumber>{props.client.orders.filter((order) => order.payment_state === 'balance_due').length}</TabPageNumber>
                </div>
            </TabPageSummary>
            <DataTable>
                <DataThead>
                    <Header>Ver</Header>
                    <Header>Numero</Header>
                    <Header>Total</Header>
                    <Header>Estado</Header>
                    <Header>Paqueteria</Header>
                    <Header>Pagos</Header>
                    <Header>Fecha</Header>
                </DataThead>
                <DataTbody>
                    {orders.map((order) => {
                        let selectedStatus = statusObj[order.payment_state];
                        return (
                            <Row>
                                <Datum onClick={() => props.setSelectedOrderId(order.id)} width={150}>
                                    <Button variant="outlined" endIcon={<UnfoldMoreIcon />}>
                                        Abrir
                                    </Button>
                                </Datum>
                                <Datum>{order.number}</Datum>
                                <Datum>{order.total}</Datum>
                                <Datum>{statusObj[order.state]?.text}</Datum>
                                <Datum>{order.shipment_state && statusObj[order.shipment_state]?.text}</Datum>
                                {selectedStatus ? (
                                    <ColoredDatum variant={selectedStatus.type} icon={selectedStatus.icon}>
                                        {selectedStatus?.text}
                                    </ColoredDatum>
                                ) : (
                                    <Datum />
                                )}
                                <MomentDatum>{order.created_at}</MomentDatum>
                            </Row>
                        );
                    })}
                </DataTbody>
            </DataTable>
        </TabPageWrapper>
    );
};

export default OrderTab;
