import React, { useState, useEffect } from 'react';
import { Title, RowRadio, BasicInput, BasicSelect } from 'components/Form';

const BillingAddressForm = (props) => {
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});

    useEffect(() => {
        if (countries.length === 0) {
            if (props.countries && Object.values(props.countries).length > 0) {
                let sortedCountries = Object.values(props.countries).sort((a, b) => a['id'] - b['id']);
                setCountries(sortedCountries);
            }
        }
    }, [props.countries]);

    useEffect(() => {
        if (Object.keys(props.addressData.country).length > 0) {
            setSelectedCountry(props.addressData.country);
            props.getCountry(data.country);
        }
    }, [props.addressData.country]);

    useEffect(() => {
        if (props.addressData.country.id) {
            setSelectedCountry(Object.values(props.countries).find((e) => e.id === props.addressData.country.id));
        }
    }, [props.countries]);

    let data = props.addressData;

    const handleDisabled = () => {
        if (props?.addressData?.country) {
            return !Object.values(props?.addressData?.country)?.length;
        }

        return true;
    };

    return (
        <>
            <Title>Seleccióna una dirección de facturación</Title>
            <div style={{ paddingBottom: '15px' }}>
                {props.addressList && props.addressList.map((address) => <RowRadio selected={props.selectedAddress} onChange={props.setSelectedAddress} data={address} id={address.id} />)}
                <RowRadio title="Agregar nueva dirección" selected={props.selectedAddress} onChange={props.setSelectedAddress} id="new_address" />
            </div>
            {props.selectedAddress === 'new_address' && (
                <div>
                    <Title>Agrega una nueva dirección</Title>
                    <BasicInput title="Calle" id="street1" name="street1" onChange={props.handleAddressInputChange} value={data.street1} />
                    <BasicInput title="Calle (Línea 2)" id="street2" name="street2" onChange={props.handleAddressInputChange} value={data.street2} />
                    <BasicInput title="Ciudad" id="city" name="city" onChange={props.handleAddressInputChange} value={data.city} />
                    <BasicInput title="Código Postal" id="zipcode" name="zipcode" onChange={props.handleAddressInputChange} value={data.zipcode} />
                    <BasicSelect id="country" name="country" value={data?.country?.id} onChange={props.handleCountrySelectChange} title={'País'} type={'Text'} defaultText={'Selecciona un País'}>
                        {props.countries &&
                            countries.map((country) => {
                                return <option id={country.id}>{country.attributes && country.attributes.name}</option>;
                            })}
                    </BasicSelect>
                    {Object.values(selectedCountry).length > 0 && (
                        <BasicSelect id="state" name="state" value={data?.state?.id} onChange={props.handleCountrySelectChange} disabled={handleDisabled} title={'Estado/provincia'} type={'Text'} defaultText={'Selecciona un Estado'}>
                            {selectedCountry &&
                                selectedCountry.relationships &&
                                selectedCountry.relationships.states &&
                                Object.values(selectedCountry.relationships.states).map((state) => {
                                    return <option id={state.id}>{state.name}</option>;
                                })}
                        </BasicSelect>
                    )}
                </div>
            )}
        </>
    );
};

export default BillingAddressForm;
