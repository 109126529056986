/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRank, getRankNetwork, getRankById, getRankManagers, getManagersFromId, getChildrenFromIds, getRankLevels } from 'actions/rankActions';
import DistributorHeader from './distributorHeader';
import NetworkViewer from './networkViewer';
import ProfileViewer from './profileViewer';
import { MainContainer } from './style';

const TABS = {
    profile: 'Perfil',
    organization: 'Organización',
    messages: 'Mensajes',
};

const NetworkIndex = (props) => {
    const [userRank, setUserRank] = useState({});
    const [managerRanks, setManagerRanks] = useState([]);
    const [childrenRanks, setChildrenRanks] = useState([]);
    const [activeRankId, setActiveRankId] = useState();
    const [selectedTab, setSelectedTab] = useState('profile');

    useEffect(() => {
        if (!activeRankId) {
            setActiveRankId(props.user.data.attributes.rank_id);
        }
        if (!props.ranks.length) {
            refreshRankData();
        }
        fetchRankLevels();
    }, []);

    useEffect(() => {
        if (!!props.ranks.length) {
            updateShownRanks();
        }
    }, [props.ranks]);

    useEffect(() => {
        if (activeRankId) {
            updateShownRanks();
        }
    }, [activeRankId]);

    const handleSelectRank = (id) => {
        setActiveRankId(id);
    };

    const updateShownRanks = () => {
        let selectedRank = getRankById(props.ranks, activeRankId);
        if (selectedRank) {
            setUserRank(selectedRank);
            setActiveRankId(selectedRank.id);
            setManagerRanks(getManagersFromId(props.ranks, selectedRank.id));
            setChildrenRanks(getChildrenFromIds(props.ranks, selectedRank.attributes.children_ids));
        }
    };

    const refreshRankData = () => {
        props.getRankNetwork(props.user.data.attributes.rank_id);
        props.getRankManagers(props.user.data.attributes.rank_id);
    };

    const fetchRankLevels = () => {
        if (!props.rank_levels?.length) {
            props.getRankLevels();
        }
    };

    return (
        <MainContainer>
            {!!userRank.id && <DistributorHeader TABS={TABS} userRank={userRank} selectedTab={selectedTab} setSelectedTab={setSelectedTab} disabledTabs={['messages']} />}

            {selectedTab === 'profile' && <ProfileViewer userRank={userRank} activeRankId={activeRankId} rank_levels={props.rank_levels} RANKINFO={RANKINFO} />}

            {selectedTab === 'organization' && <NetworkViewer userRank={userRank} activeRankId={activeRankId} managerRanks={managerRanks} childrenRanks={childrenRanks} handleSelectRank={handleSelectRank} RANKINFO={RANKINFO} />}
        </MainContainer>
    );
};

const RANKINFO = {
    unranked: {
        name: 'Sin Rango',
        color: '#20C261',
        requirements: [
            {
                name: 'Membresia',
                description: 'Activa tu Membresia para subir de rango',
            },
        ],
        benefits: [
            {
                name: 'unranked',
                category: 'no-benefit',
                description: 'No hay benficios disponibles.',
            },
        ],
        prices: [
            {
                name: 'Sin rango',
                category: 'no-benefit',
                description: 'No hay premios especiales.',
            },
        ],
    },
    entrepreneur: {
        name: 'Emprendedor',
        color: '#1AC2B0',
        requirements: [
            {
                name: 'Membresia activa',
                description: 'Se requiere tener una Membresia activa',
            },
        ],
        benefits: [
            {
                name: 'Precio de distribuidor',
                category: 'discount',
                description: '32.5% de descuento en pedidos adicionales',
            },
            {
                name: 'Primera linea',
                category: 'comissions',
                description: '8% de comisiones en compras de tu primera línea',
            },
            {
                name: 'Segunda linea',
                category: 'comissions',
                description: '4% de comisiones en compras de tu segunda línea',
            },
        ],
        prices: [
            {
                name: 'Doble de Membresia',
                category: 'membership',
                description: 'Ganas un bono equivalente al doble de tu Membresia Inicial para usar en la tienda de UB.',
            },
            {
                name: 'Bono de Recomendaciónes',
                category: 'membership',
                description: 'Ganas $200 pesos de bono por inscribir a cada nuevo Emprendedor directo',
            },
        ],
    },
    bronze: {
        name: 'Lider Bronce',
        color: '#00A4D5',
        requirements: [
            {
                name: 'Membresia activa',
                description: 'Se requiere tener una Membresia activa',
            },
            {
                name: 'Grupo Central',
                description: 'Tener al menos 5 Emprendedores en tu grupo central',
            },
        ],
        benefits: [
            {
                name: 'Precio de distribuidor',
                category: 'discount',
                description: '32.5% de descuento en pedidos adicionales',
            },
            {
                name: 'Primera linea',
                category: 'comissions',
                description: '8% de comisiones en compras de tu primera línea',
            },
            {
                name: 'Segunda linea',
                category: 'comissions',
                description: '4% de comisiones en compras de tu segunda línea',
            },
            {
                name: 'Tercera linea',
                category: 'comissions',
                description: '2% de comisiones en compras de tu Tercera línea',
            },
        ],
        prices: [
            {
                name: 'Regalo',
                category: 'Bonus',
                description: 'Bono de $1,000 pesos en mercancia de Denim Boy',
            },
            {
                name: 'Premio Especial',
                category: 'Bonus',
                description: 'Chamarra Black Denim',
            },
        ],
    },
    silver: {
        name: 'Lider Plata',
        color: '#4063EA',
        requirements: [
            {
                name: 'Membresia activa',
                description: 'Se requiere tener una Membresia activa',
            },
            {
                name: 'Grupo Central',
                description: 'Tener al menos 8 Emprendedores en tu grupo central',
            },
            {
                name: 'Lideres',
                description: 'Tener al menos un Lider bronze en tu primer línea',
            },
        ],
        benefits: [
            {
                name: 'Precio de distribuidor',
                category: 'discount',
                description: '32.5% de descuento en pedidos adicionales',
            },
            {
                name: 'Primera linea',
                category: 'comissions',
                description: '8% de comisiones en compras de tu primera línea',
            },
            {
                name: 'Segunda linea',
                category: 'comissions',
                description: '4% de comisiones en compras de tu segunda línea',
            },
            {
                name: 'Tercera linea',
                category: 'comissions',
                description: '2% de comisiones en compras de tu Tercera línea',
            },
            {
                name: 'Cuarta linea',
                category: 'comissions',
                description: '1% de comisiones en compras de tu Cuarta línea',
            },
        ],
        prices: [
            {
                name: 'Cupon',
                category: 'Bonus',
                description: '50% de descuento en tus pedidos al renovar la Membresia',
            },
            {
                name: 'Premio Especial',
                category: 'Bonus',
                description: 'Envio de un paquete exclusivo para Lideres Plata',
            },
            {
                name: 'Envios gratis',
                category: 'Bonus',
                description: '1 Envio gratis por mes',
            },
        ],
    },
    gold: {
        name: 'Lider Oro',
        color: '#8700FF',
        requirements: [
            {
                name: 'Membresia activa',
                description: 'Se requiere tener una Membresia activa',
            },
            {
                name: 'Grupo Central',
                description: 'Tener al menos 11 Emprendedores en tu grupo central',
            },
            {
                name: 'Lideres',
                description: 'Tener al menos 2 Lideres bronze en tu primer línea',
            },
        ],
        benefits: [
            {
                name: 'Precio de distribuidor',
                category: 'discount',
                description: '32.5% de descuento en pedidos adicionales',
            },
            {
                name: 'Primera linea',
                category: 'comissions',
                description: '8% de comisiones en compras de tu primera línea',
            },
            {
                name: 'Segunda linea',
                category: 'comissions',
                description: '4% de comisiones en compras de tu segunda línea',
            },
            {
                name: 'Tercera linea',
                category: 'comissions',
                description: '2% de comisiones en compras de tu Tercera línea',
            },
            {
                name: 'Cuarta linea',
                category: 'comissions',
                description: '1% de comisiones en compras de tu Cuarta línea',
            },
            {
                name: 'Quinta linea',
                category: 'comissions',
                description: '0.5% de comisiones en compras de tu Quinta línea',
            },
        ],
        prices: [
            {
                name: 'Viaje',
                category: 'Trip',
                description: 'En caso de alcanzar éste nivel dentro de los 70 días posteriores a tu primer pedido, ganas un viaje todo pagado a Los Cabos junto a tus 2 Líderes Bronce',
            },
        ],
    },
    senior: {
        name: 'Consultor Senior',
        color: '#A90F90',
        requirements: [{}],
        benefits: [{}],
    },
    premier: {},
    elite: {},
};

const mapStateToProps = (state) => ({
    user: state.user,
    ranks: state.rank.ranks,
    rank_levels: state.rank.levels,
});

const mapDispatchToProps = {
    getRank,
    getRankNetwork,
    getRankManagers,
    getRankLevels,
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkIndex);
