import React, { Component } from 'react';
import {connect} from 'react-redux';
import {PageSidebar, LogoBox, SidebarContent, AccordionMenu, BarsBox} from './style';
import {API_BASE_URL} from '../../actions/types.js'
import UniqueLogo from '../../assets/images/Uniquelogo.png';

import { HeaderButton} from 'components/Header';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import {isMobileScreen} from 'utils/screen';

class Sidebar extends Component {
  constructor() {
    super();
    this.redirectTo = this.redirectTo.bind(this);
  }

  redirectTo() {
    return window.location.replace( API_BASE_URL+'/en');
  }

  render() {
    return (
      <PageSidebar {...this.props}>

        { isMobileScreen && this.props.isOpen &&
          <BarsBox>
            <HeaderButton icon={faBars} type="toggleLeftSidebar"/>
          </BarsBox>
        }
        <LogoBox onClick={this.redirectTo}>
          <img src={UniqueLogo} height="20" width="20" alt="logo" style={{marginBottom: '5px', marginRight: '2px'}}/>
          {this.props.title}
        </LogoBox>
        <SidebarContent>
          <AccordionMenu>
            {this.props.children}
          </AccordionMenu>
        </SidebarContent>
      </PageSidebar>
    );
  }
}

const mapStateToProps = state => ({
  isOpen: state.leftSidebarReducer.isOpen,
})



export default connect(mapStateToProps, {})( Sidebar );
