import React, { useEffect, useState } from 'react';
import { DefaultModal } from '../../../components/Modal';
import { Title, BasicInput, RowRadio } from '../../../components/Form';
import { AmountToPayContainer } from './style';

const ModalAddNewPayment = (props) => {
    const [selectedMethod, setSelectedMethod] = useState('Spree::CreditCard');
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [inputAmount, setInputAmount] = useState();
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

    useEffect(() => {
        if (props.show && (props.clients.length === 0 || props.creditCards.length === 0)) {
            props.toggleModal();
            props.createFlashMessageRequest({
                closeTime: 1500,
                variant: 'warning',
                message: 'Por favor asegurate de tener almenos tener una orden completada antes de crear un pago.',
            });
        }
    }, [props.show]);

    useEffect(() => {
        if (!props.isCreditCardsLoading && !props.creditCards.length === 0) {
            setSelectedCard(getDefaultCard().id);
        }
    }, [props.creditCards]);

    useEffect(() => {
        if (props.clients.length !== 0) {
            let order = props.clients[0].orders[0];
            if (order) {
                setSelectedOrder(order.id);
            }
        }
    }, [props.clients]);

    const getDefaultCard = () => {
        return (
            props.creditCards.find((card) => {
                return card.default;
            }) || props.creditCards[0]
        );
    };

    const onSave = async () => {
        if (isPaymentProcessing) {
            return true;
        }

        setIsPaymentProcessing(true);
        let res = await props.handleCreate({
            source_type: selectedMethod,
            source_id: selectedCard,
            amount: inputAmount,
            order: getSelectedOrder().number,
        });

        setIsPaymentProcessing(false);
        setInputAmount(null);
        props.toggleModal();
    };

    const getSelectedOrder = () => {
        return props.clients.map((client) => client.orders.find((order) => order.id === selectedOrder))[0];
    };

    const getOrderedScheduledPayments = () => {
        return props.clients.map((client) => client.scheduled_payments.filter((pmnt) => pmnt.order_id == selectedOrder && pmnt.state === 'scheduled'))[0].sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
    };

    const getNextPaymentAmount = () => {
        if (selectedOrder === null) {
            return 0;
        }
        let scheduled_payments = getOrderedScheduledPayments();
        let next_payment = scheduled_payments[scheduled_payments.length - 1] || { amount: 0, amount_paid: 0 };
        let next_payment_amount_due = Math.round((next_payment.amount - next_payment.amount_paid) * 100) / 100;

        return `$ ${next_payment_amount_due} MXN`;
    };

    const getOrderDueAmount = () => {
        if (selectedOrder === null) {
            return 0;
        }

        let order = getSelectedOrder();
        let order_total_due = Math.round((order.total - order.payment_total) * 100) / 100;

        return `$ ${order_total_due} MXN`;
    };

    return (
        <DefaultModal show={props.show} onHide={props.toggleModal} onSave={onSave} title={isPaymentProcessing ? 'Procesando pago...' : 'Pagar ahora'} saveButtonText={isPaymentProcessing ? 'Procesando pago...' : 'Guardar'}>
            <Title>Selecciona el pedido a pagar</Title>
            {props.clients.map(
                (client) =>
                    client.orders &&
                    client.orders
                        .filter((order) => order.payment_state === 'balance_due')
                        .map((order) => {
                            return <RowRadio title={`Pedido #${order.number} - ${client.first_name} ${client.last_name} (total: ${order.total}${order.currency})`} selected={selectedOrder} onChange={setSelectedOrder} id={order.id} />;
                        })
            )}
            <Title>Selecciona el método de pago</Title>
            <RowRadio title="Tarjeta de crédito o débito" selected={selectedMethod} onChange={setSelectedMethod} id="Spree::CreditCard" />
            <Title>Selecciona la tarjeta</Title>
            {!props.isCreditCardsLoading &&
                props.creditCards &&
                props.creditCards.map((card) => {
                    return <RowRadio id={card.id} title={card.attributes.cc_type + ' * ' + card.attributes.last_digits} selected={selectedCard} onChange={setSelectedCard} />;
                })}

            <Title>Cantidad a pagar</Title>
            <AmountToPayContainer>
                <BasicInput title="Monto Total Debido" disabled={true} value={getOrderDueAmount()} />
                <BasicInput title="Debido para próxima quincena" disabled={true} value={getNextPaymentAmount()} />
                <BasicInput title="Cantidad a pagar" value={inputAmount} onChange={(e) => setInputAmount(e.target.value)} />
            </AmountToPayContainer>
        </DefaultModal>
    );
};

export default ModalAddNewPayment;
