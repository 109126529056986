import React from 'react';
import { BasicInputLabel } from '.';
import {DefaultTextArea} from './style';

const BasicTextArea = (props) => {
  return (
    <div>
      <BasicInputLabel
        required={(props.required || (props.validators && props.validators.required))}
        for={props.id}
        tooltip="Campo Requerido"
        style={props.style}
      >
        {props.title}
      </BasicInputLabel>
      <DefaultTextArea
        id={props.id}
        type="textarea"
        rows="4"
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  )
}

export default BasicTextArea;