import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TabPageWrapper, TabPageTitle, TabPageSummary, TabPageNumber, TabPagePlansConfirm, TabPagePlansList } from '../style';
import { CreditTransferViewer } from 'components/Modal/';
import { ActionSpinner } from 'components/Spinners';

import { getUserBankInformation, withdrawStoreCredit, transferStoreCredit, findUserBank } from 'actions/bankActions';

import { getUserPaymentPlans, updateUserPaymentPlans } from 'actions/paymentPlanActions';

import { ProgressBar, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faUndo } from '@fortawesome/free-solid-svg-icons';
import { SweetAlert } from 'components/Alert';
import { moneyFormatter } from 'utils/formatters';
import { BasicCheckbox } from 'components/Form';

const CreditTab = (props) => {
    const [transferOption, setTransferOption] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bankAccount, setBankAccount] = useState({});
    const [sweetAlertData, setSweetAlertData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [plansState, setPlansState] = useState({
        isUpading: false,
        isChanged: false,
        isSuccess: false,
        isError: false,
    });
    const [plansButtonState, setPlansButtonState] = useState({ variant: '', text: '', disabled: false });
    const [userPlans, setUserPlans] = useState([]);
    const [clientPlans, setClientPlans] = useState([]);

    useEffect(() => {
        if (!props.paymentPlans[props.client.id]) {
            props.getUserPaymentPlans(props.client.id);
        }
        if (!props.paymentPlans[props.user.id]) {
            props.getUserPaymentPlans(props.user.id);
        }
    }, []);

    useEffect(() => {
        if (!!props.paymentPlans[props.client.id]) {
            setClientPlans(props.paymentPlans[props.client.id]);
        }
        setUserPlans(props.paymentPlans[props.user.id]);
    }, [props.paymentPlans]);

    useEffect(() => {
        setIsLoading(true);
        setBankAccount({});
        let userBank = findUserBank({ bankAccounts: props.bank_accounts, userId: props.client.id });
        if (props.client && !userBank?.id) {
            props.getUserBankInformation(props.client.id);
        } else if (userBank?.id) {
            setBankAccount(userBank);
        }
    }, [props.client]);

    useEffect(() => {
        let userBank = findUserBank({ bankAccounts: props.bank_accounts, userId: props.client.id });
        if (userBank) {
            setBankAccount(userBank);
        }
    }, [props.bank_accounts]);

    useEffect(() => {
        if (bankAccount.id) {
            setIsLoading(false);
        }
    }, [bankAccount]);

    useEffect(() => {
        setSweetAlertData({
            config: {
                text: 'Por favor espera unos momentos...',
                title: 'Cargando...',
            },
            show: isLoading,
            isLoading: isLoading,
        });
    }, [isLoading]);

    useEffect(() => {
        let { isError, isSuccess, isUpdating } = plansState;
        let variant;
        let text;
        let disabled = false;

        if (isError) {
            variant = 'outline-danger';
            text = 'Error';
            disabled = true;
        }

        if (isSuccess) {
            variant = 'outline-success';
            text = 'Guardado';
            disabled = true;
        }

        if (isUpdating) {
            variant = 'outline-secondary';
            text = <ActionSpinner />;
            disabled = true;
        }

        variant = variant || 'outline-primary';
        text = text || 'Confirmar';

        setPlansButtonState({ variant, text, disabled });
    }, [plansState]);

    const handleCreditTransferModalShow = (opt) => {
        setTransferOption(opt);
        setIsModalOpen(true);
    };

    const getCreditUsedPercentage = () => {
        if (Object.entries(bankAccount).length === 0) return 0;
        return parseFloat(((parseFloat(bankAccount.client_store_credit_remaining) * 100) / parseFloat(bankAccount.client_store_credit_amount)).toFixed(2));
    };

    const handlePaymentPlanToggle = (plan_id) => {
        setPlansState((prev) => {
            return { ...prev, isSuccess: false, isChanged: true };
        });
        if (!!clientPlans.find((p) => p.id === plan_id)) {
            setClientPlans((prev) => clientPlans.filter((plan) => plan.id !== plan_id));
        } else {
            setClientPlans((prev) => [...prev, userPlans.find((plan) => plan.id === plan_id)]);
        }
    };

    const handlePaymentPlanConfirmUpdate = () => {
        setPlansState((prev) => {
            return { ...prev, isUpdating: true };
        });
        props
            .updateUserPaymentPlans(
                props.client.id,
                clientPlans.map((plan) => plan.id)
            )
            .then((res) => {
                if (res) {
                    setPlansState((prev) => {
                        return { ...prev, isUpdating: false, isSuccess: true };
                    });
                }
            })
            .catch((res) => {
                setPlansState((prev) => {
                    return { ...prev, isUpdating: false, isEror: true };
                });
            });
    };

    return (
        <TabPageWrapper>
            <SweetAlert {...sweetAlertData} />
            <TabPageSummary>
                <div>
                    <TabPageTitle>Crédito Total</TabPageTitle>
                    <TabPageNumber>{moneyFormatter(parseFloat(bankAccount.client_store_credit_amount))}</TabPageNumber>
                </div>
                <div>
                    <TabPageTitle>Crédito usado</TabPageTitle>
                    <TabPageNumber>{moneyFormatter(parseFloat(bankAccount.client_store_credit_used))}</TabPageNumber>
                </div>
                <div>
                    <TabPageTitle>Crédito restante</TabPageTitle>
                    <TabPageNumber>{moneyFormatter(parseFloat(bankAccount.client_store_credit_remaining))}</TabPageNumber>
                </div>
                {props.user?.attributes && (
                    <div style={{}}>
                        <TabPageTitle>Mi crédito actual</TabPageTitle>
                        <TabPageNumber>{moneyFormatter(parseFloat(props.user?.attributes.distributor_store_credit_remaining))}</TabPageNumber>
                    </div>
                )}
            </TabPageSummary>
            {props.user?.attributes.allow_extended_payment_plans && !!userPlans.length && (
                <TabPageSummary>
                    <TabPagePlansList>
                        {userPlans.map((plan) => {
                            let checked = plan.default || !!clientPlans.find((p) => p.id === plan.id);
                            return (
                                <div style={{ flexGrow: 1, width: '45%' }}>
                                    <BasicCheckbox
                                        key={plan.id}
                                        id={plan.id}
                                        label={plan.name}
                                        checked={checked}
                                        onChange={() => {
                                            handlePaymentPlanToggle(plan.id);
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </TabPagePlansList>
                    <TabPagePlansConfirm>
                        {Object.values(plansState).some(Boolean) && (
                            <Button variant={plansButtonState.variant} onClick={handlePaymentPlanConfirmUpdate} style={{ width: '200px', height: '60px' }} disabled={plansButtonState.disabled}>
                                {plansButtonState.text}
                            </Button>
                        )}
                    </TabPagePlansConfirm>
                </TabPageSummary>
            )}
            <div>{bankAccount.client_store_credit_amount > 0 && <ProgressBar animated style={{ height: '40px' }} now={getCreditUsedPercentage()} label={`Crédito restante: ${moneyFormatter(parseFloat(bankAccount.client_store_credit_remaining))} (${getCreditUsedPercentage()} %)`} variant={'primary'} />}</div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignContent: 'stretch', marginTop: '10px', gap: '10px' }}>
                <Button variant="outline-secondary" style={{ width: '30%' }} onClick={() => handleCreditTransferModalShow('withdraw')}>
                    <FontAwesomeIcon icon={faUndo} /> Retirar
                </Button>
                <Button variant="outline-primary" onClick={() => handleCreditTransferModalShow('transfer')} style={{ width: '30%' }}>
                    <FontAwesomeIcon icon={faDollarSign} /> Enviar
                </Button>
            </div>
            {!!bankAccount.id && <CreditTransferViewer show={isModalOpen} setShow={setIsModalOpen} transferOption={transferOption} userId={props.client.id} setIsLoading={setIsLoading} />}
        </TabPageWrapper>
    );
};

const mapStateToProps = (state) => ({
    bank_accounts: state.bank.bank_accounts,
    user: state.user.data,
    paymentPlans: state.paymentPlan.list,
});

const mapDispatchToProps = {
    getUserBankInformation,
    getUserPaymentPlans,
    updateUserPaymentPlans,
    transferStoreCredit,
    withdrawStoreCredit,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditTab);
