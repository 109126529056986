import React, {useState, useEffect} from 'react';
import {PaymentActivityContainer, PaymentActivityTableContainer} from './style';
import {DataTable, DataTbody, DataThead, Datum, Header, Row} from '../../../components/Table';
import {dateFormat} from '../../../utils/common';

const PaymentActivity = (props) => {
  const [activityList, setActivityList] = useState([]);

  useEffect(() => {
    if (props.bankAccount?.transactions.length) {
      setActivityList([...props.bankAccount.transactions]);
    }
  }, [props.bankAccount])

  return (
    <PaymentActivityContainer>
      <PaymentActivityTableContainer>
        <DataTable>
          <DataThead>
            <Header>ID</Header>
            <Header>Categoría</Header>
            <Header>Cantidad</Header>
            <Header>Descripción</Header>
            <Header>Fecha</Header>
          </DataThead>
          <DataTbody>
            {activityList.length ? 
              props.bankAccount.transactions.map(transaction => {
                return (
                  <Row>
                    <Datum>{transaction.id}</Datum>
                    <Datum>{transaction.category}</Datum>
                    <Datum>{transaction.amount}</Datum>
                    <Datum>{transaction.description}</Datum>
                    <Datum>{dateFormat(transaction.updated_at)}</Datum>
                  </Row>
                )
              })
            :
              <Row>Aun no se ha registrado ninguna actividad de pagos.</Row>
            }
          </DataTbody>
        </DataTable>
      </PaymentActivityTableContainer>
    </PaymentActivityContainer>
  )
}

export default PaymentActivity;
