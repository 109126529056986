import React, { useState, useRef, useEffect } from 'react';
import { PagedCarouselContainer, BackPageButtonContainer, CarouselPageIcon, NextPageButtonContainer, PresentationContainer, PresentationChildren, PageIconsContainer } from './style';

import IconButton from '@mui/material/IconButton';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import useResize from 'hooks/useResize';

const PagedCarousel = (props) => {
    const [isHover, setIsHover] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [perPage, setPerPage] = useState(1);
    const [touchPos, setTouchPos] = useState(null);

    const contentRef = useRef();
    const { width } = useResize(contentRef);

    useEffect(() => {
        if (!!props.itemWidth) {
            const perP = Math.floor(width / props.itemWidth);
            setPerPage(perP);
            setTotalPages(props.children.length);
        }
    }, [width]);

    const changePage = (newPage) => {
        if (newPage < 1 || newPage > totalPages) {
            return true;
        }

        setCurrentPage(newPage);
    };

    const next = () => {
        changePage(currentPage + 1);
    };

    const prev = () => {
        changePage(currentPage - 1);
    };

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPos(touchDown);
    };

    const handleTouchMove = (e) => {
        if (touchPos === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchPos - currentTouch;
        if (diff > 5) {
            next();
        }
        if (diff < -5) {
            prev();
        }
        setTouchPos(null);
    };

    const getTranslateXAmount = () => {
        let unit = (props.itemWidth / width) * 100;
        return (currentPage - 1) * -unit;
    };

    return (
        <PagedCarouselContainer onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <BackPageButtonContainer show={isHover}>
                {currentPage > 1 && (
                    <IconButton onClick={() => changePage(currentPage - 1)} color="primary" aria-label="Atras" size="large">
                        <ArrowBackIosOutlinedIcon />
                    </IconButton>
                )}
            </BackPageButtonContainer>
            <PresentationContainer>
                <PresentationChildren translateX={getTranslateXAmount()} ref={contentRef}>
                    {props.children}
                </PresentationChildren>
                <PageIconsContainer>
                    {[...Array(totalPages)].map((e, i) =>
                        i + 1 === currentPage ? (
                            <CarouselPageIcon key={i} active={true} sx={{ fontSize: 16 }}>
                                circle_outlined
                            </CarouselPageIcon>
                        ) : (
                            <CarouselPageIcon key={i} sx={{ fontSize: 16 }}>
                                circle_outlined
                            </CarouselPageIcon>
                        )
                    )}
                </PageIconsContainer>
            </PresentationContainer>
            <NextPageButtonContainer show={isHover}>
                {currentPage < totalPages && (
                    <IconButton onClick={() => changePage(currentPage + 1)} color="primary" aria-label="Siguiente" size="large">
                        <ArrowForwardIosOutlinedIcon />
                    </IconButton>
                )}
            </NextPageButtonContainer>
        </PagedCarouselContainer>
    );
};

export default PagedCarousel;
