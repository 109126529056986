import React from 'react';
import {Datum, Row, MomentDatum, ColoredDatum} from '../../../components/Table';
import {faCheck, faExclamationTriangle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {variantStates} from '../../../utils/common';

const ComissionRow = (props) => {
  let client = props.comission.client_from_order;
  let selectedStatus = variantStates[props.comission.status];

  return (
    <Row type={selectedStatus.variant}>
      <Datum>
        { (client === undefined) ? 
          ("Información no disponible.") :
          (<>
            {client.first_name+" "+client.last_name}
            <br/>
            {client.email}
          </>)
        } 
      </Datum>
      <Datum>
        $ {props.comission.amount} MXN
      </Datum>
      <ColoredDatum
        variant={selectedStatus.variant}
        icon={selectedStatus.icon}
      >
        {selectedStatus.text}
      </ColoredDatum>
      <MomentDatum>
        {props.comission.created_at}
      </MomentDatum>
    </Row>
  )
}

export default ComissionRow;
