export default function(state, action, actionHandler) {
  switch(action.type) {
    case actionHandler.loading:
      return {
        ...state,
        isLoading: true,
        loaded: false,
        error: null,
      }
    case actionHandler.success:
      return {
        ...state,
        isLoading: false,
        loaded: true,
        data: dataHandler(state.data, action.payload, action.replace),
        error: null
      }
    case actionHandler.error:
       return {
        ...state,
        isLoading: false,
        loaded: true,
        error: action.payload.message,
        data: null,
      }
    default:
      return state;
  }
}

function dataHandler(data, payload, replace=false) {
  if (!replace && Array.isArray(data)) {
    return [
      ...data.filter((obj) => obj.id !== payload[0].id),
      ...payload
    ]
  }

  return payload;
}
