import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import CandidatesTable from './candidatesTable';
import {Tab, TabGroup} from '../../../components/Navs';
import {
  indexAdminCandidates,
  registerDocumentAdminCandidate,
  clearSelectedRegisterDocumentAdminCandidate,
  adminCandidateAccept,
  adminCandidateReject,
  adminCandidateMessage,
  retireveUserMembershipData,
} from '../../../actions/adminActions';
import { SweetAlert } from '../../../components/Alert';

import {
  SearchFieldContainer
} from './style';

import {createFlashMessageRequest} from '../../../actions/alertActions';
import {BasicInput} from '../../../components/Form';

import {Page, MainWrapper} from '../../../components/Body';

import ModalCandidateView from './modalCandidateView.js';

import Swal from 'sweetalert2';

const AdminCandidates = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSort, setSelectedSort] = useState();
  const [sort, setSort] = useState("asc");
  const [sortedCandidates, setSortedCandidates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState();
  const [searchText, setSearchText] = useState("");
  const [sweetAlertData, setSweetAlertData] = useState({});

  const TabTitles = {
    "pending": "Solicitudes pendientes",
    "completed": "Completadas",
    "rejected": "Rechazados",
  }

  const [selectedTab, setSelectedTab] = useState("pending");

  useEffect(() => {
    props.indexAdminCandidates()
    .then( res => {
      setIsLoading(false);
    })
  }, [])
  
  useEffect(() => {
    if(isLoading) {
      setSweetAlertData({
        config: {
          title: "Cargando...",
          text: "Por favor espera unos momentos...",
        },
        show: isLoading,
        isLoading: true
      })
    }
  }, [isLoading])

  useEffect(() => {
    if(props.candidates.length === 0) {
      return;
    }
    updateSortedCandidates();
  }, [sort, selectedSort, props.candidates, selectedTab]);

  useEffect(() => {
    if (selectedCandidateId) {
      props.registerDocumentAdminCandidate(selectedCandidateId)
      .then(res => {
        //isloading
      });
      props.retireveUserMembershipData(selectedCandidateId, "candidate");
      setIsModalOpen(true);
    } else {
      props.clearSelectedRegisterDocumentAdminCandidate()
      setIsModalOpen(false);
    }
  }, [selectedCandidateId])

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedCandidateId();
    }
  }, [isModalOpen])

  const updateSortedCandidates = () => {
    // let tabCandidateList = props.candidates.filter(candidate => candidate.status === selectedTab)
    let tabCandidateList = props.candidates;
    let updatedSortedCandidates = tabCandidateList.sort((a, b)=>customSorting(a, b));
    setSortedCandidates(updatedSortedCandidates);
  }

  const customSorting = (a ,b) => {
    if(a[selectedSort] === null) {
      return 1;
    } else if(b[selectedSort] === null) {
      return -1;
    } else if(a[selectedSort] === b[selectedSort]) {
      return 0;
    } else if(sort === "asc") {
      return a[selectedSort] < b[selectedSort] ? -1 : 1;
    } else {
      return a[selectedSort] < b[selectedSort] ? 1 : -1;
    }
  }

  const handleSendMessage = (candidate_id, text) => {
    setIsLoading(true)
    props.adminCandidateMessage(candidate_id, text)
      .then(res => {
        setIsModalOpen(false);
        setIsLoading(false)
        Swal.fire(
          res.data.success ? 'El mensaje se ha enviado exitosamente!' : 'Ha habido un error al enviar el mensaje',
          res.data.message,
          res.data.success ? 'success' : 'error'
        )

      })
  }

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  }

  const getCandidates = () => {
    // if (sortedCandidates.length === 0) {
    //   return props.candidates;
    // } else {
      return sortedCandidates;
    // }
  }

  if (props.candidates.length !== 0) {
    return (
      <Page title="Candidatos">
        {isLoading &&
          <SweetAlert {...sweetAlertData} />
        }
        <SearchFieldContainer>
          <BasicInput
            id={"tableSearch"}
            placeholder={"Escribe algún dato del candidato."}
            onChange={(e) => handleSearchTextChange(e)}
            value={searchText}
            title={"Buscar"}
          />
        </SearchFieldContainer>
        <MainWrapper>
          <TabGroup>
            {Object.entries(TabTitles).map(([id, title]) => (
              <Tab
                id={id}
                onClick={setSelectedTab}
                active={selectedTab}
              >{title}</Tab>
            ))}
          </TabGroup>
          <CandidatesTable
            isLoading={isLoading}
            candidates={getCandidates()}
            selectedSort={selectedSort}
            setSelectedSort={setSelectedSort}
            setSelectedCandidateId={setSelectedCandidateId}
            sort={sort}
            selectedTab={selectedTab}
            setSort={setSort}
            searchText={searchText}
          />
        </MainWrapper>
        <ModalCandidateView
          show={isModalOpen}
          toggleModal={() => setIsModalOpen(!isModalOpen)}
          selectedCandidateId={selectedCandidateId}
          createFlashMessageRequest={props.createFlashMessageRequest}
          candidates={props.candidates}
          registerDocument={props.registerDocument}
          acceptCandidate={props.adminCandidateAccept}
          rejectCandidate={props.adminCandidateReject}
          onSendMessage={handleSendMessage}
          setIsLoading={setIsLoading}
        />
      </Page>
    )
  } else {
    return <>Loading...</>
  }
}

const mapStateToProps = state => ({
  user: state.user,
  candidates: state.admin.candidates,
  registerDocument: state.admin.register_document
})

const mapDispatchToProps = {
  indexAdminCandidates,
  registerDocumentAdminCandidate,
  clearSelectedRegisterDocumentAdminCandidate,
  createFlashMessageRequest,
  adminCandidateAccept,
  adminCandidateReject,
  adminCandidateMessage,
  retireveUserMembershipData,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminCandidates);
