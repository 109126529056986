import React, { useState } from 'react';
import { DefaultModal } from '../../../components/Modal';
import { Tabs, TabItem } from '../../../components/Navbar';

import CreditTab from './selectedClientTabs/creditTab';
import OrderTab from './selectedClientTabs/orderTab';
import PaymentTab from './selectedClientTabs/paymentTab';
import ClientInfoTab from './selectedClientTabs/clientInfoTab';

const ModalSelectedClient = (props) => {
    const [selectedTab, setSelectedTab] = useState('info');

    const handleTabSelect = (tab) => {
        setSelectedTab(tab);
    };

    return (
        <DefaultModal show={props.show} size="xl" title={props.client.first_name + ' ' + props.client.last_name} onHide={props.closeModal}>
            <div>
                <Tabs defaultActiveKey={selectedTab}>
                    <TabItem eventKey="info" onClick={handleTabSelect} active={selectedTab === 'info'}>
                        Información General
                    </TabItem>
                    <TabItem eventKey="credit" active={selectedTab === 'credit'} onClick={handleTabSelect}>
                        Crédito
                    </TabItem>
                    <TabItem eventKey="orders" onClick={handleTabSelect} active={selectedTab === 'orders'}>
                        Ordenes
                    </TabItem>
                    <TabItem eventKey="payments" onClick={handleTabSelect} active={selectedTab === 'payments'}>
                        Pagos
                    </TabItem>
                </Tabs>
            </div>
            {selectedTab === 'credit' && <CreditTab client={props.client} />}
            {selectedTab === 'info' && <ClientInfoTab client={props.client} />}
            {selectedTab === 'orders' && <OrderTab client={props.client} setSelectedOrderId={props.setSelectedOrderId} />}
            {selectedTab === 'payments' && <PaymentTab client={props.client} />}
        </DefaultModal>
    );
};

export default ModalSelectedClient;
