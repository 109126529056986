import Styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'; 

export const DropdownMenuLi = Styled.div`
  position: relative;
  display: block;
  height: 50%;
`;

export const PaymentMethodCardDetails = Styled.div`
  display: flex;
  flex-direction: row;
`;

export const PaymentMethodExpires = Styled.div`
  font-size: 14px;
`;
export const PaymentMethodInformation = Styled.div`
  padding: 12px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255,255,255,0.1);
`;

export const PaymentMethodNumberDetails = Styled.div`
  padding-right: 15px;
`;

export const PaymentMethodNumbers = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
`;
export const PaymentMethodType = Styled.div`
  padding-right: 15px;
`;

export const PaymentMethodTypeIcon = Styled(FontAwesomeIcon)`
  color: white;
`;

export const ModalAddPaymentMethodCodeExpirationContainer = Styled.div`

`;

export const PaymentDetailsSectionWrapper = Styled.div`
  min-height: 250px;
`;

export const PaymentAmountFieldsContainer = Styled.div`
  display: flex;
  justify-content: space-evenly;

  div {
    flex-grow: 1;
  }
  div:first-child {
    padding-right: 25px;
  }
`;

export const SubscriptionActionBtn = Styled(FontAwesomeIcon)`
  color: white;
  margin: 0 15px;
`;