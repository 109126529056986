import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import DistributorTable from './distributorsTable';
import {
  indexAdminDistributors,
  adminDistributorFetchSummary,
  adminDistributorFetchSubscription,
} from 'actions/adminActions';

import { SweetAlert } from 'components/Alert';

import {
  getSubscriptionPlans,
} from 'actions/subscriptionActions'

import {
  ListCreditCards
} from 'actions/creditCardActions';

import {
  showMultiLevel
} from 'actions/networkActions';

const DistributorIndex = (props) => {
  const [distributors, setDistributors] = useState([])
  const [selectedDistributor, setSelectedDistributor] = useState([])
  const [distributorData, setDistributorData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sweetAlertData, setSweetAlertData] = useState({});

  useEffect(() => {
    props.indexAdminDistributors().then(() => {
      setIsLoading(false);
    })
  }, [])

  useEffect(() => {
    setSweetAlertData({
      config: {
        title: "Cargando...",
        text: "Por favor espera unos momentos...",
      },
      show: isLoading,
      isLoading: true
    })
  }, [isLoading])

  useEffect(() => {
    if (props.distributors) {
      setDistributors(props.distributors);
      if (selectedDistributor[0]) {
        let fetched_distributor = getDistributorById(selectedDistributor[0]);
        setDistributorData(fetched_distributor);
        setIsLoading(false);
      }
    }
  }, [props.distributors])

  const getDistributorById = (id) => props.distributors.filter(match => match.id === id)[0];
  
  const fetchSummaryData = (id) => {
    props.adminDistributorFetchSummary({id: id})
  }

  const fetchPlans = () => {
    props.getSubscriptionPlans()
  }

  const fetchSubscriptionData = (id) => {
    //props.adminDistributorFetchSubscription({id: id})
  }
  
  const fetchNetworkData = (id) => {
    props.showMultiLevel(id)
  }

  return (
    <div style={{width: '100%'}}>
      <SweetAlert {...sweetAlertData} />
      <DistributorTable
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        distributors={distributors}
        setSelectedDistributor={setSelectedDistributor}
        selectedDistributor={selectedDistributor}
        distributorData={distributorData}
        fetchSummaryData={fetchSummaryData}
        fetchSubscriptionData={fetchSubscriptionData}
        fetchNetworkData={fetchNetworkData}
        fetchPlans={fetchPlans}
        subscription={props.subscription}
        showMultiLevel={props.showMultiLevel}
        network={props.network}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  distributors: state.admin.distributors,
  subscription: state.subscription,
  network: state.network,
})

const mapDispatchToProps = {
  indexAdminDistributors,
  adminDistributorFetchSummary,
  adminDistributorFetchSubscription,
  getSubscriptionPlans,
  ListCreditCards,
  showMultiLevel,
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorIndex);
