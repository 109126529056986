import React from 'react';
import {DataTable, DataTbody, DataThead, Datum, Header, Row} from '../../../components/Table';
import ComissionRow from './comissionRow';

const ComissionsTable = (props) => {
  return (
    <DataTable isLoading={props.isLoading}>
      <DataThead>
        <Header>Comision de</Header>
        <Header>Cantidad</Header>
        <Header>Estado</Header>
        <Header>Fecha</Header>
      </DataThead>
      <DataTbody>
        {(!props.isLoading && props.comissions.length > 0) &&
          props.comissions.map(comission => {
            return <ComissionRow comission={comission}/>
          })
        }
      </DataTbody>
    </DataTable>
  )
}

export default ComissionsTable
