import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {ActionSpinnerContainer} from './style'

const ActionSpinner = (props) => {
  return (
    <ActionSpinnerContainer>
      <CircularProgress color={props.color}/>
    </ActionSpinnerContainer>
  )
}

export default ActionSpinner;