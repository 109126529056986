import Styled, {css} from 'styled-components';

const hoverColor = css`
  &:hover{
    color: ${props => props.hoverColor};
  }
` 

export const TabGroupContainer = Styled.div`
  display: flex;
  margin-bottom: 16px;
  box-sizing: border-box;
  box-shadow: inset 0 -1px ${props => props.theme.bg.border};
  padding-top: 8px;
  user-select: none;
  ${props => props.hideLine && "box-shadow: none;"}
  div {
    ${props => !!props.hoverColor && hoverColor}
  }
`;

export const TabContainer = Styled.div`
  padding: 5px 10px 13px 5px;
  margin-right: 16px;
  font-weight: 600;
  font-size: 14px;
  color: #99aed6;
  &:hover {
    // color: #1A1F36;
    color: white;
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
  }
  ${props => props.active && ActiveTab }
  ${props => props.disabled && DisabledTab }
  ${props => (!props.active && !props.disabled) && DefaultTab }
`;

export const ActiveTab = css`
  color: #5469d4;
  border-bottom: 0.2rem solid #5469d4;
  &:hover {
    color: #5469d4 !important;
  }
`;

export const DisabledTab = css`
  color: #bdbdbd;
`;

export const DefaultTab = css`
  color: #99aed6;
  &:hover {
    border-bottom: 0.2rem solid ${props => props.theme.bg.border};
  }
`;

export const TabText = Styled.li`
  list-style: none;
`;
