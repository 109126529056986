import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DistributorsTable from './distributorsTable';
import { MainWrapper, Page } from 'components/Body';
import Swal from 'sweetalert2';

import { distributorNetworkCreate, distributorNetworkIndex } from 'actions/distributorActions';
import ModalDistributorCreate from './ModalDistributorCreate';
import ModalDistributorInvite from './ModalDistributorInvite';

const DistributorNetwork = (props) => {
    const [isModalDistributorCreateOpen, setIsModalDistributorCreateOpen] = useState(false);
    const [isModalDistributorInviteOpen, setIsModalDistributorInviteOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        props.distributorNetworkIndex().then((data) => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (props.distributors.length) {
            setIsLoading(false);
        }
    }, [props.distributors]);

    const onDistributorCreate = (data) => {
        setIsLoading(true);
        props.distributorNetworkCreate(data).then((res) => {
            if (res.status === 200) {
                Swal.fire(`Se ha registrado a un nuevo distribuidor exitosamente.`, 'Por favor pide a tu distribuidor completar su registro para la validación.', 'success');
            }

            if (res.status >= 400) {
                Swal.fire('Error', res.errors.toString(), 'error');
            }

            setIsLoading(false);
        });
    };

    const onDistributorInvite = () => {};

    return (
        <Page title="Mis Distribuidores">
            <MainWrapper title="Listado" actionButton={true} showSearch={false} inputPlaceholder={'Busca a un distribuidor'} onActionClick={() => setIsModalDistributorCreateOpen(!isModalDistributorCreateOpen)}>
                <DistributorsTable isLoading={isLoading} distributors={props.distributors} />
            </MainWrapper>
            <ModalDistributorCreate show={isModalDistributorCreateOpen} toggleModal={() => setIsModalDistributorCreateOpen(!isModalDistributorCreateOpen)} onCreate={onDistributorCreate} user={props.user.data} />
            <ModalDistributorInvite show={isModalDistributorInviteOpen} toggleModal={() => setIsModalDistributorInviteOpen(!isModalDistributorInviteOpen)} onCreate={onDistributorInvite} user={props.user.data} />
        </Page>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    distributors: state.distributor.distributors,
    rank: state.rank,
});

const mapDispatchToProps = {
    distributorNetworkCreate,
    distributorNetworkIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributorNetwork);
