export const dispatchAction = function (dispatch, res, type) {
    if (res.status < 200 && res.status > 299) {
        console.error(res.data?.errors);
        if (res.data?.status === 400) {
            return res.data;
        }
    }

    let payload = res.data || Object.values(res.data.data)[0];

    dispatch({
        type,
        payload,
    });

    return payload;
};
