import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { createFlashMessageRequest } from '../../actions/alertActions';
import { loginUserWithExistingToken, renewUserTokenRequest } from '../../actions/userActions';
import { getUserMembershipData } from '../../actions/subscriptionActions';

const ProtectedRoute = ({ component: Component, ...props }) => {
    const [tokenVerified, setTokenVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionStorageItems, setSessionStorageItems] = useState();

    useEffect(() => {
        let loginKeys = JSON.parse(sessionStorage.getItem('sessionToken'));
        setSessionStorageItems(loginKeys);
        if (!loginKeys) {
            setIsLoading(false);
            setTokenVerified(false);
        }
    }, []);

    useEffect(() => {
        if (!tokenVerified && sessionStorageItems) {
            loginOrRenewToken().then((res) => {
                let isVerified = !(res && res.error && res.error.status === 401) && res.status === 200;
                setTokenVerified(isVerified);
            });
        }
    }, [tokenVerified, sessionStorageItems]);

    useEffect(() => {
        if (props.user.isLoggedIn) {
            props.getUserMembershipData(props.user.data.id);
            if (props.user.data.attributes.change_password_needed) {
                //props.showpasswordchandemodal
            }
            if (isLoading) setIsLoading(false);
        }
    }, [props.user]);

    const loginOrRenewToken = () => {
        return storageItemsValid() ? props.loginUserWithExistingToken(sessionStorageItems) : props.renewUserTokenRequest();
    };

    const storageItemsValid = () => {
        if (!sessionStorageItems) return false;
        let timeNow = Math.floor(Date.now() / 1000);
        return timeNow - sessionStorageItems.created_at < sessionStorageItems.expires_in;
    };

    const hasRequiredRole = () => {
        if (!props.only) return true;
        return !!props.user.data.attributes && props.only.indexOf(props.user.data.attributes.role) >= 0;
    };

    if (isLoading) {
        return <div>LOADING</div>;
    }

    return (
        <Route
            {...props}
            render={() => {
                if (props.user.isLoggingOut) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
                if (props.user.isLoggedIn) {
                    if (hasRequiredRole()) {
                        return <Component {...props} />;
                    }

                    props.createFlashMessageRequest({
                        closeTime: 1500,
                        variant: 'danger',
                        message: 'No tienes los permisos para acceder a esta pagina',
                    });

                    return (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                } else {
                    props.createFlashMessageRequest({
                        closeTime: 1500,
                        variant: 'danger',
                        message: 'Necesitas iniciar sesión para poder acceder a esta pagina',
                    });
                    return (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    createFlashMessageRequest,
    loginUserWithExistingToken,
    renewUserTokenRequest,
    getUserMembershipData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
