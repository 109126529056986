import {
    CREATE_DISTRIBUTOR_CLIENT,
    CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT,
    INDEX_DISTRIBUTOR_CLIENT,
    UPDATE_DISTRIBUTOR_CLIENT,
    DELETE_DISTRIBUTOR_CLIENT,
    PAY_DISTRIBUTOR_COMISSION_LIST,
    INDEX_DISTRIBUTOR_COMISSION,
    INDEX_DISTRIBUTOR_MONEY,
    INDEX_DISTRIBUTOR_ORDER,
    CREATE_DISTRIBUTOR_NETWORK,
    INDEX_DISTRIBUTOR_NETWORK,
    INDEX_DISTRIBUTOR_CARDS,
    INDEX_DISTRIBUTOR_ADDRESSES,
    CREATE_DISTRIBUTOR_PAYMENT,
    CREATE_DISTRIBUTOR_SOURCE,
    UPDATE_DISTRIBUTOR_PAYMENT,
    UPDATE_DEFAULT_CREDIT_CARD,
    DELETE_DISTRIBUTOR_SOURCE,
    CREATE_CREDIT_CARD_PAYMENT,
} from '../actions/types';

const initialState = {
    clients: {},
    distributors: [],
    credit_cards: [],
    payments: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_DISTRIBUTOR_PAYMENT:
            let updated_payment = action.payload;
            return { ...state, payments: [...state.payments.filter((match) => match.id !== action.payload.id), updated_payment] };
        case CREATE_DISTRIBUTOR_PAYMENT:
            return { ...state, payments: [...state.payments, action.payload] };
        case INDEX_DISTRIBUTOR_ADDRESSES:
            return { ...state, addresses: [...action.payload] };
        case INDEX_DISTRIBUTOR_CARDS:
            return { ...state, credit_cards: [...action.payload] };
        case CREATE_CREDIT_CARD_PAYMENT:
            return { ...state, credit_cards: [...state.credit_cards, action.payload] };
        case CREATE_DISTRIBUTOR_SOURCE:
            let updated_credit_cards = updateCreditCards(state.credit_cards, action.payload);
            return { ...state, credit_cards: [...updated_credit_cards, action.payload] };
        case DELETE_DISTRIBUTOR_SOURCE:
            return { ...state, credit_cards: [...state.credit_cards.filter((card) => card.id !== action.payload.id)] };
        case INDEX_DISTRIBUTOR_NETWORK:
            return { ...state, distributors: [...action.payload.distributors] };
        case CREATE_DISTRIBUTOR_NETWORK:
            return { ...state, distributors: [...state.distributors, action.payload.distributor] };
        case CREATE_DISTRIBUTOR_CLIENT:
            return { ...state, clients: [...state.clients, action.payload.user] };
        case INDEX_DISTRIBUTOR_CLIENT:
            return { ...state, clients: { ...action.payload } };
        case UPDATE_DISTRIBUTOR_CLIENT:
            return { ...state };
        case DELETE_DISTRIBUTOR_CLIENT:
            return { ...state };
        case UPDATE_DEFAULT_CREDIT_CARD:
            return { ...state, credit_cards: updateDefaultCreditCard(action.payload, state.credit_cards) };
        case PAY_DISTRIBUTOR_COMISSION_LIST:
            return { ...state };
        case INDEX_DISTRIBUTOR_COMISSION:
            return { ...state, comissions: [...action.payload.comissions] };
        case INDEX_DISTRIBUTOR_MONEY:
            return { ...state, ...action.payload };
        case INDEX_DISTRIBUTOR_ORDER:
            return { ...state, orders: action.payload.orders && [...action.payload.orders] };
        case CREATE_DISTRIBUTOR_CLIENT_STORE_CREDIT:
            let user_id = action.payload.credit[0].credit.user_id;
            let client = state.clients.find((client) => client.id === user_id);
            let updated_client = {
                ...state.clients.find((match) => match.id === user_id),
                store_credits: [...client.store_credits, ...action.payload.credit.map((credits) => credits.credit)],
                bank_account: {
                    ...client.bank_account,
                    client_store_credit_amount: parseFloat(client.bank_account.client_store_credit_amount) + action.payload.used_amount,
                    client_store_credit_remaining: parseFloat(client.bank_account.client_store_credit_remaining) + action.payload.used_amount,
                },
            };
            return {
                ...state,
                clients: [...state.clients.filter((match) => match.id !== user_id), updated_client],
            };
        default:
            return state;
    }
}

const updateDefaultCreditCard = (new_default_card, credit_cards) => {
    let updated_credit_cards = updateCreditCardsToSecondary(credit_cards);
    return [...updated_credit_cards.filter((card) => card.id !== new_default_card.id), new_default_card];
};

const updateCreditCards = (credit_cards, payload) => {
    if (payload.attributes.default) {
        credit_cards = updateCreditCardsToSecondary(credit_cards);
    }

    return credit_cards;
};

const updateCreditCardsToSecondary = (credit_cards) => {
    return credit_cards.map((card) => {
        return {
            ...card,
            attributes: {
                ...card.attributes,
                default: false,
            },
        };
    });
};
