import React, { useState, useEffect } from 'react';
import { ModalTabContainer } from '../style';
import { TableContainer, Paper, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { dateFormat, variantStates, setupMUIDataGridColumns } from 'utils/common';
import { extractAttributes } from 'utils/formatters';
import { ColoredDatum } from '../../../../components/Table';

const PaymentsTab = (props) => {
    const [paymentColumns, setPaymentColumns] = useState([]);
    const [scheduledPaymentColumns, setScheduledPaymentColumns] = useState([]);
    const [comissionColumns, setComissionColumns] = useState([]);
    const [isExpanded, setIsExpanded] = useState({ payments: false, scheduled_payments: false, comissions: false });

    const setupPaymentColumns = () => {
        setPaymentColumns(setupMUIDataGridColumns(props.payments.data, PAYMENT_TABLE_HEADERS));
    };

    const setupScheduledPaymentColumns = () => {
        let IGNORE = ['created_at'];
        setScheduledPaymentColumns(setupMUIDataGridColumns(props.scheduled_payments, TABLE_HEADERS, IGNORE));
    };

    const setupComissionColumns = () => {
        setComissionColumns(setupMUIDataGridColumns(props.comissions, TABLE_HEADERS));
    };

    const onToggleExpand = (e) => {
        setIsExpanded({ ...isExpanded, [e.currentTarget.id]: !isExpanded[e.currentTarget.id] });
    };

    useEffect(() => {
        setIsExpanded({
            payments: !!paymentColumns.length,
            scheduled_payments: !!scheduledPaymentColumns.length,
            comissions: !!comissionColumns.length,
        });
    }, [paymentColumns, scheduledPaymentColumns, comissionColumns]);

    useEffect(() => {
        setupPaymentColumns();
        setupScheduledPaymentColumns();
        setupComissionColumns();
    }, []);

    function getCompletedPayments() {
        return extractAttributes(props.payments.data).filter((row) => row.state === 'completed');
    }

    // TOOD: tables can be hideable
    return (
        <ModalTabContainer>
            <Accordion onChange={onToggleExpand} expanded={isExpanded['payments']}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="payments-content" id="payments">
                    <Typography sx={{ width: '50%', flexShrink: 0 }}>Pagos realizados</Typography>
                    {!paymentColumns.length && <Typography sx={{ color: 'text.secondary' }}>No hay registros</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {!!paymentColumns.length ? (
                        <TableContainer component={Paper} style={{ width: '100%', height: '300px' }}>
                            <DataGrid rows={getCompletedPayments()} columns={paymentColumns} pageSize={15} />
                        </TableContainer>
                    ) : (
                        <div> No hay pagos realizados. </div>
                    )}
                </AccordionDetails>
            </Accordion>

            <Accordion onChange={onToggleExpand} expanded={isExpanded['scheduled_payments']}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="scheduledpayments-content" id="scheduled_payments">
                    <Typography sx={{ width: '50%', flexShrink: 0 }}> Quincenas </Typography>
                    {!scheduledPaymentColumns.length && <Typography sx={{ color: 'text.secondary' }}>No hay registros</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {!!scheduledPaymentColumns.length ? (
                        <TableContainer component={Paper} style={{ width: '100%', height: '300px' }}>
                            <DataGrid rows={props.scheduled_payments} columns={scheduledPaymentColumns} pageSize={15} />
                        </TableContainer>
                    ) : (
                        <div> No hay información en esta sección. </div>
                    )}
                </AccordionDetails>
            </Accordion>

            <Accordion onChange={onToggleExpand} expanded={isExpanded['comissions']}>
                <AccordionSummary expandIcon={<ExpandMore />} aria-controls="comissions-content" id="comissions">
                    <Typography sx={{ width: '50%', flexShrink: 0 }}> Comisiones </Typography>
                    {!comissionColumns.length && <Typography sx={{ color: 'text.secondary' }}>No hay registros</Typography>}
                </AccordionSummary>
                <AccordionDetails>
                    {!!comissionColumns.length ? (
                        <TableContainer component={Paper} style={{ width: '100%', height: '300px' }}>
                            <DataGrid rows={props.comissions} columns={comissionColumns} pageSize={15} />
                        </TableContainer>
                    ) : (
                        <div> No hay información en esta sección. </div>
                    )}
                </AccordionDetails>
            </Accordion>

            {/* add generated comissions if they exist*/}
        </ModalTabContainer>
    );
};

const PAYMENT_TABLE_HEADERS = {
    number: {
        field: 'number',
        headerName: 'Número',
        width: 150,
    },
    state: {
        field: 'state',
        headerName: 'Estado',
        width: 150,
    },
    amount: {
        field: 'amount',
        headerName: 'Cantidad',
        valueGetter: (params) => {
            return params.value + ' MXN';
        },
    },
    payment_method_name: {
        field: 'payment_method_name',
        headerName: 'Método de pago',
        width: 200,
    },
    card_last_digits: {
        field: 'card_last_digits',
        headerName: 'Últimos digitos',
        width: 200,
    },
};

const TABLE_HEADERS = {
    id: {
        field: 'id',
        headerName: 'id',
        width: 80,
        hidden: true,
    },
    state: {
        field: 'state',
        headerName: 'Estado',
        width: 150,
        renderCell: (params) => {
            let currState = variantStates[params.value] || variantStates['default'];
            return (
                <ColoredDatum variant={currState.variant} icon={currState.icon}>
                    {currState.text}
                </ColoredDatum>
            );
        },
    },
    status: {
        field: 'status',
        headerName: 'Estado',
        width: 150,
        renderCell: (params) => {
            let currState = variantStates[params.value] || variantStates['default'];
            return (
                <ColoredDatum variant={currState.variant} icon={currState.icon}>
                    {currState.text}
                </ColoredDatum>
            );
        },
    },
    order_percentage: {
        field: 'order_percentage',
        headerName: 'Porc. del total',
        width: 100,
        renderCell: (params) => {
            return (params.value * 100).toFixed(2) + ' %';
        },
    },
    number: {
        field: 'number',
        headerName: 'Número',
        width: 100,
    },
    amount: {
        field: 'amount',
        headerName: 'Total',
        width: 120,
        valueGetter: (params) => {
            return params.value.toFixed(2) + ' MXN';
        },
    },
    amount_paid: {
        field: 'amount_paid',
        headerName: 'Cant. Pagada',
        width: 120,
        valueGetter: (params) => {
            return params.value.toFixed(2) + ' MXN';
        },
    },
    source_type: {
        field: 'source_type',
        headerName: 'Tipo',
        width: 150,
        valueGetter: (params) => {
            return SOURCE_TYPES[params.value].value;
        },
    },
    created_at: {
        field: 'created_at',
        headerName: 'Fecha',
        width: 80,
        valueGetter: (params) => {
            return dateFormat(params.value);
        },
    },
    due_date: {
        field: 'due_date',
        headerName: 'Limite de pago',
        width: 140,
        valueGetter: (params) => {
            return dateFormat(params.value);
        },
    },
};

const SOURCE_TYPES = {
    'Spree::StoreCredit': {
        value: 'Crédito de distribuidor',
    },
    'Spree::CreditCard': {
        value: 'Tarjeta de crédito',
    },
};

export default PaymentsTab;
