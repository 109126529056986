import React from 'react';
import { FormGroup, DefaultInput, InputLabel } from './style.js';
import { validateFields } from '../../utils/validations/forms';
import { BasicErrorField, BasicInputLabel } from './';
import { CheckOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import InformationAlert from 'components/Alert/Information.js';

class BasicInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert: (props.error && props.error.showAlert) || (props.validators && props.validators.errors),
            showErrorField: false,
            validationMessages: [],
            updatedAfterRender: false,
            isValid: false,
        };

        this.errorPresent = props.validators;
        this.handleChange = this.handleChange.bind(this);
        this.setShowAlert = this.setShowAlert.bind(this);
    }

    componentDidUpdate() {
        if (this.props.value && this.props.value.length > 0 && !this.state.updatedAfterRender) {
            // validates browser's autocompleted values
            let name = this.props.name || this.props.id;
            this.handleChange({ target: { name } });
            this.setState({ ...this.state, updatedAfterRender: true });
        }
    }

    handleChange(e) {
        if (this.props.type === 'phone') {
            if (/^[0-9]{0,10}$/.test(this.props.value)) {
                return;
            }
        }

        if (this.errorPresent) {
            let valMessages = validateFields(this.props);
            this.props.sendFieldValidation && this.props.sendFieldValidation(e, valMessages.validInput);
            return this.setState({
                validationMessages: valMessages,
                showAlert: !valMessages.validInput,
                showErrorField: !valMessages.validInput,
                isValid: this.props.value.length && valMessages.validInput,
            });
        }
    }

    setShowAlert = (e) => {
        this.setState({ showAlert: e || !this.state.showAlert });
    };

    getType = () => {
        if (this.props.type === 'password') {
            return this.props.showPassword ? 'text' : 'password';
        }

        return this.props.type || 'text';
    };

    render() {
        let props = this.props;
        let { showErrorField, validationMessages, showAlert } = this.state;
        let errorPresent = this.errorPresent;
        let setShowAlert = this.setShowAlert;
        let label = props.label || props.title;
        return (
            <FormGroup>
                {props.isRequired && (
                    <BasicInputLabel required={props.required || (props.validators && props.validators.required)} for={props.id} errorField={showErrorField} tooltip="Campo Requerido" style={props.style}>
                        {props.title}
                    </BasicInputLabel>
                )}
                {label && <InputLabel>{label}</InputLabel>}
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <DefaultInput id={props.id} type={this.getType()} name={props.name} placeholder={props.placeholder} onChange={this.props.onChange} onBlur={this.handleChange} errorField={showErrorField} onKeyPress={props.onKeyPress} disabled={props.disabled} value={props.value} defaultChecked={props.defaultChecked} style={props.inputStyle} isValid={this.state.isValid} />
                        {props.type === 'password' && (
                            <div style={{ marginLeft: 7 }} onClick={() => props.onClickShowPassword()}>
                                {props.showPassword ? <VisibilityOff sx={{ color: '#fff' }} /> : <Visibility sx={{ color: '#fff' }} />}
                            </div>
                        )}
                        {errorPresent && validationMessages.length > 0 && <BasicErrorField style={{ marginLeft: 7 }} showAlert={showAlert} setShowAlert={setShowAlert} validationMessages={validationMessages} errorPresent={errorPresent} showErrorField={showErrorField} />}
                    </div>
                    {errorPresent && showAlert && validationMessages.length > 0 && (
                        <InformationAlert show={showAlert} onClose={() => setShowAlert(false)}>
                            {validationMessages.map((err, i) => {
                                if (!!err && !err.valid) {
                                    return <li key={i}>{err.error}</li>;
                                }
                                return <React.Fragment />;
                            })}
                        </InformationAlert>
                    )}
                </div>
                {props.type !== 'password' && !!this.state.isValid && (
                    <div className="absolute right-1 top-1">
                        <CheckOutlined sx={{ color: '#047857' }} />
                    </div>
                )}
            </FormGroup>
        );
    }
}

export default BasicInput;
