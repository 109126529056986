import React from "react";
import {IconButton} from "./";
import { Edit } from '@mui/icons-material';
import Radio from "@mui/material/Radio";

function AddressItem({checked=false, address}) {
  return (
    <div
      className="w-full flex h-24 flex-row items-start rounded-xl mb-2 p-2 overflow-hidden"
      style={{backgroundColor: "rgb(70, 90, 124)"}}
    >
      <div>
        <Radio color="error" checked={checked}/>
      </div>
      <div className="flex flex-column flex-1 pl-2">
        <div className="text-white text-xl font-bold">
          Casa
        </div>
        <div className="text-gray-200 text-base font-thin">
          Calle 1, Col. 2, C.P. 12345, Ciudad, Estado
        </div>
      </div>
      <div className="flex justify-start">
        <IconButton size="small">
          <div className="text-white">
            <Edit sx={{ color: "white" }} />
          </div>
        </IconButton>
      </div>
    </div>
  )
}

export default AddressItem;
