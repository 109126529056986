import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import './index.css';

function LandingPages(props) {

  const [pathName, setPathName] = useState();

  useEffect(() => {
    if(props.user.isLoggedIn) {
      let role = props.user && props.user.data.attributes.role;
      if(role === "candidate") {
        setPathName("/candidate/register");
      } else {
        setPathName("/"+role);
      }
    } else {
      setPathName("/unauthenticated");
    }
  }, [])

  if(pathName) {
    return <Redirect to={pathName} from={props.location}/>
  }

  return (
    <div className="main-landing-content">
      
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
})

export default connect(mapStateToProps)(LandingPages);
