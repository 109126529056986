import {TOGGLE_LEFT_SIDEBAR} from '../actions/types';
import {isMobileScreen} from 'utils/screen';

const initialState = {
  isOpen: !isMobileScreen,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    default:
      return state;
  }

}
