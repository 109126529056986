import path from 'path';

export const fileFilter = async (file) => {
  try {
    if(!file.length) {
      //cambiar a no hacer nada
      throw new Error('No se ha eligido a ninguna imagen');
    }
    const validFileTypes = new RegExp(/jpeg|jpg|png|gif|pdf/);
    const extName = validFileTypes.test(path.extname(file[0].name).toLowerCase());
    const mimeType = validFileTypes.test(file[0].type)
    //max 15mb
    const validSize = file[0].size <= 15*1024*1024

    if (!mimeType) {
      throw new Error('El tipo de archivo seleccionado no es valido, por favor revisa que el archivo sea una imagen.');
    }else if(!extName) {
      throw new Error('La extension del archivo seleccionado no es valida');
    } else if (!validSize) {
      throw new Error('La imagen seleccionada es demasiado grande (max: 15 MB)');
    }
  
    if(mimeType && extName && validSize) {
      return Promise.resolve(file);
    }
  } catch(err) {
    return Promise.reject(err)
  }
}