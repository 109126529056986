import React from 'react';

import {variantStates} from '../../../../utils/common';

import {
  ModalDataColumn,
  DataTitle,
  DataValue,
  DataStatus,
  StateIcon,
} from '../style';

const ColumnDatum = (props) => {

  const states = variantStates

  const getStateOrDefault = () => {
    return states[props.status] || states["default"];
  }

  const selectedState = getStateOrDefault();

  return (
    <ModalDataColumn>
      {
        props.status &&
          <DataStatus variant={selectedState.variant}>
            <StateIcon icon={selectedState.icon}/>
            {selectedState.text}
          </DataStatus>
      }
      { props.value &&
        <DataValue>
          {props.value || "Sin Información"}
        </DataValue>
      }

      <DataTitle>
        {props.title}
      </DataTitle>
      
    </ModalDataColumn>
  )
}

export default ColumnDatum;
