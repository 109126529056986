import React, {useState} from 'react';
import {DefaultModal} from '../../../components/Modal';
import {Title, BasicInput, BasicSelect} from '../../../components/Form';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { DefaultButton } from '../../../components/Button';
import DistributorInviteUserTable from './distributorInviteUserTable'

const ModalDistributorInvite = (props) => {
  const [data, setData] = useState({
    userName: "",
    email: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setData({
      ...data,
      [id]: value,
    });
  }

  const resetFields = () => {
    let keys = Object.keys(data);
    let newData = {}
    keys.forEach(key => {
      newData[key] = ""
    });
    setData(newData);
  }

  const handleCreateNewClient = async () => {
    await props.onCreate(data);
    await props.toggleModal();
    await resetFields();
  }

  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      onSave={handleCreateNewClient}
      title={"Invita un usuario registrado a tu red"}
      saveButtonText="Enviar Invitación"
    >
      <Title icon={faUserCircle}>Busca a un usuario</Title>
      <BasicInput
        id={"Nombre de Usuario"}
        value={data.username}
        placeholder={"Escribe aquí el nombre de usuario."}
        onChange={(e) => handleInputChange(e)}
        title={"Nombre de Usuario"}
      />
      <BasicInput
        id={"email"}
        value={data.email}
        placeholder={"Escribe aquí... Ej: usuario@dominio.com"}
        onChange={(e) => handleInputChange(e)}
        title={"Correo Electrónico"}
      />
      <DefaultButton>
        Buscar
      </DefaultButton>
      <DistributorInviteUserTable/>
    </DefaultModal>
  )
}

export default ModalDistributorInvite;