/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DefaultModal } from 'components/Modal';
import { CircularProgressSpinner } from 'components/Spinners';
import { getUserPaymentPlans, getPayrollPayments } from 'actions/paymentPlanActions';
import { updateCart, AddCredit, RemoveCredit } from 'actions/pos/cartActions';
import { getPaymentMethods, getPayments, setPaymentMethodForBuy, setPaymentCreditMethodForBuy } from 'actions/pos/paymentActions';
import { PaidOutlined, PriceCheckOutlined, AttachMoneyOutlined, CreditCardOutlined, LocalAtmOutlined } from '@mui/icons-material';
import { ToggleButton, RadioButton, Flash } from '../components';
import UserCardsList from './userCardsList';
import { moneyFormatter } from 'utils/formatters';
import { getIntervalToStart, getStartDateWithIntervals } from 'utils/helpers/paymentModal';
import moment from 'moment';

const CREDIT_TYPES = {
    client: {
        name: 'Cliente',
        icon: <LocalAtmOutlined sx={{ color: 'white', fontSize: '32px' }} />,
    },
    store: {
        name: 'Monedero',
        icon: <PaidOutlined sx={{ color: 'white', fontSize: '32px' }} />,
    },
    payroll: {
        name: 'Nómina',
        icon: <PriceCheckOutlined sx={{ color: 'white', fontSize: '32px' }} />,
        disabled: true,
    },
};

const PAYMENT_METHODS = {
    cash: {
        name: 'Efectivo y terminal',
        type: 'Spree::PaymentMethod::Check',
        icon: <AttachMoneyOutlined sx={{ color: 'white', fontSize: '40px' }} />,
    },
    card: {
        name: 'Tarjeta',
        type: 'Spree::Gateway::StripeGateway',
        icon: <CreditCardOutlined sx={{ color: 'white', fontSize: '40px' }} />,
    },
};

const PaymentModal = ({ cart, setPaymentMethodForBuy, setPaymentCreditMethodForBuy, paymentCreditMethodSelected, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [paymentPlans, setPaymentPlans] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const [selectedCredit, setSelectedCredit] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
    const [selectedPaymentPlanId, setSelectedPaymentPlanId] = useState(null);
    const [selectedCreditCardId, setSelectedCreditCardId] = useState(null);
    const [notEnoughtCredit, setNotEnoughtCredit] = useState(false);
    const [paymentListInfo, setPaymentListInfo] = useState(null);

    useEffect(() => {
        if (cart.data === null) {
            setSelectedPaymentMethod(null);
            setSelectedPaymentMethodId(null);
            setSelectedPaymentPlanId(null);
            setSelectedCreditCardId(null);
            setNotEnoughtCredit(null);
        }
    }, [cart.data]);

    useEffect(() => {
        if (props.client.selectedClientId && props.client.loaded && cart.loaded) {
            if (!props.payments.data?.length && !props.payments.loaded && !props.payments.isLoading && !props.payments.error) {
                props.getPayments();
            }
            if (props.payments.paymentMethods.length) {
                setPaymentMethods(props.payments.paymentMethods);
            } else if (!props.payments.isLoading && !props.payments.error && !props.payments.loaded) {
                props.getPaymentMethods();
            }
        }
    }, [selectedPaymentMethodId, props.client, cart.loaded, props.payments.paymentMethods]);

    useEffect(() => {
        if (paymentMethods && selectedPaymentMethod) {
            let method = paymentMethods.find((m) => m.attributes.type === PAYMENT_METHODS[selectedPaymentMethod].type);

            setPaymentMethodForBuy({ type: method, cardId: selectedCreditCardId });

            if (method) {
                setSelectedPaymentMethodId(method.id);
            }
        } else {
            setPaymentMethodForBuy(null);
        }
    }, [selectedPaymentMethod, paymentMethods, selectedCreditCardId]);

    useEffect(() => {
        if (!client || props.client.selectedClientId !== client.id) {
            resetData();
            updateClient();
        }
    }, [props.client.data]);

    useEffect(() => {
        if (!isLoading && cart.isLoading) {
            setIsLoading(true);
        }

        if (isLoading && !cart.isLoading) {
            setIsLoading(false);
        }
    }, [cart.isLoading]);

    useEffect(async () => {
        if (selectedCredit) {
            if (selectedCredit !== 'store') {
                fetchPaymentPlans();
            } else {
                setSelectedPaymentMethod(null);
                setPaymentMethodForBuy(null);
                setPaymentPlans(null);
            }
        } else {
            setPaymentPlans(null);
            setSelectedPaymentPlanId(null);
        }
    }, [selectedCredit]);

    async function fetchPaymentPlans() {
        if (client) {
            if (selectedCredit !== 'payroll') {
                await props.getUserPaymentPlans(client.id);
            } else {
                await props.getPayrollPayments(client.id);
            }
        }
    }

    useEffect(() => {
        if (client && props.paymentPlans.list[client.id]?.length > 0) {
            setPaymentPlans(props.paymentPlans.list[client.id]);
        }
    }, [props.paymentPlans.list]);

    useEffect(async () => {
        if (selectedPaymentPlanId && selectedCredit) {
            let plan = paymentPlans.find((plan) => plan.id === selectedPaymentPlanId);

            if (plan && cart.data) {
                setPaymentCreditMethodForBuy({ type: selectedCredit, plan });
                getDataInfoPlan(plan);

                if (plan?.upfront_percentage === '' || plan?.upfront_percentage === '0.0') {
                    setPaymentMethodForBuy(null);
                    setSelectedPaymentMethod(null);
                }
            }
        }
    }, [selectedPaymentPlanId]);

    useEffect(() => {
        if (paymentPlans?.length > 0 && selectedCredit) {
            if (!selectedPaymentPlanId && !!cart.data) {
                let currentPlan = paymentPlans.find((p) => p.id === cart.data.attributes.payment_plan_id);
                if (currentPlan) {
                    setSelectedPaymentPlanId(cart.data.attributes.payment_plan_id);
                } else {
                    currentPlan = props.paymentPlans.list[client.id][0];
                    setSelectedPaymentPlanId(currentPlan.id);
                }
                setPaymentCreditMethodForBuy({ type: selectedCredit, plan: currentPlan });

                getDataInfoPlan(currentPlan);
            }
        }
    }, [paymentPlans]);

    useEffect(() => {
        if (selectedCredit && selectedCredit !== 'client' && client) {
            let notCredit = !(parseFloat(cart?.data?.attributes.item_total) < parseFloat(client.credits[selectedCredit].remaining));
            setPaymentCreditMethodForBuy({ type: selectedCredit, notEnoughtCredit: notCredit });
            setNotEnoughtCredit(notCredit);
        } else if (selectedCredit) {
            setPaymentCreditMethodForBuy({ type: selectedCredit });
            setNotEnoughtCredit(false);
        }
    }, [selectedCredit]);

    const getDataInfoPlan = (currentPlan) => {
        let toPay = cart.data.attributes.item_total;
        let percentage = 0;
        let total = cart.data.attributes.total;

        if (currentPlan.upfront_percentage) {
            percentage = parseFloat(currentPlan.upfront_percentage);

            toPay = toPay * percentage;
        }

        if (cart.data.attributes.ship_total) {
            toPay += parseFloat(cart.data.attributes.ship_total);
        }

        let index = 0;

        if (toPay) {
            index = 1;
        }

        let amountPerPay = 0;

        if (currentPlan.total_intervals > 0) {
            amountPerPay = (total - toPay) / (currentPlan.total_intervals - index);
        }

        let payFinnancingObjList = [];

        for (let i = index; i < currentPlan.total_intervals; i++) {
            let payFinnancingObj = {};
            let intervalToStart = '';
            if (i === 0 || (i === 1 && payFinnancingObjList.length === 0)) {
                let payDate = Date.now();

                if (!currentPlan.name.includes('sin interes')) {
                    let diffOfDates;

                    if (currentPlan.pay_after_date) {
                        payDate = currentPlan.pay_after_date;
                    } else {
                        if (currentPlan.pay_after_count != null) {
                            if (currentPlan.pay_after_count !== 0) {
                                payDate = getStartDateWithIntervals(currentPlan.interval, currentPlan.pay_after_count, 1, Date.now());
                            } else {
                                payDate = getStartDateWithIntervals(currentPlan.interval, 1, currentPlan.interval_count, Date.now());
                            }
                        } else {
                            payDate = getStartDateWithIntervals(currentPlan.interval, 1, currentPlan.interval_count, Date.now());
                        }
                    }

                    diffOfDates = payDate - Date.now();
                    if (diffOfDates > 0) {
                        intervalToStart = getIntervalToStart(currentPlan.interval, diffOfDates.Days);
                    }
                }

                payFinnancingObj.date = payDate;
            } else {
                if (payFinnancingObjList.Count > i - 1) {
                    payFinnancingObj.date = getStartDateWithIntervals(currentPlan.interval, 1, currentPlan.interval_count, payFinnancingObjList[i - 1]?.date);
                } else if (currentPlan.name.includes('sin intereses')) {
                    payFinnancingObj.date = getStartDateWithIntervals(currentPlan.interval, 1, currentPlan.interval_count, payFinnancingObjList[i - 1]?.date);
                } else {
                    if (currentPlan.category_name === 'payroll') {
                        payFinnancingObj.date = getStartDateWithIntervals(currentPlan.interval, 1, currentPlan.interval_count, payFinnancingObjList[i - 1]?.date);
                    } else {
                        let currentIndexForList = i - 2;
                        if (currentIndexForList < 0) {
                            currentIndexForList = 0;
                        }

                        payFinnancingObj.date = getStartDateWithIntervals(currentPlan.interval, 1, currentPlan.interval_count, payFinnancingObjList[currentIndexForList]?.date);
                    }
                }
            }

            payFinnancingObj.title = 'Pago ' + (i + 1);
            payFinnancingObj.amount = amountPerPay;
            payFinnancingObj.interval = currentPlan.interval;
            payFinnancingObj.interval_count = intervalToStart;
            payFinnancingObjList.push(payFinnancingObj);
        }

        setPaymentListInfo({ firstPay: { amount: toPay }, list: payFinnancingObjList });
    };

    function updateClient() {
        let selectedClient = props.client.data.find((client) => client.attributes?.id === props.client.selectedClientId);

        if (selectedClient) {
            setClient(selectedClient.attributes);
        }
    }

    async function onSave() {
        // update cart
        props.onClose();
    }

    function resetData() {
        setClient(null);
        setPaymentPlans(null);
    }

    function handleSelectCreditType(type, creditRemaining) {
        if (selectedCredit === type) {
            setSelectedCredit(null);
            setPaymentPlans(null);
            setNotEnoughtCredit(false);
            setPaymentListInfo(null);
            setPaymentCreditMethodForBuy(null);
            setPaymentMethodForBuy(null);
            return;
        }
        setSelectedCredit(type);
    }

    function handleSelectPaymentMethod(type) {
        if (selectedPaymentMethod === type) {
            setSelectedPaymentMethod(null);
            return;
        }
        setSelectedPaymentMethod(type);
    }

    if (client == null) return null;

    return (
        <DefaultModal show={props.show} onClose={props.onClose} onHide={props.onClose} title="Pago" onSave={onSave} saveButtonText="Guardar" closeButtonText="Cancelar" isLoading={isLoading}>
            <div className="">
                <div className="my-4">
                    {/*<PaymentSummary PAYMENT_METHODS={PAYMENT_METHODS} />*/}

                    <div className="text-xl mb-2">Método de pago</div>
                    <div className="flex flex-row justify-start items-center">
                        {Object.keys(PAYMENT_METHODS).map((key, i) => {
                            return (
                                <div className="mr-4" key={i}>
                                    <ToggleButton
                                        width="no"
                                        key={key}
                                        selected={selectedPaymentMethod === key}
                                        icon={PAYMENT_METHODS[key].icon}
                                        disabled={(key === 'card' && props.creditCards?.length === 0) || (selectedCredit !== 'client' ? parseFloat(cart?.data?.attributes.item_total) < parseFloat(client?.credits[selectedCredit]?.remaining) : Number(paymentListInfo?.firstPay?.amount).toFixed(2) <= 0)}
                                        onClick={() => handleSelectPaymentMethod(key)}
                                        description={<div className="text-white text-lg">{PAYMENT_METHODS[key].name}</div>}
                                    ></ToggleButton>
                                </div>
                            );
                        })}
                    </div>
                    {selectedPaymentMethod === 'card' && (
                        <div className="mt-4">
                            <UserCardsList selectedCreditCardId={selectedCreditCardId} setSelectedCreditCardId={setSelectedCreditCardId} selectedPaymentMethodId={selectedPaymentMethodId} />
                        </div>
                    )}
                </div>

                {!!client && (
                    <div className="my-4">
                        <div className="flex items-end mb-2">
                            <div className="text-xl mr-4">Crédito</div>
                        </div>

                        <div className="flex items-end mb-2">
                            <div className="text-sm mr-4"></div>
                        </div>

                        {notEnoughtCredit && selectedCredit !== 'client' && (
                            <>
                                <Flash hasButton={false} typeFlash="error">
                                    No hay suficiente crédito para pagar el saldo del carrito, favor de seleccionar otro método de pago para complementar
                                </Flash>

                                <div className="flex mb-4">
                                    <div className="text-lg underline font-bold text-white mr-1">Falta por pagar: </div>
                                    <div className="text-lg font-bold text-white">${Number(parseFloat(cart?.data?.attributes.item_total) - parseFloat(client.credits['store'].remaining)).toFixed(2)}</div>
                                </div>
                            </>
                        )}

                        <div className="flex flex-row justify-start items-center mb-3">
                            {isLoading ? (
                                <CircularProgressSpinner />
                            ) : (
                                Object.keys(CREDIT_TYPES).map((key) => (
                                    <div className="mr-3" key={key}>
                                        <ToggleButton width="no" key={key} selected={selectedCredit === key} icon={CREDIT_TYPES[key].icon} disabled={(client.credits && client.credits[key].remaining <= 0) || parseFloat(client.credits[key].remaining) < parseFloat(cart?.data?.attributes.item_total)} onClick={() => handleSelectCreditType(key, client.credits[key].remaining)} description={CREDIT_TYPES[key].name}>
                                            {moneyFormatter(client.credits[key].remaining)} MXN
                                        </ToggleButton>
                                    </div>
                                ))
                            )}
                        </div>

                        {paymentPlans?.length && selectedCredit && (
                            <>
                                <div className="mt-2">
                                    <div className="text-xl mb-2">Planes de pago</div>
                                    <div className="flex flex-column">
                                        {paymentPlans.map((paymentPlan, index) => (
                                            <RadioButton
                                                key={paymentPlan.id}
                                                checked={selectedPaymentPlanId === paymentPlan.id}
                                                onClick={() => {
                                                    setSelectedPaymentPlanId(paymentPlan.id);
                                                }}
                                                label={paymentPlan.name}
                                                value={paymentPlan.id}
                                            />
                                        ))}
                                    </div>
                                </div>

                                {paymentListInfo && (
                                    <div>
                                        <div className="text-base mb-2">
                                            <span className="text-white font-bold">Información de pagos</span>
                                        </div>
                                        <div className="flex mb-1">
                                            <div className="flex-1">
                                                <div className="text-lg underline font-bold text-white">Hoy pagaras con tu tarjeta: </div>
                                            </div>

                                            <div className="text-lg font-bold text-white">${Number(paymentListInfo?.firstPay?.amount).toFixed(2)}</div>
                                        </div>
                                        <div className="flex flex-column">
                                            {paymentListInfo?.list?.length &&
                                                paymentListInfo.list.map((p, index) => (
                                                    <div key={index} className="flex mb-1">
                                                        <div className="flex-1">
                                                            <div className="text-lg underline font-bold text-white">{p.title}</div>
                                                            <div className="text-base">{moment(p.date).locale('es-mx').format('D MMM YYYY')}</div>
                                                        </div>

                                                        <div className="text-lg font-bold text-white flex items-center">${Number(p.amount).toFixed(2)}</div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="flex bg-white h-px mb-1" />
                                        <div className="flex">
                                            <div className="flex-1">
                                                <div className="text-xl mb-2 underline font-bold text-white">Pago total: </div>
                                            </div>

                                            <div className="text-xl underline font-bold text-white">${Number(cart.data?.attributes.total).toFixed(2)}</div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </DefaultModal>
    );
};

const mapStateToProps = (state) => ({
    cart: state.posCart,
    client: state.posClient,
    paymentPlans: state.paymentPlan,
    payments: state.posPayment,
    creditCards: state.creditCard,
    paymentCreditMethodSelected: state.posPayment.paymentCreditMethodSelected,
});

const mapDispatchToProps = {
    getUserPaymentPlans,
    getPayrollPayments,
    updateCart,
    AddCredit,
    RemoveCredit,
    getPaymentMethods,
    getPayments,
    setPaymentMethodForBuy,
    setPaymentCreditMethodForBuy,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
