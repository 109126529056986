import React from "react";
import MUIIconBtn from '@mui/material/IconButton'

function CircledIconButton({
  children,
  size="large",
  style={},
  disabled=false,
  bgcolor="white",
  onClick=()=>{}}
) {
  return (
    <MUIIconBtn
      onClick={onClick}
      aria-label="Search"
      size={size}
      color="primary"
      variant="outlined"
      disabled={disabled}
      style={{backgroundColor: bgcolor, ...style}}
      sx={[
        {'&:hover': { bgcolor: "hsl(205, 31%, 45%, 40%)" }},
        { bgcolor: "white",
          width: "48px",
          height: "48px",
          color: "black"
        }
      ]}
    >{children}</MUIIconBtn>
  )
}

export default CircledIconButton;
