import axios from 'axios';

import { API_BASE_URL, EXPORT_COMISSIONS } from './types';

export const getComissionsForExport = (which) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'get',
            url: API_BASE_URL + '/es/app/api/v1/comissions/all_commissions',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        }).then((res) => {
            dispatch({ type: EXPORT_COMISSIONS, payload: res.data });
            return res;
        });
    };
};

export const getScheduledPaymentsExport = (user_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'get',
            url: API_BASE_URL + '/es/app/api/v1/scheduled_payments/all_scheduled_payments',
            params: { user_id },
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        }).then((res) => {
            dispatch({ type: EXPORT_COMISSIONS, payload: res.data });
            return res;
        });
    };
};

export const getRecordsExport = (user_id) => {
    return;
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'get',
            url: API_BASE_URL + '/es/app/api/v1/comissions/all_commissions',
            params: { user_id },
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        }).then((res) => {
            dispatch({ type: EXPORT_COMISSIONS, payload: res.data });
            return res;
        });
    };
};

export const getReportsExport = (which) => {
    let url = '';
    if (which === 'orders') url = '/es/app/api/v1/cubit_orders/all_cubit_orders';
    if (which === 'items') url = '/es/app/api/v1/cubit_line_items/all_cubit_line_items';
    if (which === 'items_clients') url = '/es/app/api/v1/cubit_items_clients/all_cubit_items_client';

    if (url === '') return;

    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'get',
            url: API_BASE_URL + url,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        }).then((res) => {
            dispatch({ type: EXPORT_COMISSIONS, payload: res.data });
            return res;
        });
    };
};
