import React, {useState, useEffect} from 'react';
import { TableContainer, Paper} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import {Header} from '../../../components/Header';
import { dateFormatter } from 'utils/formatters';

import DistributorDetails from './DistributorDetails';

import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import {
  TableContainerWrapper,
  TableToggleButton,
  TableToggleBar,
  TableToggleIcon
} from './style';

export default function DistributorTable (props) {
  const [columns, setColumns] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [tableMinimized, setTableMinimized] = useState(false);
  const [sortModel, setSortModel] = useState()

  useEffect(() => {
    if (props.distributors.length) {
      props.setIsLoading(false);
    }
  }, [props.distributors])


  useEffect(() => {
    setupColumns();
  }, [props.isLoading])

  useEffect(() => {
    if(columns.length) {
      setSortModel([{field: 'id', sort: 'asc'}]);
    }
  }, [columns])

  useEffect(() => {
    if (selectedRow.length) {
      setTableMinimized(selectedRow.length > 0);
      props.setSelectedDistributor(selectedRow);
    }
  }, [selectedRow])

  const setupColumns = () => {
    if (!props.isLoading) {
      let cols = Object.keys(props.distributors[0])
        .filter((id) => {
          if(TABLE_HEADERS[id]){
            return id
          }
        });
      //filter cols to show only whitelisted
      let formatCols = cols.map((id) => {
        return TABLE_HEADERS[id];
      })
      setColumns(formatCols);
    }
  }

  const handleRowSelection = (e) => {
    if (e.length == 0) {
      setSelectedRow([])
    } else {
      setSelectedRow([e[e.length-1]]);
    }
  }

  const handleTableButtonClick = () => {
    if (!props.selectedDistributor.length) {
      return;
    }
    setTableMinimized(!tableMinimized);
  }

  const findDistributor = () => {
    if (!props.selectedDistributor[0]) {
      return {}
    }
    return props.distributors.filter(match => match.id === props.selectedDistributor[0])[0];
  }

 return (
    <>
      {props.isLoading && columns.length ?
        <div>Loading...</div> :
        <>
          { selectedRow.length > 0 ?
            <Header>Distribuidor Seleccionado: {findDistributor().first_name +" "+ findDistributor().last_name}</Header>
            :
            <Header>Selecciona a un distribuidor</Header>
          }
          <DistributorDetails
            selectedDistributor={props.selectedDistributor}
            distributorData={props.distributorData}
            fetchSummaryData={props.fetchSummaryData}
            fetchCreditData={props.fetchCreditData}
            fetchSubscriptionData={props.fetchSubscriptionData}
            fetchNetworkData={props.fetchNetworkData}
            setIsLoading={props.setIsLoading}
            isLoading={props.isLoading}
            fetchPlans={props.fetchPlans}
            subscription={props.subscription}
            showMultiLevel={props.showMultiLevel}
            network={props.network}
          />
          <TableContainerWrapper minimized={tableMinimized}>
            <TableToggleBar>
              <TableToggleButton onClick={handleTableButtonClick}>
                <TableToggleIcon icon={tableMinimized ? faArrowUp : faArrowDown}/>
              </TableToggleButton>
            </TableToggleBar>
            <TableContainer
              component={Paper}
              style={{width: '100%', height: '100%'}}  
            >
              <DataGrid 
                rows={props.distributors}
                columns={columns}
                loading={props.distributors.length === 0}
                pageSize={15}
                rowsPerPageOptions={[10,15,25]}
                checkboxSelection
                onSelectionModelChange={handleRowSelection}
                selectionModel={selectedRow}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                defaultPageSize={100}
                pagination
              />
            </TableContainer>
          </TableContainerWrapper>
        </>
      }
    </>
  )
}

const TABLE_HEADERS = {
  id: {
    field: 'id',
    headerName: 'Id',
    width: 80,
  },
  email: {
    field: 'email',
    headerName: 'Correo',
    width: 160,
  },
  created_at: {
    field: 'created_at',
    headerName: 'Creado en',
    width: 160,
    renderCell: (p) => dateFormatter(p.value)
  },
  first_name: {
    field: 'fullName',
    headerName: 'Nombre',
    width: 160,
    description: 'Esta columna tiene un campo calculado y no puede ser ordenado.',
    valueGetter: (params) => {
      return `${params.getValue(params.id, 'first_name') || ''} ${params.getValue(params.id, 'last_name') || ''}`
    }
  },
  birthdate: {
    field: 'birthdate',
    headerName: 'Nacimiento',
    width: 160,
    description: 'Fecha de nacimiento',
    renderCell: (p) => dateFormatter(p.value)
  },
  telephone: {
    field: 'telephone',
    headerName: 'Teléfono',
    width: 80,
  },
  gender: {
    field: 'gender',
    headerName: 'Sexo',
    width: 80,
  },
  distributor_discount_code: {
    field: 'distributor_discount_code',
    headerName: 'Código',
    description: 'Codigo de distribuidor',
    width: 80,
  },
  membership: {
    field: 'membership',
    headerName: 'Membresia',
    description: 'Estado de la membresia del distribuidor',
    width: 80,
    valueGetter: (params) => {
      return params.row.membership?.state;
    }
  }
}
