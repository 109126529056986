import React, {useState} from "react";
import {connect} from "react-redux";
import Button from '@mui/material/Button';
import { MuiTextField } from "./";
import {createCreditCard} from "actions/creditCardActions";
import {formatNewCardFormValues} from "utils/formatters";
import {FormFlashMessage} from "components/Alert";
import { ActionSpinner } from "components/Spinners";


const CreateCardForm = ({user_id, afterCreate=(id)=>{}, ...props}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [creditCard, setCreditCard] = useState({
    name: "",
    cardNumber: "",
    expiration: "",
    code: "",
    default: false,
    valid: false,
  });

  function handleInputChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    let formattedValue = formatNewCardFormValues(value, name);

    setCreditCard({
      ...creditCard,
      [name]: formattedValue,
    });
  }

  async function handleSave() {
    setIsLoading(true);
    let response = await props.createCreditCard({
      credit_card: creditCard,
      user_id: user_id 
    });
    setIsLoading(false);
    if (response.status === 400) {
      setError(response.error)
      return;
    }
    afterCreate(response.id);
  }

  return (
    <div>
      {!!error &&
        <div className="mb-2">
          <FormFlashMessage
            onClose={() => setError(null)}
            variant="danger"
          >{error}</FormFlashMessage>
        </div>
      }
      <div>
        <MuiTextField
          name="name"
          value={creditCard.name}
          onChange={handleInputChange}
          label="Nombre del titular"
        />
      </div>
      <div className="mt-3">
        <div>
          <MuiTextField
            name="cardNumber"
            value={creditCard.cardNumber}
            onChange={handleInputChange}
            label="Número de tarjeta"
          />
        </div>
        <div className="flex">
          <MuiTextField
            name="expiration"
            value={creditCard.expiration}
            onChange={handleInputChange}
            label="Expiración"
          />
          <MuiTextField
            name="code"
            value={creditCard.code}
            onChange={handleInputChange}
            label="CVV"
          />
        </div>
      </div>
      <div className="flex self-stretch justify-end mt-3">
        <Button
          variant="contained"
          onClick={handleSave}
        >
          { isLoading ? <ActionSpinner color="white" /> : "Guardar" }
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  createCreditCard,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCardForm);
