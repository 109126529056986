import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ClientSearchModal, CustomButton, ClientItem } from './';
import { searchClientNetwork } from 'actions/pos/clientActions';
import { updateAutofocus } from 'actions/pos/cartActions';
import { addToNetwork } from 'actions/networkActions';

function AddToNetworkButton({ selectedFatherId, setSelectedFatherId, ...props }) {
    const [page, setPage] = useState('search');
    const [isLoading, setIsLoading] = useState(false);
    const [networkDistributors, setNetworkDistributors] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (props.client.network.length) {
            setNetworkDistributors(props.client.network);
        }
    }, [props.client.network]);

    useEffect(() => {
        if (show && props.cart.autoFocus) {
            props.updateAutofocus(false);
        } else {
            props.updateAutofocus(true);
        }
    }, [show]);

    function getFather() {
        return networkDistributors.find((d) => d.attributes.id === selectedFatherId);
    }

    const handleSelect = async (client) => {
        setShow(false);
        setSelectedFatherId(client.id);
        await addToNetwork({ user_id: props.client.data[0].attributes.id, code: client?.distributor_discount_code });
    };

    async function handleNetworkSearch(search) {
        setIsLoading(true);
        await props.searchClientNetwork({
            //user_id: props.client.selectedClientId,
            distributors_only: true,
            search_params: search,
        });
        setPage('results');
        setIsLoading(false);
    }

    const handleClose = () => {
        setShow(false);
        setIsLoading(false);
        setPage('search');
        setNetworkDistributors([]);
    };

    if (show) {
        return <ClientSearchModal onClose={handleClose} onHide={handleClose} show={show} onSave={handleNetworkSearch} onSelect={handleSelect} selectedClientId={selectedFatherId} selectAllClient={true} searchResults={networkDistributors} isLoading={isLoading} title={'Agregar a red'} page={page} setPage={setPage} />;
    }

    if (selectedFatherId && networkDistributors?.length) {
        return (
            <div className="w-full sm:pl-2">
                <div className="flex flex-row w-full flex-wrap space-between">
                    <ClientItem attributes={getFather().attributes} onSelect={() => setSelectedFatherId(null)} selectText="Borrar" />
                </div>
            </div>
        );
    }

    return (
        <div className="w-full">
            <CustomButton onClick={() => setShow(true)}>Agregar a red</CustomButton>
        </div>
    );
}

const mapStateToProps = (state) => ({
    client: state.posClient,
    cart: state.posCart,
    user: state.user,
});

const mapDispatchToProps = {
    searchClientNetwork,
    updateAutofocus,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToNetworkButton);
