import React from 'react';
import { Slider, styled, Box } from '@mui/material'

const MuiSlider = ({value, onChange, label, min, max, step, ...props}) => {

  const marks = [
    {
      value: min,
      label: min+'MXN'
    }, {
      value: Math.ceil(max * (1/4)),
      label: Math.ceil(max * (1/4))+' MXN'
    }, {
      value: max/2,
      label: (max/2)+' MXN'
    },{
      value: Math.ceil(max * (3/4)),
      label: Math.ceil(max * (3/4))+' MXN'
    },{
      value: max,
      label: max+' MXN'
    }
  ]

  function handleSliderChange(e) {
    let val = parseInt( e.target.value );
    if ( val <= min ) {
      val = min;
    }
    if ( val >= max ) {
      val = max;
    }
    onChange(val)
  }

  return (
    <Box sx={{width: "100%"}}>
      <div className='flex mb-2'>
        {label && <div className='text-gray-200 text-sm'>{label}</div>}
        <div className='ml-2'>
          {!!value && <div className='text-white text-m font-bold'>{`${value} MXN`}</div>}
        </div>
      </div>
      <CustomSlider
        arial-label="Pago"
        marks={marks}
        defaultValue={0}
        min={min}
        max={max}
        value={value}
        step={step}
        getArialValueText={`${value} MXN`}
        onChange={handleSliderChange}
        color="secondary"
      />
    </Box>
  );
}

export const CustomSlider = styled(Slider)(
  ({theme}) => ({
    '& .MuiSlider-markLabel': {
      color: "white",
    }
  })
);

export default MuiSlider;
