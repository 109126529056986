import React, {useState, useEffect} from 'react';
import { Title } from '../../../../components/Form';
import NetworkTable from './NetworkTable';

const NetworkPage = (props) => {
  return (
    <div>
      <Title>Distribuidores en Red</Title>

      <NetworkTable
        distributors={props.distributors}
        showMultiLevel={props.showMultiLevel}
        network={props.network}
        data={props.data}
        selectedDistributor={props.selectedDistributor}
      />
    </div>
  )
}

export default NetworkPage;