import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, DefaultSelect } from './style.js';
import { BasicInputLabel, BasicErrorField } from '../Form';
import { validateFields } from 'utils/validations/forms';

const BasicSelect = (props) => {
    const [showAlert, setShowAlert] = useState(props.error && props.error.showAlert);
    const [showErrorField, setShowErrorField] = useState(false);
    const [fieldObj, setFieldObj] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [validationMessages, setValidationMessages] = useState([]);
    const isInitialMount = useRef(true);
    let errorPresent = props.validators;

    const handleChange = async (e) => {
        e.persist();

        return Promise.resolve(props.onChange(e)).then(setFieldObj(e.target)).then(setSelectedIndex(e.target.selectedIndex));
    };

    const getValue = () => {
        try {
            if (props.children) {
                let value = props.children.find((child, index) => child.props.children === props.value || child.props.id === props.value);
                if (value) {
                    return value.props.children;
                } else {
                    return props.value;
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (props.required || (props.validators && props.validators.selectionRequired)) {
                let valMessages = validateFields({ ...props, selectedIndex: selectedIndex });
                let value = props.value;
                if (props.errors && props.errors.valid && !valMessages.validInput) {
                    setValidationMessages(valMessages);
                    setShowAlert(false);
                    setShowErrorField(false);
                }
                setShowAlert(!valMessages.validInput);
                setShowErrorField(!valMessages.validInput);
                props.sendFieldValidation(fieldObj, value.length > 0);
            }
        }
    }, [selectedIndex]);

    return (
        <FormGroup style={props.style}>
            {props.title && (
                <BasicInputLabel required={props?.required} htmlFor={props.id} errorField={showErrorField} tooltip="Selección requerida">
                    {props.title}
                </BasicInputLabel>
            )}
            <DefaultSelect id={props.id} onChange={handleChange} name={props.name} value={getValue()} errorField={showErrorField} disabled={props?.disabled}>
                {!props?.noDefault && (
                    <option id="0" value={props?.defaultValue || 'null'}>
                        {props.defaultText || 'Selecciona una opción'}
                    </option>
                )}
                {props?.children}
            </DefaultSelect>
            <BasicErrorField showAlert={showAlert} setShowAlert={setShowAlert} validationMessages={validationMessages} errorPresent={errorPresent} showErrorField={showErrorField} />
        </FormGroup>
    );
};

export default BasicSelect;
