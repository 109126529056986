import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { searchVariant, selectVariant, variantLoaded } from '../../actions/pos/variantActions';
import { addToCart, removeFromCart } from '../../actions/pos/cartActions';
import { Avatar, ColorRadio, CounterButton, SizeRadio, ActionButton } from './components';
import Skeleton from '@mui/material/Skeleton';
import { API_BASE_URL } from 'actions/types';
import { CheckOutlined } from '@mui/icons-material';

function ProductPanel(props) {
    const [data, setData] = useState({
        color: { presentation: '', name: '', id: '' },
        size: { presentation: '', name: '', id: '' },
        quantity: 1,
        changed: false,
    });
    const [showPanel, setShowPanel] = useState(false);
    const [variant, setVariant] = useState(null);
    const [lineItem, setLineItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [colorPresenter, setColorPresenter] = useState([]);
    const [sizePresenter, setSizePresenter] = useState([]);

    useEffect(async () => {
        setIsLoading(true);
        resetData();
        if (props.variant.selectedVariantId) {
            setShowPanel(true);
            await handleSearchVariant(props.variant.selectedVariantId);
            handleUpdateLineItem(props.variant.selectedVariantId);
            props.variantLoaded();
        }
        if (!props.variant.selectedVariantId) {
            setShowPanel(false);
            setIsLoading(false);
        }
    }, [props.variant.selectedVariantId]);

    useEffect(() => {
        if (props.variant.isLoading || props.cart.isLoading) {
            setIsLoading(true);
        } else if (isLoading && !!props.variant.data?.length && !props.variant.isLoading && !props.cart.isLoading) {
            setIsLoading(false);
        }
    }, [props.variant.isLoading, props.cart.isLoading]);

    useEffect(() => {
        if (variant) {
            if (props.variant.selectedVariantId !== parseInt(variant.id)) {
                props.selectVariant(parseInt(variant.id));
            }
            loadVariantData();
            setIsLoading(false);
        }
    }, [variant]);

    useEffect(() => {
        if (lineItem) {
            setData({
                ...data,
                quantity: lineItem.attributes.quantity,
            });
        }
    }, [lineItem]);

    function loadVariantData() {
        let color = variant.attributes.option_values.find((option) => {
            return option.attributes.option_type_name === 'color';
        });
        let size = variant.attributes.option_values.find((option) => {
            return ['talla', 'size'].includes(option.attributes.option_type_name.toLowerCase());
        });

        setData({
            ...data,
            color: { presentation: color.attributes.presentation, name: color.attributes.name, id: parseInt(color.id) },
            size: { presentation: size.attributes.presentation, name: size.attributes.name, id: parseInt(size.id) },
            changed: false,
        });

        let color_option_type = variant.attributes.option_types_presenter.find((option) => {
            return option.name === 'color';
        });

        let size_option_type = variant.attributes.option_types_presenter.find((option) => {
            return ['talla', 'size'].includes(option.name.toLowerCase());
        });

        setColorPresenter(color_option_type.option_values);
        setSizePresenter(size_option_type.option_values);
    }

    async function handleSearchVariant(id) {
        let variant = props.variant.data.find((variant) => {
            return parseInt(variant.id) === id;
        });
        if (!variant) {
            let result = await props.searchVariant({ id: id });
            variant = result[0];
        }
        setVariant(variant);
    }

    function handleUpdateLineItem(id) {
        if (!props.cart.data) return;
        let cartLineItem = props.cart.data.attributes.line_items.data.find((li) => {
            return li.attributes.variant_id === parseInt(id);
        });
        setLineItem(cartLineItem);
    }

    async function handleSaveChanges() {
        if (data.changed) {
            setData({ ...data, changed: false });
            let result = await props.searchVariant({
                product_id: variant.attributes.product_id,
                option_value_ids: [data.size.id, data.color.id],
            });

            await props.removeFromCart(lineItem.id).then((r) => {
                props
                    .addToCart({
                        variant_id: result[0].id,
                        quantity: data.quantity,
                    })
                    .then((r2) => {
                        const variant = r2.attributes.line_items.data.find((v) => v.attributes.variant_id === parseInt(result[0].id));
                        setLineItem({ ...lineItem, id: variant.id, attributes: { ...lineItem.attributes, quantity: data.quantity } });
                    });
            });

            setVariant(result[0]);
        }
    }

    function resetData() {
        setData({
            color: { presentation: '', name: '', id: '' },
            size: { presentation: '', name: '', id: '' },
            quantity: 1,
            changed: false,
        });
        setLineItem(null);
        setVariant(null);
    }

    function getImageUrl() {
        if (!variant) return '';
        if (!variant.attributes.images.length) return '';
        return API_BASE_URL + variant.attributes.images[0].attributes.styles[2].url;
    }

    function setSize(size) {
        setData({
            ...data,
            size: { presentation: size.presentation, name: size.name, id: size.id },
            changed: true,
        });
    }

    function setColor(color) {
        setData({
            ...data,
            color: { presentation: color.presentation, name: color.name, id: color.id },
            changed: true,
        });
    }

    function setQuantity(quantity) {
        setData({
            ...data,
            quantity: quantity,
            changed: true,
        });
    }

    if (!showPanel) return null;

    return (
        <div className="w-72 flex flex-column">
            <div className="rounded-xl flex flex-1 flex-column m-2 px-2 pt-2" style={{ backgroundColor: 'hsl(217, 32%, 28%)' }}>
                <div className="flex flex-row justify-between text-gray-200 text-sm font-thin w-full mb-1">
                    <div className="">
                        {!variant || isLoading ? (
                            <div className="h-4 w-24 mb-2">
                                <Skeleton
                                    sx={{
                                        backgroundColor: 'rgb(70, 90, 124)',
                                    }}
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        ) : (
                            `#${variant.attributes.barcode}`
                        )}
                    </div>
                    <div className="">
                        {!variant || isLoading ? (
                            <div className="h-4 w-28">
                                <Skeleton
                                    sx={{
                                        backgroundColor: 'rgb(70, 90, 124)',
                                    }}
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        ) : (
                            `SKU: ${variant.attributes.sku}`
                        )}
                    </div>
                </div>
                <div className="flex flex-1 flex-column justify-start items-center text-white text-2xl font-bold">
                    {!variant || isLoading ? (
                        <div className="w-full aspect-square">
                            <Skeleton
                                sx={{
                                    backgroundColor: 'rgb(70, 90, 124)',
                                    borderRadius: '0.5rem',
                                }}
                                variant="rectangular"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ) : (
                        <Avatar url={getImageUrl()} />
                    )}
                    <div className="flex text-gray-300 text-2xl font-bold text-left w-full pt-2 mb-2">
                        {!variant || isLoading ? (
                            <div className="h-8 w-48 mt-2">
                                <Skeleton
                                    sx={{
                                        backgroundColor: 'rgb(70, 90, 124)',
                                    }}
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        ) : (
                            !!variant && variant.attributes.name
                        )}
                    </div>
                    <div className="flex flex-row justify-between w-full px-2">
                        <div className="flex flex-row">
                            <div className="flex text-white text-xl font-bold text-left items-start">
                                {!variant || isLoading ? (
                                    <div className="h-6 w-14 -ml-2">
                                        <Skeleton
                                            sx={{
                                                backgroundColor: 'rgb(70, 90, 124)',
                                            }}
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                ) : (
                                    !!variant && variant.attributes.display_price_with_benefits
                                )}
                            </div>
                            <div className="flex text-gray-50 text-base font-thin text-left items-end pl-2">
                                {!variant || isLoading ? (
                                    <div className="h-4 w-10">
                                        <Skeleton
                                            sx={{
                                                backgroundColor: 'rgb(70, 90, 124)',
                                            }}
                                            variant="rectangular"
                                            width="100%"
                                            height="100%"
                                        />
                                    </div>
                                ) : (
                                    'MXN'
                                )}
                            </div>
                        </div>
                        <div className="flex text-red-300 text-base font-bold text-left items-end px-2">
                            {!variant || isLoading ? (
                                <div className="h-4 w-14">
                                    <Skeleton
                                        sx={{
                                            backgroundColor: 'rgb(70, 90, 124)',
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            ) : (
                                variant.attributes.benefits_percentage > 0 && `(-${variant.attributes.benefits_percentage * 100} %)`
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row justify-start w-full py-2 mb-2">
                        {!variant || isLoading ? (
                            <div className="h-16 w-full my-3">
                                <Skeleton
                                    sx={{
                                        backgroundColor: 'rgb(70, 90, 124)',
                                        borderRadius: '4rem',
                                    }}
                                    variant="rectangular"
                                    width="100%"
                                    height="100%"
                                />
                            </div>
                        ) : (
                            <CounterButton value={data.quantity} onClick={setQuantity} />
                        )}
                    </div>
                    <div className="flex flex-column w-full flex-1 justify-start items-start">
                        <div className="text-left w-full ml-3 pb-2 text-gray-300 font-light text-base underline">
                            {!variant || isLoading ? (
                                <div className="h-4 w-20 -ml-1">
                                    <Skeleton
                                        sx={{
                                            backgroundColor: 'rgb(70, 90, 124)',
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            ) : variant.attributes.in_stock ? (
                                'En stock'
                            ) : (
                                'Fuera de stock'
                            )}
                        </div>

                        <div className="flex flex-wrap justify-start w-full px-2">
                            {!variant || isLoading ? (
                                <div className="h-10 w-full my-1">
                                    <Skeleton
                                        sx={{
                                            backgroundColor: 'rgb(70, 90, 124)',
                                            borderRadius: '0.5rem',
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            ) : (
                                sizePresenter.length > 0 &&
                                sizePresenter.map((size, index) => {
                                    return <SizeRadio key={index} size={size.presentation} selected={parseInt(size.id) === parseInt(data.size.id)} onClick={() => setSize(size)} />;
                                })
                            )}
                        </div>

                        <div className="flex justify-start w-full py-1 pl-2">
                            {!variant || isLoading ? (
                                <div className="h-10 w-full my-1 -ml-1">
                                    <Skeleton
                                        sx={{
                                            backgroundColor: 'rgb(70, 90, 124)',
                                            borderRadius: '4rem',
                                        }}
                                        variant="rectangular"
                                        width="100%"
                                        height="100%"
                                    />
                                </div>
                            ) : (
                                colorPresenter.length > 0 && colorPresenter.map((color, index) => <ColorRadio key={index} color={color.presentation} selected={parseInt(color.id) === parseInt(data.color.id)} onClick={() => setColor(color)} />)
                            )}
                        </div>
                        {data.changed && (
                            <div className="flex w-full flex-1 items-end">
                                <div className="flex h-16 w-full mb-2">
                                    <ActionButton onClick={handleSaveChanges} icon={<CheckOutlined />}>
                                        Guardar Cambios
                                    </ActionButton>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    variant: state.posVariant,
    cart: state.posCart,
});

const mapDispatchToProps = {
    searchVariant,
    selectVariant,
    variantLoaded,
    addToCart,
    removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductPanel);
