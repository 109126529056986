import { MainWrapper, Page } from 'components/Body';
import { MuiDataTable } from 'components/Table';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tab } from '../../components/Navs';
import { BasicSelect, MuiDatePicker } from '../../components/Form';
import { getComissions, updateComissions } from '../../actions/comissionsActions';
import { getStatementAccount } from '../../actions/statementAccountActions';
import { getScheduledPayments } from '../../actions/scheduledPaymentActions';
import { getComissionRecords } from '../../actions/comissionsRecordsActions';
import { getReportsItemsClient, getReportsOrders, getReportsLineItems } from '../../actions/reportsActions';
import { getComissionsForExport, getScheduledPaymentsExport, getRecordsExport, getReportsExport } from '../../actions/exportActions';
import Button from '@mui/material/Button';
import { moneyFormatter, percentageFormatter, dateFormatter } from '../../utils/formatters';
import { ColoredDatum } from '../../components/Table';
import { variantStates } from '../../utils/common';
import { RestoreOutlined, PriceCheckOutlined, UnfoldMore, AddTaskOutlined, CloseOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { OrderViewerModal } from 'components/Modal';
import { FilterButton } from 'components/Filter';
import { MuiTextField } from 'routes/pos/components';
import ComissionsModal from 'components/Modal/ComissionsModal';
import { LineChart } from 'components/Charts';
import { createLabelsChart, dataLineChart, getDataSet, optionsLineChart } from './dataLineChart';
import moment from 'moment';
import { exportToExcel } from 'utils/actions/export';

const TabTitles = {
    pending: 'Pendiente',
    delayed: 'Retrasado',
    approved: 'Aprobado',
    rejected: 'Rechazado',
    paid: 'Pagado',
    all: 'Todo',
    record: 'Procesos',
    orders: 'Órdenes',
    items: 'Artículos',
    items_clients: 'Artículos por cliente',
    scheduled: 'Programado',
};

const ScheduledPaymentStates = {
    scheduled: ['scheduled'],
    paid: ['paid'],
    delayed: ['failed', 'delayed'],
    all: [],
};

const ComissionStates = {
    pending: ['pending'],
    approved: ['approved'],
    rejected: ['rejected', 'failed'],
    paid: ['paid'],
    all: [],
};

const comissionRecordstates = {
    record: ['ok'],
};

const reportsStates = {
    orders: ['orders'],
    items: ['items'],
    items_clients: ['items_clients'],
};

const statementAccountStates = {
    all: [],
};

const PageTitles = {
    comissions: 'Comisiones',
    scheduled_payments: 'Pagos Programados',
    record: 'Historial de Comisiones',
    reports: 'Reporte de ventas',
    statementAccount: 'Saldos',
};

const COMMISSION_RECORD_TABLE_HEADERS = ['id', 'user_name', 'total', 'status', 'created_at'];

const COMISSIONS_TABLE_HEADERS = ['id', 'user_name', 'father_name', 'total', 'order_percentage', 'status', 'order_number'];

const SCHEDULED_PAYMENTS_TABLE_HEADERS = ['id', 'user_name', 'father_name', 'due_date', 'total', 'amount_paid', 'percentage', 'status', 'order_number', 'created_at'];

const REPORTS_TABLE_HEADERS = ['id', 'number', 'user_name', 'email', 'completed_at', 'payment_method', 'total_reports'];

const STATEMENTACCOUNT_TABLE_HEADERS = ['user_id', 'first_name', 'last_name', 'saldopendiente', 'saldovencido'];

const AdminComissions = (props) => {
    const [selectedTab, setSelectedTab] = useState('all');
    const [tabs, setTabs] = useState(Object.keys(reportsStates));
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedTotal, setSelectedTotal] = useState(0);
    const [selectedOrderId, setSelectedOrderId] = useState(null);
    const [selectedComission, setSelectedComission] = useState(null);
    const [page, setPage] = useState('scheduled_payments');
    const [data, setData] = useState({ meta: {}, list: [] });
    const [dateFilter, setDateFilter] = useState({
        option: null,
        from: null,
        to: null,
        apply: false,
    });
    const [userFilter, setUserFilter] = useState({
        option: null,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        apply: false,
    });
    const [fatherFilter, setFatherFilter] = useState({
        option: null,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        apply: false,
    });

    const [currentTitle, setCurrentTitle] = useState('');

    const [currentDataLineChart, setCurrentDataLineChart] = useState(dataLineChart);

    useEffect(() => {
        fetchPageData();
    }, []);

    useEffect(() => {
        fetchPageData();
    }, [dateFilter.apply, userFilter.apply, fatherFilter.apply]);

    useEffect(() => {
        if (typeof props.comissions.meta.count === 'number' && page === 'comissions') {
            setData(props.comissions);
            setIsLoading(false);
        }
    }, [props.comissions]);

    useEffect(() => {
        if (props.scheduledPayments.list.length && page === 'scheduled_payments') {
            setData(props.scheduledPayments);
            setIsLoading(false);
        }
    }, [props.scheduledPayments]);

    useEffect(() => {
        if (props.comissionRecords?.list.length && page === 'record') {
            setData(props.comissionRecords);
        }
    }, [props.comissionRecords]);

    useEffect(() => {
        if (page === 'reports') {
            switch (selectedTab) {
                case 'orders':
                    if (props.reports?.orders?.list?.length) {
                        setCurrentTitle('Órdenes del mes de' + Date.now.get);
                        setData(props.reports.orders);
                        setIsLoading(false);

                        const title = `${moment().format('MMMM')} - ${moment().year()}`;

                        setCurrentTitle(title);

                        setCurrentDataLineChart({
                            ...currentDataLineChart,
                            labels: createLabelsChart('', '', props.reports.orders.list),
                            datasets: [getDataSet(title, props.reports.orders.list)],
                        });
                    }
                    break;
                case 'items':
                    if (props.reports?.line_items?.list?.length) {
                        setData(props.reports.line_items);
                        setIsLoading(false);
                    }
                    break;
                case 'items_clients':
                    if (props.reports?.items_clients?.list?.length) {
                        setData(props.reports.items_clients);
                        setIsLoading(false);
                    }
                    break;
                default:
                    break;
            }
        }
    }, [props.reports]);

    useEffect(() => {
        if (props.statementAccount?.list.length && page === 'statementAccount') {
            setData(props.statementAccount);
            setIsLoading(false);
        }
    }, [props.statementAccount]);

    useEffect(() => {
        setIsLoading(!(typeof data.meta.count === 'number'));

        if (page === 'statementAccount') {
            setIsLoading(false);
        }
    }, [data]);

    useEffect(() => {
        fetchPageData();
    }, [currentPage, selectedTab, page]);

    useEffect(() => {
        if (page === 'comissions') {
            setTabs(Object.keys(ComissionStates));
        } else if (page === 'scheduled_payments') {
            setTabs(Object.keys(ScheduledPaymentStates));
        } else if (page === 'record') {
            setTabs(Object.keys(comissionRecordstates));
        } else if (page === 'reports') {
            setTabs(Object.keys(reportsStates));
        } else if (page === 'statementAccount') {
            setTabs(Object.keys(statementAccountStates));
        }
    }, [page]);

    useEffect(() => {
        if (tabs.includes('all')) {
            setSelectedTab('all');
        } else if (!['record', 'approved'].includes(selectedTab)) {
            setSelectedTab('record');
        } else {
            setSelectedTab('pending');
        }

        if (page === 'reports') setSelectedTab('orders');

        if (page === 'statementAccount') setSelectedTab('all');
    }, [tabs]);

    useEffect(() => {
        if (props.exportData?.list?.length) {
            exportToExcel(props.exportData?.list, PageTitles[page] + ' - ' + TabTitles[selectedTab], { page, selectedTab });
        }
    }, [props.exportData]);

    const fetchPageData = async () => {
        setData({ meta: {}, list: [] });
        setSelectedRows([]);
        if (page === 'comissions') await fetchComissions();
        if (page === 'scheduled_payments') await fetchScheduledPayments();
        if (page === 'record') await fetchComissionsRecords();
        if (page === 'reports') await fetchReports();
        if (page === 'statementAccount') await fetchStatementAccount();
    };

    const buildFetchObject = () => {
        let tabStates;
        if (page === 'comissions') {
            tabStates = ComissionStates;
        } else if (page === 'scheduled_payments') {
            tabStates = ScheduledPaymentStates;
        } else if (page === 'record') {
            tabStates = comissionRecordstates;
        } else if (page === 'reports') {
            tabStates = reportsStates;
        } else if (page === 'statementAccount') {
            tabStates = statementAccountStates;
        }

        let fetchObject = {
            page: currentPage,
            status: tabStates[selectedTab],
        };

        if (dateFilter.apply) {
            let dateRange = [dateFilter.from, dateFilter.to];
            if (page === 'comissions') {
                fetchObject.created_at = dateRange;
            } else if (page === 'scheduled_payments') {
                fetchObject.due_date = dateRange;
            } else if (page === 'reports') {
                fetchObject.completed_at = dateRange;
            }
        }

        if (userFilter.apply) {
            fetchObject = {
                ...fetchObject,
                first_name: userFilter.first_name,
                last_name: userFilter.last_name,
                email: userFilter.email,
                phone: userFilter.phone,
            };
        }

        if (!props.user.isAdmin) {
            fetchObject = {
                ...fetchObject,
                user_id: props.user.data.id,
                with_network: true,
            };
        }

        if (fatherFilter.apply) {
            fetchObject = {
                ...fetchObject,
                father_first_name: fatherFilter.first_name,
                father_last_name: fatherFilter.last_name,
                father_email: fatherFilter.email,
                father_phone: fatherFilter.phone,
            };
        }

        if (page === 'statementAccount') {
            fetchObject.user_id = props.user.isAdmin ? 0 : props.user.data.id;
        }

        return fetchObject;
    };

    const fetchScheduledPayments = () => {
        return props.getScheduledPayments(buildFetchObject()).then(() => setIsLoading(false));
    };

    const fetchComissions = () => {
        return props.getComissions(buildFetchObject());
    };

    const fetchComissionsRecords = () => {
        return props.getComissionRecords(buildFetchObject()).then(() => setIsLoading(false));
    };

    const fetchReports = () => {
        if (selectedTab === 'orders') return props.getReportsOrders().then(() => setIsLoading(false));
        if (selectedTab === 'items') return props.getReportsLineItems().then(() => setIsLoading(false));
        if (selectedTab === 'items_clients') return props.getReportsItemsClient().then(() => setIsLoading(false));
    };

    const fetchStatementAccount = () => {
        return props.getStatementAccount(buildFetchObject()).then(() => setIsLoading(false));
    };

    const handleTabClick = (id) => {
        if (selectedTab === id && currentPage === 1) {
            fetchPageData();
        }
        setSelectedTab(id);
        setCurrentPage(1);
    };

    const handleSelect = (selected) => {
        setSelectedRows(selected);
        calculateSelectedTotal(selected);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const calculateSelectedTotal = (selected) => {
        if (page === 'statementAccount') return;

        if (!data.list || data.list.length === 0) return;
        let total = data.list
            .filter((c) => selected.includes(c.attributes.id))
            .reduce((acc, curr) => {
                return acc + curr.attributes.amount;
            }, 0);

        setSelectedTotal(total);
    };

    const handleStatusChange = (status) => {
        Swal.fire({
            show: true,
            title: 'Por favor confirma esta operación',
            text: `¿Estás seguro de que quieres cambiar a ${variantStates[status].actionText}, a las comisiones seleccionadas?`,
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: `Si, ${variantStates[status].actionText}`,
            confirmButtonColor: '#379824',
            cancelButtonText: 'Regresar',
        }).then((result) => {
            if (result.isConfirmed) {
                if (selectedRows.length === 0) return;
                props.updateComissions({ new_status: status, ids: selectedRows }).finally((res) => {
                    fetchComissions();
                });
            }
        });
    };

    const getTableHeaders = () => {
        let headers = [];
        if (page === 'comissions') headers = COMISSIONS_TABLE_HEADERS;
        if (page === 'scheduled_payments') headers = SCHEDULED_PAYMENTS_TABLE_HEADERS;
        if (page === 'record') headers = COMMISSION_RECORD_TABLE_HEADERS;
        if (page === 'reports') headers = REPORTS_TABLE_HEADERS;
        if (page === 'statementAccount') headers = STATEMENTACCOUNT_TABLE_HEADERS;

        let entries = Object.entries(TABLE_HEADERS).filter(([key, value]) => headers.includes(key));

        return Object.fromEntries(entries);
    };

    const handleFilterOptionChange = (opt, e) => {
        let id = e.target.selectedOptions[0].id;
        if (id === '0') {
            id = null;
        }
        getFilterSetter(opt)({ ...getFilter(opt), option: id });
    };

    const getFilterSetter = (opt) => {
        if (opt === 'date') {
            return setDateFilter;
        } else if (opt === 'user') {
            return setUserFilter;
        } else if (opt === 'father') {
            return setFatherFilter;
        }
    };

    const getFilter = (opt) => {
        if (opt === 'date') {
            return dateFilter;
        } else if (opt === 'user') {
            return userFilter;
        } else if (opt === 'father') {
            return fatherFilter;
        }
    };

    const handleDateFilterConfirm = () => {
        let from,
            to = '';
        if (dateFilter.option === 'between') {
            from = dateFilter.from;
            to = dateFilter.to;
        } else if (dateFilter.option === 'equals') {
            from = dateFilter.from;
        } else if (dateFilter.option === 'after') {
            from = dateFilter.from;
        } else if (dateFilter.option === 'before') {
            to = dateFilter.to;
        }

        setDateFilter({ ...dateFilter, from, to, apply: true });
    };

    const handleUserFilterConfirm = (opt) => {
        let filter = getFilter(opt);
        let setFilter = getFilterSetter(opt);
        let email, first_name, last_name, phone;

        if (filter.option === 'email') {
            email = filter.email;
        } else if (filter.option === 'name') {
            first_name = filter.first_name;
            last_name = filter.last_name;
        } else if (filter.option === 'phone') {
            phone = filter.phone;
        }

        setFilter({
            ...filter,
            email,
            first_name,
            last_name,
            phone,
            apply: true,
        });
    };

    const handleFilterConfirm = (opt) => {
        if (getFilter(opt).option === null) {
            return;
        }
        setCurrentPage(1);
        let isRefresh = getFilter(opt).apply;
        if (opt === 'date') {
            handleDateFilterConfirm();
        } else if (opt === 'user') {
            handleUserFilterConfirm('user');
        } else if (opt === 'father') {
            handleUserFilterConfirm('father');
        }

        if (isRefresh) {
            fetchPageData();
        }
    };

    const getFilterResultText = (opt) => {
        let filter = getFilter(opt);
        if (!filter.apply) {
            return '';
        }
        switch (filter.option) {
            case 'email':
                return `Correo: ${filter.email}`;
            case 'name':
                return `Nombre: ${filter.first_name} ${filter.last_name}`;
            case 'phone':
                return `Teléfono: ${filter.phone}`;
            case 'between':
                return `entre ${dateFilter.from} y ${dateFilter.to}`;
            case 'equals':
                return `igual a ${dateFilter.from}`;
            case 'after':
                return `después de ${dateFilter.from}`;
            case 'before':
                return `antes de ${dateFilter.to}`;
            default:
                return '';
        }
    };

    const handleClickOpen = (params) => {
        if (selectedTab !== 'record') {
            if (page === 'reports') {
                setSelectedOrderId(params.row.id);
            } else if (page === 'comissions') {
                setSelectedComission(params.row.id);
            } else {
                setSelectedOrderId(params.row.order_id);
            }
        } else {
            setSelectedOrderId(params.row.id);
        }
    };

    const TABLE_HEADERS = {
        id: {
            field: 'id',
            headerName: 'Ver más',
            width: 130,
            renderCell: (params) => {
                return (
                    <Button onClick={() => (selectedTab !== 'record' ? (page === 'reports' ? setSelectedOrderId(params.row.id) : setSelectedOrderId(params.row.order_id)) : setSelectedOrderId(params.row.id))} variant="outlined" endIcon={<UnfoldMore />}>
                        Abrir
                    </Button>
                );
            },
        },
        total: {
            field: 'amount',
            headerName: 'Total (MXN)',
            width: 130,
            isCustom: true,
            renderCell: (p) => moneyFormatter(p.value),
        },
        amount_paid: {
            field: 'amount_paid',
            headerName: 'Monto pagado',
            width: 130,
            isCustom: true,
            renderCell: (p) => moneyFormatter(p.value),
        },
        status: {
            field: 'status',
            headerName: 'Estado',
            width: 180,
            isCustom: true,
            renderCell: (p) => (
                <ColoredDatum variant={variantStates[p.value].variant} icon={variantStates[p.value].icon}>
                    {variantStates[p.value].text}
                </ColoredDatum>
            ),
        },
        user_name: {
            field: 'user_name',
            headerName: 'Nombre del usuario',
            width: 200,
        },
        father_name: {
            field: 'father_name',
            headerName: 'Nombre del padre',
            width: 200,
        },
        due_date: {
            field: 'due_date',
            headerName: 'Fecha de pago',
            width: 180,
            isCustom: true,
            renderCell: (p) => dateFormatter(p.value),
        },
        order_percentage: {
            field: 'order_percentage',
            headerName: 'Comisión (%)',
            width: 150,
            isCustom: true,
            renderCell: (p) => percentageFormatter(p.value),
        },
        order_number: {
            field: 'order_number',
            headerName: 'Pedido',
            width: 180,
        },
        number: {
            field: 'number',
            headerName: 'Pedido',
            width: 180,
        },
        created_at: {
            field: 'created_at',
            headerName: 'Fecha de creación',
            width: 180,
            isCustom: true,
            renderCell: (p) => dateFormatter(p.value),
        },
        percentage: {
            field: 'percentage',
            headerName: '% del total',
            width: 180,
            isCustom: true,
            renderCell: (p) => percentageFormatter(p.value),
        },
        email: {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        completed_at: {
            field: 'completed_at',
            headerName: 'Fecha de compra',
            width: 180,
            isCustom: true,
            renderCell: (p) => dateFormatter(p.value),
        },
        total_reports: {
            field: 'total',
            headerName: 'Total (MXN)',
            width: 130,
            isCustom: true,
            renderCell: (p) => moneyFormatter(p.value),
        },
        first_name: {
            field: 'first_name',
            headerName: 'Nombre',
            width: 200,
        },
        last_name: {
            field: 'last_name',
            headerName: 'Apellido',
            width: 200,
        },
        saldopendiente: {
            field: 'scheduled_payment',
            headerName: 'Saldo Pendiente (MXN)',
            isCustom: true,
            width: 200,
            renderCell: (p) => moneyFormatter(p.value),
        },
        saldovencido: {
            field: 'delayed_payment',
            headerName: 'Saldo Vencido (MXN)',
            isCustom: true,
            width: 200,
            renderCell: (p) => moneyFormatter(p.value),
        },
    };

    function resetFilter(opt) {
        if (opt === 'user') {
            setUserFilter({
                option: null,
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
                apply: false,
            });
        } else if (opt === 'father') {
            setFatherFilter({
                option: null,
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
                apply: false,
            });
        } else if (opt === 'date') {
            setDateFilter({
                option: null,
                start: null,
                end: null,
                apply: false,
            });
        }
    }

    function onFilterClose(opt) {
        if (opt === 'user') {
            setUserFilter((prev) =>
                prev.apply
                    ? prev
                    : {
                          option: null,
                          email: '',
                          first_name: '',
                          last_name: '',
                          phone: '',
                          apply: false,
                      }
            );
        } else if (opt === 'father') {
            setFatherFilter((prev) =>
                prev.apply
                    ? prev
                    : {
                          option: null,
                          email: '',
                          first_name: '',
                          last_name: '',
                          phone: '',
                          apply: false,
                      }
            );
        } else if (opt === 'date') {
            setDateFilter((prev) => (prev.apply ? prev : { option: null, start: null, end: null, apply: false }));
        }
    }

    function renderDateFilter() {
        return (
            <div className="flex flex-col items-center">
                <div className="w-full mb-2">
                    <BasicSelect id="date-filter" name="date-filter" defaultText="Selecciona una opción" onChange={(e) => handleFilterOptionChange('date', e)} value={dateFilter.option} style={{ margin: 0, width: '100%' }}>
                        <option id={'equals'}>es igual a</option>
                        <option id={'between'}>entre</option>
                        <option id={'after'}>es posterior a</option>
                        <option id={'before'}>es anterior a</option>
                    </BasicSelect>
                </div>
                <div className="flex">
                    {['between', 'equals', 'after'].includes(dateFilter.option) && (
                        <MuiDatePicker
                            label="Inicio"
                            value={dateFilter.from}
                            onChange={(date) =>
                                setDateFilter({
                                    ...dateFilter,
                                    from: date.format('DD/MM/YYYY'),
                                })
                            }
                        />
                    )}
                    {['between'].includes(dateFilter.option) && <div className="flex mx-2 flex-1 justify-center items-center">y</div>}
                    {['between', 'before'].includes(dateFilter.option) && (
                        <MuiDatePicker
                            label="Fin"
                            value={dateFilter.to}
                            onChange={(date) =>
                                setDateFilter({
                                    ...dateFilter,
                                    to: date.format('DD/MM/YYYY'),
                                })
                            }
                        />
                    )}
                </div>
            </div>
        );
    }

    function handleInputChange(event, opt) {
        if (opt === 'user') {
            setUserFilter({
                ...userFilter,
                [event.target.name]: event.target.value,
            });
        } else if (opt === 'father') {
            setFatherFilter({
                ...fatherFilter,
                [event.target.name]: event.target.value,
            });
        }
    }

    function renderUserFilter(opt) {
        let filter = getFilter(opt);
        return (
            <div className="flex flex-col items-center">
                <div className="w-full mb-2">
                    <BasicSelect id="user-filter" name="user-filter" defaultText="Selecciona una opción" onChange={(e) => handleFilterOptionChange(opt, e)} value={filter.option} style={{ margin: 0, width: '100%' }}>
                        <option id={'name'}>Nombre</option>
                        <option id={'email'}>Correo Electrónico</option>
                        <option id={'phone'}>Teléfono</option>
                    </BasicSelect>
                </div>
                <div className="flex flex-column">
                    {filter.option === 'name' && (
                        <>
                            <div className="mb-1">
                                <MuiTextField name="first_name" value={filter.first_name} onChange={(e) => handleInputChange(e, opt)} label="Nombre" />
                            </div>
                            <MuiTextField name="last_name" value={filter.last_name} onChange={(e) => handleInputChange(e, opt)} label="Apellidos" />
                        </>
                    )}
                    {filter.option === 'phone' && <MuiTextField name="phone" value={filter.phone} onChange={(e) => handleInputChange(e, opt)} label="Teléfono" />}
                    {filter.option === 'email' && <MuiTextField name="email" value={filter.email} onChange={(e) => handleInputChange(e, opt)} label="Correo Electrónico" />}
                </div>
            </div>
        );
    }

    const handleExport = () => {
        if (page === 'comissions') {
            props.getComissionsForExport(selectedTab);
        } else if (page === 'scheduled_payments') {
            props.getScheduledPaymentsExport();
        } else if (page === 'record') {
            props.getRecordsExport();
        } else if (page === 'reports') {
            props.getReportsExport(selectedTab);
        }
    };

    return (
        <div className="flex flex-col flex-1">
            <div className="flex py-2 border-b border-gray-700 pl-3">
                <MenuItem onClick={() => setPage('scheduled_payments')} selected={page === 'scheduled_payments'}>
                    {PageTitles['scheduled_payments']}
                </MenuItem>
                <MenuItem onClick={() => setPage('comissions')} selected={page === 'comissions'}>
                    {PageTitles['comissions']}
                </MenuItem>
                <MenuItem onClick={() => setPage('record')} selected={page === 'record'}>
                    {PageTitles['record']}
                </MenuItem>
                <MenuItem onClick={() => setPage('reports')} selected={page === 'reports'}>
                    {PageTitles['reports']}
                </MenuItem>
                <MenuItem onClick={() => setPage('statementAccount')} selected={page === 'statementAccount'}>
                    {PageTitles['statementAccount']}
                </MenuItem>
            </div>
            <Page>
                <div className="text-blue-100 text-2xl font-bold mb-2">{PageTitles[page]}</div>
                <div className="flex flex-1 self-stretch mb-2">
                    {tabs.map((key) => (
                        <Tab key={key} id={key} active={selectedTab} onClick={handleTabClick}>
                            {TabTitles[key]}
                        </Tab>
                    ))}
                </div>
                <MainWrapper>
                    {page !== 'statementAccount' && (
                        <>
                            {' '}
                            <div className="flex flex-row">
                                <FilterButton name="Fecha" resultText={getFilterResultText('date')} onClose={() => onFilterClose('date')} onRemove={() => resetFilter('date')} onConfirm={() => handleFilterConfirm('date')} disabled={!dateFilter.option}>
                                    {renderDateFilter()}
                                </FilterButton>
                                <FilterButton name="Usuario" resultText={getFilterResultText('user')} onClose={() => onFilterClose('user')} onRemove={() => resetFilter('user')} onConfirm={() => handleFilterConfirm('user')} disabled={!userFilter.option}>
                                    {renderUserFilter('user')}
                                </FilterButton>
                                {page !== 'reports' && (
                                    <FilterButton name="Padre" resultText={getFilterResultText('father')} onClose={() => onFilterClose('father')} onRemove={() => resetFilter('father')} onConfirm={() => handleFilterConfirm('father')} disabled={!fatherFilter.option}>
                                        {renderUserFilter('father')}
                                    </FilterButton>
                                )}
                            </div>
                            <div className="flex flex-row justify-between my-2">
                                <div className="flex flex-row  flex-wrap">
                                    {selectedTab === 'pending' && selectedRows.length > 0 && (
                                        <>
                                            <div className="mr-2">
                                                <Button variant="contained" color="primary" onClick={() => handleStatusChange('approved')} startIcon={<AddTaskOutlined />}>
                                                    Aprobar
                                                </Button>
                                            </div>

                                            <div className="mx-2">
                                                <Button variant="contained" color="error" onClick={() => handleStatusChange('rejected')} endIcon={<CloseOutlined />}>
                                                    Rechazar
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                    {selectedTab === 'approved' && selectedRows.length > 0 && (
                                        <>
                                            <div className="mr-2">
                                                <Button variant="contained" color="success" onClick={() => handleStatusChange('paid')} startIcon={<PriceCheckOutlined />}>
                                                    Pagar
                                                </Button>
                                            </div>

                                            <div className="mx-2">
                                                <Button variant="contained" color="info" onClick={() => handleStatusChange('pending')} endIcon={<RestoreOutlined />}>
                                                    Pendiente
                                                </Button>
                                            </div>

                                            <div className="mx-2">
                                                <Button variant="contained" color="error" onClick={() => handleStatusChange('rejected')} endIcon={<CloseOutlined />}>
                                                    Rechazar
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                    {selectedTab === 'rejected' && selectedRows.length > 0 && (
                                        <>
                                            <div className="mx-2">
                                                <Button variant="contained" color="info" onClick={() => handleStatusChange('pending')} endIcon={<RestoreOutlined />}>
                                                    Pendiente
                                                </Button>
                                            </div>
                                        </>
                                    )}
                                    {selectedRows.length > 0 && (
                                        <>
                                            <div className="mx-2 text-gray-300 text-lg font-medium flex items-center">Seleccionados: {selectedRows.length}</div>
                                            <div className="mx-2 text-gray-300 text-lg font-medium flex items-center">Total: {moneyFormatter(selectedTotal)}</div>
                                        </>
                                    )}
                                </div>
                                <div className="flex flex-row">
                                    <Button variant="contained" color="primary" onClick={() => handleExport()}>
                                        Exportar
                                    </Button>
                                </div>
                            </div>
                        </>
                    )}
                    <MuiDataTable data={data.list} table_headers={getTableHeaders()} onSelectMultiple={handleSelect} onSelectionModelChange={handleSelect} isLoading={isLoading} checkboxSelection={true} pageSize={data.meta?.count} currentPage={currentPage} totalPages={data.meta?.total_pages} onPageChange={handlePageChange} />
                    {page === 'reports' && (
                        <div style={{ marginTop: 10 }}>
                            <div className="text-blue-100 text-2xl font-bold mb-2">Ventas de {currentTitle}</div>
                            <LineChart data={currentDataLineChart} options={optionsLineChart} />
                        </div>
                    )}
                </MainWrapper>

                <OrderViewerModal
                    show={!!selectedOrderId}
                    toggleModal={() => {
                        setSelectedOrderId(false);
                    }}
                    orderId={selectedOrderId}
                />
                <ComissionsModal
                    show={!!selectedComission}
                    toggleModal={() => {
                        setSelectedComission(null);
                    }}
                    comissionId={selectedComission}
                />
            </Page>
        </div>
    );
};

function MenuItem({ onClick, selected, children }) {
    let className = 'px-2 py-1 mx-2 rounded-full font-bold cursor-pointer select-none';
    if (selected) className += ' text-white bg-blue-800';
    else className += ' text-blue-100 hover:bg-gray-300 hover:text-gray-900';

    return (
        <div className={className} onClick={onClick}>
            {children}
        </div>
    );
}

const mapStateToProps = (state) => ({
    comissions: state.comissions,
    comissionRecords: state.comissionRecords,
    scheduledPayments: state.scheduledPayment,
    user: state.user,
    reports: state.reports,
    exportData: state.exportData,
    statementAccount: state.statementAccount,
});

const mapDispatchToProps = {
    getComissions,
    updateComissions,
    getScheduledPayments,
    getComissionRecords,
    getReportsOrders,
    getReportsLineItems,
    getReportsItemsClient,
    getComissionsForExport,
    getScheduledPaymentsExport,
    getRecordsExport,
    getReportsExport,
    getStatementAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminComissions);
