import React from "react";
import ChangeItem from "./changeItem";
import { Title } from 'components/Texts';
import {ChangesList} from './style';
import { faWaveSquare } from "@fortawesome/free-solid-svg-icons";

const MonthlyChange = (props) => {
  return (
    <div>
      <Title>
        Cambio en mes 
      </Title>
      <ChangesList>
        <ChangeItem title="Views" color="#80A3F4" icon={faWaveSquare}>
          2625
        </ChangeItem>
        <ChangeItem title="Increase" color="#A683F8" icon={faWaveSquare}>
          425
        </ChangeItem>
        <ChangeItem title="Amount" color="#FF6384" icon={faWaveSquare}>
          1293.00
        </ChangeItem>
        <ChangeItem title="Conversion" color="#FEB962" icon={faWaveSquare}>
          8.51%
        </ChangeItem>
      </ChangesList>
    </div>
  )
}

export default MonthlyChange;
