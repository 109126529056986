import {
  LOGIN_USER,
  LOGOUT_USER,
  SET_ACCESS_TOKEN, 
  REMOVE_USER_STORE_CREDITS,
  UPDATE_USER_ATTRIBUTES,
} from '../actions/types';

const initialState = {
  data: {},
  isLoggedIn: false,
  isLoggingOut: false,
  isAdmin: false,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case LOGIN_USER:
      let newState = {...state, data: {...state.data, ...action.payload}, isLoggedIn: true, isLoggingOut: false}
      newState.isAdmin = action.payload.attributes.role === "admin" 
      return newState;
    case LOGOUT_USER:
      return {...state, data: {}, isLoggedIn: false, isLoggingOut: true}
    case SET_ACCESS_TOKEN:
      return {...state, data: {...state.data, access_token: {...action.payload}}}
    case REMOVE_USER_STORE_CREDITS:
      let new_store_credit = parseFloat(state.data.attributes.distributor_store_credit_remaining) + parseFloat(action.payload)
      return {...state, data: {
        ...state.data,
        attributes: {
          ...state.data.attributes,
          distributor_store_credit_remaining: new_store_credit
        }
      }}
    case UPDATE_USER_ATTRIBUTES:
      return {...state,
        data: { 
          ...state.data,
          attributes: {...state.data.attributes, ...action.payload} 
        }
      }
    default:
      return state;
  }
}
