import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Panel, IconButton, Avatar, CircledIconButton, AddToNetworkButton } from './components';
import { updateAutofocus, unselectCart } from '../../actions/pos/cartActions';
import { unselectVariant } from '../../actions/pos/variantActions';
import { getClientById, unselectClient } from '../../actions/pos/clientActions';
import { clearCreditCards } from '../../actions/creditCardActions';
import { clearPayments } from 'actions/pos/paymentActions';
import { SearchOutlined, Edit, PersonAdd, Phone, AlternateEmail, Home, Group, ShoppingCart, LocalAtm, Close } from '@mui/icons-material';
import ClientModal from './modals/clientModal';
import CreateClientModal from './modals/createClientModal';
import { moneyFormatter } from 'utils/formatters';
import { CircularProgressSpinner } from 'components/Spinners';

function ClientPanel(props) {
    const [showClientModal, setShowClientModal] = useState(false);
    const [selectedFatherId, setSelectedFatherId] = useState(null);
    const [showCreateClientModal, setShowCreateClientModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [client, setClient] = useState(null);

    useEffect(() => {
        let modalOpen = showClientModal || showCreateClientModal;
        if (modalOpen && props.cart.autoFocus) {
            props.updateAutofocus(false);
        } else {
            props.updateAutofocus(true);
        }
    }, [showClientModal, showCreateClientModal]);

    useEffect(() => {
        setIsLoading(true);
        props.unselectCart();
        props.unselectVariant();
        props.clearPayments();
        props.clearCreditCards();
        if (props.client.selectedClientId !== client?.id) {
            setClient(null);
        }
    }, [props.client.selectedClientId]);

    useEffect(() => {
        if (props.client.selectedClientId && props.client.selectedClientId !== client?.id) {
            let fetchedClient = findClient();
            if (fetchedClient && fetchedClient.length) {
                setClient(fetchedClient[0]?.attributes);
            }
        }
    }, [props.client.data, props.client.selectedClientId]);

    useEffect(() => {
        if (client) {
            setIsLoading(false);
        }
    }, [client]);

    function findClient() {
        let fetchedClient;
        if (props.client.selectedClientId) {
            fetchedClient = props.client.data.filter((client) => client.attributes.id === props.client.selectedClientId);
            if (!fetchedClient || !fetchedClient.length) {
                props.getClientById(props.client.selectedClientId);
            }
        }
        return fetchedClient;
    }

    function renderFather(father) {
        return (
            <div className="flex flex-column w-full flex-1">
                <div className="text-gray-300 text-l font-bold pb-2">Distribuidor #{father.id}</div>

                <div className="text-gray-100 text-l font-bold">
                    {father.attributes.first_name} {father.attributes.last_name}
                </div>

                <Avatar url={`https://ui-avatars.com/api/?name=+${father.attributes.first_name}+${father.attributes.last_name}`} />

                <div className="text-gray-100 text-l text-sm py-1">
                    <Group fontSize="small" /> {father.attributes.referreds_count} Referidos
                </div>
                <div className="text-gray-100 text-l text-sm py-1">
                    <ShoppingCart /> {father.attributes.network_orders_count} Ventas en red
                </div>
                {!!father.attributes.credits && (
                    <div className="text-gray-100 text-l text-sm py-1">
                        <LocalAtm /> {moneyFormatter(father.attributes.credits.distributor.amount)} MXN Crédito
                    </div>
                )}
            </div>
        );
    }

    function renderNoFather() {
        if (client?.id && !client?.father?.id) {
            return (
                <div className="flex flex-1 flex-column items-center justify-center">
                    {!selectedFatherId && <div className="text-gray-300 text-normal font-base pb-1 mb-3">{`${client.first_name} ${client.last_name}`} No tiene un distribuidor asignado</div>}
                    {selectedFatherId ? renderFather(props.client.network.find((distr) => distr.attributes.id === selectedFatherId)) : <AddToNetworkButton selectedFatherId={selectedFatherId} setSelectedFatherId={setSelectedFatherId} />}
                </div>
            );
        }

        return (
            <div className="flex flex-column flex-1 items-center justify-center">
                <div className="text-center text-gray-300 text-normal font-base pb-1">No se ha seleccionado un distribuidor</div>
            </div>
        );
    }

    return (
        <div className="w-56 flex flex-column">
            <Panel>
                {!props.client.selectedClientId ? (
                    <div className="flex flex-1 items-center justify-center">
                        <div className="text-center text-gray-300 text-normal font-base pb-1">No se ha seleccionado un cliente</div>
                    </div>
                ) : isLoading || !client ? (
                    <div className="flex flex-1 items-center justify-center">
                        <CircularProgressSpinner />
                    </div>
                ) : (
                    <>
                        <div className="flex flex-1 flex-column">
                            <div className="relative w-full">
                                <div className="text-white text-l font-bold pb-1">Cliente #{props.client.selectedClientId}</div>
                                <div className="absolute -top-2 -right-3">
                                    <CircledIconButton onClick={props.unselectClient} style={{ width: '30px', height: '30px', opacity: '0.8' }}>
                                        <Close />
                                    </CircledIconButton>
                                </div>
                            </div>
                            <Avatar url={`https://ui-avatars.com/api/?name=+${client.first_name}+${client.last_name}`} />
                            <div className="flex flex-column pb-4 pt-1">
                                {isLoading ? (
                                    <div className="text-gray-100 text-l text-sm py-1">Cargando...</div>
                                ) : (
                                    <div className="flex flex-row w-full items-center">
                                        <div className="text-gray-50 text-l font-bold">{`${client?.first_name} ${client?.last_name}`}</div>
                                        {!!client?.years_old && <div className="text-gray-200 text-sm ml-2">{`(${client?.years_old} años)`}</div>}
                                    </div>
                                )}
                                <div className="text-gray-100 text-l text-sm py-1">
                                    <Phone fontSize="small" /> {client?.telephone}
                                </div>

                                <div className="text-gray-100 text-l text-sm py-1">
                                    <AlternateEmail fontSize="small" /> {client?.email}
                                </div>

                                {!!client?.addresses?.length && (
                                    <div className="text-gray-100 text-l text-sm py-1">
                                        <Home fontSize="small" /> {client.addresses[0]?.address}
                                    </div>
                                )}
                                <div className="text-gray-100 text-l text-sm py-1">
                                    <ShoppingCart /> {client?.number_of_orders} Compras
                                </div>
                                <div className="text-gray-100 text-l text-sm py-1">
                                    <LocalAtm /> {moneyFormatter(client?.credits?.client.remaining)} MXN Crédito de cliente
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="flex self-stretch justify-between">
                    <IconButton onClick={() => setShowClientModal(true)}>
                        <SearchOutlined sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton>
                        <Edit sx={{ color: 'white' }} />
                    </IconButton>
                    <IconButton onClick={() => setShowCreateClientModal(true)}>
                        <PersonAdd sx={{ color: 'white' }} />
                    </IconButton>
                </div>
                <CreateClientModal show={showCreateClientModal} onClose={() => setShowCreateClientModal(false)} />
                <ClientModal show={showClientModal} onClose={() => setShowClientModal(false)} />
            </Panel>
            <div className="flex basis-5/12">
                <Panel>{!!client && !!client?.father?.id ? renderFather(client.father) : renderNoFather()}</Panel>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.posCart,
    client: state.posClient,
});

const mapDispatchToProps = {
    updateAutofocus,
    getClientById,
    unselectCart,
    unselectVariant,
    unselectClient,
    clearPayments,
    clearCreditCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientPanel);
