import {
  ADD_COMISSION,
  GET_COMISSIONS,
  UPDATE_COMISSION,
} from '../actions/types';

const initialState = {
  list: [],
  meta: {},
  links: {},
}

export default function comissionsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COMISSIONS:
      return {
        ...state,
        list: action.payload.data,
        meta: action.payload.meta,
        links: action.payload.links,
      }
    case UPDATE_COMISSION:
      return {
        ...state,
        list: state.list.map((comission) => {
          if (comission.id === action.payload.id) {
            return action.payload
          }
          return comission
        }),
      }
    case ADD_COMISSION:
      return {
        ...state,
        list: [...state.list, action.payload],
      }
    default:
      return state
  }
}
