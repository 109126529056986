import React from 'react';
import './index.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from "styled-components";

import defaultTheme             from './components/themes/default';
import { Alert }                from './components/Alert'
import {ProtectedRoute}         from './components/Route';

import {ChangePasswordModal}    from './components/Modal'

import Header            from './routes/shared/Header';
import Sidebar           from './routes/shared/Sidebar';
import RightSidebar      from './routes/shared/RightSidebar';

import SettingsPage from './routes/account/settingsPage';

import CandidateIndex    from './routes/candidate';
import CandidateRegister from './routes/candidate/register';

import DistributorIndex         from './routes/distributor';
import DistributorClients       from './routes/distributor/clients';
import DistributorMoney         from './routes/distributor/money';
import DistributorOrders        from './routes/distributor/orders';
import DistributorProfile       from './routes/distributor/profile';
import DistributorNetwork       from './routes/distributor/network';
import DistributorPayments      from './routes/distributor/payments';

import MoneyPage from './routes/money'
import AdminCandidates from './routes/admin/candidates'
import AdminDistributors from './routes/admin/distributors'
import AdminIndex from './routes/admin'

import NetworkIndex from './routes/network';
import DashboardIndex from './routes/dashboard';
import POSIndex from './routes/pos';

import LandingHome  from './routes/landing/authenticated';
import AuthPage    from './routes/session';
import NotFound     from './routes/notfound';

const Routes = () => (
  <Router>
    <ThemeProvider theme={defaultTheme}>
      <div className="page-container">
        <Sidebar/>
        <Header/>
        <Alert/>
        {/* <RightSidebar/>*/}
        <div className="body-content">
          <Switch>
            <ProtectedRoute exact path="/" component={LandingHome}/>
            <ProtectedRoute exact path="/dashboard" only={"admin"} component={DashboardIndex}/>
            <ProtectedRoute exact path="/pos" only={["cashier","admin"]} component={POSIndex}/>
            <ProtectedRoute exact path="/account/settings" component={SettingsPage} />
            {/* Candidate routes */}
            <ProtectedRoute exact path="/candidate" only={"candidate"} component={CandidateIndex}/>
            <ProtectedRoute exact path="/candidate/register" only={"candidate"} component={CandidateRegister}/>
            <ProtectedRoute exact path="/candidate/subscription" only={"candidate"} component={DistributorPayments}/>

            {/* Distributor routes */}
            <ProtectedRoute exact path="/distributor" only={"distributor"} component={DistributorIndex}/>
            <ProtectedRoute exact path="/distributor/clients" only={"distributor"} component={DistributorClients}/>
            <ProtectedRoute exact path="/money" only={["distributor", "admin"]} component={MoneyPage}/>
            <ProtectedRoute exact path="/distributor/orders" only={"distributor"} component={DistributorOrders}/>
            <ProtectedRoute exact path="/distributor/profile" only={["distributor"]} component={DistributorProfile}/>
            <ProtectedRoute exact path="/distributor/network" only={"distributor"} component={DistributorNetwork}/>
            <ProtectedRoute exact path="/distributor/payments" only={["distributor"]} component={DistributorPayments}/>

            <ProtectedRoute exact path="/multinivel" only={["distributor", "admin"]} component={NetworkIndex}/>

            {/* Admin routes */}
            <ProtectedRoute exact path="/admin" only={"admin"} component={AdminIndex}/>
            <ProtectedRoute exact path="/admin/candidates" only={"admin"} component={AdminCandidates}/>
            <ProtectedRoute exact path="/admin/distributors" only={"admin"} component={AdminDistributors}/>

            {/* Session routes */}
            <Route path="/login" component={AuthPage} />
            <Route path="/register" component={AuthPage} />
            <Route path="/forgot" component={AuthPage} />
            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
      <ChangePasswordModal/>
    </ThemeProvider>
  </Router>
)

export default Routes;
