import React from 'react';
import {Th, SortIcon} from './style';
import { faSort, faSortDown, faSortUp, faSortAlphaDown, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';

const Header =  (props) => {

  const sortDirection = (sort) => {
    const fa = {
      defaultSort: faSort,
      sortDown: props.alpha ? faSortAlphaDown : faSortDown,
      sortUp: props.alpha ? faSortAlphaUp : faSortUp,
    }

    if (props.selectedSort === props.name) {
      return (sort == "asc" ? fa.sortDown : fa.sortUp);
    } else {
      return fa.defaultSort
    }
  }

  const toggleSort = () => {
    if (!!props.setSort()) {
      return true;
    }
    let newSort = props.sort === "asc" ? "desc" : "asc";
    props.setSort(newSort);
  }

  const handleClick = () => {
    if (!props.setSort) {
      return;
    }
    if (!!props.selectedSort && props.selectedSort === props.name) {
      toggleSort();
    } else if(props.sort !== "asc") {
      props.setSort("asc");
    }
    !!props.name && !!props.setSelectedSort && props.setSelectedSort(props.name);
  }

  return (
    <Th
      onClick={handleClick}
      width={props.width}
      style={props.style}
    >
      {'sort' in props && <SortIcon icon={sortDirection(props.sort)}/>}
      {props.children}
    </Th>
  )
}

export default Header;