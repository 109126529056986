import Styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const ChangesList = Styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 25px;
`;

export const ChangeItemContainer = Styled.div`
`;

export const ChangeItemTitle = Styled.div`
  color: #C4C3C7;
  font-size: 18px;
  font-weight: 500;
`;

export const ChangeItemValue = Styled.div`
  font-size: 24px;
  font-weight: 500;
  color: #45465B;
`;

export const ChangeItemIcon = Styled(FontAwesomeIcon)`
  font-size: 32px;

`;
