import React, {useEffect, useState} from 'react';
import {Page, MainWrapper} from '../../../components/Body';
import {Tab, TabGroup} from '../../../components/Navs';
import { SweetAlert } from '../../../components/Alert';
import AccountBalance from './accountBalance';
import PaymentMethods from './paymentMethods';
import PaymentActivity from './paymentActivity';
import ModalAddPaymentMethod from './ModalAddPaymentMethod';
import ModalAddNewPayment from './ModalAddNewPayment';
import ModalUpdateSelectedPaymentMethod from './ModalUpdateSelectedPaymentMethod';
import ModalAddDepositAccount from './ModalAddDepositAccount';
import Swal from 'sweetalert2';

import MembershipPage from './membership';

import {
  distributorListAdresses,
  distributorCreateNewOrderPayment,
  distributorCreateNewAddress,
  indexDistributorClient,
} from '../../../actions/distributorActions';

import {
  ListCreditCards,
  createCreditCard,
  deleteCreditCard,
  updateDefaultCreditCard,
} from '../../../actions/creditCardActions';

import {
  getListOfCountries,
  getCountry,
} from '../../../actions/countryActions';

import {
  getSubscriptionPlans,
  paySubscription,
  getUserMembershipData,
  updateMembershipPaymentSource,
  cancelUserSubscription,
} from '../../../actions/subscriptionActions';

import {
  createFlashMessageRequest
} from '../../../actions/alertActions';

import {
  getUserBankInformation,
  updateSelectedCreditCard
} from '../../../actions/bankActions';

import {connect} from 'react-redux';

const DistributorPayments = (props) => {
  const TabTitles = {
    "summary": "Resumen de cuenta",
    "payment_methods": "Métodos de pago",
    "payment_activity": "Actividad de pagos",
    "membership": "Membresía"
  }
  const [selectedTab, setSelectedTab] = useState("membership");
  const [isModalAddPaymentMethodOpen, setIsModalAddPaymentMethodOpen] = useState(false);
  const [isModalAddNewPaymentOpen, setIsModalAddNewPaymentOpen] = useState(false);
  const [modalUpdateSelectedPaymentMethodData, setModalUpdateSelectedPaymentMethodData] = useState({isOpen: false, screen: "deposit"});
  const [isModalAddDepositAccountOpen, setIsModalAddDepositAccountOpen] = useState(false);
  const [isCreditCardsLoading, setIsCreditCardsLoading] = useState(true);
  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [sweetAlertData, setSweetAlertData] = useState({});
  const [bankAccount, setBankAccount] = useState(false);
  const [chargeCard, setChargeCard] = useState();
  const [depositCard, setDepositCard] = useState();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      props.ListCreditCards(props.user.data.id),
      props.distributorListAdresses(),
      props.indexDistributorClient(),
      props.getUserBankInformation(props.user.data.id),
    ]).then(() => {
      if (!props.countries.length) {
        props.getListOfCountries()
      }
      setIsInitialDataLoading(false);
      setIsLoading(false);
    })
  }, [])

  useEffect(() => {
    if (props.subscriptions?.membership.id && props.credit_cards.length) {
      let charge_card = props.credit_cards.find(card => card.id == props.subscriptions.membership.spree_payment_source_id)
      setChargeCard(charge_card)

    }
  }, [props.subscriptions])

  useEffect(() => {
    if(!!props.bank.bank_accounts.length) {
      let selectedBankAccount = props.bank.bank_accounts.find(acc => props.user.data.id == acc.user_id)
      setBankAccount(selectedBankAccount)
    }
  }, [props.bank])

  useEffect(() => {
    if (props.credit_cards.length) {
      setIsCreditCardsLoading(false)
      props.getUserMembershipData()
    }
  }, [props.credit_cards])

  useEffect(() => {
    setSweetAlertData({
      config: {
        title: "Cargando...",
        text: "Por favor espera unos momentos...",
      },
      show: isLoading,
      isLoading: true
    })
  }, [isLoading])
  
  const handleCreatePayment = async (data) => {
    await props.distributorCreateNewOrderPayment(data)
    
    await Promise.all([
      props.ListCreditCards(),
      props.indexDistributorClient(),
    ])

    return;
  }

  const onDeletePaymentMethod = (e) => {
    let id = e.target.id;

    setIsDeleting(true)
    Swal.fire({
      show: true,
      title: 'Estás seguro?',
      text: "Esta acción es irreversible!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borralo!',
      cancelButtonText: "Cancelar"
    }).then((result) => {
      setIsDeleting(false)
      if (result.isConfirmed) {
        setIsLoading(true);
        props.deleteCreditCard(id)
        .then(res => {
          setIsLoading(false);
          if (res.status === 204) {
            Swal.fire(
              'Eliminado!',
              'La información de la tarjeta registrada ha sido eliminada.',
              'success'
            )  
          } else {
            Swal.fire(
              'Error!',
              'La tarjeta no pudo ser eliminada.',
              'error'
            )
          }
        })
      }
    })
  }

  const toggleUpdateSelectedPaymentMethodModal = (type) => {
    setModalUpdateSelectedPaymentMethodData({ isOpen: !modalUpdateSelectedPaymentMethodData.isOpen, screen: type })
  }

  const handleUpdateSelectedPaymentMethod = (selected_card_id) => {
    setIsLoading(true);
    //props.updateSelectedCreditCard(props.user.data.id, selected_card_id, modalUpdateSelectedPaymentMethodData.screen)
    props.updateMembershipPaymentSource(props.subscriptions.membership.id, selected_card_id)
    .then((res) => {
      setModalUpdateSelectedPaymentMethodData({ isOpen: !modalUpdateSelectedPaymentMethodData.isOpen, screen: modalUpdateSelectedPaymentMethodData.screen })
      setIsLoading(false);

      if (res.status === 200) {
        Swal.fire(
          "Actualizado!",
          `La tarjeta de cobro ha sido actualizada exitosamente.`,
          'success'
        )
      } else if (res.status % 400 < 100) {
        Swal.fire(
          "Hubo un problema!",
          `La tarjeta de cobro no pudo ser actualizada: ${res.data.error.message}`,
          'error'
        )
      }       
    })
  }

  const toggleModalAddDepositAccount = () => {
    setIsModalAddDepositAccountOpen(!isModalAddDepositAccountOpen)
  }

  const handleUpdateAddDepositAccount = (data) => {
    debugger;
  }

  return (
    <Page title="Pagos">
      <MainWrapper
        title={TabTitles[selectedTab]}
      >
        <TabGroup>
          {Object.entries(TabTitles).map(([id, title]) => (
            <Tab
              id={id}
              onClick={setSelectedTab}
              active={selectedTab}
            >{title}</Tab>
          ))}
        </TabGroup>
        <SweetAlert {...sweetAlertData} />
        {selectedTab === "summary" &&
          <AccountBalance
            bankAccount={bankAccount}
          />
        }
        {selectedTab === "payment_methods" &&
          <PaymentMethods
            creditCards={props.credit_cards}
            isCreditCardsLoading={isCreditCardsLoading}
            setIsModalAddPaymentMethodOpen={setIsModalAddPaymentMethodOpen}
            toggleUpdateSelectedPaymentMethodModal={toggleUpdateSelectedPaymentMethodModal}
            toggleModalAddDepositAccount={toggleModalAddDepositAccount}
            onDeletePaymentMethod={onDeletePaymentMethod}
            updateDefaultCreditCard={props.updateDefaultCreditCard}
            setIsLoading={setIsLoading}
            bankAccount={bankAccount}
            depositCard={depositCard}
            chargeCard={chargeCard}
          />  
        }
        {selectedTab === "payment_activity" &&
          <PaymentActivity
            setIsLoading={setIsLoading}
            bankAccount={bankAccount}
          />
        }
        {selectedTab === "membership" &&
          <MembershipPage
            setIsLoading={setIsLoading}
            getSubscriptionPlans={props.getSubscriptionPlans}
            getUserMembershipData={props.getUserMembershipData}
            paySubscription={props.paySubscription}
            subscription={props.subscriptions}
            cancelUserSubscription={props.cancelUserSubscription}
            setIsModalAddPaymentMethodOpen={setIsModalAddPaymentMethodOpen}
            setIsModalUpdateChargeCardOpen={setModalUpdateSelectedPaymentMethodData}          
          />
        }
      </MainWrapper>
      {!isInitialDataLoading &&
        <>
          <ModalAddPaymentMethod
            show={isModalAddPaymentMethodOpen}
            toggleModal={() => setIsModalAddPaymentMethodOpen(!isModalAddPaymentMethodOpen)}
            getUserMembershipData={props.getUserMembershipData}
            onCreate={()=>{}}
            user={props.user.data}
            createCreditCard={props.createCreditCard}
            distributorCreateNewAddress={props.distributorCreateNewAddress}
            addressList={props.addresses}
            countries={props.countries}
            getCountry={props.getCountry}
            setSweetAlertData={setSweetAlertData}
            setIsLoading={setIsLoading}
          />
          <ModalAddNewPayment
            show={isModalAddNewPaymentOpen}
            toggleModal={() => setIsModalAddNewPaymentOpen(!isModalAddNewPaymentOpen)}
            onCreate={()=> {}}
            creditCards={props.credit_cards}
            isCreditCardsLoading={isCreditCardsLoading}
            user={props.user.data}
            handleCreate={handleCreatePayment}
            clients={props.clients}
            createFlashMessageRequest={props.createFlashMessageRequest}
          />
          <ModalUpdateSelectedPaymentMethod
            show={modalUpdateSelectedPaymentMethodData.isOpen}
            toggleModal={toggleUpdateSelectedPaymentMethodModal}
            screen={modalUpdateSelectedPaymentMethodData.screen}
            onSave={handleUpdateSelectedPaymentMethod}
            user={props.user.data}
            creditCards={props.credit_cards}
            setIsLoading={setIsLoading}
            isCreditCardsLoading={isCreditCardsLoading}
            bankAccount={bankAccount}
            depositCard={depositCard}
            chargeCard={chargeCard}
            selectedPaymentOption={chargeCard && chargeCard.id}
          />

          <ModalAddDepositAccount
            show={isModalAddDepositAccountOpen}
            toggleModal={toggleModalAddDepositAccount}
            onSave={handleUpdateAddDepositAccount} 
          />
        </>
      }
    </Page>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  addresses: state.distributor.addresses,
  countries: state.country.countries,
  credit_cards: state.creditCard,
  clients: state.distributor.clients,
  subscriptions: state.subscription,
  bank: state.bank,
});

const mapDispatchToProps = {
  distributorListAdresses,
  distributorCreateNewOrderPayment,
  distributorCreateNewAddress,
  deleteCreditCard,
  updateDefaultCreditCard,
  indexDistributorClient,
  paySubscription,
  getListOfCountries,
  getCountry,
  getSubscriptionPlans,
  createFlashMessageRequest,
  createCreditCard,
  getUserMembershipData,
  getUserBankInformation,
  updateSelectedCreditCard,
  ListCreditCards,
  updateMembershipPaymentSource,
  cancelUserSubscription,
}


export default connect(mapStateToProps, mapDispatchToProps)(DistributorPayments);
