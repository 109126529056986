import axios from 'axios';

import { API_BASE_URL, CREATE_DISTRIBUTOR_SOURCE, INDEX_DISTRIBUTOR_SOURCES, DELETE_DISTRIBUTOR_SOURCE, UPDATE_DEFAULT_CREDIT_CARD, CLEAR_CREDIT_CARDS } from './types';

import { dispatchAction } from '../utils/actions/common';

export const ListCreditCards = (user_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        if (user_id === null) return;

        return axios({
            method: 'get',
            url: API_BASE_URL + '/app/api/v1/account/credit_cards.js',
            params: { user_id },
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        })
            .then((res) => {
                return dispatchAction(dispatch, res, INDEX_DISTRIBUTOR_SOURCES);
            })
            .catch((err) => {
                return { ...err.response.data, status: err.response.status };
            });
    };
};

export const createCreditCard = (data) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return (dispatch) => {
        return axios({
            method: 'post',
            url: API_BASE_URL + '/app/api/v1/account/credit_cards.js',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data,
        })
            .then((res) => {
                let card = res.data.data;
                dispatch({ type: CREATE_DISTRIBUTOR_SOURCE, payload: card });
                return card;
            })
            .catch((err) => {
                return { ...err.response.data, status: err.response.status };
            });
    };
};

export const deleteCreditCard = (cardId) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return (dispatch) => {
        return axios({
            method: 'DELETE',
            url: API_BASE_URL + `/es/app/api/v1/account/credit_cards/${cardId}`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
        }).then((res) => {
            if (res.status !== 204) {
                return res;
            }

            dispatch({ type: DELETE_DISTRIBUTOR_SOURCE, payload: { id: cardId } });
            return res;
        });
    };
};

export const updateDefaultCreditCard = (cardId) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'POST',
            url: API_BASE_URL + `/es/app/api/v1/account/credit_cards/update_default`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { credit_card_id: cardId },
            json: true,
        }).then((res) => {
            dispatchAction(dispatch, res.data, UPDATE_DEFAULT_CREDIT_CARD);
            return res;
        });
    };
};

export const clearCreditCards = () => (dispatch) => {
    dispatch({
        type: CLEAR_CREDIT_CARDS,
    });
};
