import React from 'react';
import ClientPanel from './clientPanel';
import ProductPanel from './productPanel';
import CartPanel from './cartPanel';
import ActionsPanel from './actionsPanel';

function PointOfSale() {
    return (
        <div className="flex flex-grow flex-row self-stretch" style={{ height: 'calc(100vh - 56px)' }}>
            <ClientPanel />
            <ProductPanel />
            <CartPanel />
            <ActionsPanel />
        </div>
    );
}

export default PointOfSale;
