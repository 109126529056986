import {TOGGLE_RIGHT_SIDEBAR} from '../actions/types';

const initialState = {
  isOpen: false,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case TOGGLE_RIGHT_SIDEBAR:
      return {
        ...state,
        isOpen: !state.isOpen
      }
    default: 
      return state;
  }

}