import React, {useEffect} from 'react';
import Swal from 'sweetalert2';

const SweetAlert = (props) => {
  useEffect(() => {
    if (!Object.keys(props).length) { return; }

    if (props.show) {
      return showAlert();
    } 

    if (Swal && Swal.isLoading()) {
      Swal.close()
    }

  }, [props.show])

  const showAlert = () => {
    if (Object.keys(props.config)){
      Swal.fire({
        allowOutsideClick: false,
        ...props.config,
      })
    }

    if(props.isLoading) {
      Swal.showLoading()
    }

  }
  return <></>
}

export default SweetAlert;
