import React from 'react';
import Moment from 'react-moment';
import {Datum} from './index';

const MomentDatum = (props) => {
  //month, day, year
  let dateLocale = new Date(props.children).toLocaleString().split(",");
  let dateArray = dateLocale[0].split("/");
  return (
    <Datum>
      <Moment 
        date={props.children} 
        fromNow 
        locale="es-mx"
      /> {" "}
      <br/>{dateArray[1]+"/"+dateArray[0]+"/"+dateArray[2]} {/*+","+dateLocale[1] */}
    </Datum>
  )
}

export default MomentDatum;