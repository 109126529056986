import { combineReducers } from 'redux';

import admin from './adminReducer';
import alert from './alertReducer';
import bank from './bankReducer';
import candidate from './candidateReducer';
import category from './categoryReducer';
import comissions from './comissionsReducer';
import country from './countryReducer';
import creditCard from './creditCardReducer';
import distributor from './distributorReducer';
import leftSidebarReducer from './leftSidebarReducer';
import network from './networkReducer';
import order from './orderReducer';
import paymentPlan from './paymentPlanReducer';
import product from './productReducer';
import rightSidebarReducer from './rightSidebarReducer';
import rank from './rankReducer';
import scheduledPayment from './scheduledPaymentReducer';
import subscription from './subscriptionReducer';
import unitOfMeasurement from './unitOfMeasurement';
import user from './userReducer';
import comissionRecords from './comissionRecordsReducer';
import statementAccount from './statementAccountReducer';
import reports from './reportsReducer';
import exportData from './exportReducer';

import posVariant from './pos/variantReducer';
import posCart from './pos/cartReducer';
import posClient from './pos/clientReducer';
import posPayment from './pos/paymentReducer';

export default combineReducers({
    admin,
    alert,
    bank,
    candidate,
    category,
    comissions,
    country,
    creditCard,
    distributor,
    leftSidebarReducer,
    network,
    order,
    paymentPlan,
    product,
    rank,
    rightSidebarReducer,
    scheduledPayment,
    subscription,
    unitOfMeasurement,
    user,
    posVariant,
    posCart,
    posClient,
    posPayment,
    comissionRecords,
    statementAccount,
    reports,
    exportData,
});
