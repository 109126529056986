const {useState, useCallback, useEffect} = require("react")

const useResize = (ref) => {
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  const handleResize = () => {
    setWidth(ref.current.offsetWidth);
    setHeight(ref.current.offsetHeight);
  }

  const handleResizeCallback = useCallback(handleResize, [ref])
  const useMountEffect = (fun) => useEffect(fun, []);
  useMountEffect(handleResize);

  useEffect(() => {
    window.addEventListener('load', handleResizeCallback)
    window.addEventListener('resize', handleResizeCallback)

    return () => {
      window.removeEventListener('load', handleResizeCallback)
      window.removeEventListener('resize', handleResizeCallback)
    }

  }, [ref, handleResize])

  return { width, height }
}

export default useResize;
