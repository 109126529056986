import React from 'react';
import {
  Panel,
  PanelTitle,
  PanelSubtitle,
  PanelTitleText,
  PanelHeaderSearchContainer} from './style';

import {BasicInput} from '../Form';
import {DefaultButton} from '../Button';

const MainWrapper = (props) => {
  return (
    <Panel style={props.style}>
      {props.title &&
        <PanelTitle>
            <PanelTitleText>
            {props.title}
          </PanelTitleText>
          { props.showSearch &&
            <PanelHeaderSearchContainer>
              <BasicInput
                placeholder={props.inputPlaceholder || "Buscar un cliente"}
              />
            </PanelHeaderSearchContainer>
          }
          <div>
            { props.actionButton &&
              <DefaultButton
                style={{backgroundColor: "#6f9a37", borderColor: "rgb(111, 154, 55)"}}
                onClick={props.onActionClick}
              >
                {props.actionButtonText || "Registrar Nuevo"}
              </DefaultButton>
            }
            { !!props.headerRight && props.headerRight }
          </div>
          { props.subtitle &&
            <PanelSubtitle>
              {props.subtitle}
            </PanelSubtitle>
          }
        </PanelTitle>
      }
      {props.children}
    </Panel>
  );
};

export default MainWrapper;
