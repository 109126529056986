import { requestBuilder, simpleRequestBuilder } from 'utils/actions/requests';

import { API_BASE_URL, POS_ADD_TO_CART, POS_REMOVE_FROM_CART, POS_GET_CART, POS_UPDATE_CART_AUTOFOCUS, POS_UNSELECT_CART } from '../types';

const STOREFRONT_API_URL = API_BASE_URL + '/es/api/v2/storefront/';

export const createCart =
    ({ user_id }) =>
    async (dispatch) => {
        return requestBuilder('post', STOREFRONT_API_URL + `cart`, { user_id }, dispatch, POS_GET_CART);
    };

export const addToCart = (params) => async (dispatch) => {
    return requestBuilder('post', STOREFRONT_API_URL + `cart/add_item`, params, dispatch, POS_ADD_TO_CART);
};

export const removeFromCart =
    (id, simple = false) =>
    async (dispatch) => {
        if (!simple) {
            return requestBuilder('delete', STOREFRONT_API_URL + `cart/remove_line_item/${id}`, {}, dispatch, POS_REMOVE_FROM_CART);
        } else {
            return simpleRequestBuilder('delete', STOREFRONT_API_URL + `cart/remove_line_item/${id}`, {});
        }
    };

export const getCart =
    (params = {}) =>
    async (dispatch) => {
        return requestBuilder('get', STOREFRONT_API_URL + `cart`, params, dispatch, POS_GET_CART);
    };

export const updateCart = (params) => async (dispatch) => {
    return requestBuilder('put', STOREFRONT_API_URL + `checkout`, { order: params }, dispatch, POS_GET_CART);
};

export const updatePaymentPlan = (params) => async (dispatch) => {
    return requestBuilder('patch', STOREFRONT_API_URL + `checkout`, { order: params }, dispatch, POS_GET_CART);
};

export const AddCredit = (params) => async (dispatch) => {
    return requestBuilder('post', STOREFRONT_API_URL + `checkout/add_store_credit`, params, dispatch, POS_GET_CART);
};

export const RemoveCredit = (params) => async (dispatch) => {
    return requestBuilder('post', STOREFRONT_API_URL + `checkout/remove_store_credit`, params, dispatch, POS_GET_CART);
};

export const updateAutofocus = (autofocus) => (dispatch) => {
    dispatch({
        type: POS_UPDATE_CART_AUTOFOCUS,
        payload: autofocus,
    });
};

export const unselectCart = () => (dispatch) => {
    dispatch({
        type: POS_UNSELECT_CART,
    });
    sessionStorage.removeItem('orderToken');
};

export const completeCheckout = (params) => async (dispatch) => {
    return requestBuilder('PATCH', STOREFRONT_API_URL + `checkout/complete`, { pos_sale: true, ...params }, dispatch, POS_GET_CART);
};

export const setStoreDefaultShippingAddress = () => async (dispatch) => {
    return requestBuilder('post', STOREFRONT_API_URL + `checkout/set_store_default_shipping_address`, {}, dispatch, POS_GET_CART);
};

export const nextCheckout = () => async (dispatch) => {
    return requestBuilder('patch', STOREFRONT_API_URL + `checkout/next`, {}, dispatch, POS_GET_CART);
};

export const advanceCheckout = () => async (dispatch) => {
    return requestBuilder('patch', STOREFRONT_API_URL + `checkout/advance`, {}, dispatch, POS_GET_CART);
};

export const getCartByToken = (token) => async (dispatch) => {};
