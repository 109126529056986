import React from 'react';
import {ActionButtonWrapper, ActionButtonIcon} from './style';

const ActionButton = (props) => {
  return (
    <ActionButtonWrapper onClick={props.onClick}>
      <ActionButtonIcon icon={props.icon} size="xl">
      </ActionButtonIcon>
    </ActionButtonWrapper>
  )
}

export default ActionButton;
