import React from "react";
import {ToggleButton} from "@mui/material";

function ColorRadio({ color="", selected=false, onClick=()=>{}}) {
  return (
      <ToggleButton
        className="m-2 w-8 h-8"
        onClick={onClick}
        style={{
          backgroundColor: color,
          borderRadius: "50%",
          border: selected ? "2px solid white" : "none",
          boxShadow: selected ? `0 0 5px 3px #48abe0` : "2px 4px 4px hsl(0deg 0% 0% / 0.40)",

        }}>
        <div className="w-6 h-6 rounded-full"
          style={{
            position: "absolute",
            backgroundColor: "white",
            opacity: selected ? 0 : 0.45,
          }}
        />
      </ToggleButton>
  )
}

export default ColorRadio;
