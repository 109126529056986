import React, {useState} from 'react';
import { BasicTextArea } from '../../../components/Form';
import { EditButton } from './style';

const SendMessageTab = (props) => {
  const [text, setText] = useState("");

  const handleTextChange = (e) => {
    let val = e.target.value;
    setText(val);
  } 

  return (
    <div>
      <BasicTextArea
        type="textarea"
        title={`Este mensaje será enviado a ${props.candidate.email}`}
        placeholder="Escribe un mensaje..."
        rows="5"
        cols="5"
        value={text}
        onChange={handleTextChange}
      />
      <EditButton
        style={{float: "right", width: "200px", color: "white"}}
        onClick={() => props.onSendMessage(props.candidate.id, text)}
      >Enviar Mensaje</EditButton>
    </div>
  )
}

export default SendMessageTab;