import React from 'react';
import { Forgot } from './style';
import { Link } from 'components/Button';
import { Anchor } from 'components/Button/style';

const ActionLinks = (props) => {
    return (
        <>
            {props.usePage !== '/forgot' && (
                <Forgot>
                    <Link to="/forgot" onClick={() => props.setUsePage('forgot')}>
                        ¿Olvidaste tu contraseña?
                    </Link>
                </Forgot>
            )}
            {props.usePage !== '/register' && (
                <Forgot>
                    ¿Necesitas una cuenta?{' '}
                    <Link to="/register" onClick={() => props.setUsePage('register')}>
                        Iniciar Registro
                    </Link>
                </Forgot>
            )}
            {props.usePage !== '/login' && (
                <Forgot>
                    ¿Ya tienes una cuenta?{' '}
                    <Link to="/login" onClick={() => props.setUsePage('login')}>
                        Iniciar Sesión
                    </Link>
                </Forgot>
            )}
            Versión 1.4.9
            <p>
                <Anchor href="https://www.uniquebrother.com/ayuda/terminos" target="_blank">
                    Ver términos y condiciones
                </Anchor>
            </p>
        </>
    );
};

export default ActionLinks;
