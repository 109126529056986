import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';
import { DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { StyledTableContainer, StyledPagination, StyledPaginationItem } from './style';
import { setupMUIDataGridColumns, setupTableDataRows } from 'utils/common';

const MuiDataTable = (props) => {
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const setupCols = () => {
        setCols(setupMUIDataGridColumns(props.data || [], props.table_headers));
    };

    const setupRows = () => {
        setRows(setupTableDataRows(props.data || []));
    };

    useEffect(() => {
        if (props.isLoading !== undefined) {
            setIsLoading(props.isLoading);
        } else {
            setIsLoading(rows.length < 1);
        }
    }, [rows, props.isLoading]);

    useEffect(() => {
        if (props.data) {
            setupCols();
            setupRows();
        }
    }, [props.data]);

    return (
        <>
            <StyledTableContainer component={Paper}>
                <DataGrid
                    checkboxSelection={props.checkboxSelection || false}
                    rows={isLoading ? [] : rows}
                    columns={cols}
                    pageSize={props.pageSize || 15}
                    loading={isLoading}
                    style={{ color: 'white' }}
                    rowsPerPageOptions={[10, 20, 30]}
                    onSelectionModelChange={props.onSelectionModelChange}
                    components={{
                        Pagination: () =>
                            CustomPagination({
                                currentPage: props.currentPage,
                                totalPages: props.totalPages,
                                onChange: props.onPageChange,
                            }),
                    }}
                />
            </StyledTableContainer>
        </>
    );
};

function CustomPagination({ currentPage, totalPages, onChange = () => {} }) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    function handlePageChange(event, value) {
        onChange && onChange(event, value);
        apiRef.current.setPage(value - 1);
    }

    return <StyledPagination color="primary" variant="outlined" shape="rounded" page={currentPage || page + 1} count={totalPages || pageCount} renderItem={(props2) => <StyledPaginationItem {...props2} disableRipple />} onChange={handlePageChange} />;
}

export default MuiDataTable;
