import React from 'react';
import { Title, RowRadio, BasicInput, BasicCheckbox } from '../../../components/Form';
import { ModalAddPaymentMethodCodeExpirationContainer } from './style';

const CardDetailsForm = (props) => {
    let data = props.data;
    return (
        <>
            <Title>Método de pago</Title>
            <div>
                <RowRadio title="Tarjeta de crédito o débito" selected={props.selectedMethod} onChange={props.setSelectedMethod} id="credit_card" />
                {/* <RowRadio
          title="Paypal"
          selected={selectedMethod}
          onChange={setSelectedMethod}
          id="paypal"
        />
        <RowRadio 
          title="Saldo de la cuenta (Comisiones)"
          selected={selectedMethod}
          onChange={setSelectedMethod}
          id="balance"
        /> */}
            </div>
            <Title>Datos de la Tarjeta</Title>
            {props.user.attributes && (
                <>
                    <BasicInput title={'Nombre'} placeholder={"Ingresa tu nombre, eg: '" + props.user.attributes.first_name + ' ' + props.user.attributes.last_name + "'"} id="name" onChange={props.handleNameChange} value={data.name} />
                    <BasicInput title={'Número de tarjeta'} placeholder={'16 digitos'} id="cardNumber" onChange={props.handleCardNumberChange} value={data.cardNumber} />
                    <ModalAddPaymentMethodCodeExpirationContainer>
                        <BasicInput title={'Vencimiento'} placeholder="MM/YYYY" id="expiration" value={data.expiration} onChange={props.handleCardExpireChange} />
                        <BasicInput title={'Código de Tarjeta'} placeholder="CVV" id="code" value={data.code} onChange={props.handleCodeChange} />
                    </ModalAddPaymentMethodCodeExpirationContainer>
                    <BasicCheckbox id="defaultCheckbox" label="Establecer como predeterminada" onChange={props.handleDefaultToggle} checked={data.default} />
                </>
            )}
        </>
    );
};

export default CardDetailsForm;
