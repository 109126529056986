import React, { useState, useEffect } from 'react';
import { getCart } from '../../actions/pos/cartActions';
import { connect } from 'react-redux';
import { updateAutofocus } from '../../actions/pos/cartActions';
import { moneyFormatter } from 'utils/formatters';

import { ActionButton } from './components';
import Skeleton from '@mui/material/Skeleton';
import { AttachMoneyOutlined } from '@mui/icons-material';
import PaymentModal from './modals/paymentModal';

function PricePanel({ cart, ...props }) {
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (cart.isLoading) {
            setIsLoading(true);
        } else if (cart.data !== null) {
            setIsLoading(false);
        }
    }, [cart]);

    useEffect(() => {
        if (showPaymentModal && cart.autoFocus) {
            props.updateAutofocus(false);
        } else {
            props.updateAutofocus(true);
        }
    }, [showPaymentModal]);

    return (
        <div className="flex h-40 justify-center items-center text-white text-2xl font-bold px-2">
            <div className="pr-4 py-3 flex flex-column w-32 justify-end h-full">
                <div className="flex justify-center flex-row self-stretch h-12">
                    <ActionButton onClick={() => setShowPaymentModal(true)} icon={<AttachMoneyOutlined />} disabled={!cart?.data?.attributes?.line_items?.data.length}>
                        <div className="pl-1 pr-2">Pago</div>
                    </ActionButton>
                </div>
            </div>
            <div className="flex flex-1 mx-2 flex-column justify-start items-center mt-auto mb-2">
                <div className="flex w-full flex-row justify-between border-b border-gray-600 mb-1 items-end">
                    <div className="text-xl font-base text-gray-400">Crédito</div>
                    {isLoading ? (
                        <div className="h-4 w-28 mb-2">
                            <Skeleton
                                sx={{
                                    backgroundColor: 'rgb(70, 90, 124)',
                                }}
                                variant="rectangular"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ) : (
                        <div className="text-xl font-bold text-red-300">{cart.loaded ? `${moneyFormatter(cart.data.attributes.total_applied_store_credit)} ${cart.data.attributes.currency}` : '00.00 MXN'}</div>
                    )}
                </div>
                {!isLoading && cart.loaded && cart.data.attributes.benefit_total > 0 && (
                    <div className="flex w-full flex-row justify-between items-end">
                        <div className="text-xl font-base text-red-300">Descuento</div>
                        <div className="text-xl font-bold text-red-300">
                            {cart.data.attributes.benefit_total} {cart.data.attributes.currency}
                        </div>
                    </div>
                )}
                <div className="flex w-full flex-row justify-between border-b border-gray-600 mb-1">
                    <div className="text-xl font-base text-gray-400">Subtotal</div>
                    {isLoading ? (
                        <div className="h-4 w-28 mb-2">
                            <Skeleton
                                sx={{
                                    backgroundColor: 'rgb(70, 90, 124)',
                                }}
                                variant="rectangular"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ) : (
                        <div className="text-xl font-bold">{cart.loaded ? `${cart.data.attributes.display_item_total} ${cart.data.attributes.currency}` : '00.00 MXN'}</div>
                    )}
                </div>
            </div>

            <div className="flex flex-1 flex-column justify-end items-end">
                <div className="flex w-full flex-column items-end ">
                    <div className="text-xl font-base text-gray-400">TOTAL</div>
                    {isLoading ? (
                        <div className="h-8 w-56 mt-2">
                            <Skeleton
                                sx={{
                                    backgroundColor: 'rgb(70, 90, 124)',
                                }}
                                variant="rectangular"
                                width="100%"
                                height="100%"
                            />
                        </div>
                    ) : (
                        <div className="text-3xl font-bold">{cart.loaded ? `${cart.data.attributes.display_total} ${cart.data.attributes.currency}` : '00.00 MXN'}</div>
                    )}
                </div>
            </div>
            <PaymentModal show={showPaymentModal} onClose={() => setShowPaymentModal(false)} />
        </div>
    );
}

const mapStateToProps = (state) => ({
    cart: state.posCart,
    client: state.posClient,
});

const mapDispatchToProps = {
    getCart,
    updateAutofocus,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricePanel);
