import React, {useEffect, useState} from 'react';

import RegisteredPaymentMethod from './registeredPaymentMethod';

import {
  PaymentMethodsContainer,
  PaymentMethodsTitle,
  AddPaymentMethodButton,
  AddPaymentMethodContainer,
} from './style';

const PaymentMethods = (props) => {

  const getCardStates = (card) => {
    let states = []
    if (card.attributes.default) states.push("primary");
    if (props.chargeCard && card.id == props.chargeCard.id) states.push("charge");
    if (props.depositCard && card.id == props.depositCard.id) states.push("deposit");

    return states;
  }

  return (
    <>
      <PaymentMethodsContainer>
        <PaymentMethodsTitle>
          Metodos de pago registrados
        </PaymentMethodsTitle>
        
        {!props.isCreditCardsLoading && props.creditCards &&
          props.creditCards.map((card) => {
            return (
              <RegisteredPaymentMethod
                id={card.id}
                type={card.type}
                attributes={card.attributes}
                onDeletePaymentMethod={props.onDeletePaymentMethod}
                updateDefaultCreditCard={props.updateDefaultCreditCard}
                setIsLoading={props.setIsLoading}
                states={getCardStates(card)}
              />
            )
          })
        }
        <AddPaymentMethodContainer>
          <AddPaymentMethodButton
            onClick={() => props.setIsModalAddPaymentMethodOpen(true)}
          >
            Agregar metodo de pago
          </AddPaymentMethodButton>
        </AddPaymentMethodContainer>
        </PaymentMethodsContainer>

        <PaymentMethodsContainer>
          <PaymentMethodsTitle>
            Cuenta de cobro seleccionada
          </PaymentMethodsTitle>
          { props.chargeCard ?
            <RegisteredPaymentMethod 
              id={props.chargeCard.id}
              type={props.chargeCard.type}
              attributes={props.chargeCard.attributes}
              setIsLoading={props.setIsLoading}
              states={getCardStates(props.chargeCard)}
            /> :
            <div>No se ha seleccionado ninguna tarjeta.</div>
          }

          <AddPaymentMethodContainer>
            <AddPaymentMethodButton
              onClick={() => props.toggleUpdateSelectedPaymentMethodModal("charge")}
            >
              Cambiar selección
            </AddPaymentMethodButton>
          </AddPaymentMethodContainer>
        </PaymentMethodsContainer>

        <PaymentMethodsContainer>
          <PaymentMethodsTitle>
            Cuenta de deposito seleccionada
          </PaymentMethodsTitle>

          { props.depositCard ? 
            <div>
              Cuenta de deposito
            </div> :
            <div>No se ha seleccionado ninguna tarjeta.</div>
          }
          
          <AddPaymentMethodContainer>
            <AddPaymentMethodButton
              onClick={props.toggleModalAddDepositAccount}
            >
              Cambiar selección
            </AddPaymentMethodButton>
          </AddPaymentMethodContainer>
        
      </PaymentMethodsContainer>
    </>
  )
}

export default PaymentMethods;
