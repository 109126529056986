import {
  ADD_RANK,
  ADD_RANK_LEVEL,
  UPDATE_RANK,
} from 'actions/types'

const initialState = {
  ranks: [],
  levels: [],
}

export default function(state = initialState, action) {
  switch(action.type) {
    case ADD_RANK:
      return {...state, ranks: [...state.ranks, ...action.payload.rank]}
    case ADD_RANK_LEVEL:
      return {...state, levels: [...state.levels, ...action.payload.levels]}
    default:
      return state;
  }
}
