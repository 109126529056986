import React, {useState} from 'react';

const CustomButton = ({children, selected=false, ...props}) => {
  return (
    <div
      className={`text-gray-200 w-full bg-gray-800 border-2 border-gray-500 cursor-pointer select-none flex flex-row justify-center items-center p-3 rounded-lg w-full hover:bg-gray-700 ${!selected && "hover:text-white hover:font-bold"}`}
      {...props}
    >
      {children}
    </div>
  );
}

export default CustomButton;
