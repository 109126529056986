import React from 'react';

const ToggleButton = ({
  icon,
  children,
  selected=false,
  disabled=false,
  onClick=() => {},
  width,
  ...props
}) => {
  function getBgColor() {
    if (disabled) {
      return "border-gray-200 bg-gray-700 opacity-40 cursor-not-allowed"
    }
    if (selected) {
      return "border-blue-200 bg-blue-500"
    }

    return "border-blue-200 hover:bg-blue-900"
  }

  function handleOnClick() {
    if (disabled) {
      return;
    }
    onClick();
  }

  return (
    <div className={width === 'no' ? '' : 'w-48' }>
      <div
        onClick={handleOnClick}
        className={`flex h-full flex-column border-4 self-stretch rounded-xl px-3 py-2 cursor-pointer ${getBgColor()}`}
      >
        <div className="flex flex-1">
          {icon}
          <div className={`flex flex-column justify-center ml-2 text-lg ${selected && "text-white"}`}>
            {props.description}
          </div>
        </div>
        <div className="text-xl font-bold text-white mt-2">
          {children}
        </div>
      </div>
    </div>
  )
}

export default ToggleButton;
