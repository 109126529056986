import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DefaultModal } from 'components/Modal';
import { BasicInput, BasicCheckbox } from 'components/Form';
import { createNewUserRequest } from 'actions/userActions';
import { CircularProgressSpinner } from 'components/Spinners';
import { selectClient } from 'actions/pos/clientActions';
import { MuiSlider } from 'components/Slider';
import { AddToNetworkButton } from '../components';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

const CreateClientModal = ({ show, onClose, ...props }) => {
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectedFatherId, setSelectedFatherId] = useState(null);
    const [fieldsVerified, setFieldsVerified] = useState(false);
    const [errors, setErrors] = useState(null);
    const [data, setData] = useState({
        firstName: '',
        lastName: '',
        telephone: '',
        email: '',
        password: '',
        password_confirmation: '',
        candidate: false,
        credit: 0,
    });

    const [inputValidators, setInputValidators] = useState({
        firstName: {
            required: true,
            length: {
                min: 3,
                max: 30,
            },
            errors: {
                valid: false,
            },
        },
        lastName: {
            required: true,
            length: {
                min: 3,
                max: 30,
            },
            errors: {
                valid: false,
            },
        },
        telephone: {
            required: true,
            isPhoneNumber: true,
            errors: {
                valid: false,
            },
        },
        email: {
            required: true,
            isEmail: true,
            errors: {
                valid: false,
            },
        },
        password: {
            required: true,
            length: {
                min: 6,
                max: 15,
            },
            errors: {
                valid: false,
            },
        },
        password_confirmation: {
            required: true,
            equals: '',
            errors: {
                valid: false,
            },
        },
    });

    useEffect(() => {
        let isValid = Object.values(inputValidators).every((field) => field.errors.valid);
        if (isValid !== fieldsVerified) {
            setFieldsVerified(isValid);
        }
    }, [inputValidators]);

    useEffect(() => {
        setInputValidators({
            ...inputValidators,
            password_confirmation: {
                ...inputValidators.password_confirmation,
                equals: data.password,
            },
        });
    }, [data.password]);

    const handleInputChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;
        setData({ ...data, [id]: val });
    };

    const handleSubmit = async () => {
        if (fieldsVerified) {
            setErrors(null);
            setIsProcessing(true);
            if (selectedFatherId) {
                data.father_id = selectedFatherId;
            }
            let res = await props.createNewUserRequest(data);
            if (res.created) {
                setErrors(null);
                props.selectClient(parseInt(res.user.data.id));
                onClose();
                resetData();
            } else {
                setErrors(res.errors);
            }
            setIsProcessing(false);
        }
    };

    const resetData = () => {
        setData({
            firstName: '',
            lastName: '',
            telephone: '',
            email: '',
            password: '',
            password_confirmation: '',
            candidate: false,
        });
    };

    const sendFieldValidation = (e, isValid) => {
        let id = e.target.id || e.target.name;
        setInputValidators({
            ...inputValidators,
            [id]: {
                ...inputValidators[id],
                errors: {
                    ...inputValidators[id].errors,
                    valid: isValid,
                },
            },
        });
    };

    const renderForm = () => {
        return (
            <>
                <div className="flex flex-row w-full flex-wrap space-between">
                    <div className="sm:pr-2 w-full sm:w-1/2">
                        <BasicInput
                            id={'firstName'}
                            label={'Nombre'}
                            showLabel={true}
                            value={data.firstName}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder={'Ej. Juan'}
                            validators={inputValidators.firstName}
                            sendFieldValidation={sendFieldValidation}
                        />
                    </div>
                    <div className="w-full sm:pl-2 sm:w-1/2">
                        <BasicInput
                            id={'lastName'}
                            label={'Apellido'}
                            showLabel={true}
                            value={data.lastName}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder={'Ej. Perez'}
                            validators={inputValidators.lastName}
                            sendFieldValidation={sendFieldValidation}
                        />
                    </div>
                </div>
                <div className="flex flex-row w-full flex-wrap space-between">
                    <div className="sm:pr-2 w-full sm:w-1/2">
                        <BasicInput
                            id={'email'}
                            label={'Correo electrónico'}
                            showLabel={true}
                            value={data.email}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder={'Ej. usuario@hotmail.com'}
                            validators={inputValidators.email}
                            sendFieldValidation={sendFieldValidation}
                        />
                    </div>
                    <div className="w-full sm:pl-2 sm:w-1/2">
                        <BasicInput
                            id={'telephone'}
                            label={'Teléfono'}
                            showLabel={true}
                            value={data.telephone}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder={'Ej. 781-123-4567'}
                            validators={inputValidators.telephone}
                            sendFieldValidation={sendFieldValidation}
                        />
                    </div>
                </div>
                <div className="flex flex-row w-full flex-wrap space-between">
                    <div className="sm:pr-2 w-full sm:w-1/2">
                        <BasicInput
                            id={'password'}
                            label={'Contraseña'}
                            showLabel={true}
                            value={data.password}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder={''}
                            validators={inputValidators.password}
                            sendFieldValidation={sendFieldValidation}
                        />
                    </div>
                    <div className="w-full sm:pl-2 sm:w-1/2">
                        <BasicInput
                            id={'password_confirmation'}
                            label={'Confirma tu contraseña'}
                            showLabel={true}
                            value={data.password_confirmation}
                            onChange={(e) => {
                                handleInputChange(e);
                            }}
                            placeholder={''}
                            validators={inputValidators.password_confirmation}
                            sendFieldValidation={sendFieldValidation}
                        />
                    </div>
                </div>
                <div className="w-full sm:pl-2 sm:w-1/2">
                    <BasicCheckbox
                        id="candidate"
                        onChange={(e) => {
                            setData({ ...data, candidate: e });
                        }}
                        checked={data.candidate}
                    >
                        ¿Es candidato a Distribuidor?
                    </BasicCheckbox>
                </div>
                {!data.candidate && (
                    <div className="w-full px-7 my-4">
                        <MuiSlider
                            label={'¿Cuánto crédito planeas entregar?'}
                            value={data.credit}
                            min={0}
                            max={20000}
                            step={50}
                            onChange={(val) => {
                                setData({ ...data, credit: val });
                            }}
                        />
                    </div>
                )}

                <div className="sm:pr-2 sm:w-1">
                    <AddToNetworkButton selectedFatherId={selectedFatherId} setSelectedFatherId={setSelectedFatherId} />
                </div>
            </>
        );
    };

    function renderProcessing() {
        return (
            <div className="flex flex-column justify-center my-8">
                <CircularProgressSpinner />
                <div className="flex text-xl font-bold mb-3 items-center justify-center mt-4">Registrando usuario...</div>
            </div>
        );
    }

    function renderErrors() {
        return (
            <div className="rounded-lg bg-red-700 border-2 border-red-900 flex flex-column justify-center my-8">
                <div className="flex flex-column justify-center items-center">
                    {errors?.map((error, index) => (
                        <div key={index} className="flex text-sm text-white font-bold mb-2 items-start justify-start mt-2">
                            {error}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <DefaultModal show={show} onClose={onClose} onHide={onClose} title="Registrar cliente nuevo" saveButtonText="Registrar" onSave={handleSubmit}>
            {!!errors && !isProcessing && renderErrors()}
            {isProcessing ? renderProcessing() : renderForm()}
        </DefaultModal>
    );
};

const mapStateToProps = (state) => ({
    client: state.posClient,
});

const mapDispatchToProps = {
    createNewUserRequest,
    selectClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClientModal);
