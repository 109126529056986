import React, { useState, useEffect } from 'react';
import {MomentDatum} from 'components/Table';
import Button from '@mui/material/Button'
import { Paper } from '@mui/material';
import {DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector} from '@mui/x-data-grid';
import { setupMUIDataGridColumns, setupTableDataRows} from 'utils/common';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {StyledTableContainer, StyledPagination, StyledPaginationItem} from './style';

const OrdersTable = (props) => {
  const [orderCols, setOrderCols] = useState([]);
  const [orderRows, setOrderRows] = useState([]);

  const setupOrderCols = () => {
    setOrderCols(setupMUIDataGridColumns(props.orders || [], getTableHeaders()))
  }

  const setupOrderRows = () => {
    setOrderRows(setupTableDataRows(props.orders || []))
  }

  const getTableHeaders = () => {
    let headers = TABLE_HEADERS;

    if (props.minimalData) {
      delete headers.email;
      delete headers.item_total;
    }

    return headers;
  }

  useEffect(() => {
    if (props.orders) {
      setupOrderCols();
      setupOrderRows();
    }
  }, [props.orders])

  return (
    <>
      <StyledTableContainer
        component={Paper}
      >
        <DataGrid
          //checkboxSelection
          rows={orderRows}
          columns={orderCols}
          pageSize={15}
          loading={orderRows.length < 1}
          style={{color: "white"}}
          rowsPerPageOptions={[10, 20, 30]}
          onSelectionModelChange={(model) => {props.setSelectedOrderId(model[0])}}
          components={{
            Pagination: CustomPagination,
          }}
        />
      </StyledTableContainer>
    </>
  )
}

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <StyledPagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <StyledPaginationItem {...props2} disableRipple/>}
      onChange={(event, value) => apiRef.current.setPage(value - 1) }
    />
  )
}

const states = {
  "balance_due": "Balance Debido",
  "paid": "Pagado",
  "credit_owed": "Crédito debido",
  "complete": "Completado",
  "payment": "Pagando",
  "cart": "Pedido en carrito",
  "address": "Dirección de envío",
}


const TABLE_HEADERS = {
  id: {
    field: "id",
    headerName: "Ver mas",
    width: 130,
    renderCell: (params) => {
      return (
        <Button variant="outlined" endIcon={<UnfoldMoreIcon />}>Abrir</Button>
      )
    }
  },
  number: {
    field: "number",
    headerName: "Referencia",
  },
  email: {
    field: "email",
    headerName: "Email",
    width: 200,
  },
  item_total: {
    field: "item_total",
    headerName: "Total de Productos (MXN)",
    width: 180,
  },
  total: {
    field: "total",
    headerName: "Total (MXN)",
    width: 150,
  },
  state: {
    field: "state",
    headerName: "Estado",
    width: 150,
    renderCell: (params) => {
      return states[params.row.state]
    }
  },
  payment_state: {
    field: "payment_state",
    headerName: "Estado del Pago",
    width: 150,
    renderCell: (params) => {
      return states[params.row.payment_state]
    }
  },
  created_at: {
    field: "created_at",
    headerName: "Fecha de Creación",
    width: 150,
    renderCell: (params) => {
      return (
        <MomentDatum>
          {params.row.created_at}
        </MomentDatum>
      )
    }
  },
}

export default OrdersTable;
