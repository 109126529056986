import Styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'; 
import { mobileScreenSize } from "utils/screen";

export const AccountDebtContainer = Styled.div`
  padding-left: 10px;
  padding-right: 10px;
  border-right: 1px solid #5469d4;
`;

export const AccountDebtTitle = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
`;

export const AccountDebtDescription = Styled.div`
  font-size: 14px;
`;

export const AccountDebtTotal = Styled.div`
  font-size: 1.74rem;
  font-weight: 700;
  line-height: 1.1429;
  margin: -6px;
  margin-left: 0;
  padding-top: 16px;
  padding-bottom: 8px;
  color: ${props => props.theme.text.color.light};
`;

export const AccountDebtTotalDue = Styled.div``;
export const AccountBalanceContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding-left: 10px;
`;
export const AccountBalanceTitle = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
`;
export const AccountBalanceDescription = Styled.div`
  font-size: 14px;
`;

export const AccountBalanceTotal = Styled.div`
  font-size: 1.74rem;
  font-weight: 700;
  line-height: 1.1429;
  margin: -6px;
  margin-left: 0;
  padding-top: 16px;
  padding-bottom: 8px;
  color: ${props => props.theme.text.color.light};
`;

export const AccountBalanceTotalStatus = Styled.div`
  ${props => props.state === "positive" && AccountBalanceTotalStatusPositive};
  ${props => props.state === "negative" && AccountBalanceTotalStatusNegative};

`;

const AccountBalanceTotalStatusPositive = css`
  color: #0e6245;
  width: 150px;
  text-align: center;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
`;

const AccountBalanceTotalStatusNegative = css`
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  color: #856404;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  width: 150px;
  text-align: center;
`;


export const AccountDetailsContainer = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const AccountComissionsContainer = Styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding-right: 10px;
  border-right: 1px solid #5469d4;

`
export const AccountComissionsTitle = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
`
export const AccountComissionsDescription = Styled.div`
  font-size: 14px;
`
export const AccountComissionsTotal = Styled.div`
  font-size: 1.74rem;
  font-weight: 700;
  line-height: 1.1429;
  margin: -6px;
  margin-left: 0;
  padding-top: 16px;
  padding-bottom: 8px;
  color: ${props => props.theme.text.color.light};
`

/* Payment Methods */
export const PaymentMethodsContainer = Styled.div`
  display: flex;
  flex-direction: column;
`;

export const PaymentMethodsTitle = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
  height: 36px;
`;

export const PaymentMethodInformation = Styled.div`
  padding: 12px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  cursor: ${props => props.selectable && "pointer"};
`;

export const PaymentMethodCardDetails = Styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${mobileScreenSize}px) {

  }
`;

export const PaymentMethodStates = Styled.div`
  display: flex;
  @media (max-width: ${mobileScreenSize}px) {
    flex-flow: column;

  }
`;


export const PaymentMethodType = Styled.div`
  padding-right: 15px;
`;

export const PaymentMethodTypeIcon = Styled(FontAwesomeIcon)`
  color: white;
`;

export const PaymentMethodNumberDetails = Styled.div`
  padding-right: 15px;
`;

export const PaymentMethodNumbers = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
`;

export const PaymentMethodExpires = Styled.div`
  font-size: 14px;
`;

export const PaymentMethodState = Styled.div`
  padding: 5px;
  background-color: rgb(231, 243, 255);
  border-radius: 10px;
  height: 20px;
  box-sizing: border-box;
  display: flex;
  margin-top: 5px;
`;

export const PaymenthMethodStateText = Styled.span`
  line-height: 1.5em;
  font-size: 12px;
  color: rgb(24, 119, 242);
  aling-self: flex-start;
  margin-top: -3px;
`;

export const PaymentMethodActions = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  // color: ${props => props.theme.text.color.light};
  &:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,0.3);
  }
`;

export const AddPaymentMethodButton = Styled.button`
  background-color: #E7F3FF;
  color: #1877F2;
  cursor: pointer;
  line-height: 20px;
  font-size: 15px;
  font-weight: 600;
  height: 36px;
  padding: 0 12px;
  border-radius: 5px;
  border-color: rgb(231, 243, 255);
  &:hover {
    opacity: 0.9;
  }
  @media (max-width: ${mobileScreenSize}px) {
    width: 100%;
  }

`;

export const AddPaymentMethodContainer = Styled.div`
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const ModalAddPaymentMethodCodeExpirationContainer = Styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

/* Payment Activity */

export const PaymentActivityContainer = Styled.div`
`;

export const PaymentActivityTableContainer = Styled.div`
`;

/* Modal add new payment */

export const AmountToPayContainer = Styled.div`
  display: flex;
  justify-content: space-around;
`; 

/* Registered payment method */

export const DropdownMenuLi = Styled.div`
  position: relative;
  display: block;
  height: 50%;
`;
