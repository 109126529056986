import React, {useState, useEffect } from 'react';
import {DefaultModal} from '../../components/Modal';
import {BasicFileUpload, Title, BasicInput, BasicRadio} from '../../components/Form';
import { faIdCard, faIdBadge, faCheckCircle, faPassport } from '@fortawesome/free-solid-svg-icons';

const ModalId = (props) => {
  const [data, setData] = useState({});
  
  useEffect(() => {
    if(Object.keys(props.data).length) {
      setData({...data, ...props.data});
    }
  }, [props.data])

  useEffect(() => {
    setFormData({...formData, curp: props.curp})
  }, [props.curp])

  const [formData, setFormData] = useState({
    OfficialIdSelect: {title: "INE", id: "ine", checked: true},
    curp: '',
  });

  const [radioData, setRadioData] = useState([
      {title: "Pasaporte", id: "passport", checked: false},
      {title: "INE", id: "ine", checked: true}
  ]);

  const handleRadioChange = (data) => {
    setFormData({...formData, OfficialIdSelect: {...data, checked: !data.checked}});
    setRadioData(
      radioData.map(radio => {
        return {
          ...radio,
          checked: (radio.id === data.id) ? !data.checked : data.checked
        }
      })
    )
  }

  const handleCurpChange = (e) => {
    let val = e.target.value;
    setFormData({...formData, curp: val})
  }

  const handleSave = () => {
    props.updateCurp(formData.curp);
    props.toggleModal()
  }

  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      title={"Identificación oficial para activación de cuenta"}
      onSave={handleSave}
    >
      <p>Por favor subir una Identificación Oficial, INE o pasaporte, al igual que tu CURP.</p>
      <Title icon={faIdBadge}>CURP</Title>
      <BasicInput
        icon={faIdBadge }
        placeholder={"Introduce tu CURP"}
        value={formData.curp}
        onChange={(e) => handleCurpChange(e)}
      />

      <Title icon={faIdBadge}>Identificación Oficial a utilizar</Title>
      <BasicRadio
        onChange={handleRadioChange}
        groupName={"OficialIdSelect"}
        data={radioData}
      />
    { formData.OfficialIdSelect.id === "ine" &&
      <>
        <Title icon={faIdCard}>INE Frente y Reverso</Title>
        <BasicFileUpload
          text="Frente"
          id="ine_front"
          onChange={(e) => props.handleFileUploadChange(e, "ine_front")}
          icon={faIdCard}
          isLoading={props.isLoading}
          successIcon={faCheckCircle}
          upload={props.formData}
          display={props.data.ine_front}
          data={data}
        />
        <BasicFileUpload
          text="Reverso"
          id="ine_back"
          onChange={(e) => props.handleFileUploadChange(e, "ine_back")}
          icon={faIdCard}
          successIcon={faCheckCircle}
          upload={props.formData}
          data={data}
        />
      </>
    }

    { formData.OfficialIdSelect.id === "passport" && 
      <>
        <Title icon={faPassport}>Pasaporte</Title>
        <BasicFileUpload
          text="copia de pasaporte"
          id="passport"
          onChange={(e) => props.handleFileUploadChange(e, "passport")}
          icon={faPassport}
          successIcon={faCheckCircle}
          upload={props.formData}
          data={data}
        />
      </>
    }
    </DefaultModal>
  )
}

export default ModalId;