import Styled, {css} from 'styled-components';
import {DataGrid} from '@mui/x-data-grid';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const ModalTabContainer = Styled.div`
  padding: 30px 0;
`;

export const ModalDataColumn = Styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.09);
  box-shadow: 5px 5px 5px rgb(0 0 0 / 10%);
  text-align: center;
  margin: 8px;
  padding: 15px;
  border-radius: 5px;
  aspect-ratio: 1.9;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0.2);
`;

export const ModalDataRow = Styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;

export const DataTitle = Styled.div`    
  font-size: 14px;
`;

export const DataValue = Styled.div`
  font-size: 24px;
  font-weight: 700;
  padding: 15px;
  color: white;
`;

export const DataStatus = Styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 15px;
  font-size 17px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  ${props => props.variant === "pending" && pendingVariant}
  ${props => props.variant === "success" && successVariant}
  ${props => props.variant === "danger" && dangerVariant}
  ${props => props.variant === "secondary" && secondaryVariant}
`;

export const StateIcon = Styled(FontAwesomeIcon)`
  padding-right: 5px;
`;

const pendingVariant = css`
  background-color: #fff3cd;
  border-color: #ffeeba;
  color: #856404;
`;
const successVariant = css`
  background-color: #d4edda;
  border-color: #c3e6cb;
  color: #155724;
`;
const dangerVariant = css`
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
`;
const secondaryVariant = css`
  border-color: #d6d8db;
  color: #383d41;
  background-color: #e2e3e5;
`;

export const NoMaxHeightDataGrid = Styled(DataGrid)`
  .MuiDataGrid-cell {
    max-height: none !important;
  }
  
  .MuiDataGrid-row {
    max-height: none !important;
  }
`
