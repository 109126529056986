import React, { useState, useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import {DefaultModal} from 'components/Modal';
import {getOrders} from 'actions/orderActions';
import { CircularProgressSpinner } from 'components/Spinners';
import OrdersTable from 'routes/distributor/orders/ordersTable';
import {OrderViewerModal} from 'components/Modal';

const OrderListerModal = ({show, onClose, orders, ...props}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(false);

  useEffect(() => {
    if (show && props.client.selectedClientId) {
      setIsLoading(true);
      props.getOrders({user_id: props.client.selectedClientId});
    }
  }, [show, props.client.selectedClientId]);

  useEffect(() => {
    if (!!orders.length) {
      setIsLoading(false);
    }
  }, [orders]);

  function renderNoData() {
    return (
      <div className="flex flex-column justify-center">
        <div className="flex text-xl font-bold mb-3 items-center justify-center mt-4">
          No hay pedidos para mostrar
        </div>
      </div>
    )
  }

  function renderLoading() {
    return (
      <div className="flex flex-column justify-center">
        <CircularProgressSpinner />
        <div className="flex text-xl font-bold mb-3 items-center justify-center mt-4">
          Buscando pedidos...
        </div>
      </div>
    )
  }

  function renderOrders() {
    return (
      <div className="row">
        <OrdersTable
          orders={orders}
          setSelectedOrderId={setSelectedOrderId}
          isLoading={isLoading}
          minimalData={true}
        />
        <OrderViewerModal
          show={!!selectedOrderId}
          toggleModal={() => {setSelectedOrderId(false)}}
          orderId={selectedOrderId}
        />
      </div>
    )
  }

  function renderContent() {
    if (isLoading) {
      return renderLoading();
    }

    if (!orders.length) {
      return renderNoData();
    }

    return renderOrders();
  }

  return (
    <DefaultModal
      show={show}
      title="Pedidos"
      onClose={onClose}
      onHide={onClose}
      isLoading={isLoading}
    >
      {renderContent()}
    </DefaultModal>
  )
}

const mapStateToProps = (state) => ({
  orders: state.order.index,
  cart: state.posCart,
  client: state.posClient,
})

const mapDispatchToProps = {
  getOrders
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListerModal);
