import Styled from 'styled-components';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { mobileScreenSize } from "utils/screen";

/* Header */

export const HeaderContainer = Styled.div`
  color: #788797;
  font-weight: 500;
  font-size: 18px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`;


/* Default header*/

export const StyledNav = Styled(Nav)`
  background-color: ${props => props.theme.cons.bgColor};
  border-bottom: 1px solid #27303e;
  padding: 0 15px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: ${mobileScreenSize}px) {
    padding-right: 0px;
  }
`;


export const ActionBtnList = Styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
`;

export const NotificationBtnList = Styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
`;

/* Header Btn*/

export const HeaderBtnLi = Styled.li`
  float: left;
  position: relative;
  display: block;
  height: 100%;
  background-color: none;
`;

export const HeaderBtnA = Styled(Link)`
  padding: 0 15px;
  height: 100%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
`;

export const HeaderBtnFa = Styled(FontAwesomeIcon)`
  color: rgba(225,235,245,.95)!important;
`;
