import axios from 'axios';
import {
  API_BASE_URL,
  INDEX_SUBSCRIPTION_PLANS,
  SUBSCRIBE_USER_TO_PLAN,
  UPDATE_USER_SUBSCRIPTIONS,
  UPDATE_USER_MEMBERSHIP,
  ADD_USER_MEMBERSHIP
 } from './types';

import {dispatchAction} from '../utils/actions/common';

export const getSubscriptionPlans = () => {
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/plans`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
    }).then((res) => {
      dispatch({ type: INDEX_SUBSCRIPTION_PLANS, payload: res.data });
      return res;
    })
  }
}

export const paySubscription = ({user_id = null, amount = 0, subscription_data={}}) => {
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'POST',
      url: API_BASE_URL+`/es/app/api/v1/membership`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {subscription: {...subscription_data, amount}, user_id}
    }).then((res) => {
      let payload = {membership: JSON.parse(res.data.membership)}
      dispatch({type: UPDATE_USER_MEMBERSHIP, payload})
      return res;
    }).catch(err => {
      return err.response
      //throw alert
      //message: err.response.data.error.message
    })
  }
}

export const cancelUserSubscription = (membership_id) => {
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'POST',
      url: API_BASE_URL+`/es/app/api/v1/membership/${membership_id}/expire_active_subscription`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      }
    }).then(res => {
      let payload = {membership: JSON.parse(res.data.membership)}
      dispatch({type: UPDATE_USER_MEMBERSHIP, payload})
      return res;
    }).catch((err) => {
    })
  }
}

export const getUserMembershipData = (user_id) => {
  // SET LOGGED IN USER'S MEMBERSHIP
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/membership/${user_id}`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      params: {user_id}
    }).then((res) => {
      let membership = JSON.parse(res.data.membership);
      dispatch({type: UPDATE_USER_MEMBERSHIP, payload: {membership}})
      return res;
    }).catch(err => {
      return err.response
      //throw alert
      //message: err.response.data.error.message
    })
  }
}

export const fetchMembershipByUserID = (user_id) => {
  // APPENDS MEMBERSHIP TO MEMBERSHIPS ARRAY
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/membership/by_user_id/${user_id}`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
    }).then((res) => {
      let membership = JSON.parse(res.data.membership);
      dispatch({type: ADD_USER_MEMBERSHIP, payload: {membership}})
      return res;
    }).catch(err => {
      return err.response
      //throw alert
      //message: err.response.data.error.message
    })
  }
}

export const toggleAutomaticMembershipRenewal = (membership_id, is_automatic) => {
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'POST',
      url: API_BASE_URL+`/es/app/api/v1/membership/${membership_id}/update_subscription_type`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {membership: {is_automatic}}
    }).then((res) => {
      let membership = JSON.parse(res.data.membership);
      return dispatchAction(dispatch, {data: {membership}}, UPDATE_USER_MEMBERSHIP)
    }).catch(err => {
      return err.response
      //throw alert
      //message: err.response.data.error.message
    })
  }
}

export const updateMembershipPaymentSource = (membership_id, payment_source_id) => {
  let token = JSON.parse(sessionStorage.getItem('sessionToken'));
  return dispatch => {
    return axios({
      method: 'POST',
      url: API_BASE_URL+`/es/app/api/v1/membership/${membership_id}/update_payment_source`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      data: {
        membership: {payment_source_id, payment_source_type: "Spree::CreditCard"}
      }
    }).then((res) => {
      let membership = JSON.parse(res.data.membership);
      dispatch({type: UPDATE_USER_MEMBERSHIP, payload: {membership}})
      return res;
    }).catch(err => {
      return err.response
      //throw alert
      //message: err.response.data.error.message
    })
  }
}
