import React from 'react';
import {MenuItem} from '../../../components/Sidebar';
import { faStore, faUserTie, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import {API_BASE_URL} from '../../../actions/types';

const CandidateMenu = (props) => {
  return (
    <div>
      <MenuItem {...props} text="Tienda" icon={faStore} link={API_BASE_URL} isExternal={true} selectedColor="#2B91A7"/>
      <MenuItem {...props} text="Registro" icon={faUserTie} link="/candidate/register" selectedColor="#2B91A7"/>
      <MenuItem {...props} text="Subscripción" icon={faCreditCard} link="/candidate/subscription" selectedColor="#2B91A7"/>
    </div>
  )
}

export default CandidateMenu;