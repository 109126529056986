import { MuiDataTable } from 'components/Table';
import React from 'react';
import Button from '@mui/material/Button';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { moneyFormatter, limitString } from 'utils/formatters';

const ClientTable = (props) => {
    const getStoreCredits = (params) => {
        return params.row.client_store_credits || [];
    };

    const getOrders = (params) => {
        return params.row.orders.filter((order) => !!order.completed_at);
    };

    const getPayments = (params) => {
        /* FIX > First payment also counts as payment */
        return getOrders(params)
            .map((order) => order.payments.filter((payment) => payment.source_type !== 'Spree::StoreCredit' && payment.state === 'completed'))
            .flat();
    };

    const getPaymentTotal = (params) => {
        return getPayments(params).reduce((acc, payment) => acc + parseFloat(payment.amount), 0) || 0;
    };

    const getPurchasedTotal = (params) => {
        return getOrders(params).reduce((acc, order) => {
            if (!order.completed_at) {
                return acc;
            }
            return acc + parseFloat(order.total);
        }, 0);
    };

    const getCreditTotal = (params) => {
        const storeCredits = getStoreCredits(params);
        return storeCredits.length ? storeCredits.reduce((acc, curr) => acc + parseFloat(curr.amount), 0) : 0;
    };

    const getCreditUsed = (params) => {
        const storeCredits = getStoreCredits(params);
        return storeCredits.reduce((acc, curr) => acc + parseFloat(curr.amount_used), 0);
    };

    const getProfits = (params) => {
        return getOrders(params)
            .map((order) => order.comissions)
            .flat()
            .filter((com) => parseInt(com.user_id) === parseInt(props.user.id))
            .reduce((acc, cv) => acc + parseFloat(cv.amount), 0);
    };

    const getOwedAmount = (params) => {
        return getPurchasedTotal(params) - getPaymentTotal(params);
    };

    const TABLE_HEADERS = {
        id: {
            field: 'id',
            headerName: 'Ver más',
            width: 130,
            renderCell: (params) => {
                return (
                    <Button onClick={() => props.onClickUser(params.row)} variant="outlined" endIcon={<UnfoldMoreIcon />}>
                        Abrir
                    </Button>
                );
            },
        },
        full_name: {
            field: 'full_name',
            headerName: 'Nombre',
            width: 190,
            isCustom: true,
            renderCell: (params) => {
                return limitString(params.row.first_name + ' ' + params.row.last_name);
            },
        },
        email: {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        telephone: {
            field: 'telephone',
            headerName: 'Teléfono',
            width: 200,
        },
        credit_used: {
            field: 'credit_used',
            headerName: 'Crédito Usado (MXN)',
            isCustom: true,
            width: 180,
            renderCell: (p) => moneyFormatter(getCreditUsed(p)),
        },
        total: {
            field: 'total',
            headerName: 'Crédito Total (MXN)',
            isCustom: true,
            width: 180,
            renderCell: (p) => moneyFormatter(getCreditTotal(p)),
        },
        orders: {
            field: 'orders',
            headerName: 'Pedidos',
            width: 120,
            renderCell: (params) => {
                return getOrders(params).length;
            },
        },
        profits: {
            field: 'profits',
            headerName: 'Comisiones',
            isCustom: true,
            width: 150,
            renderCell: (p) => moneyFormatter(getProfits(p)),
        },
        purchased: {
            field: 'purchased',
            headerName: 'Cant. Comprado',
            width: 150,
            isCustom: true,
            renderCell: (p) => moneyFormatter(getPurchasedTotal(p)),
        },
        owed: {
            field: 'owed',
            headerName: 'Cant. Debido',
            width: 150,
            isCustom: true,
            renderCell: (p) => moneyFormatter(getOwedAmount(p)),
        },
    };

    return <MuiDataTable data={props.clients} table_headers={TABLE_HEADERS} onSelectionModelChange={(model) => {}} isLoading={props.isLoading} pageSize={props.pageSize} currentPage={props.currentPage} totalPages={props.totalPages} onPageChange={props.handlePageChange} />;
};
export default ClientTable;
