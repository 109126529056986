import React from 'react';
import { PersonCard, ReportPerson } from 'components/Organizers';
import { DirectReportsContainer, SearchContainer, UsersContainer, PeopleSearchBox, PeopleSearchInput } from './style';

const NetworkViewer = (props) => {
    return (
        <UsersContainer>
            <SearchContainer>
                <PeopleSearchBox>
                    <PeopleSearchInput placeholder="Busca por nombre" />
                </PeopleSearchBox>
            </SearchContainer>

            {!!props.managerRanks.length && (
                <div className="managers">
                    {props.managerRanks.map((rank) => (
                        <ReportPerson showLine={true} active={(props.activeRankId === rank.id).toString()} id={rank.id} onClick={() => props.handleSelectRank(rank.id)} name={rank.attributes.user.attributes.full_name} detail={props.RANKINFO[rank.attributes.state].name} detail2={rank.attributes.user.attributes.email} />
                    ))}
                </div>
            )}
            {!!props.userRank.id && (
                <div className="person">
                    <PersonCard showLine={props.childrenRanks.length > 0} id={props.userRank.id} active={(props.activeRankId === props.userRank.id).toString()} name={props.userRank.attributes.user.attributes.full_name} detail={props.RANKINFO[props.userRank.attributes.state].name} detail2={props.userRank.attributes.user.attributes.email} detail3={props.userRank.attributes.user.attributes.telephone} onClick={() => props.handleSelectRank(props.userRank.id)} />
                </div>
            )}
            {!!props.childrenRanks.length && (
                <DirectReportsContainer>
                    {props.childrenRanks.map((rank) => (
                        <ReportPerson active={(props.activeRankId === rank.id).toString()} id={rank.id} showLine={false} name={rank.attributes.user.attributes.full_name} detail={props.RANKINFO[rank.attributes.state].name} detail2={props.userRank.attributes.user.attributes.email} isChildren={true} onClick={() => props.handleSelectRank(rank.id)} />
                    ))}
                </DirectReportsContainer>
            )}
        </UsersContainer>
    );
};
export default NetworkViewer;
