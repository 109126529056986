import { UPLOAD_OFFICIAL_ID, UPDATE_REGISTER_DOCUMENTS, UPDATE_REGISTER_DOCUMENTS_STATUS, UPDATE_CURP } from '../actions/types';

const initialState = {
  documents: {},
  document_status: {},
}

export default function(state = initialState, action) {
  switch(action.type) {
    case UPLOAD_OFFICIAL_ID:
      return {...state}
    case UPDATE_REGISTER_DOCUMENTS:
      return {...state, documents: {...state.data, ...action.payload}}
    case UPDATE_REGISTER_DOCUMENTS_STATUS:
      return {...state, document_status: {...action.payload}}
    case UPDATE_CURP:
      return {...state, curp: action.payload.curp}
    default:
      return state;
  }
}