import React, {useEffect, useState} from 'react';
import { DefaultModal } from '../../../../components/Modal';
import { BasicInput, RowRadio, Title } from '../../../../components/Form';
import CardPaymentDetailsForm from './CardPaymentDetailsForm';
import CashPaymentDetailsForm from './CashPaymentDetailsForm'
import PaypalPaymentDetailsForm from './PaypalPaymentDetailsForm'

const AddMembershipModal = (props) => {
  const [formData, setFormData] = useState({
    payment_method: "",
    payment_information: {},
    selected_card_id: 0,
    free_membership: false,
    new_card_form: {
      name: "",
      cardNumber: "",
      expiration: "",
      code: "",
      default: false,
      valid: false,
    },
    cash_form: {
      amount: 0,
      credit: 0,
      ref: "",
      description: "",
      valid: false,
    }
  })
  const [show, setShow] = useState(true);
  const [mayConfirm, setMayConfirm] = useState(false);

  useEffect(() => {
    verifyMayConfirm()
  }, [formData])

  useEffect(() => {
    if (isNewCardFormValid()) {
      setFormData({
        ...formData, new_card_form: {
          ...formData.new_card_form,
          valid: true
        }
      })
    }
  }, [formData.new_card_form])

  useEffect(() => {
    if (isCashFormValid()) {
      updateCashForm({valid: true});
    }
  }, [formData.cash_form])

  useEffect(() => {
    setShow(props.show);
  }, [props.show])

  const updateCashForm =(obj) => {
    let updatedForm = {
      ...formData,
      cash_form: {
        ...formData.cash_form,
        ...obj
      }
    };
    setFormData(updatedForm);
  }

  const isCashFormValid = () => {
    if (formData.cash_form.valid) {
      return false;
    }
    return formData.cash_form.amount > 0 && formData.cash_form.ref.length > 0
  }

  const isNewCardFormValid = () => {
    if (formData.new_card_form.valid) {
      return false;
    }
    let form = Object.values(formData.new_card_form);
    let result = form.map(e => typeof e === "boolean" || e.length > 0);

    return result.every(Boolean);
  }

  const verifyMayConfirm = () => {
    let may_confirm = false;
    if(formData.free_membership) {
      may_confirm = true;
    } else if (formData.selected_card_id || (formData.payment_method === "card" && formData.new_card_form.valid)) {
      may_confirm = true;
    } else if (formData.payment_method === "cash" && formData.cash_form.valid) {
      may_confirm = true;
    }
    setMayConfirm(may_confirm)
  }

  const handleInputChange = (e) => {
    let val = e.target.value;
    let name = e.target.name;

    if (name === "free_membership") {
      val = e.target.checked
    }
    setFormData({...formData, [name]: val})
  }

  const handlePaymentMethodSelection = (opt) => {
    setFormData({...formData, payment_method: opt})
  }

  const handleCardSelection = (card_id) => {
    setFormData({...formData, selected_card_id: card_id})
  }

  const handleNewCardFormChange = (e) => {
    let val = e.target.value;
    let id = e.target.id;
    val = formatNewCardFormValues(val, id);

    setFormData({
      ...formData,
      new_card_form: {
        ...formData.new_card_form,
        [id]: val
      }
    })
  }

  const formatNewCardFormValues = (val, id) => {
    let newVal = val
    switch(id) {
      case 'code':
        newVal = val.replace(/[^\d.-]/g, '');
        break;
      case 'expiration':
        newVal = val.replace(/[^\d.-]/g, '').replace(/(.{2})/, '$1/').slice(0, 7);
        break;
      case 'cardNumber':
        newVal = val.replace(/[^\d.-]/g, '').replace(/\W/gi, '').replace(/(.{4})/g, '$1-').slice(0, 19);
        break;
      case 'name':
        break;
      default:
        break;
    }
    return newVal;
  }

  const handleNewCardSetDefaultChange = (e) => {
    setFormData({
      ...formData, new_card_form:
      {...formData.new_card_form,
        default: e
      }
    })
  }

  const handleOnSave = () => {
    props.setIsLoading(true);
    props.onSave(formData)
  }

  const handleClose = () => {
    setShow(false);
    props.toggleModal();
  }

  return (
    <DefaultModal
      show={show}
      onHide={handleClose}
      onSave={handleOnSave}
      saveButtonText={"Confirmar"}
      title={"Agregar membresía"}
      disabledSaveButton={!mayConfirm}
    >
      <div className='flex flex-col'>
        <div className='flex flex-row w-full justify-center items-start h-20'>
          <BasicInput
            showLabel={true}
            label={"¿Regalar membresía?"}
            id={"free_membership"}
            name={"free_membership"}
            type={"checkbox"}
            defaultChecked={formData.free_membership}
            onChange={handleInputChange}
          />
        </div>
        <div className='flex-grow'>
          {!formData.free_membership &&
            <>
              <Title>Metodo de pago</Title>
              {/* tarjeta, efectivo, paypal */}
              <RowRadio
                title="Tarjeta de crédito o débito"
                id="card"
                groupName="payment_method"
                onChange={handlePaymentMethodSelection}
                selected={formData.payment_method}
              />
              <RowRadio
                title="Efectivo"
                id="cash"
                groupName="payment_method"
                onChange={handlePaymentMethodSelection}
                selected={formData.payment_method}
              />
            </>
          }
        </div>
      </div>
      {!formData.free_membership &&
        <>
          <Title>Detalles del pago</Title>
          {formData.payment_method === "card" &&
            <CardPaymentDetailsForm
              isLoading={props.isLoading}
              creditCardList={props.creditCardList}
              handleCardSelection={handleCardSelection}
              handleNewCardFormChange={handleNewCardFormChange}
              handleNewCardSetDefaultChange={handleNewCardSetDefaultChange}
              formData={formData}
              data={props.data}
            />
          }
          {formData.payment_method === "cash" &&
            <CashPaymentDetailsForm
              cashForm={formData.cash_form}
              updateCashForm={updateCashForm}
            />
          }
          {formData.payment_method === "paypal" &&
            <PaypalPaymentDetailsForm />
          }
        </>
      }
      {/* List of cards*/}
      {/* cash fields*/}
      {/* Paypal btn*/}
    </DefaultModal>
  )
}

export default AddMembershipModal;
