import Styled from 'styled-components';

export const LoginContainer = Styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
`;

export const PromoContainer = Styled.div`
  align-self: flex-end;
  height: 95%;
  max-width: 80%;
  min-height: 750px;
  border-top-right-radius: 20%;
  overflow: hidden;
  position: relative;
  margin-right: auto;
  margin-top: 50px; 
  overflow:hidden;

  @media (max-width: 1200px) {
    max-width: 97%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
    border-top-right-radius: 0%;
    height: 100%;
  }

`;
export const PromoLayer = Styled.div`
  position: absolute;
  background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0.2));
  z-index: 6;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const PromoTextLayer = Styled.div`
  width: 70%;
  margin: auto;
  text-align: center;
  color: #21385e;
  line-height: 2rem;
  font-size: 1.2rem;
  h1 {
    font-size: 4rem;
  }
  h2 {
    margin-top: 2rem;
  }
  p {
    font-weight: 600;
    font-size: 1.4rem;
  }
  b {
    color: #0D8399;
  }
  @media (max-width: 1200px) {
    margin-top: 20px;

    h1 {
      font-size: 2rem;
    }

    h2 { display: none; }
    p { display: none; }
    div {display: none;}
  }

  @media (max-width: 600px) {
    width: 90%;

  }
`;

export const PromoTextInstructions = Styled.div`
  width: 50%;
  text-align: justify;
  margin: auto;
  margin-top: 3rem;
`;

export const PromoImg = Styled.img`
  height: 100%;
  z-index: 5;
`;

export const AuthContainer = Styled.div`
  padding: 1rem;
  color: rgba(225,235,245,.95);
  font-size: 14px;
  line-height: 1.5;
  position: relative;
  width: 550px;
  min-width: 450px;

  @media (max-width: 1200px) {
    position: absolute;
    z-index: 7;
    top: 60px;
    min-width: unset;
    width: 100%;
    padding: 0;
  }

  @media (max-width: 380px) {
    width: 480px;
    top: 60px;
  }

`;

export const SignInHeader = Styled.div`
  text-align: center;
  margin: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  padding: 10px 45px 50px;
  font-size: 34px;
  text-shadow: 2px 2px 2px rgba(80, 80, 80,1);
  font-family: 'Lobster', cursive;
  letter-spacing: 0.5 px;
  user-select: none;
  @media (max-width: 1200px) {
  }

    @media (max-width: 600px) {
       margin-top: 73px;
  }
`;

export const AuthContent = Styled.div`
  background-color: #24344d;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0, 0.2335);
  text-align: center;
  padding: 60px 42px 20px;
  margin-bottom: 60px;

  @media (max-width: 600px) {
   margin-bottom: 48px;
  }

  @media (max-width: 380px) {
 
    padding: 45px 10px 20px;
  }
`;

export const AuthHeader = Styled.div`
  margin-top: 10px;
  margin-bottom: 14px;
  font-size: 1.5rem;
`;

export const LogoContainer = Styled.div`
  background-color: #24344d;
  /* background-color: white; */
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50px;
  position: absolute;
  right: 50%;
  margin-right: -50px;
  top: 80px;
  font-weight: 800;

  @media (max-width: 600px) {
       top: 153px;
  }
`;

export const LogoBorder = Styled.div`
  position: absolute;
  border: 2px solid #a7a7a7;
  border-radius: 50%;
  bottom: -2px;
  left: -2px;
  right: -2px;
  top: -2px;
  z-index: 0;
`;

export const LogoImg = Styled.img`
  position: absolute;
  left: 6px;
  top: 5px;
  bottom: 5px;
`;

export const Forgot = Styled.div`
  text-algin: left;
`;
