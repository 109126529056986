import React from "react";
import {ToggleButton} from "@mui/material";

function SizeRadio({
  size,
  selected=false,
  onClick=()=>{},
}) {
  return (
    <div className="mb-2">
      <ToggleButton
        onClick={onClick}
        className="m-1 w-16 h-10"
        style={{
          backgroundColor: selected ? "#4575C4" : "#465A7C",
          borderRadius: "10px",
          border: selected ? "2px solid #0d99ff" : "1px solid #ccc",
          boxShadow: "2px 4px 4px hsl(0deg 0% 0% / 0.40)",
        }}>
        <div className="flex justify-center items-center text-white text-xl font-light">
          {size}
        </div>
      </ToggleButton>
    </div>
  )
}

export default SizeRadio;
