import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { searchClients, selectClient } from '../../../actions/pos/clientActions';
import { ClientSearchModal } from '../components';
import { ListCreditCards } from 'actions/creditCardActions';

const ClientModal = (props) => {
    const [page, setPage] = useState('search');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    async function onSave(data) {
        setIsLoading(true);
        let result = await props.searchClients(data);
        setSearchResults(result);
        setPage('results');
        setIsLoading(false);
    }

    function handleSelect(id) {
        if (id === props.client.selectedClientId) return;

        setSearchResults([]);
        props.selectClient(id);
        props.ListCreditCards(id);
        props.onClose();
    }

    function handleClose() {
        setPage('search');
        props.onClose();
    }

    return <ClientSearchModal show={props.show} onClose={handleClose} onHide={handleClose} onSave={onSave} onSelect={handleSelect} searchResults={searchResults} isLoading={isLoading} selectedClientId={props.client.selectedClientId} page={page} setPage={setPage} />;
};

const mapStateToProps = (state) => ({
    client: state.posClient,
});

const mapDispatchToProps = {
    searchClients,
    selectClient,
    ListCreditCards,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientModal);
