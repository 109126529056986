import React from 'react';
import {Datum, Row, MomentDatum, ColoredDatum} from '../../../components/Table';
import {faCheck, faExclamationTriangle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {variantStates} from '../../../utils/common';

const ScheduledPaymentRow = (props) => {
  let payment = props.scheduledPayment

  let selectedStatus = variantStates[payment.state];

  return (
    <Row type={selectedStatus.variant}>
      <Datum>
        $ {payment.amount} MXN
      </Datum>
      <Datum>
        {payment.amount_paid}
      </Datum>
      <Datum>
        {parseFloat(payment.amount - payment.amount_paid).toFixed(2)}
      </Datum>
      <MomentDatum>
        {payment.due_date}
      </MomentDatum>
      <ColoredDatum variant={selectedStatus.variant} icon={selectedStatus.icon}>{selectedStatus.text}</ColoredDatum>
      <Datum>
        {payment.user.first_name+" "+payment.user.last_name}
      </Datum>
      <Datum>
        {payment.order.number}
      </Datum>
    </Row>
  )
}

export default ScheduledPaymentRow;
