import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getOrderData } from 'actions/orderActions';
import { Tabs, TabItem } from 'components/Navbar';
import ClientTab from './OrderViewerTabs/client';
import GeneralTab from './OrderViewerTabs/general';
import ProductsTab from './OrderViewerTabs/products';
import PaymentsTab from './OrderViewerTabs/payments';
import DepositTab from './OrderViewerTabs/deposit';

import { DefaultModal } from '../';

import { CircularProgressSpinner } from 'components/Spinners';

const OrderViewer = (props) => {
    const TAB_NAMES = ['General', 'Productos', 'Pagos', 'Cliente', 'Abonar'];
    const [reload, setReload] = useState(false);
    const [selectedTabId, setSelectedTabId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedOrder, setSelectedOrder] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        if (props.orderId) {
            let orderFound = findSelectedOrder();
            if (!orderFound || reload) {
                props.getOrderData(props.orderId);
                setReload(false);
            } else {
                setSelectedOrder(findSelectedOrder());
            }
        } else {
            setSelectedOrder(false);
        }
    }, [props.orderId, reload]);

    useEffect(() => {
        if (props.orders.length) {
            setSelectedOrder(findSelectedOrder());
        }
    }, [props.orders]);

    useEffect(() => {
        if (selectedOrder) {
            setIsLoading(false);
        }
    }, [selectedOrder]);

    const findSelectedOrder = () => {
        return props.orders.find((order) => parseInt(order.id) === props.orderId);
    };

    const getHeaderText = () => {
        if (isLoading) {
            return 'Cargando...';
        }

        return `Orden # ${selectedOrder.attributes.number}`;
    };

    return (
        <DefaultModal show={props.show} onHide={props.toggleModal} title={getHeaderText()}>
            <Tabs>
                {TAB_NAMES.map((tab, index) => {
                    return (
                        <TabItem eventKey={tab} active={index === selectedTabId} onClick={() => setSelectedTabId(index)}>
                            {tab}
                        </TabItem>
                    );
                })}
            </Tabs>
            {isLoading ? (
                <div style={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <CircularProgressSpinner />
                </div>
            ) : (
                <>
                    {TAB_NAMES[selectedTabId] === 'General' && <GeneralTab order={selectedOrder} />}
                    {TAB_NAMES[selectedTabId] === 'Productos' && <ProductsTab products={selectedOrder.attributes.line_items.data} />}
                    {TAB_NAMES[selectedTabId] === 'Pagos' && <PaymentsTab payments={selectedOrder.attributes.payments} scheduled_payments={selectedOrder.attributes.scheduled_payments} comissions={selectedOrder.attributes.comissions} />}
                    {TAB_NAMES[selectedTabId] === 'Abonar' && <DepositTab setReload={setReload} order={selectedOrder} />}
                    {TAB_NAMES[selectedTabId] === 'Cliente' && <ClientTab client={() => {}} />}
                </>
            )}
        </DefaultModal>
    );
};

const mapStateToProps = (state) => ({
    orders: state.order.index,
});

const mapDispatchToProps = {
    getOrderData,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderViewer);
