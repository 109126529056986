import React, {useState, useEffect} from 'react';
import ModalImageViewer from './modalImageViewer';
import { Title, Subtitle } from '../../../components/Form';
import {Link} from '../../../components/Button'
import {ImageFile, ImageContainer, ImageTitle, ImageName, RegisterDocumentAttribute} from './style';

const RegistrationInformationTab = (props) => {
  let [selectedImage, setSelectedImage] = useState();
  let [imageViewerOpen, setImageViewerOpen] = useState(false);

  useEffect(() => {
    setImageViewerOpen(!!selectedImage);
  }, [selectedImage])  

  useEffect(() => {
    if(!imageViewerOpen){
      setSelectedImage();
    }
  }, [imageViewerOpen])

  const isRegisterDocumentEmpty = () => {
    let res = !Object.keys(props.registerDocument).length
    return res;
  }

  const handleImageClick = (obj) => {
    setSelectedImage(obj);
  }
  
  const openDocument = (document_url) => {
    window.open(document_url)
  }

  const showDocumentImage = (document) => {
    return (
      <>
        { document.content_type === 'application/pdf' ?
        <div>
          Ver aquí: <Link to="#" onClick={() => openDocument(document.url)}>{document.file_name}</Link>
        </div> :
        <>
          <ImageFile
            url={document.url}
            onClick={() => handleImageClick(document)}
          />
          <ImageName>{document.name}</ImageName>
        </>
        }
      </>
    )
  }

  return (
    <> 
    { (isRegisterDocumentEmpty()) ?
      <Title>Este candidato aún no ha actualizado su documentación...</Title> :
      <div>
        {!!props.registerDocument.curp && (
          <div>
            <Title>CURP</Title>
            <RegisterDocumentAttribute>
              <div>
                {props.registerDocument.curp}
              </div>
            </RegisterDocumentAttribute>
          </div>
        )}
        {
          !!props.registerDocument.ine_front && !!props.registerDocument.ine_back && (
            <>
              <Title>INE</Title>
              <RegisterDocumentAttribute>
                <ImageContainer style={{marginRight: '15px'}}>
                  {showDocumentImage(props.registerDocument.ine_front)}
                </ImageContainer>

                <ImageContainer>
                  <ImageTitle>Reverso</ImageTitle>
                  {showDocumentImage(props.registerDocument.ine_back)}
                </ImageContainer>
              </RegisterDocumentAttribute>
            </>
          )
        }
        {!!props.registerDocument.passport && (
          <div>
            <Title>Pasaporte</Title>
            <RegisterDocumentAttribute>
              <ImageContainer style={{marginRight: '15px'}}>
                <ImageTitle>Pasaporte</ImageTitle>
                {showDocumentImage(props.registerDocument.passport)}
              </ImageContainer>
            </RegisterDocumentAttribute>
          </div>
        )}
        {(!!props.registerDocument.promissory_note || !!props.registerDocument.contract) && (
          <div>
            <Title>Pagaré y Contrato</Title>
            <RegisterDocumentAttribute>
              {!!props.registerDocument.promissory_note && (
                <ImageContainer style={{marginRight: '15px'}}>
                  <ImageTitle>Pagaré</ImageTitle>
                  {showDocumentImage(props.registerDocument.promissory_note)}
                </ImageContainer>
              )}

              {!!props.registerDocument.contract && (
                <ImageContainer style={{marginRight: '15px'}}>
                  <ImageTitle>Contrato</ImageTitle>
                  {showDocumentImage(props.registerDocument.contract)}
                </ImageContainer>
              )}
            </RegisterDocumentAttribute>
          </div>
        )}
        <ModalImageViewer
          show={imageViewerOpen}
          toggleModal={() => setImageViewerOpen(!imageViewerOpen)}
          image={selectedImage}/>
      </div>
    }
    </>
  )
}

export default RegistrationInformationTab;
