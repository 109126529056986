import React from "react";
import Skeleton from "@mui/material/Skeleton";

function ProductItemSkeleton() {
  return (
    <div className="flex flex-row w-full text-white bg-gray-800 rounded-xl h-24 mb-2 overflow-hidden">
      <div className="flex aspect-square mr-2 p-3">
        <Skeleton
          sx={{ 
            backgroundColor: "rgb(70, 90, 124)",
            borderRadius: "0.5rem",
          }}
          variant="rectangular"
          width="100%"
          height="100%" />
      </div>
      <div className="flex flex-1 flex-row justify-between items-center py-3 pr-3">
        <div className="h-full justify-between">
          <div className="items-start text-2xl mb-3 w-40 h-6">
            <Skeleton
              sx={{ 
                backgroundColor: "rgb(70, 90, 124)",
              }}
              variant="rectangular"
              width="100%"
              height="100%" />
          </div>
          <div className="flex flex-row">
            <div className="font-light w-36 h-4">
              <Skeleton
                sx={{ 
                  backgroundColor: "rgb(70, 90, 124)",
                }}
                variant="rectangular"
                width="100%"
                height="100%" />

            </div>
            <div className="mx-2 font-light">
            </div>
          </div>
        </div>
        <div className="flex flex-column justify-end items-end">
          <div className="w-36 h-3 mb-4">
            <Skeleton
              sx={{ 
                backgroundColor: "rgb(70, 90, 124)",
              }}
              variant="rectangular"
              width="100%"
              height="100%" />
          </div>
          <div className="w-36 h-3">
            <Skeleton
              sx={{ 
                backgroundColor: "rgb(70, 90, 124)",
              }}
              variant="rectangular"
              width="100%"
              height="100%" />
          </div>
        </div>
      </div>
      <div
        style={{backgroundColor: "rgb(70, 90, 124)"}}
        className="flex flex-row justify-between items-center p-3 rounded-l-3xl w-32">
        <div className="m-auto animate-spin rounded-full h-10 w-10 border-b-4 border-white"></div>
      </div>
    </div>
  );
}

export default ProductItemSkeleton;
