import React from 'react';
import {EditButton, SummaryActionContainer, SummaryInputContainer} from './style';
import { BasicInput } from '../../../components/Form';
import {ActionSpinner} from '../../../components/Spinners';

const SummaryTabField = (props) => {
  return (
    <SummaryActionContainer>
      <SummaryInputContainer>
        <BasicInput
          title={props.title}
          name={props.id}
          value={props.data[props.id]}
          onChange={props.onChange}
          type={props.type}
          disabled={props.disabled}
        />
      </SummaryInputContainer>
      <EditButton onClick={props.disabled ? props.onClick : ()=> props.onSave(true) }>{props.disabled ? "Editar" : (props.isSaving ? <ActionSpinner/> : "Ok")}</EditButton>
    </SummaryActionContainer>
  )
}

export default SummaryTabField;