import React, {useState, useEffect} from 'react';
import {Title} from 'components/Form';
import {API_BASE_URL} from 'actions/types';
import { TableContainer, Paper} from '@mui/material';
import {ModalTabContainer, NoMaxHeightDataGrid} from '../style';
import { setupMUIDataGridColumns, setupTableDataRows} from 'utils/common';

const ProductsTab = (props) => {
  const [productCols, setProductCols] = useState([]);
  const [productRows, setProductRows] = useState([]);

  const setupProductCols = () => {
    setProductCols(setupMUIDataGridColumns(props.products || [], TABLE_HEADERS))
  }

  const setupProductRows = () => {
    setProductRows(setupTableDataRows(props.products || []));
  }

  useEffect(() => {
    setupProductCols()
    setupProductRows()
  }, [])

  return (
    <ModalTabContainer>
      <Title>Productos del pedido</Title>
      <div>
        {!!productCols.length ?
          <TableContainer
            component={Paper}
            style={{width: '100%', height: '300px'}}
          >
            <NoMaxHeightDataGrid
              rows={productRows}
              columns={productCols}
              pageSize={15}
            />
          </TableContainer> :
          <div> No hay información de productos. </div>
        }
      </div>
    </ModalTabContainer>
  )
}

const TABLE_HEADERS = {
  product_images: {
    field: "product_images",
    headerName: "Imagen",
    width: 120,
    renderCell: (params) => {
      let imgObj = params.row.variant_images[0] || params.value[0];
      return <img style={{height: "150px"}} src={API_BASE_URL + imgObj?.attributes.original_url}/>
    },
  },
  name: {
    field: "name",
    headerName: "Producto",
    width: 200,
  },
  options_text: {
    field: "options_text",
    headerName: "Descripción",
    width: 180,
  },
  quantity: {
    field: "quantity",
    headerName: "Cantidad",
    width: 86,
  },
  display_price: {
    field: "display_price",
    headerName: "P. Unitario",
  },
  display_total: {
    field: "display_total",
    headerName: "P. Total",
  },
}

export default ProductsTab
