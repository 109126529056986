import React from 'react';
import {DataTable, DataTbody, DataThead, Header} from '../../../components/Table';
import CandidateRow from './candidateRow';

const CandidatesTable = (props) => {

  const headerDefaultProps = {
    sort: props.sort,
    setSort: props.setSort,
    selectedSort: props.selectedSort,
    setSelectedSort: props.setSelectedSort,
  }

  const filterCandidatesBySearchText = () => {
    if (props.searchText.length) {
      return props.candidates.filter(cand => {
        return Object.values(cand)
          .filter(Boolean)
          .map(val => 
            val.toString()
            .toLowerCase()
            .indexOf(props.searchText.toLowerCase()) >= 0
          ).some(Boolean);
      })
    } else {
      return props.candidates;
    }
  }

  const filterCandidatesByTab = () => {
    let candidateList = filterCandidatesBySearchText();

    if (props.selectedTab) {
      return candidateList.filter((cand) => {
        if (!cand.register_document) {
          return props.selectedTab === "pending";
        }
        return cand.register_document.status === props.selectedTab
      });
    } else {
      return candidateList
    }
  }

  return (
    <>
    <DataTable isLoading={props.isLoading}>
      <DataThead style={{paddingLeft: "5px"}}>
        <Header width="20"/>
        <Header 
          {...headerDefaultProps}
          alpha={true}
          name={"first_name"}
        >Nombre</Header>
        <Header 
          {...headerDefaultProps}
          width={230}
          alpha={true}
          name="email"
        >Email</Header>
        <Header
          {...headerDefaultProps}
          name={"gender"}
        >Género</Header>
        <Header
          {...headerDefaultProps}
          name="telephone"
        >Teléfono</Header>
        <Header
          {...headerDefaultProps}
          name="status"
        >Estado</Header>
        <Header
          {...headerDefaultProps}
          name="created_at"
        >Fecha de registro</Header>
      </DataThead>
      <DataTbody>
        {!props.isLoading &&
          filterCandidatesByTab().map(candidate => {
            return <CandidateRow
              candidate={candidate}
              setSelectedCandidateId={props.setSelectedCandidateId}
            />
          }
        )}
      </DataTbody>
    </DataTable>
    </>
  )
}

export default CandidatesTable;
