import React from 'react';
import { TabPage, SettingToggle, TabContentSection } from '../../../components/RightSidebar';

const SettingsPage = (props) => {
  return (
    <TabPage title="Settings">
      { props.switches.map(obj => {
        let group = Object.keys(obj)[0];
        return (
          <TabContentSection title={group}>
            {obj[group].map(sw => {
              let key = Object.keys(sw)[0];
              return <SettingToggle {...sw[key]} onClick={props.onClick}>{key}</SettingToggle>
            })}
          </TabContentSection>
        )
      })}
    </TabPage>
  )
}

export default SettingsPage;