import axios from 'axios';
import { requestBuilder } from 'utils/actions/requests';

import {
  API_BASE_URL,
  POS_SEARCH_VARIANT,
  POS_SELECT_VARIANT,
  POS_UNSELECT_VARIANT,
  POS_VARIANT_LOADED
} from '../types';


const STOREFRONT_API_URL = API_BASE_URL+'/es/api/v2/storefront/';

export const searchVariant = (search_params) => async dispatch => {
  return requestBuilder('post',
    STOREFRONT_API_URL+`variants/search/`,
    {search_params},
    dispatch,
    POS_SEARCH_VARIANT
  );
};

export const selectVariant = (variantId) => async dispatch => {
  return dispatch({
    type: POS_SELECT_VARIANT,
    payload: variantId
  });
}

export const unselectVariant = () => async dispatch => {
  return dispatch({
    type: POS_UNSELECT_VARIANT
  });
}

export const variantLoaded = () => async dispatch => {
  dispatch({
    type: POS_VARIANT_LOADED.success
  });
}
