import React from 'react';
import {LinkAnchor} from './style';

const Link = (props) => {
  //bug with onClick event, do not use
  return (
    <LinkAnchor
      style={props.style}
      onClick={props.onClick}
      to={props.to}
      target={props.target}
      rel={props.rel}
    >{props.children}</LinkAnchor>
  )
}

export default Link;
