import React from 'react';
import {Datum, Row, MomentDatum} from '../../../components/Table';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import {ViewCandidateInfoButtonIcon} from './style';

const CandidateRow = (props) => {
  const candidate = props.candidate;

  const candidate_states = {
    pending: "Pendiente",
    uploading: "Subiendo documentación",
    uploaded: "Registro completo",
    rejected: "Rechazado",
    accepted: "Aceptado",
  }

  return (
    <Row>
      <Datum width="50">
        <ViewCandidateInfoButtonIcon
          icon={faPencilAlt}
          onClick={() => props.setSelectedCandidateId(candidate.id)}
        />
      </Datum>
      <Datum showMore={50}>{candidate.first_name +" "+ candidate.last_name}</Datum>
      <Datum showMore={30}>{candidate.email}</Datum>
      <Datum>{candidate.gender}</Datum>
      <Datum showMore={13}>{candidate.telephone}</Datum>
      <Datum>{candidate.register_document ? candidate.register_document.status : "pending" }</Datum>
      <MomentDatum>{candidate.created_at}</MomentDatum>
    </Row>
  )
}

export default CandidateRow;  
