import React from 'react';
import CircledIconButton from './circledIconButton';
import { Add, Remove } from '@mui/icons-material';

function CounterButton({ value, onClick }) {
    function handleOnClick(delta) {
        if (value + delta < 0) return;

        onClick(value + delta);
    }

    return (
        <div className="flex w-full flex-row justify-between px-2 py-2 rounded-full" style={{ backgroundColor: 'rgb(70, 90, 124)' }}>
            <CircledIconButton disabled={value <= 1} onClick={() => handleOnClick(-1)}>
                <Remove />
            </CircledIconButton>
            <div className="flex flex-row justify-center items-center w-12 h-12 text-gray-100 text-2xl font-bold">{value}</div>
            <CircledIconButton onClick={() => handleOnClick(1)}>
                <Add />
            </CircledIconButton>
        </div>
    );
}

export default CounterButton;
