import React, {useState, useEffect} from 'react';
import {DistributorDetailsContainer} from './style';
import {BasicInput} from '../../../components/Form';
import { Title } from '../../../components/Form';

const SummaryPage = (props) => {
  return (
    <div>
      <Title>Información General</Title>
      <DistributorDetailsContainer>
        <BasicInput
          id={"first_name"}
          value={props.data.first_name}
          placeholder={"Escribe aquí el nombre del distribuidor..."}
          onChange={props.handleInputChange}
          title={"Nombre"}
        />
        <BasicInput
          id={"last_name"}
          value={props.data.last_name}
          placeholder={"Escribe aquí el Apellido de tu distribuidor"}
          onChange={props.handleInputChange}
          title={"Apellido"}
        />

        <BasicInput
          id={"email"}
          value={props.data.email}
          placeholder={"Escribe aquí el correo del distribuidor..."}
          onChange={props.handleInputChange}
          title={"Correo"}
        />

        <BasicInput
          id={"birthdate"}
          value={props.data.birthdate}
          placeholder={"Escribe aquí la fecha de nacimiento del distribuidor..."}
          onChange={props.handleInputChange}
          title={"Fecha de nacimiento"}
        />

        <BasicInput
          id={"gender"}
          value={props.data.gender}
          placeholder={"Escribe aquí el género del distribuidor..."}
          onChange={props.handleInputChange}
          title={"Género"}
        />

        <BasicInput
          id={"telephone"}
          value={props.data.telephone}
          placeholder={"Escribe aquí el teléfono del distribuidor"}
          onChange={props.handleInputChange}
          title={"Teléfono"}
        />

        <BasicInput
          id={"distributor_discount_code"}
          value={props.data.distributor_discount_code}
          placeholder={"Escribe aquí el código de descuento"}
          onChange={props.handleInputChange}
          title={"Código de descuento"}
        />

        <BasicInput
          id={"subscription_active"}
          value={props.data.subscription_active}
          placeholder={"Suscripcion acitva?"}
          onChange={props.handleInputChange}
          title={"Suscripcion activa?"}
        />
      </DistributorDetailsContainer>
      <div>
        <Title>Historico de estadísticas</Title>
      </div>
    </div>
  )
}

export default SummaryPage;
