import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const RadioButton = ({
  checked=false,
  disabled=false,
  onClick=() => {},
  label="",
  value="",
  ...props
}) => {
  function handleOnClick() {
    if (disabled) {
      return;
    }
    onClick();
  }

  return (
    <FormControlLabel
      value={value}
      onClick={handleOnClick}
      sx={{
        color: "white",
        '&.Mui-checked': {
          color: "#3B82F6",
        },
      }}
      control={
        <Radio 
          checked={checked}
          sx={{
            color: "white",
          }}
        />
      }
      label={label}
      labelPlacement="end"
    />

  )
}

export default RadioButton;
