import React, { useState, useEffect } from 'react';
import { AuthHeader } from '../style';
import { BasicInput, BasicCheckbox } from 'components/Form';
import { SubmitButton } from 'components/Button';
import ActionLinks from '../action_links';

const LoginPage = (props) => {
    const [usernameInput, setUsernameInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [validations, setValidations] = useState({
        username: {
            required: true,
            length: {
                min: 5,
                max: 40,
            },
        },
        password: {
            required: true,
            length: {
                min: 5,
                max: 50,
            },
        },
    });
    const [errors, setErrors] = useState({
        username: { valid: false },
        password: { valid: false },
    });
    const [fieldsValidated, setFieldsValidated] = useState(false);

    useEffect(() => {
        handleGetData();
    }, []);

    useEffect(() => {
        setFieldsValidated(Object.values(errors).every((field) => field.valid));
    }, [errors]);

    const sendFieldValidation = (e, isValid = true) => {
        let fieldName = e.target.name;
        if (errors[fieldName].valid !== isValid) {
            setErrors({ ...errors, [fieldName]: { valid: isValid } });
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && fieldsValidated) {
            handleLogin();
        }
    };

    const handleLogin = () => {
        if (fieldsValidated) {
            props.handleLogin({
                email: usernameInput,
                password: passwordInput,
                rememberMe: rememberMe,
            });
        }
    };

    const handleGetData = async () => {
        const apiUrl = 'https://jsonplaceholder.typicode.com/users';

        // write your code here

        let users = [];

        await fetch(apiUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error.apply('An error has ocurred');
                }

                return response.json();
            })
            .then((data) => {
                users = data;
            })
            .catch((error) => console.log('Error trying to get data:' + error));

        const order = -1;

        const sortedUsers = users.slice().sort((a, b) => {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();

            if (order === 1) {
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
            } else if (order === -1) {
                if (nameA > nameB) return -1;
                if (nameA < nameB) return 1;
            }

            return 0;
        });

        const text = 'cLeme'.trim().toUpperCase();

        return users;
    };

    return (
        <>
            <AuthHeader>Iniciar Sesión</AuthHeader>
            <BasicInput value={usernameInput} placeholder="Correo electrónico" name="username" validators={validations.username} sendFieldValidation={sendFieldValidation} hideIsRequired={true} onChange={(e) => setUsernameInput(e.target.value)} />
            <BasicInput placeholder="Contraseña" type="password" name="password" value={passwordInput} validators={validations.password} sendFieldValidation={sendFieldValidation} hideIsRequired={true} onChange={(e) => setPasswordInput(e.target.value)} onKeyPress={handleKeyPress} showPassword={showPassword} onClickShowPassword={() => setShowPassword(!showPassword)} />
            <BasicCheckbox id="remember-me" checked={rememberMe} onChange={setRememberMe}>
                Mantener sesión iniciada
            </BasicCheckbox>
            <div style={{ paddingBottom: '0px' }}>
                <SubmitButton onClick={handleLogin}>Iniciar Sesión</SubmitButton>
            </div>
            <ActionLinks setUsePage={props.setUsePage} usePage={props.usePage} />
        </>
    );
};

export default LoginPage;
