import axios from 'axios';

import { LOGIN_USER, SET_ACCESS_TOKEN, LOGOUT_USER, API_BASE_URL, CREATE_FLASH_MESSAGE, REMOVE_USER_STORE_CREDITS, UPDATE_USER_ATTRIBUTES } from './types';

import { dispatchAction } from 'utils/actions/common';

export const loginUserRequest = (credentials) => (dispatch) => {
    return axios({
        method: 'post',
        url: API_BASE_URL + `/spree_oauth/token.js?grant_type=password&username=${credentials.email}&password=${credentials.password}`,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    }).then(
        (res) => {
            if (res.status === 200) {
                sessionStorage.setItem(`sessionToken`, JSON.stringify(res.data));
                dispatchAction(dispatch, res, SET_ACCESS_TOKEN);
                //fetch user data using retrieved access key
                return fetchUserData(res.data, dispatch);
            } else {
                dispatch({
                    type: CREATE_FLASH_MESSAGE,
                    payload: {
                        closeTime: 1500,
                        variant: 'danger',
                        message: 'Error: Los datos introducidos son incorrectos',
                    },
                });
            }
        },
        (reason) => {
            dispatch({
                type: CREATE_FLASH_MESSAGE,
                payload: {
                    closeTime: 1500,
                    variant: 'danger',
                    message: 'Error: Los datos introducidos son incorrectos',
                },
            });
            return false;
        }
    );
};

export const removeUserStoreCredits = (amount) => (dispatch) => {
    dispatch({ type: REMOVE_USER_STORE_CREDITS, payload: amount });
};

export const loginUserWithExistingToken = () => (dispatch) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));

    return fetchUserData(token, dispatch);
};

const fetchUserData = (token, dispatch) => {
    return axios({
        method: 'GET',
        url: API_BASE_URL + `/api/v2/storefront/account.js`,
        headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
        },
    }).then(
        (res) => {
            if (res.status === 200) {
                dispatchAction(dispatch, res.data, LOGIN_USER);
                return res;
            } else {
                return res.status;
            }
        },
        (err) => {
            dispatch({
                type: CREATE_FLASH_MESSAGE,
                payload: {
                    closeTime: 1500,
                    variant: 'danger',
                    message: 'Error: ' + err,
                },
            });
        }
    );
};

export const changeUserPassword = ({ user_id, current_password, new_password }) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'POST',
            url: API_BASE_URL + `/app/api/v1/account/change_password.js`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user_id, current_password, new_password },
        }).then(
            (res) => {
                let user_attributes = JSON.parse(res.data.user);
                dispatch({ type: UPDATE_USER_ATTRIBUTES, payload: user_attributes });
                return res;
            },
            (res) => {
                return res.response.data;
            }
        );
    };
};

export const resetUserPassword = (email) => {
    return () => {
        return axios({
            method: 'POST',
            url: API_BASE_URL + `/api/v2/storefront/passwords.js`,
            data: { user: { email } },
        });
    };
};

export const logoutUserRequest = () => {
    return (dispatch) => {
        sessionStorage.clear();
        dispatch({ type: LOGOUT_USER, payload: {} });
    };
};

export const renewUserTokenRequest = () => {
    if (!sessionStorage.getItem('sessionToken')) {
        return false;
    }
    return (dispatch) => {
        let sessionToken = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'POST',
            url: API_BASE_URL + `/spree_oauth/token.js?grant_type=refresh_token&refresh_token=${sessionToken.refresh_token}`,
            json: true,
        })
            .then((res) => {
                sessionStorage.setItem(`sessionToken`, JSON.stringify(res.data));
                dispatchAction(dispatch, res, SET_ACCESS_TOKEN);
                return res;
            })
            .catch((err) => {
                sessionStorage.clear();
                dispatch({
                    type: CREATE_FLASH_MESSAGE,
                    payload: {
                        closeTime: 1500,
                        variant: 'warning',
                        message: 'La sesión ha caducado, por favor inicia sesión nuevamente.',
                    },
                });
                return { error: { ...err.response } };
            });
    };
};

export const createNewUserRequest = (user) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    let headers = token
        ? {
              'Content-Type': 'application/json',
              Authorization: `${token.token_type} ${token.access_token}`,
          }
        : { 'Content-Type': 'application/json' };
    return (dispatch) => {
        return axios({
            method: 'post',
            url: API_BASE_URL + `/api/v2/storefront/account.js`,
            headers,
            data: { user },
        }).then(
            (res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_FLASH_MESSAGE,
                        payload: {
                            closeTime: 1500,
                            variant: 'success',
                            message: `El usuario ha sido creado con éxito!`,
                        },
                    });
                    return { created: true, ...res.data };
                }
            },
            (err) => {
                dispatch({
                    type: CREATE_FLASH_MESSAGE,
                    payload: {
                        closeTime: 10000,
                        variant: 'danger',
                        message: `Este correo ya esta en uso.`,
                    },
                });
                return { created: false, errors: err.response.data.error };
            }
        );
    };
};

export const updateUserRequest = (user) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return (dispatch) => {
        return axios({
            method: 'PATCH',
            url: API_BASE_URL + `/api/v2/storefront/account.js`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user },
        }).then(
            (res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_FLASH_MESSAGE,
                        payload: {
                            closeTime: 1500,
                            variant: 'success',
                            message: `La información ha sido actualizada!`,
                        },
                    });
                    return true;
                }
            },
            (err) => {
                dispatch({
                    type: CREATE_FLASH_MESSAGE,
                    payload: {
                        closeTime: 1500,
                        variant: 'danger',
                        message: `${err}`,
                    },
                });
                return false;
            }
        );
    };
};

export const deleteUserRequest = (user_id) => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return (dispatch) => {
        return axios({
            method: 'DELETE',
            url: API_BASE_URL + `/api/v2/storefront/account.js`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user_id },
        }).then(
            (res) => {
                if (res.status === 200) {
                    dispatch({
                        type: CREATE_FLASH_MESSAGE,
                        payload: {
                            closeTime: 1500,
                            variant: 'success',
                            message: `El usuario ha sido eliminado!`,
                        },
                    });
                    return true;
                }
            },
            (err) => {
                dispatch({
                    type: CREATE_FLASH_MESSAGE,
                    payload: {
                        closeTime: 1500,
                        variant: 'danger',
                        message: `${err}`,
                    },
                });
                return false;
            }
        );
    };
};

export function isUserAdmin(user) {
    if (!user && !user.data.attributes) {
        return false;
    }

    return user.data.attributes.role === 'admin';
}

export function getUserFromId(users, id) {
    if (!users.length) {
        return null;
    }
    return users.find((user) => user.id == id);
}
