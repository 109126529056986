import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

const Flash = ({ children, onButtonClick, buttonLabel, hasButton = true, typeFlash = 'info', ...props }) => {
    const [show, setShow] = useState(true);
    function handleButtonClick() {
        if (onButtonClick) {
            setShow(false);
            onButtonClick();
        }
    }

    const handleBGColor = () => {
        switch (typeFlash) {
            case 'info':
                return 'bg-blue-600';
            case 'error':
                return 'bg-red-500';
            default:
                return 'bg-blue-600';
        }
    };

    if (!show) {
        return null;
    }
    return (
        <div className={`flex flex-row py-2 px-3 ${handleBGColor()} rounded-xl text-white border-2 border-${handleBGColor()}-300 mb-2`}>
            <div className="flex flex-1 flex-row">{children}</div>
            {hasButton && (
                <Button onClick={handleButtonClick} style={{ borderColor: 'white' }}>
                    {buttonLabel}
                </Button>
            )}
        </div>
    );
};

export default Flash;
