import axios from 'axios'
import {
  API_BASE_URL,
  ADD_RANK,
  ADD_RANK_LEVEL,
  UPDATE_RANK,
} from './types';
import {dispatchAction} from '../utils/actions/common';

export const getRank = (rank_id) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/ranks/${rank_id}`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      //catch error

      let rank = {data: {rank: res.data.data || []}}

      return dispatchAction(dispatch, rank, ADD_RANK)
    })
  }

}


export const getRankNetwork = (rank_id) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/ranks/${rank_id}/network`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      //catch error
      let rank = {data: {rank: res.data.data || []}}

      return dispatchAction(dispatch, rank, ADD_RANK)
    })
  }
}

export const getRankManagers = (rank_id) => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/ranks/${rank_id}/managers`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      //catch error
      let rank = {data: {rank: res.data.data || []}}

      return dispatchAction(dispatch, rank, ADD_RANK)
    })
  }

}

export const getRankLevels = () => {
  return dispatch => {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    return axios({
      method: 'GET',
      url: API_BASE_URL+`/es/app/api/v1/rank_levels`,
      headers: {
        Authorization: `${token.token_type} ${token.access_token}`
      },
      json: true
    }).then(res => {
      //catch error
      let levels = {data: {levels: res.data.data || []}}

      return dispatchAction(dispatch, levels, ADD_RANK_LEVEL)
    })


  }
}

export const getRankById = (ranks = [], id = 0) => {
  if (!ranks.length || !id ) {
    return null;
  }

  return ranks.find((rank) => parseInt(rank.id) === parseInt(id))
}


export const getManagersFromId = (ranks = [], id = 0) => {
  let selectedRanks = []
  let currRank = getRankById(ranks, id)

  while ( currRank?.id ) {
    currRank = getRankById(ranks, currRank.attributes.father_id)
    selectedRanks.push(currRank)
  }

  return selectedRanks.filter(Boolean).sort((a, b) => a.id - b.id );
}

export const getChildrenFromIds = (ranks = [], ids = []) => {
  return ranks.filter((rank) => ids.map(String).indexOf(rank.id.toString()) >= 0 )
}

export const sortRankLevels = (levels) => {
}
