import React from 'react';
import { SettingsSidebar, Header, ConversationTopInfo, ChatWrite, ConversationContent, MessagesContent, Message } from '../../../components/RightSidebar';

const ChatPage = (props) => {
  return (
    <SettingsSidebar isOpen={props.isOpen}>
      <Header onClick={props.toggleIsOpen} closeButton="back">
        <ConversationTopInfo userInfo={props.conversationOpenInfo}/>
      </Header>
      <ConversationContent>
        <MessagesContent>
          {props.conversation &&
            props.conversation[Object.keys(props.conversation)[0]].map(obj => {
              let conv = obj[Object.keys(obj)];
              return (<Message dateTime={conv.dateTime} type={conv.type}>{conv.content}</Message>)
            })
          }
          <div style={{ display: "hidden" }}
             ref={props.messagesEndRef}>
          </div>
        </MessagesContent>
        <ChatWrite onChange={props.chatWriteChange} onKeyDown={props.doSendMessage} value={props.chatWriteValue}>Say something</ChatWrite>
        {/* display send arrow when chatwritevalue > 0  */}
      </ConversationContent>
      
    </SettingsSidebar>
  )
}

export default ChatPage;