import axios from 'axios'

import { 
  API_BASE_URL,
  INDEX_COUNTRIES,
  CREATE_COUNTRIES,
  UPDATE_COUNTRY,
} from './types'

export const getListOfCountries = () => {
  return async dispatch => {
    let page = 1
    let pageHasContent = true;

    while (pageHasContent) {
      let countriesList = await fetchListOfCountries(page);
      dispatch({ type: CREATE_COUNTRIES, payload: countriesList });
      pageHasContent = countriesList.length > 0;
      page++;
    }
  }  
}

const fetchListOfCountries = (page) => {
  return axios({
    method: 'get',
    url: API_BASE_URL+'/api/v2/storefront/countries.js?page='+page,
  }).then((res) => {
    if (res.status !== 200) {
      return res;
    }
    let countries = res.data.data;
    return countries;
  })
}

export const getCountry = (country) => {
  return dispatch => {
    return axios({
      method: 'get',
      url: API_BASE_URL+`/es/app/api/v1/countries/${country.id}/states`,
    }).then((res) => {
      if (res.status !== 200) {
        return res;
      }
      let country = res.data.data;
      dispatch({ type: UPDATE_COUNTRY, payload: country });
    })
  }
}