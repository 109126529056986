import React from 'react';
import {HeaderContainer} from './style';

const Header = (props) => {
  return (
    <HeaderContainer>
      {props.children}
    </HeaderContainer>
  )
}

export default Header;