import React from 'react';
import Row from './Row';
import {Thead} from './style';

const DataThead = (props) => {
  return (
    <Thead>
      <Row style={props.style}>
        {props.children}
      </Row>
    </Thead>
  )
}

export default DataThead;