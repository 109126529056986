import React from 'react';
import { connect } from 'react-redux';
import { MuiDataTable } from 'components/Table';
import { moneyFormatter, limitString, telephoneFormatter } from 'utils/formatters';

const DistributorsTable = (props) => {
    const storeCredits = (params) => {
        return params.row.store_credits?.filter((credit) => credit.attributes.credit_type_name === 'distributor');
    };

    const getOrdersCount = (params) => {
        return params.row.network_orders_count;
    };

    let credit_total = (params) => {
        return storeCredits(params)?.length ? storeCredits(params)?.reduce((acc, curr) => acc + parseFloat(curr.attributes.amount), 0) : 0;
    };

    let credit_used = (params) => {
        return storeCredits(params)?.reduce((acc, curr) => acc + parseFloat(curr.attributes.amount_used), 0);
    };

    const TABLE_HEADERS = {
        full_name: {
            field: 'full_name',
            headerName: 'Nombre',
            width: 200,
            isCustom: true,
            renderCell: (params) => {
                return limitString(params.row.first_name + ' ' + params.row.last_name);
            },
        },
        email: {
            field: 'email',
            headerName: 'Email',
            width: 200,
        },
        telephone: {
            field: 'telephone',
            headerName: 'Teléfono',
            width: 200,
            renderCell: (p) => telephoneFormatter(p.row.telephone),
        },
        credit_remaining: {
            field: 'credit_used',
            headerName: 'Crédito Restante (MXN)',
            isCustom: true,
            width: 180,
            renderCell: (p) => moneyFormatter(credit_used(p)),
        },
        credit_total: {
            field: 'credit_total',
            headerName: 'Crédito Total (MXN)',
            isCustom: true,
            width: 180,
            renderCell: (p) => moneyFormatter(credit_total(p)),
        },
        profits: {
            field: 'profits',
            headerName: 'Comisiones Recibidas',
            isCustom: true,
            width: 150,
            renderCell: (p) => moneyFormatter(p.row.comissions_amount_given_to_father),
        },
        sales: {
            field: 'sales',
            headerName: 'Vendido en Red',
            isCustom: true,
            width: 150,
            renderCell: (p) => moneyFormatter(p.row.network_orders_amount),
        },
        orders: {
            field: 'orders',
            headerName: 'Pedidos en Red',
            isCustom: true,
            width: 180,
            renderCell: (p) => getOrdersCount(p),
        },
    };

    return <MuiDataTable data={props.distributors} table_headers={TABLE_HEADERS} onSelectionModelChange={(model) => {}} />;
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DistributorsTable);
