import { FETCH_ALL_PRODUCTS, CREATE_PRODUCT } from '../actions/types';

const initialState = {
  FETCH_ALL_PRODUCTS: [],
  product: {}
}

export default function(state = initialState, action) {
  switch(action.type) {
    case FETCH_ALL_PRODUCTS:
      return {
        ...state,
        FETCH_ALL_PRODUCTS: action.payload
      }
    case CREATE_PRODUCT:
      return {...state, FETCH_ALL_PRODUCTS: handleProductsRequest(state, action)}
    default:
      return state;
  }
}


const handleProductsRequest = (state=[], action) => {
  switch(action.type) {
    case CREATE_PRODUCT:
        return [
          ...state.FETCH_ALL_PRODUCTS,
          {
            ...action.payload
          }
        ]
  }
}