import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { createDistributorClient, indexDistributorClient } from 'actions/distributorActions';

import { DefaultModal } from '../../../components/Modal';
import { Title, BasicInput, BasicSelect, BasicCheckbox, BasicErrorField } from '../../../components/Form';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { getUserPaymentPlans } from 'actions/paymentPlanActions';
import Swal from 'sweetalert2';
import { FormFlashMessage } from 'components/Alert';

export const ModalClientCreate = (props) => {
    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        telephone: '',
        credit: '',
        birthdate: '',
        gender: '',
        paymentPlansIds: [],
    });

    const [inputValidators, setInputValidators] = useState({
        first_name: {
            required: true,
            length: {
                min: 3,
                max: 30,
            },
            errors: {
                valid: false,
            },
        },
        last_name: {
            required: true,
            length: {
                min: 3,
                max: 30,
            },
            errors: {
                valid: false,
            },
        },
        telephone: {
            required: true,
            isPhoneNumber: true,
            errors: {
                valid: false,
            },
        },
        email: {
            required: true,
            isEmail: true,
            errors: {
                valid: false,
            },
        },
        password: {
            required: true,
            length: {
                min: 6,
                max: 15,
            },
            errors: {
                valid: false,
            },
        },
        birthdate: {
            required: true,
            isDate: true,
            before_date: '1/1/2010',
            errors: {
                valid: false,
            },
        },
    });

    const [availablePlans, setAvailablePlans] = useState([]);
    const [fieldsVerified, setFieldsVerified] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.user.attributes.allow_extended_payment_plans && !props.paymentPlans[props.user.id]) {
            props.getUserPaymentPlans(props.user.id);
        }
    }, []);

    useEffect(() => {
        setAvailablePlans(props.paymentPlans[props.user.id] || []);
    }, [props.paymentPlans]);

    useEffect(() => {
        let isValid = Object.values(inputValidators).every((field) => field.errors.valid);
        isValid = isValid;
        if (isValid !== fieldsVerified) {
            setFieldsVerified(isValid);
        }
    }, [inputValidators]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setData({
            ...data,
            [id]: value,
        });
    };

    const resetFields = () => {
        let keys = Object.keys(data);
        let newData = {};
        keys.forEach((key) => {
            newData[key] = key === 'paymentPlansIds' ? [] : '';
        });
        setData(newData);
        setErrors([]);
    };

    const handleCreateNewClient = async () => {
        if (!fieldsVerified) return true;

        setIsLoading(true);
        let res = await props.createDistributorClient(data, { user_id: props.user.id });

        if (res.status === 200) {
            resetFields();
            Swal.fire('Registro completo!', `Se ha registrado a ${data.first_name} ${data.last_name} exitosamente. Por favor asegúrese de cambiar la contraseña al iniciar sesión.`, 'success');
            await props.indexDistributorClient({ user_id: props.user.id });
            await props.toggleModal();
        }

        if (res.status === 422) {
            setErrors([{ message: res.data.error }]);
        }

        setIsLoading(false);
    };

    const sendFieldValidation = (e, isValid) => {
        let id = e.target.id || e.target.name;

        setInputValidators({
            ...inputValidators,
            [id]: {
                ...inputValidators[id],
                errors: {
                    ...inputValidators[id].errors,
                    valid: isValid,
                },
            },
        });
    };

    const handlePaymentPlanToggle = (planId) => {
        if (data.paymentPlansIds.includes(planId)) {
            setData({
                ...data,
                paymentPlansIds: [...data.paymentPlansIds.filter((id) => id !== planId)],
            });
        } else {
            setData({
                ...data,
                paymentPlansIds: [...data.paymentPlansIds, planId],
            });
        }
    };

    return (
        <DefaultModal show={props.show} onHide={props.toggleModal} onSave={handleCreateNewClient} isLoading={isLoading} title={'Crea un nuevo cliente'} disabledSaveButton={!fieldsVerified}>
            {!!errors.length && (
                <FormFlashMessage variant="danger" heading="Error">
                    {errors.map((error) => {
                        return <li>{error.message}</li>;
                    })}
                </FormFlashMessage>
            )}
            <Title icon={faUserCircle}>Información Personal</Title>
            <BasicInput id={'first_name'} value={data.first_name} placeholder={'Escribe aquí el nombre de tu cliente...'} onChange={(e) => handleInputChange(e)} sendFieldValidation={sendFieldValidation} validators={inputValidators.first_name} title={'Nombre'} />
            <BasicInput id={'last_name'} value={data.last_name} placeholder={'Escribe aquí los apellidos de tu cliente...'} onChange={(e) => handleInputChange(e)} sendFieldValidation={sendFieldValidation} validators={inputValidators.last_name} title={'Apellidos'} />
            <BasicInput id={'email'} value={data.email} placeholder={'Escribe aquí... Ej: usuario@dominio.com'} onChange={(e) => handleInputChange(e)} title={'Correo Electrónico'} sendFieldValidation={sendFieldValidation} validators={inputValidators.email} />
            <BasicInput id={'password'} value={data.password} placeholder={'Escribe aquí una contraseña temporal. (Se actualizará al iniciar sesión)'} onChange={(e) => handleInputChange(e)} title={'Contraseña temporal'} sendFieldValidation={sendFieldValidation} validators={inputValidators.password} />
            <BasicInput id={'telephone'} value={data.telephone} placeholder={'Escribe aquí... Ej: 8711234567'} onChange={(e) => handleInputChange(e)} title={'Teléfono'} sendFieldValidation={sendFieldValidation} validators={inputValidators.telephone} type={'text'} />
            <BasicInput id={'birthdate'} value={data.birthdate} placeholder={'dd-mm-yyyy'} onChange={(e) => handleInputChange(e)} title={'Fecha de nacimiento'} type={'date'} max={'2030-12-31'} sendFieldValidation={sendFieldValidation} validators={inputValidators.birthdate} />
            <BasicSelect id={'gender'} value={data.gender} placeholder={'Escribe aquí... Ej: 8711234567'} onChange={(e) => handleInputChange(e)} title={'Género'} type={'number'}>
                <option id={'male'}>Masculino</option>
                <option id={'female'}>Femenino</option>
            </BasicSelect>
            <BasicInput id={'credit'} value={data.credit} placeholder={'Escribe la cantidad de crédito que te gustaría entregarle a este cliente. Ej: 500'} onChange={(e) => handleInputChange(e)} title={'Crédito'} type={'number'} />
            <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                {props.user.attributes.allow_extended_payment_plans &&
                    availablePlans.map((plan) => {
                        return (
                            <div style={{ flex: '1 1 45%' }}>
                                <BasicCheckbox
                                    key={plan.id}
                                    id={plan.id}
                                    label={plan.name}
                                    checked={plan.default || data.paymentPlansIds.includes(plan.id)}
                                    onChange={() => {
                                        handlePaymentPlanToggle(plan.id);
                                    }}
                                />
                            </div>
                        );
                    })}
            </div>
        </DefaultModal>
    );
};
const mapStateToProps = (state) => ({
    user: state.user.data,
    paymentPlans: state.paymentPlan.list,
});

const mapDispatchToProps = {
    getUserPaymentPlans,
    createDistributorClient,
    indexDistributorClient,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalClientCreate);
