import React from "react";
import {
  ChangeItemContainer,
  ChangeItemValue,
  ChangeItemTitle
} from "./style";

const TotalItem = ({children, title, color, icon}) => {
  return (
    <ChangeItemContainer>
      <ChangeItemValue>
        {children}
      </ChangeItemValue>

      <ChangeItemTitle>
        {title}
      </ChangeItemTitle>
    </ChangeItemContainer>
  )
}

export default TotalItem;
