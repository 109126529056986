import React from 'react';

const AdminIndex = (props) => {
  return (
    <div>
      Admin Index
    </div>
  )
}

export default AdminIndex;