import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Title } from 'components/Form';
import { CreditTransferViewer } from 'components/Modal';
import { BasicCheckbox } from 'components/Form';
import { CircularProgressSpinner } from 'components/Spinners';
import { MuiDataTable } from 'components/Table';
import Swal from 'sweetalert2';
import { DistributorCreditContainer, CreditDataContainer, CreditDataMetrics, CreditDataBar, MetricHeader, CreditActions, CreditOptionsContainer } from './style';

import CreatePaymentPlanModal from './CreatePaymentPlanModal';
import { getUserBankInformation, findUserBank } from 'actions/bankActions';

import { updateAdminDistributor } from 'actions/adminActions';
import { createFlashMessageRequest } from 'actions/alertActions';
import { getUserPaymentPlans, deletePaymentPlan } from 'actions/paymentPlanActions';

import { ProgressBar, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faUndo } from '@fortawesome/free-solid-svg-icons';

import { TIMEOPTIONS } from 'utils/formatters';

const CreditPage = (props) => {
    const [userBank, setUserBank] = useState({});
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [transferModalType, setTransferModalType] = useState('transfer');
    const [creditOptions, setCreditOptions] = useState({ allow_extended_payment_plans: false });
    const [isLoading, setIsLoading] = useState(true);
    const [showCreatePaymentPlanModal, setShowCreatePaymentPlanModal] = useState(false);
    const [userPlans, setUserPlans] = useState([]);
    const [userPlansLoading, setUserPlansLoading] = useState(true);

    const TABLE_HEADERS = {
        id: {
            field: 'id',
            headerName: 'Aplicar',
            width: 130,
            renderCell: (params) => {
                return (
                    <Button
                        variant="outline-danger"
                        onClick={() => {
                            handlePlanDelete(params.id);
                        }}
                        style={{}}
                    >
                        Eliminar
                    </Button>
                );
            },
        },
        name: {
            width: '300',
            field: 'name',
            headerName: 'Nombres',
        },
        interval: {
            field: 'interval',
            width: 250,
            headerName: 'Intervalo de pago',
            renderCell: (p) => `${p.row.interval_count} ${p.row.interval}`,
        },
        total_intervals: {
            field: 'total_intervals',
            headerName: 'pagos totales',
            width: 200,
        },
        upfront_percentage: {
            field: 'upfront_percentage',
            headerName: 'Anticipo (%)',
            width: 150,
            renderCell: (p) => `${(p.row.upfront_percentage * 100).toFixed(2)} %`,
        },
        pay_after_date: {
            field: 'pay_after_date',
            headerName: 'Primer pago',
            width: 200,
            renderCell: (p) => {
                let after_date = p.row.pay_after_date;
                let after_time = `${p.row.pay_after_count} ${TIMEOPTIONS[p.row.pay_after_interval]}`;

                if (!p.row.pay_after_count && !p.row.pay_after_date?.length) {
                    return 'Checkout';
                }

                return !!after_date?.length ? after_date : after_time;
            },
        },
    };

    useEffect(() => {
        if (props.data) {
            if (!findUserBank({ bankAccounts: props.bank_accounts, userId: props.data.id })) {
                props.getUserBankInformation(props.data.id);
            }
            setCreditOptions((prev) => ({
                ...prev,
                allow_extended_payment_plans: props.data.allow_extended_payment_plans,
            }));
        }
    }, [props.data]);

    useEffect(() => {
        let user_bank = findUserBank({ bankAccounts: props.bank_accounts, userId: props.data.id });
        if (user_bank) {
            setIsLoading(false);
            setUserBank(user_bank);
        } else {
            setIsLoading(true);
        }
    }, [props.bank_accounts]);

    useEffect(() => {
        if (creditOptions.allow_extended_payment_plans && !getUserPlan()) {
            props.getUserPaymentPlans(props.data.id);
        }
    }, [props.data.id, creditOptions.allow_extended_payment_plans]);

    useEffect(() => {
        let userPlan = getUserPlan();
        if (!!userPlan) {
            setUserPlansLoading(false);
            setUserPlans(userPlan);
        }
    }, [props.paymentPlan]);

    function handlePlanDelete(plan_id) {
        Swal.fire({
            show: true,
            title: 'Por favor confirma esta operación',
            text: 'Al confirmar ésta operación, se eliminará el plan de pago del distribuidor.',
            icon: 'info',
            showCancelButton: true,
            confirmButtonText: 'Si, Borrar',
            confirmButtonColor: '#379824',
            cancelButtonText: 'Regresar',
        }).then((result) => {
            if (result.isConfirmed) {
                setIsLoading(true);
                props.deletePaymentPlan(plan_id, props.data.id).then((res) => {
                    if (res.status >= 400) {
                    }
                    if (res.status === 200) {
                    }
                    setIsLoading(false);
                });
            }
        });
    }

    function getUserPlan() {
        return props.paymentPlan[props.data.id];
    }

    const getCreditUsedPercentage = () => {
        if (!userBank) {
            return 0;
        }

        if (Object.entries(userBank)?.length === 0) return 0;

        if (!userBank.distributor_store_credit_amount) {
            return 0;
        }

        return parseFloat((parseFloat(userBank.distributor_store_credit_remaining) * 100) / parseFloat(userBank.distributor_store_credit_amount)).toFixed(2);
    };

    const handleCreditTransferModalShow = (type) => {
        setTransferModalType(type);
        setShowTransferModal(true);
    };

    const handleUpdateCreditOptions = async (params) => {
        let distributor = await props.updateAdminDistributor({
            distributor: params,
            id: props.data.id,
        });

        if (!!distributor.data) {
            props.createFlashMessageRequest({
                closeTime: 1500,
                variant: 'success',
                message: `Se ha actualizado al distribuidor ${props.data.first_name} ${props.data.last_name}`,
            });
        }
    };

    const handleToggleExtendedPaymentPlans = (value) => {
        setCreditOptions({ allow_extended_payment_plans: value });
        handleUpdateCreditOptions({ allow_extended_payment_plans: value });
    };

    return (
        <>
            <DistributorCreditContainer>
                <Title>Crédito del distribuidor</Title>
                {isLoading ? (
                    <CircularProgressSpinner />
                ) : (
                    <CreditDataContainer>
                        <CreditDataMetrics>
                            <div>
                                <MetricHeader> Línea de crédito:</MetricHeader>
                                <div>$ {userBank.distributor_store_credit_amount}</div>
                            </div>
                            <div>
                                <MetricHeader>Crédito disponible</MetricHeader>
                                <div>$ {userBank.distributor_store_credit_remaining}</div>
                            </div>
                            <div>
                                <MetricHeader>Crédito utilizado</MetricHeader>
                                <div>$ {userBank.distributor_store_credit_used}</div>
                            </div>
                        </CreditDataMetrics>
                        <CreditDataBar>
                            <ProgressBar animated style={{ height: '60px' }} now={getCreditUsedPercentage()} label={`Crédito restante: ${getCreditUsedPercentage()} %`} variant={'primary'} />
                        </CreditDataBar>

                        <CreditActions>
                            <Button variant="outline-secondary" style={{ width: '30%' }} onClick={() => handleCreditTransferModalShow('withdraw')}>
                                <FontAwesomeIcon icon={faUndo} /> Retirar
                            </Button>
                            <Button variant="outline-primary" onClick={() => handleCreditTransferModalShow('transfer')} style={{ width: '30%' }}>
                                <FontAwesomeIcon icon={faDollarSign} /> Enviar
                            </Button>
                        </CreditActions>

                        <CreditOptionsContainer>
                            <BasicCheckbox
                                id={1}
                                checked={creditOptions.allow_extended_payment_plans}
                                label="Permitir extender quincenas?"
                                onChange={() => {
                                    handleToggleExtendedPaymentPlans(!creditOptions.allow_extended_payment_plans);
                                }}
                            />
                            {creditOptions.allow_extended_payment_plans && (
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setShowCreatePaymentPlanModal(true);
                                    }}
                                    style={{}}
                                >
                                    Crear Nuevo
                                </Button>
                            )}
                        </CreditOptionsContainer>
                        {creditOptions.allow_extended_payment_plans && <MuiDataTable data={userPlans} table_headers={TABLE_HEADERS} checkboxSelection onSelectionModelChange={(model) => {}} defaultPageSize={100} isLoading={userPlansLoading} />}
                    </CreditDataContainer>
                )}
            </DistributorCreditContainer>
            {!!userBank.id && <CreditTransferViewer show={showTransferModal} setShow={setShowTransferModal} transferOption={transferModalType} userId={userBank.user_id} />}
            <CreatePaymentPlanModal user_id={props.data.id} setShow={setShowCreatePaymentPlanModal} show={showCreatePaymentPlanModal} />
        </>
    );
};

const mapStateToProps = (state) => ({
    bank_accounts: state.bank.bank_accounts,
    paymentPlan: state.paymentPlan.list,
});

const mapdispatchtoprops = {
    getUserBankInformation,
    updateAdminDistributor,
    createFlashMessageRequest,
    getUserPaymentPlans,
    deletePaymentPlan,
};

export default connect(mapStateToProps, mapdispatchtoprops)(CreditPage);
