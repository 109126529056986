import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Page, MainWrapper} from 'components/Body';
import {OrderViewerModal} from 'components/Modal/'
import {indexDistributorClientOrders} from 'actions/distributorActions';
import OrdersTable from './ordersTable';

const DistributorOrders = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState();

  useEffect(() => {
    props.indexDistributorClientOrders()
    .then(res => {
      setIsLoading(false);
    })
  }, [])

  return (
    <Page title="Pedidos de clientes">
      <MainWrapper
        title="Listado"
        actionButton={false}
        showSearch={false}
        onActionClick={() => {}}
      >
        <OrdersTable
          orders={props.distributor.orders}
          setSelectedOrderId={setSelectedOrderId}
          isLoading={isLoading}
        />
      </MainWrapper>
      {!isLoading &&
        <OrderViewerModal
          show={!!selectedOrderId}
          toggleModal={() => {setSelectedOrderId(false)}}
          orderId={selectedOrderId}
        />
      }
    </Page>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  distributor: state.distributor,
})

const mapDispatchToProps = {
  indexDistributorClientOrders,
}

export default connect(mapStateToProps, mapDispatchToProps)(DistributorOrders);
