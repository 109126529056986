import React, {useEffect, useState} from 'react';
import {
  AccountDebtContainer,
  AccountDebtTitle,
  AccountDebtDescription,
  AccountDebtTotal,
  AccountDebtTotalDue,
  AccountBalanceContainer,
  AccountBalanceTitle,
  AccountBalanceDescription,
  AccountBalanceTotal,
  AccountBalanceTotalStatus,
  AccountDetailsContainer,
  AccountComissionsContainer,
  AccountComissionsTitle,
  AccountComissionsDescription,
  AccountComissionsTotal,

} from './style';

const AccountBalance = (props) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if(props.bankAccount){
    }
  }, [props.bankAccount])

  return (
    <AccountDetailsContainer>

      <AccountComissionsContainer>
        <AccountComissionsTitle>
          Crédito de distribuidor 
        </AccountComissionsTitle>
        <AccountComissionsDescription>
          Crédito total recurrente con el que cuentas actualmente.
        </AccountComissionsDescription> 
        <AccountComissionsTotal>
          $ {props.bankAccount?.store_credit_balance || 0}
        </AccountComissionsTotal>
        Actualmente cuentas con los siguientes numeros
        <AccountComissionsTitle>
          Crédito usado 
        </AccountComissionsTitle>
        <AccountComissionsTotal>
          $ {props.bankAccount?.store_credit_used || 0 }
        </AccountComissionsTotal>
      </AccountComissionsContainer>


      <AccountDebtContainer>
        <AccountDebtTitle>
          Deuda por pagar
        </AccountDebtTitle>
        <AccountDebtDescription>
          Se te cobrará esta cantidad automaticamente al corte de quincena si no se realiza el pago antes.
          <br/>
          (se cobra con tu saldo o a tu tarjeta de crédito registrada)
        </AccountDebtDescription>
        <AccountDebtTotal>
          $0
        </AccountDebtTotal>
        <AccountDebtTotalDue>
          Pagar antes de:
        </AccountDebtTotalDue>
      </AccountDebtContainer>

      <AccountBalanceContainer>
        <AccountBalanceTitle>
          Saldo en cuenta
        </AccountBalanceTitle>
        <AccountBalanceDescription>
          Esta es la cantidad que se depositará a tu cuenta de banco esta quincena.
        </AccountBalanceDescription>
        <AccountBalanceTotal>
          $0
        </AccountBalanceTotal>
        <AccountBalanceTotalStatus state={"positive"}>
          Saldo positivo
        </AccountBalanceTotalStatus>
      </AccountBalanceContainer>      
    </AccountDetailsContainer>
  )
};

export default AccountBalance;
