import React from 'react';
import {DataTable, DataTbody, DataThead, Datum, Header, Row} from '../../../components/Table';
import ScheduledPaymentRow from './scheduledPaymentRow';

const ScheduledPaymentsTable = (props) => {
  return (
    <DataTable isLoading={props.isLoading}>
      <DataThead>
        <Header>Total</Header>
        <Header>Cantidad Pagada</Header>
        <Header>Debido total</Header>
        <Header>Fecha de pago</Header>
        <Header>Estado</Header>
        <Header>Usuario</Header>
        <Header>Número de orden</Header>
      </DataThead>
      <DataTbody>
        {!props.isLoading &&
          props.scheduledPayments && props.scheduledPayments.map(payment => {
            return <ScheduledPaymentRow scheduledPayment={payment}/>
          })
        }
      </DataTbody>
    </DataTable>
  )
}

export default ScheduledPaymentsTable