import React, {useEffect, useState} from 'react';
import { DataTable, DataTbody, DataThead, Datum, Row } from '../../../../components/Table';

const NetworkTable = (props) => {
  const [selectedNetwork, setSelectedNetwork] = useState({distributors: []});

  useEffect(() => {
    if (props.data) {
      props.showMultiLevel(props.data.id)
    }
  }, [])

  useEffect(() => {
    if (props.network.networks.length) {
      let user_network = props.network.networks.find(network => network.user_id === props.selectedDistributor[0]);
      setSelectedNetwork({...user_network});
    }
  }, [props.network, props.data])

  return (
    <div>
      <DataTable>
        <DataThead>
          <Row>
            <Datum>Id</Datum>
            <Datum>Nombre</Datum>
            <Datum>Correo</Datum>
          </Row>
        </DataThead>
        <DataTbody>
          {(selectedNetwork.distributors && selectedNetwork.distributors.length) ?
            selectedNetwork.distributors.map(distributor => {
              return (
                <Row>
                  <Datum>
                    {distributor.id}
                  </Datum>
                  <Datum>
                    {distributor.first_name} {distributor.last_name}
                  </Datum>
                  <Datum>
                    {distributor.email}
                  </Datum>
                </Row>
              )
            }) :
            <Row>
              <div style={{padding: "15px"}}>
                El usuario seleccionado no tiene distribuidores en su red.
              </div>
            </Row>
          }
        </DataTbody>
      </DataTable>
    </div>
  )
}

export default NetworkTable;