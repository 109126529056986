import axios from 'axios';

import { API_BASE_URL, GET_SCHEDULED_PAYMENTS, UPDATE_SCHEDULED_PAYMENT } from './types';

export const getScheduledPayments = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        console.log(params);

        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/scheduled_payments`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: params,
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_SCHEDULED_PAYMENTS,
                    payload: response.data,
                });

                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

const updateScheduledPayment = (params) => {};
