import {
  ADD_ORDER,
  INDEX_ORDER,
} from '../actions/types'

const initialState = {
  index: [],
}

export default function(state= initialState, action) {
  switch(action.type) {
    case ADD_ORDER:
      return {
        ...state,
        index: [
          ...state.index.filter(order => order.id !== action.payload.order.id),
          action.payload.order
        ],
      }
    case INDEX_ORDER:
      return {
        ...state,
        index: action.payload.orders,
      }
    default:
      return state;
  }
}
