export const moneyFormatter = (number) => {
    let dollarUSLocale = Intl.NumberFormat('en-US');

    if (!number) {
        return '$ 0.00';
    }

    return '$ ' + dollarUSLocale.format(number);
};

export const percentageFormatter = (number) => {
    if (!number) {
        return '0%';
    }

    if (number < 1) {
        return (number * 100).toFixed(2) + '%';
    }

    return parseFloat(number).toFixed(2) + '%';
};

export const telephoneFormatter = (string) => {
    if (!string || isNaN(string)) return string;
    if (typeof string !== 'string') string = string.toString();

    return string.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
};

export const dateFormatter = (string) => {
    let date = new Date(string);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${day}/${month}/${year}`;
};

export const limitString = (str, len = 25) => {
    if (str.length <= len) return str;
    return str.slice(0, 20) + '...';
};

export function appendToString(str, appendix) {}

export function onlyNumbers(input) {
    return input.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
}

export const TIMEOPTIONS = {
    month: 'Mes',
    week: 'Semana',
    day: 'Dia',
};

export function extractAttributes(data) {
    return data.map((row) => ({
        id: row.id,
        ...row.attributes,
    }));
}

export const formatNewCardFormValues = (val, id) => {
    let newVal = val;
    switch (id) {
        case 'code':
            newVal = val.replace(/[^\d.-]/g, '');
            break;
        case 'expiration':
            newVal = val
                .replace(/[^\d.-]/g, '')
                .replace(/(.{2})/, '$1/')
                .slice(0, 7);
            break;
        case 'cardNumber':
            newVal = val
                .replace(/[^\d.-]/g, '')
                .replace(/\W/gi, '')
                .replace(/(.{4})/g, '$1-')
                .slice(0, 19);
            break;
        case 'name':
            break;
        default:
            break;
    }
    return newVal;
};

export const formatDate = (string) => {
    let date = new Date(string.replace(/-/g, '/').replace(/T.+/, ''));

    return date.toLocaleDateString('es-MX', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });
};
