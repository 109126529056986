import React, { useEffect, useState } from 'react';
import { Page, MainWrapper } from '../../../components/Body';
import ClientTable from './clientsTable';
import { connect } from 'react-redux';
import { indexDistributorClient } from 'actions/distributorActions';

import { changeUserPassword } from 'actions/userActions';

import { removeUserStoreCredits } from 'actions/userActions';
import { createFlashMessageRequest } from 'actions/alertActions';
import ModalClientCreate from './ModalClientCreate';
import ModalSelectedClient from './ModalSelectedClient';
import { OrderViewerModal } from 'components/Modal';
import { FilterButton } from 'components/Filter';
import { BasicSelect, MuiDatePicker } from 'components/Form';
import { MuiTextField } from 'routes/pos/components';

const DistributorClients = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedOrderId, setSelectedOrderId] = useState(false);
    const [isClientModalOpen, setIsClientModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({ meta: {}, list: [] });
    const [dateFilter, setDateFilter] = useState({
        option: null,
        from: null,
        to: null,
        apply: false,
    });
    const [userFilter, setUserFilter] = useState({
        option: null,
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        apply: false,
    });

    useEffect(() => {
        setIsLoading(true);
        props.indexDistributorClient({ user_id: props.user.data.id }).then(() => {
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        setData(props.distributor.clients);
        setIsLoading(false);
        if (Object.keys(selectedClient).length) {
            setSelectedClient(props.distributor.clients.find((client) => client.id === selectedClient.id));
        }
    }, [props.distributor.clients]);

    useEffect(() => {
        setIsClientModalOpen(Object.keys(selectedClient).length);
    }, [selectedClient]);

    useEffect(() => {
        if (!isClientModalOpen) {
            setSelectedClient({});
        }
    }, [isClientModalOpen]);

    useEffect(() => {
        fetchPageData();
    }, [currentPage, dateFilter.apply, userFilter.apply]);

    const onClickUser = (user = {}) => {
        setSelectedClient(user);
    };

    const getFilter = (opt) => {
        if (opt === 'date') {
            return dateFilter;
        } else if (opt === 'user') {
            return userFilter;
        }
    };

    const getFilterResultText = (opt) => {
        let filter = getFilter(opt);
        if (!filter.apply) {
            return '';
        }
        switch (filter.option) {
            case 'email':
                return `Correo: ${filter.email}`;
            case 'name':
                if (filter.first_name && filter.last_name) {
                    return `Nombre: ${filter.first_name} ${filter.last_name}`;
                } else if (filter.first_name) {
                    return `Nombre: ${filter.first_name}`;
                } else {
                    return `Apellido(s): ${filter.last_name}`;
                }
            case 'phone':
                return `Teléfono: ${filter.phone}`;
            case 'between':
                return `entre ${dateFilter.from} y ${dateFilter.to}`;
            case 'equals':
                return `igual a ${dateFilter.from}`;
            case 'after':
                return `después de ${dateFilter.from}`;
            case 'before':
                return `antes de ${dateFilter.to}`;
            default:
                return '';
        }
    };

    function onFilterClose(opt) {
        if (opt === 'user') {
            setUserFilter((prev) =>
                prev.apply
                    ? prev
                    : {
                          option: null,
                          email: '',
                          first_name: '',
                          last_name: '',
                          phone: '',
                          apply: false,
                      }
            );
        } else if (opt === 'date') {
            setDateFilter((prev) => (prev.apply ? prev : { option: null, start: null, end: null, apply: false }));
        }
    }

    function resetFilter(opt) {
        if (opt === 'user') {
            setUserFilter({
                option: null,
                email: '',
                first_name: '',
                last_name: '',
                phone: '',
                apply: false,
            });
        } else if (opt === 'date') {
            setDateFilter({
                option: null,
                start: null,
                end: null,
                apply: false,
            });
        }
    }

    const handleDateFilterConfirm = () => {
        let from,
            to = '';
        if (dateFilter.option === 'between') {
            from = dateFilter.from;
            to = dateFilter.to;
        } else if (dateFilter.option === 'equals') {
            from = dateFilter.from;
        } else if (dateFilter.option === 'after') {
            from = dateFilter.from;
        } else if (dateFilter.option === 'before') {
            to = dateFilter.to;
        }

        setDateFilter({ ...dateFilter, from, to, apply: true });
    };

    const handleUserFilterConfirm = (opt) => {
        let filter = getFilter(opt);
        let setFilter = getFilterSetter(opt);
        let email, first_name, last_name, phone;

        if (filter.option === 'email') {
            email = filter.email;
        } else if (filter.option === 'name') {
            first_name = filter.first_name;
            last_name = filter.last_name;
        } else if (filter.option === 'phone') {
            phone = filter.phone;
        }

        setFilter({
            ...filter,
            email,
            first_name,
            last_name,
            phone,
            apply: true,
        });
    };

    const handleFilterConfirm = (opt) => {
        if (getFilter(opt).option === null) {
            return;
        }

        setCurrentPage(1);
        if (opt === 'date') {
            handleDateFilterConfirm();
        } else if (opt === 'user') {
            handleUserFilterConfirm('user');
        }
        fetchPageData();
    };

    const fetchPageData = async () => {
        let fetchObject = {
            page: currentPage,
            user_id: props.user.data.id,
        };

        if (userFilter.option || userFilter.first_name || userFilter.last_name || userFilter.email || userFilter.phone) {
            fetchObject = {
                ...fetchObject,
                first_name: userFilter.first_name,
                last_name: userFilter.last_name,
                email: userFilter.email,
                phone: userFilter.phone,
            };
        }

        if (dateFilter.option || dateFilter.from || dateFilter.to) {
            if (dateFilter.option === 'between') {
                let dateRange = [dateFilter.from, dateFilter.to];
                fetchObject.created_at = dateRange;
            } else {
                fetchObject.created_at = dateFilter.from ?? dateFilter.to;
            }
        }

        setIsLoading(true);

        props.indexDistributorClient(fetchObject).then(() => {
            setIsLoading(false);
        });
    };

    const handleFilterOptionChange = (opt, e) => {
        let id = e.target.selectedOptions[0].id;
        if (id === '0') {
            id = null;
        }
        getFilterSetter(opt)({ ...getFilter(opt), option: id });
    };

    const getFilterSetter = (opt) => {
        if (opt === 'date') {
            return setDateFilter;
        } else if (opt === 'user') {
            return setUserFilter;
        }
    };

    function handleInputChange(event, opt) {
        if (opt === 'user') {
            setUserFilter({
                ...userFilter,
                [event.target.name]: event.target.value,
            });
        }
    }

    function renderUserFilter(opt) {
        let filter = getFilter(opt);
        return (
            <div className="flex flex-col items-center">
                <div className="w-full mb-2">
                    <BasicSelect id="user-filter" name="user-filter" defaultText="Selecciona una opción" onChange={(e) => handleFilterOptionChange(opt, e)} value={filter.option} style={{ margin: 0, width: '100%' }}>
                        <option id={'name'}>Nombre</option>
                        <option id={'email'}>Correo Electrónico</option>
                        <option id={'phone'}>Teléfono</option>
                    </BasicSelect>
                </div>
                <div className="flex flex-column">
                    {filter.option === 'name' && (
                        <>
                            <div className="mb-1">
                                <MuiTextField name="first_name" value={filter.first_name} onChange={(e) => handleInputChange(e, opt)} label="Nombre" />
                            </div>
                            <MuiTextField name="last_name" value={filter.last_name} onChange={(e) => handleInputChange(e, opt)} label="Apellidos" />
                        </>
                    )}
                    {filter.option === 'phone' && <MuiTextField name="phone" value={filter.phone} onChange={(e) => handleInputChange(e, opt)} label="Teléfono" />}
                    {filter.option === 'email' && <MuiTextField name="email" value={filter.email} onChange={(e) => handleInputChange(e, opt)} label="Correo Electrónico" />}
                </div>
            </div>
        );
    }

    function renderDateFilter() {
        return (
            <div className="flex flex-col items-center">
                <div className="w-full mb-2">
                    <BasicSelect id="date-filter" name="date-filter" defaultText="Selecciona una opción" onChange={(e) => handleFilterOptionChange('date', e)} value={dateFilter.option} style={{ margin: 0, width: '100%' }}>
                        <option id={'equals'}>es igual a</option>
                        <option id={'between'}>entre</option>
                        <option id={'after'}>es posterior a</option>
                        <option id={'before'}>es anterior a</option>
                    </BasicSelect>
                </div>
                <div className="flex">
                    {['between', 'equals', 'after'].includes(dateFilter.option) && (
                        <MuiDatePicker
                            label="Inicio"
                            value={dateFilter.from}
                            onChange={(date) =>
                                setDateFilter({
                                    ...dateFilter,
                                    from: date.format('DD/MM/YYYY'),
                                })
                            }
                        />
                    )}
                    {['between'].includes(dateFilter.option) && <div className="flex mx-2 flex-1 justify-center items-center">y</div>}
                    {['between', 'before'].includes(dateFilter.option) && (
                        <MuiDatePicker
                            label="Fin"
                            value={dateFilter.to}
                            onChange={(date) =>
                                setDateFilter({
                                    ...dateFilter,
                                    to: date.format('DD/MM/YYYY'),
                                })
                            }
                        />
                    )}
                </div>
            </div>
        );
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <Page title="Mis clientes">
            <MainWrapper title="Listado" actionButton={true} showSearch={false} onActionClick={() => setIsModalOpen(!isModalOpen)}>
                <div className="flex flex-row" style={{ paddingTop: 25, paddingBottom: 25 }}>
                    <FilterButton name="Fecha" resultText={getFilterResultText('date')} onClose={() => onFilterClose('date')} onRemove={() => resetFilter('date')} onConfirm={() => handleFilterConfirm('date')} disabled={!dateFilter.option}>
                        {renderDateFilter()}
                    </FilterButton>
                    <FilterButton name="Usuario" resultText={getFilterResultText('user')} onClose={() => onFilterClose('user')} onRemove={() => resetFilter('user')} onConfirm={() => handleFilterConfirm('user')} disabled={!userFilter.option}>
                        {renderUserFilter('user')}
                    </FilterButton>
                </div>
                <ClientTable clients={data?.data?.clients} isLoading={isLoading} user={props.user.data} onClickUser={onClickUser} pageSize={data.meta?.count} currentPage={currentPage} totalPages={data.meta?.total_pages} handlePageChange={handlePageChange} />
            </MainWrapper>
            <ModalClientCreate show={isModalOpen} toggleModal={() => setIsModalOpen(!isModalOpen)} user={props.user.data} />
            <ModalSelectedClient client={selectedClient} show={isClientModalOpen} user={props.user.data} closeModal={() => setIsClientModalOpen(false)} setSelectedOrderId={setSelectedOrderId} />
            <OrderViewerModal
                show={!!selectedOrderId}
                toggleModal={() => {
                    setSelectedOrderId(false);
                }}
                orderId={selectedOrderId}
            />
        </Page>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    distributor: state.distributor,
});

const mapDispatchToProps = {
    indexDistributorClient,
    createFlashMessageRequest,
    removeUserStoreCredits,
    changeUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(DistributorClients);
