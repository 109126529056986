import { styled } from '@mui/material/styles';

import { PaginationItem, TableContainer } from '@mui/material';
import Pagination from '@mui/material/Pagination';

export const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  width: "100%",
  height: "70vh",
  color: "white",
  backgroundColor: "#24344d",
  border: "1px solid rgb(46,63,91)",
  '& .MuiDataGrid-columnsContainer': {
    border: "1px solid rgb(46,63,91)",
  },

  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: "1px solid rgb(46,63,91)",
  },

  '& .MuiDataGrid-root': {
    border: "1px solid rgb(46,63,91)",
  },


}))
;

export const StyledPagination = styled(Pagination)(({theme}) => ({
  color: "white",
}))

export const StyledPaginationItem = styled(PaginationItem)(({theme}) => ({
  color: "white",
  borderColor: "rgb(46,63,91)",
}))
