import React, { useState, useEffect } from 'react';
import ClientItem from './../components/clientItem';

const ClientSearchResults = (props) => {
    return (
        <div className="flex flex-1 flex-column justify-start h-72 w-full -m-2 -mr-4">
            <div className="w-full overflow-y-scroll h-0 flex-auto rounded-xl p-2" style={{ backgroundColor: 'hsl(217, 36%, 22%)' }}>
                {!!props.results.length && props.results.map((client) => <ClientItem key={client.id} attributes={client.attributes} client={client} selected={props.selected === client.attributes.id} onSelect={props.onSelect} selectAllClient={props.selectAllClient} />)}
            </div>
        </div>
    );
};

export default ClientSearchResults;
