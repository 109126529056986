import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { createFlashMessageRequest } from 'actions/alertActions';
import { logoutUserRequest } from 'actions/userActions';
import { API_BASE_URL } from 'actions/types';

import { DefaultHeader, ActionButtonList, HeaderButton, NotificationButtonList } from 'components/Header';
import { faBars, faUser, faHome } from '@fortawesome/free-solid-svg-icons';

import { DistributorCodeContainer } from './style';

const Header = (props) => {
    const [isMembershipActive, setIsMembershipActive] = useState(false);

    useEffect(() => {
        if (props.user.data.type && props.user.data.attributes.role === 'distributor_inactive') {
            createFlashMessageHandler('info', 'Tu cuenta se encuentra actualmente inactiva. Por favor activa tu cuenta pagando tu membresía.', 0);
        }
    }, [props.user.data]);

    useEffect(() => {
        if (props.membership?.id) {
            setIsMembershipActive(props.membership.state === 'active');
        }
    }, [props.membership]);

    const handleLogout = () => {
        props.logoutUserRequest().then(() => {
            createFlashMessageHandler('info', 'Se ha cerrado sesión correctamente', 1500);
        });
    };

    const createFlashMessageHandler = (variant, message, closeTime) => {
        props.createFlashMessageRequest({
            closeTime,
            variant,
            message,
        });
    };

    const getMembershipStatusText = () => {
        if (props.user.isAdmin) {
            return 'ADMIN';
        }
        return isMembershipActive ? 'ACTIVO' : 'INACTIVA';
    };

    return (
        <DefaultHeader headerText="Store">
            <ActionButtonList>
                {props.user.isLoggedIn ? <HeaderButton icon={faBars} type="toggleLeftSidebar" /> : <HeaderButton icon={faHome} type="link" to={API_BASE_URL + '/en'} />}
                {/* <HeaderButton icon={faExpand}/> */}
                {/* <HeaderButton icon={faSearch}/> */}
            </ActionButtonList>

            {props.user.data.attributes?.role === 'distributor' && (
                <DistributorCodeContainer>
                    <b>Código: </b>
                    {props.user.data.attributes?.distributor_discount_code}
                </DistributorCodeContainer>
            )}

            <NotificationButtonList>
                {props.user.isLoggedIn && (
                    <HeaderButton
                        type="label"
                        notificationType={isMembershipActive ? 'alert' : 'warning'}
                        text={getMembershipStatusText()}
                        dropdownText={isMembershipActive ? `Felicidades! Tu membresía se encuentra activa hasta el día ${props.membership.expiration_date}` : 'Tu cuenta se encuentra actualmente inactiva. Por favor activa tu cuenta pagando tu membresía.'}
                        title="Estado de tu membresía"
                        style={{
                            backgroundColor: isMembershipActive || props.user.isAdmin ? '#FE9F00' : '#fff',
                            borderRadius: '5px',
                            fontSize: '15px',
                            fontWeight: '800',
                            color: isMembershipActive || props.user.isAdmin ? 'white' : 'black',
                        }}
                    />
                )}

                {props.user.isLoggedIn && <HeaderButton icon={faUser} type="profile" onLogoutClick={handleLogout} />}
            </NotificationButtonList>
        </DefaultHeader>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    membership: state.subscription.membership,
});

export default connect(mapStateToProps, { logoutUserRequest, createFlashMessageRequest })(Header);
