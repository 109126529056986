import React, {useEffect, useState} from 'react';
import {Page, MainWrapper} from 'components/Body';
import {connect} from 'react-redux';
import {indexDistributorMoney} from 'actions/distributorActions';
import {Tab, TabGroup} from 'components/Navs';
import ComissionsTable from './comissionsTable';
import ScheduledPaymentsTable from './scheduledPaymentsTable';

const DistributorMoney = (props) => {
  const [selectedTab, setSelectedTab] = useState("comissions");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    props.indexDistributorMoney().then(res => {
      setIsLoading(false);
    })
  }, [])

  return (
    <Page title="Mi dinero">
      <MainWrapper
        title="Listado"
        actionButton={false}
        showSearch={false}
        onActionClick={() => {}}
      >
        <TabGroup>
          <Tab 
            id="comissions" 
            onClick={setSelectedTab}
            active={selectedTab}>Comisiones</Tab>
          <Tab 
            id="scheduled" 
            onClick={setSelectedTab}
            active={selectedTab}>Pagos Pendientes</Tab>
          {/* <Tab 
            id="todos" 
            onClick={setSelectedTab}
            active={selectedTab}>Todos</Tab> */}
        </TabGroup>
        {selectedTab === "comissions" &&
          <ComissionsTable 
            isLoading={isLoading}
            comissions={props.distributor.comissions}
          />
        }
        {selectedTab === "scheduled" &&
          <ScheduledPaymentsTable 
            isLoading={isLoading}
            scheduledPayments={props.distributor.scheduledPayments}
          />
        }
      </MainWrapper>
    </Page>
  )
} 

const mapStateToProps = state => ({
  user: state.user,
  distributor: state.distributor
})

export default connect(mapStateToProps, {indexDistributorMoney})(DistributorMoney);
