import { requestBuilder, simpleRequestBuilder } from 'utils/actions/requests';

import { API_BASE_URL, POS_SELECT_CLIENT, POS_UNSELECT_CLIENT, POS_SET_CLIENT, POS_SET_CLIENT_NETWORK, POS_UPDATE_CLIENT, POS_CREATE_CLIENT } from '../types';

const CLIENTS_API_URL = API_BASE_URL + '/es/app/api/v1/clients';

export const searchClients = (search_params) => async (dispatch) => {
    return simpleRequestBuilder('post', CLIENTS_API_URL + `/search`, { search_params });
};

export const getClientById = (client_id) => async (dispatch) => {
    return requestBuilder('get', CLIENTS_API_URL + `/${client_id}`, {}, dispatch, POS_SET_CLIENT);
};

export const selectClient = (clientId) => async (dispatch) => {
    return dispatch({
        type: POS_SELECT_CLIENT,
        payload: clientId,
    });
};

export const updateClient = (clientId, clientData) => async (dispatch) => {
    return requestBuilder();
};

export const createClient = (clientData) => async (dispatch) => {
    return requestBuilder();
};

export const unselectClient = () => (dispatch) => {
    return dispatch({
        type: POS_UNSELECT_CLIENT,
    });
};

export const searchClientNetwork = (params) => async (dispatch) => {
    // params = {user_id: num, distributors_only: bool, search_params: {...}}
    let data = await simpleRequestBuilder('post', API_BASE_URL + '/es/app/api/v1/users/distributor/networks/search', params);

    return dispatch({
        type: POS_SET_CLIENT_NETWORK,
        payload: data,
    });
};
