import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createUserPaymentPlan } from 'actions/paymentPlanActions';
import { createFlashMessageRequest } from 'actions/alertActions';
import { DefaultModal } from 'components/Modal';
import { BasicInput, BasicSelect, Title } from 'components/Form';
import { TwoFieldsContainer } from './style';
import { onlyNumbers, TIMEOPTIONS } from 'utils/formatters';

function CreatePaymentPlanModal(props) {
    const [fieldsVerified, setFieldsVerified] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        interval_count: '',
        interval: '',
        total_intervals: '',
        upfront_percentage: '',
        starts_paying: '',
        pay_after_count: '',
        pay_after_interval: '',
        pay_after_date: '',
    });

    const [inputValidators, setInputValidators] = useState({
        name: {
            required: true,
            length: {
                min: 3,
                max: 70,
            },
            errors: {
                valid: false,
            },
        },
        pay_after_date: {
            after_today: true,
            errors: {
                valid: true,
            },
        },
        upfront_percentage: {
            isPositive: true,
            greaterThan: 0,
            errors: {
                valid: true,
            },
        },
    });

    useEffect(() => {
        let upfront_validator = {
            isPositive: true,
            greaterThan: 0,
            errors: { valid: true },
        };

        if (formData.starts_paying === 'checkout') {
            upfront_validator = {
                isPositive: true,
                greaterThan: 0,
                required: true,
                errors: { valid: false },
            };
        }

        setInputValidators((prev) => ({
            ...prev,
            pay_after_date: {
                ...prev.pay_after_date,
                errors: { valid: true },
            },
            upfront_percentage: upfront_validator,
        }));
        setFormData((prev) => ({
            ...prev,
            pay_after_date: '',
            pay_after_count: '',
            pay_after_interval: '',
        }));
    }, [formData.starts_paying]);

    useEffect(() => {
        let isValid = Object.values(inputValidators).every((field) => field.errors.valid);
        if (isValid !== fieldsVerified) {
            setFieldsVerified(isValid);
        }
    }, [inputValidators]);

    function handleInputChange(e) {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value,
        });
    }

    function handleNumberInputChange(e) {
        e.target.value = onlyNumbers(e.target.value);
        handleInputChange(e);
    }

    function handleCreate() {
        if (fieldsVerified) {
            setIsLoading(true);
            props.createUserPaymentPlan(props.user_id, formData).then((res) => {
                props.setShow(false);
                setIsLoading(false);
                props.createFlashMessageRequest({
                    closeTime: 1500,
                    variant: 'success',
                    message: `Se ha creado el plan de pago con éxito.`,
                });
            });
        }
    }

    const sendFieldValidation = (e, isValid) => {
        let id = e.target.id || e.target.name;
        setInputValidators({
            ...inputValidators,
            [id]: {
                ...inputValidators[id],
                errors: {
                    ...inputValidators[id].errors,
                    valid: isValid,
                },
            },
        });
    };

    return (
        <DefaultModal show={props.show} onHide={() => props.setShow(false)} title={'Crea un plan de pago'} onSave={handleCreate} saveButtonText="Crear Plan" disabledSaveButton={!fieldsVerified} isLoading={isLoading}>
            <div className="mb-5">
                <BasicInput label="Nombre" id="name" name="name" onChange={handleInputChange} value={formData.name} validators={inputValidators.name} sendFieldValidation={sendFieldValidation} />
            </div>
            <div className="mb-5">
                <Title>Configura los plazos de pago</Title>
                <TwoFieldsContainer>
                    <BasicInput label="Cantidad" id="interval_count" name="interval_count" type="number" onChange={handleNumberInputChange} value={formData.interval_count} />
                    <BasicSelect id="interval" name="Intervalo" value={formData.interval} onChange={handleInputChange} title={'Tiempo'} type={'Text'} defaultText={'Seleccióna una opción'}>
                        {Object.entries(TIMEOPTIONS).map(([k, v]) => (
                            <option value={k}>{v}</option>
                        ))}
                    </BasicSelect>
                </TwoFieldsContainer>
                <BasicInput label="Pagos totales" id="total_intervals" name="total_intervals" type="number" onChange={handleNumberInputChange} value={formData.total_intervals} />
            </div>
            <div className="mb-3">
                <Title>Configura la cantidad del primer pago</Title>
                <BasicInput label="Anticipo (%)" id="upfront_percentage" name="upfront_percentage" onChange={handleInputChange} type="number" value={formData.upfront_percentage} validators={inputValidators.upfront_percentage} sendFieldValidation={sendFieldValidation} />
            </div>
            <div className="mb-3">
                <Title>Configura el inicio del primer pago</Title>
                <BasicSelect id="starts_paying" name="starts_paying" value={formData.starts_paying} onChange={handleInputChange} title="Inicio del primer pago" type={'Text'} defaultText={'Seleccióna una opción'}>
                    <option key={1} value={'checkout'}>
                        {'Al Checkout'}
                    </option>
                    <option key={2} value={'time'}>
                        {'Por Tiempo'}
                    </option>
                    <option key={3} value={'date'}>
                        {'Por Fecha'}
                    </option>
                </BasicSelect>

                {formData.starts_paying === 'time' && (
                    <TwoFieldsContainer>
                        <BasicInput label="Cantidad" id="pay_after_count" name="pay_after_count" type="number" onChange={handleInputChange} value={formData.pay_after_count} />
                        <BasicSelect id="pay_after_interval" name="pay_after_interval" value={formData.pay_after_interval} onChange={handleInputChange} title="Tiempo" type={'Text'} defaultText={'Seleccióna una opción'}>
                            {Object.entries(TIMEOPTIONS).map(([k, v]) => (
                                <option key={k} value={k}>
                                    {v}
                                </option>
                            ))}
                        </BasicSelect>
                    </TwoFieldsContainer>
                )}
                {formData.starts_paying === 'date' && <BasicInput label="Empieza a pagar en" type="date" max={'2030-12-31'} id="pay_after_date" name="pay_after_date" placeholder={'dd-mm-yyyy'} onChange={handleInputChange} value={formData.pay_after_date} validators={inputValidators.pay_after_date} sendFieldValidation={sendFieldValidation} />}
            </div>
        </DefaultModal>
    );
}

const mapStateToProps = (state) => ({
    paymentPlans: state.paymentPlan.list,
});

const mapDispatchToProps = {
    createUserPaymentPlan,
    createFlashMessageRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePaymentPlanModal);
