import React from 'react';
import {DataTable, DataTbody, DataThead, Datum, Header, Row} from '../../../components/Table';
import UserRow from './userRow';

const DistributorInviteUserTable = (props) => {
  return (
    <DataTable isLoading={props.isLoading}>
      <DataThead>
        <Header width={225.6}>Nombre</Header>
        <Header width={225.6}>Apellidos</Header>
        <Header>Correo Electrónico</Header>
        <Header>Fecha de Registro</Header> 
      </DataThead>
      <DataTbody>
        {!props.isLoading && props.users &&
          props.users.map((client, index) => {
            return <UserRow onClick={props.onClickUser} odd={!index%2} client={client}/>
        })}
      </DataTbody>
    </DataTable>
  )
}

export default DistributorInviteUserTable;