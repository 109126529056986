import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { loginUserRequest, loginUserWithExistingToken, createNewUserRequest, resetUserPassword } from '../../actions/userActions';
import { FullPageSpinner } from '../../components/Spinners';

import { createFlashMessageRequest } from '../../actions/alertActions';

import { getUserMembershipData } from '../../actions/subscriptionActions';

import { APP_TITLE } from '../../actions/types';

import { LoginContainer, AuthContainer, SignInHeader, AuthContent, LogoContainer, LogoBorder, LogoImg, PromoContainer, PromoImg, PromoLayer, PromoTextLayer, PromoTextInstructions } from './style';

import { useHistory } from 'react-router-dom';

import UniqueLogo from 'assets/images/Uniquelogo.png';
import BannerImg from 'assets/images/_DSC9302.jpg';

import LoginPage from './login';
import RegisterPage from './register';
import ForgotPage from './forgot';

const AuthPage = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [usePage, setUsePage] = useState('');
    const history = useHistory();
    const [locationKeys] = useState([]);

    useEffect(() => {
        return history.listen((location) => {
            // if(history.action === 'PUSH') {
            //   setLocationKeys([ location.key ]);
            // }

            if (history.action === 'POP') {
                setUsePage(location.pathname);
            }
        });
    }, [locationKeys]);

    useEffect(() => {
        if (props.user.isLoggedIn) {
            userHasAlreadySignedIn();
        } else if (!!sessionStorage.getItem('sessionToken')) {
            props.loginUserWithExistingToken().then((res) => {
                if (res && res.error && res.error.status === 401) {
                    setIsLoading(false);
                } else {
                    userHasAlreadySignedIn();
                }
            });
        } else {
            setIsLoading(false);
        }
        setUsePage(props.location.pathname);
    }, [usePage]);

    const userHasAlreadySignedIn = () => {
        props.history.push('/');
    };

    const createFlashMessageHandler = (variant, message, closeTime) => {
        props.createFlashMessageRequest({
            closeTime,
            variant,
            message,
        });
    };

    const handleLogin = (user) => {
        setIsLoading(true);

        let credentials = {
            email: user.email,
            password: user.password,
            rememberMe: user.rememberMe || false,
        };
        props.loginUserRequest(credentials).then((isLoggedIn) => {
            setIsLoading(false);
            if (isLoggedIn) {
                createFlashMessageHandler('success', 'Se ha iniciado sesión correctamente', 1500);
                props.history.push(props.history.location.state ? props.history.location.state.from : '/');
            }
        });
    };

    const handlePasswordReset = (email) => {
        if (email.length > 9) {
            createFlashMessageHandler('success', 'Se ha enviado un correo con las instrucciones para cambiar la contraseña.', 1500);
            props.resetUserPassword(email);
        }
    };

    const handleRegister = async (user) => {
        let res = await props.createNewUserRequest(user);
        if (res.created) {
            handleLogin(user);
        }
    };

    return (
        <LoginContainer>
            {isLoading && <FullPageSpinner />}
            <PromoContainer>
                <PromoLayer>
                    <PromoTextLayer>
                        <h1>
                            ¡Comienza a ganar <b>Ahora!</b>
                        </h1>
                        <p>Invierte $2500 y recibe $5000 de mercancía en tu primer pedido.</p>
                        <h2>
                            <b>Inscribete en Línea</b>
                        </h2>
                        <div>Es muy fácil, sigue estos pasos</div>
                        <PromoTextInstructions>
                            <div>1.- Ingresa en este enlace y completa tus datos.</div>
                            <div>2.- Realiza tu pago de $2500 para recibir $5000 en productos.</div>
                            <div>3.- Recibe tu confirmación y comienza a generar ganancias de inmediato.</div>
                        </PromoTextInstructions>
                    </PromoTextLayer>
                </PromoLayer>
                <PromoImg src={BannerImg} />
            </PromoContainer>

            <AuthContainer>
                <SignInHeader>{APP_TITLE}</SignInHeader>
                <LogoContainer>
                    <LogoBorder>
                        <LogoImg src={UniqueLogo} height="90" width="90" alt="logo" />
                    </LogoBorder>
                </LogoContainer>
                <AuthContent>
                    {usePage === '/login' && <LoginPage handleLogin={handleLogin} setUsePage={setUsePage} usePage={usePage} />}
                    {usePage === '/register' && <RegisterPage handleRegister={handleRegister} setUsePage={setUsePage} usePage={usePage} />}
                    {usePage === '/forgot' && <ForgotPage setUsePage={setUsePage} usePage={usePage} handlePasswordReset={handlePasswordReset} />}
                </AuthContent>
            </AuthContainer>
        </LoginContainer>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = {
    loginUserRequest,
    createFlashMessageRequest,
    loginUserWithExistingToken,
    createNewUserRequest,
    getUserMembershipData,
    resetUserPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
