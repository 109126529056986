import {
  INDEX_SUBSCRIPTION_PLANS,
  CANCEL_USER_PLAN_SUBSCRIPTION,
  UPDATE_USER_SUBSCRIPTIONS,
  UPDATE_USER_MEMBERSHIP,
  ADD_USER_MEMBERSHIP,
} from '../actions/types';

const initialState = {
  plans: [],
  membership: {
    subscriptions: [],
  },
  memberships: []
}

export default function(state = initialState, action) {
  switch(action.type) {
    case INDEX_SUBSCRIPTION_PLANS:
      return {
        ...state,
        plans: action.payload.plans,
      }
    case UPDATE_USER_SUBSCRIPTIONS:
      return {
        ...state,
      }
    case UPDATE_USER_MEMBERSHIP:
      return {
        ...state,
        membership: {
          ...state.membership,
          ...action.payload.membership,
        },
      }
    case ADD_USER_MEMBERSHIP:
      if (!action.payload.membership) return state;

      return {
        ...state,
        memberships: [
          ...filterMemberships(state.memberships, action.payload.membership?.id),
          action.payload.membership,
        ].flat().filter(Boolean)
      }
    default:
      return state;
  }
}

const filterMemberships = (memberships, id) => (
  memberships.filter((membership) => membership.id !== id)
)
