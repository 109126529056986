import React from 'react';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ExclamationIcon } from './style';
import { InformationAlert } from '../Alert';

const BasicErrorField = (props) => {
    return <div className="items-center justify-center">{props.showErrorField && <ExclamationIcon icon={faExclamationCircle} style={props.style} onClick={() => props.setShowAlert(!props.showAlert)} />}</div>;
};

export default BasicErrorField;
