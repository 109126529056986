import React from "react";
import {
  ChangeItemContainer,
  ChangeItemValue,
  ChangeItemIcon,
  ChangeItemTitle
} from "./style";

const ChangeItem = ({children, title, color, icon}) => {
  return (
    <ChangeItemContainer>
      <ChangeItemTitle>
        {title}
      </ChangeItemTitle>
      <ChangeItemValue>
        {children}
      </ChangeItemValue>
      <ChangeItemIcon color={color} icon={icon}/>
    </ChangeItemContainer>
  )
}

export default ChangeItem;
