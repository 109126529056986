import React, {useEffect, useState} from 'react';
import Moment from 'react-moment';
import {
  PageTitle,
  PageSubtitle,
  ButtonContainer,
  PageSubtitleContainer,
  CancelMembershipContainer,
} from './style';
import MembershipSettings from './MembershipSettings';
import {DefaultButton} from '../../../../components/Button';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';

const MembershipPage = (props) => {
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState();
  const [selectedChargeCardId, setSelectedChargeCardId] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(2500);
  const [mayPurchase, setMayPurchase] = useState(false);

  useEffect(() => {
    props.setIsLoading(true);
    Promise.all([
      props.getSubscriptionPlans(),
      props.getUserMembershipData()
    ]).then(() => {
      props.setIsLoading(false);
    })
  }, [])

  useEffect(() => {
    mayActivateMembership();
  }, [props.subscription, paymentAmount, selectedChargeCardId])

  const mayActivateMembership = () => {
    let mayActivate = !isMembershipPaid() &&
                   selectedChargeCardId &&
                   isPaymentAmountInRange();

    if (mayActivate !== mayPurchase) {
      setMayPurchase(!!mayActivate)
    }
  }

  const isPaymentAmountInRange = () => {
    return paymentAmount >= 2500 && paymentAmount <= 15000;
  }

  const handleUserSubscription = () => {
    Swal.fire({
      show: true,
      title: "Por favor confirma esta operación",
      text: "Al confirmar ésta operación, se realizara un cobro a tu tarjeta de crédito seleccionada y podrás comenzar a disfrutar de los beneficios de ser un distribuidor Unique Brother",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Si, Confirmo',
      confirmButtonColor: '#379824',
      cancelButtonText: "Regresar"
    }).then((result) => {
      if (result.isConfirmed) {
        props.setIsLoading(true);
        props.paySubscription({amount: paymentAmount})
        .then(res => {
          if (res.status >= 400) {
            Swal.fire(
              'Error al procesar el pago!',
              res.data.error.message,
              'error'
            )
          }
          if (res.status === 200) {
            Swal.fire(
              'Suscripción Iniciada!',
              res.data.message,
              'success'
            )
          }
          props.setIsLoading(false);
        })
      }
    })
  }


  const handleSubscriptionCancel = () => {
    Swal.fire({
      show: true,
      title: "Estas seguro de la cancelación?",
      text: "Al cancelar perderás todos los beneficios de distribuidor. Pero podrás activarla en cualquier otro momento.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Si, deseo cancelar',
      cancelButtonText: "Regresar",
      confirmButtonColor: 'red'
    }).then((result) => {
      if (result.isConfirmed) {
        props.setIsLoading(true);
        props.cancelUserSubscription(props.subscription.membership.id)
        .then(res => {
          if (res.status === 200) {
            Swal.fire(
              'Suscripción Cancelada!',
              res.data.message,
              'info'
            )
            props.getUserMembershipData()
          }
          props.setIsLoading(false);
        }).catch(res => {
          debugger;
        })
      }
    })
  }


  const isMembershipActive = () => {
    return props.subscription.membership?.state === "active"
  }

  const isMembershipPaid = () => {
    return isMembershipActive() ||
      props.subscription.membership?.subscriptions &&
      props.subscription.membership?.subscriptions.some(
        sub => (sub.state === "paid")
      )
  }

  const getHeaderMessage = () => {
    return isMembershipActive() ?
        ("Plan seleccionado") : ( isMembershipPaid() ? 
          ("Tu membresía ya está pagada, no es necesario hacer nada más.") : ("Selecciona tu plan")
        )
  }

  const getSubtitleMessage = () => {
    return isMembershipActive() ?
            `Tu membresía se activó exitosamente el día ` : isMembershipPaid() ?
            "Tu Membresía se pagó el día " :
            (
              <>
                <h5>Promoción!</h5>
                <p>
                  Con tu inscripción podrás comprar el <b>doble de productos</b>!
                </p>
                <p>
                  Paga ahora y recibe un bono de tienda que te permite obtener el doble en mercancía!
                </p>
              </>
            )
  }

  const getSubscriptionButtonMessage = () => {
    return isMembershipActive() ? "Membresía Activada" :
    isMembershipPaid() ?  "Membresía pagada" : "Comenzar mi subscripción"
  }

  return (
    <div style={{position: "relative"}}>
      {isMembershipActive() &&
        <CancelMembershipContainer>
          <Button
            variant="secondary"
            onClick={handleSubscriptionCancel}
          >Cancelar Membresía</Button>
        </CancelMembershipContainer>
      }

      <PageSubtitleContainer>
        {getSubtitleMessage()}
        {isMembershipActive() &&
          <Moment
            date={props.subscription.membership.start_date}
            format="YYYY/MM/DD"
            locale="es-mx"
          />
        }
        { !isMembershipActive() && isMembershipPaid() &&
          <Moment
            date={props.subscription.membership.purchase_date}
            format="YYYY/MM/DD"
            locale="es-mx"
          />
        }
      </PageSubtitleContainer>

      <MembershipSettings
        selectedSubscriptionId={selectedSubscriptionId}
        setSelectedSubscriptionId={setSelectedSubscriptionId}
        setSelectedChargeCardId={setSelectedChargeCardId}
        setIsModalAddPaymentMethodOpen={props.setIsModalAddPaymentMethodOpen}
        setIsModalUpdateChargeCardOpen={props.setIsModalUpdateChargeCardOpen}
        paymentAmount={paymentAmount}
        setPaymentAmount={setPaymentAmount}
      />

      <ButtonContainer>
        <DefaultButton
          style={{
            padding: '10px',
            fontSize: '16px',
            width: '100%',
          }}
          onClick={ handleUserSubscription }
          disabled={ !mayPurchase }
        >{ getSubscriptionButtonMessage() }</DefaultButton>
      </ButtonContainer>
    </div>
  )
}

export default MembershipPage;
