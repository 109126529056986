import React from "react";

function Avatar({ url="" }) {
  return (
    <div className="bg-gray-300 self-stretch aspect-square rounded-lg border-1 border-white overflow-hidden justify-center">
      <img src={url} alt="avatar" className="w-full h-full" />
    </div>
  )
}

export default Avatar;
