import React, { useState, useEffect } from 'react';
import { AuthHeader } from '../style';
import { BasicInput, BasicCheckbox } from '../../../components/Form';
import { SubmitButton } from '../../../components/Button';
import ActionLinks from '../action_links';

const RegisterPage = (props) => {
    const [fieldsVerified, setFieldsVerified] = useState(false);
    const [termsChk, setTermsChk] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [formInputs, setFormInputs] = useState({
        firstName: '',
        lastName: '',
        telephone: '',
        email: '',
        telephone: '',
        password: '',
        password_confirmation: '',
    });
    const [inputValidators, setInputValidators] = useState({
        firstName: {
            required: true,
            length: {
                min: 3,
                max: 30,
            },
            errors: {
                valid: false,
            },
        },
        lastName: {
            required: true,
            length: {
                min: 3,
                max: 30,
            },
            errors: {
                valid: false,
            },
        },
        telephone: {
            required: true,
            isPhoneNumber: true,
            errors: {
                valid: false,
            },
        },
        email: {
            required: true,
            isEmail: true,
            errors: {
                valid: false,
            },
        },
        password: {
            required: true,
            length: {
                min: 6,
                max: 15,
            },
            errors: {
                valid: false,
            },
        },
        password_confirmation: {
            required: true,
            equals: '',
            errors: {
                valid: false,
            },
        },
    });

    useEffect(() => {
        let isValid = Object.values(inputValidators).every((field) => field.errors.valid);
        isValid = isValid && termsChk;
        if (isValid !== fieldsVerified) {
            setFieldsVerified(isValid);
        }
    }, [inputValidators, termsChk]);

    useEffect(() => {
        setInputValidators({
            ...inputValidators,
            password_confirmation: {
                ...inputValidators.password_confirmation,
                equals: formInputs.password,
            },
        });
    }, [formInputs.password]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            props.onClick();
        }
    };

    const handleInputChange = (e) => {
        let id = e.target.id;
        let val = e.target.value;

        if (val === ' ') {
            val = '';
        }

        setFormInputs({ ...formInputs, [id]: val });
    };

    const handleSubmit = () => {
        if (fieldsVerified) {
            props.handleRegister(formInputs);
        }
    };

    const sendFieldValidation = (e, isValid) => {
        let id = e.target.id || e.target.name;
        setInputValidators({
            ...inputValidators,
            [id]: {
                ...inputValidators[id],
                errors: {
                    ...inputValidators[id].errors,
                    valid: isValid,
                },
            },
        });
    };

    return (
        <div>
            <AuthHeader>Regístrate</AuthHeader>
            <BasicInput
                value={formInputs.firstName}
                title={'Nombres'}
                placeholder="¿Cuál es tu nombre?"
                id="firstName"
                style={{ float: 'left' }}
                onChange={(e) => {
                    handleInputChange(e);
                }}
                sendFieldValidation={sendFieldValidation}
                validators={inputValidators.firstName}
            />

            <BasicInput
                value={formInputs.lastName}
                title="Apellidos"
                id="lastName"
                placeholder="¿Cuál es tu apellido?"
                style={{ float: 'left' }}
                onChange={(e) => {
                    handleInputChange(e);
                }}
                sendFieldValidation={sendFieldValidation}
                validators={inputValidators.lastName}
            />
            <BasicInput
                value={formInputs.telephone}
                title={'Teléfono'}
                id="telephone"
                type="text"
                placeholder="+393 1234567"
                style={{ float: 'left' }}
                onChange={(e) => {
                    handleInputChange(e);
                }}
                sendFieldValidation={sendFieldValidation}
                validators={inputValidators.telephone}
            />
            <BasicInput
                value={formInputs.email}
                id="email"
                title={'Correo Electrónico'}
                placeholder="usuario@hotmail.com"
                style={{ float: 'left' }}
                onChange={(e) => {
                    handleInputChange(e);
                }}
                sendFieldValidation={sendFieldValidation}
                validators={inputValidators.email}
            />
            <BasicInput
                placeholder="Contraseña"
                title={'Contraseña'}
                type="password"
                id="password"
                value={formInputs.password}
                style={{ float: 'left' }}
                onChange={(e) => {
                    handleInputChange(e);
                }}
                sendFieldValidation={sendFieldValidation}
                validators={inputValidators.password}
                showPassword={showPassword}
                onClickShowPassword={() => setShowPassword(!showPassword)}
            />
            <BasicInput
                placeholder="Escribe aquí tu misma contraseña."
                title={'Confirmar Contraseña'}
                type="password"
                id="password_confirmation"
                value={formInputs.password_confirmation}
                style={{ float: 'left' }}
                onChange={(e) => {
                    handleInputChange(e);
                }}
                validators={inputValidators.password_confirmation}
                sendFieldValidation={sendFieldValidation}
                onKeyPress={handleKeyPress}
                showPassword={showConfirmPassword}
                onClickShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
            />
            <BasicCheckbox id="terms-conditions" onChange={setTermsChk} checked={termsChk}>
                Acepto los términos y condiciones{' '}
            </BasicCheckbox>

            <div style={{ paddingBottom: '0px' }}>
                <SubmitButton onClick={handleSubmit} disabled={!fieldsVerified}>
                    Iniciar Registro
                </SubmitButton>
            </div>
            <ActionLinks setUsePage={props.setUsePage} usePage={props.usePage} />
        </div>
    );
};

export default RegisterPage;
