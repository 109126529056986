import Styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'; 
import Icon from '@mui/material/Icon';

export const RankCardBodyWrapper = Styled.div`
  flex: 1;
  overflow-y: scroll;
`;

export const RankCardContainer = Styled.div`
  margin: 6px;
  height: 450px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  user-select: none;
  outline: none;
  width: 406px;
  min-width: 405px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 10%);
  border-radius: 6px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-color: white;
`;

export const RankCardHeader = Styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.color || "#20C261"};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

export const RankCardHeaderContent = Styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
`;

export const RankCardBody = Styled.div`
  padding: 6px 18px;
`;

export const RankCardList = Styled.ul`
  margin: 0;
  padding: 18px 18px 24px;
  list-style-type: none;
`;

export const RankCardListItem = Styled.li`
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const RankCardListBullet = Styled(FontAwesomeIcon)`
  color: ${props => props.color};
  align-self: flex-start;
  border-radius: 50%;
  margin-right: 12px;
`;

export const RankCardLockIcon = Styled(Icon)`
  margin-right: 4px;
  height: 24px;
  width: 16px;
  color: white;
`;

export const RankCardBodyTitle = Styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.color || "black"};
  filter: brightness(65%);
`;
