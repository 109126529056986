import React from 'react';
import { LiItem, Anchor, ItemIcon, SpanText, SubmenuAnchor } from './style.js';
import {connect} from 'react-redux';
import { useLocation } from 'react-router-dom';
import {isMobileScreen} from 'utils/screen';
import {toggleLeftSidebarOpen} from 'actions/leftSideBarActions';

const MenuItem = (props) => {
  const redirectTo = () => {
    if (props.isExternal) {
      return window.open(props.link)
    }
  }
  const location = useLocation()

  const handleClick = (e) => {
    e.preventDefault();
    if (isMobileScreen && props.isOpen) {
      props.toggleLeftSidebarOpen();
    }

  }

  return (
    <div>
      <LiItem onClick={handleClick}>
        <Anchor
          active={location.pathname === props.link}
          to={!props.isExternal && props.link}
          onClick={redirectTo}
        >
          <ItemIcon
            icon={props.icon}
            selectedColor={props.selectedColor}
            isSelected={props.link === location.pathname}
          />
          { props.link ?
            (
              <SpanText>
                <SubmenuAnchor to={!props.isExternal && props.link}>
                  {props.text}
                </SubmenuAnchor>
              </SpanText>
            ) :
            <SpanText style={{color: props.selectedColor}}>{props.text}</SpanText>
          }
        </Anchor>
      </LiItem>
      { props.isMenuOpen && props.openMenuText === props.text &&
        <ul>
          {props.children}
        </ul>
      }
    </div>
  )
};
 
const mapStateToProps = state => ({
  isOpen: state.leftSidebarReducer.isOpen,
})

const mapDispatchToProps = {
  toggleLeftSidebarOpen
}


export default connect(mapStateToProps, mapDispatchToProps)( MenuItem );
