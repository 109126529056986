import React from "react";

function TabButton({
  children,
  active=false,
  onClick=()=>{},
}) {

  function getColor() {
    if (active) {
      return "bg-blue-500 border";
    } else {
      return "bg-gray-800";
    }
  }

  return (
    <button
      onClick={onClick}
      className={`${getColor()} p-2 rounded-xl justify-center items-center w-full `}
    >
      <div className="text-white font-base text-base text-center">{children}</div>
    </button>
  )
}

export default TabButton;
