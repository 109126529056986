import Styled, { css } from 'styled-components';
import { mobileScreenSize } from 'utils/screen';

const CommonPersonInfoStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    text-align: left;
`;

const CommonProfileImageStyle = css`
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
`;

const HoverStyle = css`
    background-color: rgb(239, 239, 239);
    border-color: rgb(239, 239, 239);
`;

const SelectedBorder = css`
    ${HoverStyle}
    border-color: #444791 !important;
`;

const ContainerDefaultStyle = css`
    border: 0.2rem solid #fff;
    background: #fff;
    position: relative;
    box-sizing: border-box;
    &:hover {
        ${HoverStyle}
    }
    ${(props) => props.active && SelectedBorder}
`;

export const ReportPersonContainer = Styled.div`
  ${ContainerDefaultStyle}
  height: 6.4rem;
  margin: 0 auto;
  padding: 1rem;
  width: 24rem;
  ${(props) => props.isChildren && 'margin: 0 0 0.6rem 1.2rem;'}
`;

export const PersonCardContainer = Styled.div`
  ${ContainerDefaultStyle}
  position: relative;
  border-bottom: 0.2rem solid #5b5fc7;
  text-align: left;
  width: 31rem;
  max-width: 100%;
  margin: 0 auto;
  @media (max-width: ${mobileScreenSize}px) {
   margin: 0 auto;

  }
`;

export const PersonInfoWrapper = Styled.div`
  ${CommonPersonInfoStyle}
  width: 16.8rem;
  @media (max-width: ${mobileScreenSize}px) {
    

  }
`;

export const PersonCardInfoWrapper = Styled.div`
  ${CommonPersonInfoStyle}
  width: 100%;
  padding: 2rem 2rem;
  @media (max-width: ${mobileScreenSize}px) {
    padding: 0.34rem 0 0 0.7rem;
  }
`;

export const PersonName = Styled.div`
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #242424;
  box-sizing: border-box;
  font-weight: 700;
   @media (max-width: ${mobileScreenSize}px) {
    font-size: 1rem;
  }
`;

export const PersonDetail = Styled.div`
  font-size: 1.2rem;
  line-height: 1.25;
  color: #424242;
  margin-top: 0.2rem;
   @media (max-width: ${mobileScreenSize}px) {
    font-size: 0.8rem;
  }
`;

export const ProfileImage = Styled.div`
  ${CommonProfileImageStyle}
  padding-left: 1rem;
  position: relative;

   @media (max-width: ${mobileScreenSize}px) {
    display: none;
  }
`;

export const PersonCardProfileImage = Styled.div`
  ${CommonProfileImageStyle}
  position: absolute;
  top: 2rem;
  right: 2rem;

  @media (max-width: ${mobileScreenSize}px) {
     display: none;
  }
`;

export const PersonPictureWrapper = Styled.div`
  height: ${(props) => (!!props.size ? props.size : '3rem')};
  width: ${(props) => (!!props.size ? props.size : '3rem')};
`;

export const PersonPicture = Styled.img`
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
  display: block;
  vertical-align: middle;
  border: 0;
  box-sizing: border-box;
  border-radius: 50%;
  overflow: hidden;
  background-clip: content-box;
`;

export const PersonStatus = Styled.div`

`;

export const ActionButtonsWrapper = Styled.div`
  margin-top: 2rem;
  border-top: solid 0.2rem #f3f2f1;
  padding: 0.8rem 0 0.3rem 0.3rem;
  box-sizing: border-box;
  &:hover {
    border-color: white;
  }
  @media (max-width: ${mobileScreenSize}px) {
    margin-top: 1rem;
  }
`;

export const ButtonsContainer = Styled.div`
  display: flex;
  position: relative;
`;

export const VertLine = Styled.div`
  margin-bottom: 3.4rem;
  position: relative;
  &:after {
    bottom: -3.6rem;
    content: "";
    height: 3.4rem;
    left: 50%;
    position: absolute;
    width: 1px;
    background: #bdbdbd;
  }
`;
