import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getPaymentMethods, createPayment } from 'actions/pos/paymentActions';
import { ListCreditCards } from 'actions/creditCardActions';
import Button from '@mui/material/Button';
import { dateFormat } from '../../../../utils/common';
import { CircularProgressSpinner } from 'components/Spinners';
import { ToggleButton, MuiTextField, RadioButton } from 'routes/pos/components';
import { moneyFormatter } from 'utils/formatters';
import { CreateCardForm } from 'components/Form';
import { AttachMoneyOutlined, CreditCardOutlined } from '@mui/icons-material';
import Swal from 'sweetalert2';

const DepositTab = ({ order, user, setReload, ...props }) => {
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
    const [selectedCreditCardId, setSelectedCreditCardId] = useState(null);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('cash');
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({
        amount: 0,
    });

    const PAYMENT_METHODS = {
        cash: {
            name: 'Efectivo',
            type: 'Spree::PaymentMethod::Check',
            icon: <AttachMoneyOutlined sx={{ color: 'white', fontSize: '40px' }} />,
            disabled: !user.isAdmin,
        },
        client_card: {
            name: 'Tarjeta de cliente',
            type: 'Spree::Gateway::StripeGateway',
            icon: <CreditCardOutlined sx={{ color: 'white', fontSize: '40px' }} />,
        },
        distributor_card: {
            name: 'Tarjeta propia',
            type: 'Spree::Gateway::StripeGateway',
            icon: <CreditCardOutlined sx={{ color: 'white', fontSize: '40px' }} />,
        },
        //oxxo: {
        //  name: "Oxxo Pay",
        //  icon: <StoreOutlined sx={{color: "white", fontSize: "40px"}}/>,
        //  disabled: true,
        //},
    };

    useEffect(() => {
        props.getPaymentMethods();
    }, []);

    useEffect(() => {
        if (order.attributes) {
            setIsLoading(true);
            props.ListCreditCards(getPaymentMethodUser()?.id);
        }
    }, [order?.attributes?.user?.id, selectedPaymentMethod]);

    useEffect(() => {
        if (props.creditCards.length) {
            setIsLoading(false);
        }
    }, [props.creditCards]);

    useEffect(() => {
        if (props.paymentMethods && selectedPaymentMethod) {
            let method = props.paymentMethods.find((m) => m.attributes.type === PAYMENT_METHODS[selectedPaymentMethod].type);
            if (method) {
                setSelectedPaymentMethodId(method.id);
            }
        }
    }, [selectedPaymentMethod, props.paymentMethods]);

    const handleInputChange = (e) => {
        if (isNaN(+e.target.value)) return;
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    function getPaymentMethodUser() {
        if (selectedPaymentMethod === 'client_card') {
            return order.attributes.user;
        } else if (selectedPaymentMethod === 'distributor_card') {
            return user.data.attributes;
        }
    }

    function handleSelectPaymentMethod(type) {
        if (selectedPaymentMethod === type) {
            setSelectedPaymentMethod(null);
            return;
        }
        setSelectedPaymentMethod(type);
    }

    async function handlePaymentCreate() {
        if (selectedPaymentMethod === 'cash') {
            await props
                .createPayment({
                    payment_method_id: selectedPaymentMethodId,
                    amount: data.amount,
                    order_id: order.id,
                })
                .then(() => Swal.fire('¡Abono registrado exitosamente!', `Se ha abonado exitosamente a la orden la cantidad de ${data.amount} pesos.`, 'success'))
                .catch((e) => {
                    Swal.fire('¡Error!', e.message, 'error');
                });
        } else {
            await props
                .createPayment({
                    payment_method_id: selectedPaymentMethodId,
                    amount: data.amount,
                    order_id: order.id,
                    source_id: selectedCreditCardId,
                })
                .then(() => Swal.fire('¡Abono registrado exitosamente!', `Se ha abonado exitosamente a la orden la cantidad de ${data.amount} pesos.`, 'success'))
                .catch((e) => {
                    Swal.fire('¡Error!', e.message, 'error');
                });
        }

        setReload(true);
        setData({
            amount: 0,
        });
    }

    function renderCashForm() {
        return (
            <div className="mt-3">
                <div className="flex mb-2">
                    <div className="mr-2 w-72">
                        <MuiTextField label="Cantidad" name="amount" value={data.amount} onChange={handleInputChange} />
                    </div>
                    <div className="float-right">
                        <Button onClick={handlePaymentCreate} variant="contained" style={{ height: '100%' }}>
                            Guardar
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    function renderCardForm(selectedUser) {
        return (
            <div className="mt-4">
                <div className="flex flex-column">
                    <div className="flex flex-column mb-2">
                        <div className="flex">
                            <div className="mr-2 w-72">
                                <MuiTextField label="Cantidad" name="amount" value={data.amount} onChange={handleInputChange} />
                            </div>
                            <div className="float-right">
                                <Button onClick={handlePaymentCreate} style={{ height: '100%' }}>
                                    Guardar
                                </Button>
                            </div>
                        </div>
                    </div>
                    {isLoading ? (
                        <CircularProgressSpinner />
                    ) : (
                        props.creditCards.map((creditCard) => {
                            let { id, cc_type, last_digits, year, month, name } = creditCard.attributes;
                            return <RadioButton key={id} checked={selectedCreditCardId === creditCard.id} label={`${name.toUpperCase()} (${cc_type.toUpperCase()}) **** ${last_digits} Expira ${month}/${year}`} value={creditCard.id} onClick={() => setSelectedCreditCardId(creditCard.id)} />;
                        })
                    )}
                    <RadioButton key="add" checked={selectedCreditCardId === 'add'} label={`Agregar nueva tarjeta a ${selectedUser.first_name} ${selectedUser.last_name}`} onClick={() => setSelectedCreditCardId('add')} value="add" />
                </div>

                {selectedCreditCardId === 'add' && <CreateCardForm user_id={selectedUser.id} afterCreate={setSelectedCreditCardId} />}
            </div>
        );
    }

    if (order.attributes.payment_state !== 'balance_due') {
        return (
            <div className="flex flex-column flex-1 w-full mt-4">
                <div className="text-xl mb-2">El pedido ya se encuentra pagado</div>
            </div>
        );
    }

    return (
        <div className="flex flex-column flex-1 w-full mt-4">
            <div className="text-xl mb-2">Método de pago</div>
            <div className="flex flex-row justify-start items-center">
                {Object.keys(PAYMENT_METHODS).map((key) => (
                    <div className="mr-4">
                        <ToggleButton key={key} selected={selectedPaymentMethod === key} icon={PAYMENT_METHODS[key].icon} disabled={!!PAYMENT_METHODS[key].disabled} onClick={() => handleSelectPaymentMethod(key)} description={<div className="text-white text-lg">{PAYMENT_METHODS[key].name}</div>}></ToggleButton>
                    </div>
                ))}
            </div>
            <div>
                {selectedPaymentMethod === 'cash' && renderCashForm()}
                {selectedPaymentMethod === 'client_card' && renderCardForm(getPaymentMethodUser())}
                {selectedPaymentMethod === 'distributor_card' && renderCardForm(getPaymentMethodUser())}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    client: state.posClient,
    user: state.user,
    creditCards: state.creditCard,
    paymentMethods: state.posPayment.paymentMethods,
});

const mapDispatchToProps = {
    getPaymentMethods,
    ListCreditCards,
    createPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositTab);
