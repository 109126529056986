import React, {useState} from 'react';
import {DefaultModal} from '../../../components/Modal';
import {Title, BasicInput, BasicSelect} from '../../../components/Form';

import RegisteredPaymentMethod from './registeredPaymentMethod';

const ModalUpdateSelectedPaymentMethod = (props) => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState(props.selectedPaymentOption);

  const getCardStates = (card) => {
    let states = []
    if (card.attributes.default) states.push("primary");
    if (props.chargeCard && card.id == props.chargeCard.id) states.push("charge");
    if (props.depositCard && card.id == props.depositCard.id) states.push("deposit");
    return states;
  } 

  return (
    <DefaultModal
      show={props.show}
      onHide={props.toggleModal}
      onSave={() => props.onSave(selectedPaymentOption)}
      title={"Selecciona una tarjeta"}
      saveButtonText="Confirmar"
    >
      <Title>Métodos de pago registrados</Title>

      <div>
        { !props.isCreditCardLoading && 
        props.creditCards && props.creditCards.map((card) => {
            return (
              <RegisteredPaymentMethod
                id={card.id}
                type={card.type}
                attributes={card.attributes}
                setIsLoading={props.setIsLoading}
                selectable={true}
                isChecked={selectedPaymentOption === card.id}
                onOptionSelect={setSelectedPaymentOption}
                states={getCardStates(card)}
              />
            )
          })
        }

      </div>
    </DefaultModal>
  )
}

export default ModalUpdateSelectedPaymentMethod;
