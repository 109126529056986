import React from 'react';
import BasicInputLabel from './BasicInputLabel.js';
import {FormGroupFlexbox, DefaultInput} from './style.js';

const RowRadio = (props) => {
  let attrs = props.data && props.data.attributes;

  return (
    <FormGroupFlexbox
      onClick={() => props.onChange && props.onChange(props.id)}
    >
      {props.title || attrs && 
        <BasicInputLabel 
        for={props.id}
        style={{fontSize: '15px', marginBottom: 0, padding: '7px', flexGrow: "1"}}
        >
        {props.title || attrs.address1+", "+attrs.city+", "+attrs.state_code+". "+attrs.country_iso+" C.P. "+attrs.zipcode}
        </BasicInputLabel>     
      }
      <DefaultInput
        style={{width: '20px'}}
        id={props.id}
        name={props.groupName}
        type="radio"
        checked={props.selected === props.id}
      />
    </FormGroupFlexbox>
  )
}

export default RowRadio;