import React from 'react';
import { Redirect} from 'react-router-dom';

const CandidateIndex = (props) => {
  return (
    <div>
      <Redirect to={
        {
          pathname: "/candidate/register",
          state: {
            from: props.location
          }
        }
      }/> 
    </div>
  )
}

export default CandidateIndex;