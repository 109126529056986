import React, {useState} from "react";
import Avatar from "./avatar";
import { Add, DeleteOutline } from '@mui/icons-material';
import CircledIconButton from "./circledIconButton";
import { API_BASE_URL } from "actions/types";

function ProductItem({children,
  onRemove=() => {},
  onSelect=() => {},
  id,
  attributes={},
  isLoading=false,
  selected=false,
}) {
  const [deleted, setDeleted] = useState(false);
  const {name, quantity, price, currency, options_text, barcode, variant_id, benefits_percentage} = attributes;

  function getImageUrl() {
    if (!attributes.variant_images.length) return ""
    return API_BASE_URL + attributes.variant_images[0].attributes.styles[2].url
  }
  function handleRemove() {
    setDeleted(true);
    onRemove(id);
  }

  if (deleted) {
    return <></>;
  }

  return (
    <div
      style={{
        border: selected ? "2px solid white" : "none",
        boxShadow: selected ? `0 0 3px 2px #48abe0` : "2px 3px 3px hsl(0deg 0% 0% / 0.40)",
      }}
      className={[
        `flex flex-row w-full text-white rounded-xl h-24 mb-2 overflow-hidden ${selected ? "bg-blue-900" : "bg-gray-800"}`,]}>
      <div className="flex aspect-square mr-2 p-3">
        <Avatar
          url={getImageUrl()} 
        />
      </div>
      <div className="flex flex-1 flex-row justify-between items-center py-3 pr-3">
        <div className="h-full justify-between">
          <div className="items-start text-xl font-bold">
            {name} x{quantity}
          </div>
          <div className="flex flex-row">
            <div className="font-light">
              {price} {currency}
            </div>
            <div className="mx-2 font-light">
              {benefits_percentage > 0 && `-${benefits_percentage * 100}%`}
            </div>
          </div>
        </div>
        <div className="flex flex-column justify-end items-end">
          <div className="font-base">
            {options_text}
          </div>
          <div className="font-light">
            codigo: {barcode}
          </div>
        </div>
      </div>
      <div
        style={{backgroundColor: "rgb(70, 90, 124)"}}
        className="flex flex-row justify-between items-center p-3 rounded-l-3xl w-32">
          {isLoading ? 
            <div className="m-auto animate-spin rounded-full h-10 w-10 border-b-4 border-white"></div> : 
          <>
            <div className="pr-2">
              <CircledIconButton onClick={() => onSelect(variant_id)}>
                <Add />
              </CircledIconButton>
            </div>
            <div className="">
              <CircledIconButton onClick={handleRemove}>
                <DeleteOutline />
              </CircledIconButton>
            </div>
          </>
          }
      </div>
    </div>
  );
}

export default ProductItem;
