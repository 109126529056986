import axios from 'axios';
import { API_BASE_URL, ADD_PAYMENT_PLAN, DELETE_PAYMENT_PLAN, ADD_PAYMENT_PLANS_LIST } from './types';

import { dispatchAction } from 'utils/actions/common';

export const getUserPaymentPlans = (user_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/payment_plans/client`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: { user_id },
            json: true,
        }).then((res) => {
            let plansList = res.data.data?.map((plan) => plan.attributes);
            let plans = { data: { [user_id]: plansList || [] } };
            return dispatchAction(dispatch, plans, ADD_PAYMENT_PLANS_LIST);
        });
    };
};

export const getPayrollPayments = (user_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/payment_plans/payroll`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: { user_id },
            json: true,
        }).then((res) => {
            let plansList = res.data.data?.map((plan) => plan.attributes);
            let plans = { data: { [user_id]: plansList || [] } };
            return dispatchAction(dispatch, plans, ADD_PAYMENT_PLANS_LIST);
        });
    };
};

export const updateUserPaymentPlans = (user_id, payment_plans_ids) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'PATCH',
            url: API_BASE_URL + `/es/app/api/v1/payment_plans/update_user_plans`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user_id, payment_plans_ids },
            json: true,
        }).then((res) => {
            //catch error
            let plansList = res.data.data?.map((plan) => plan.attributes);
            let plans = { data: { [user_id]: plansList || [] } };
            return dispatchAction(dispatch, plans, ADD_PAYMENT_PLANS_LIST);
        });
    };
};

export const createUserPaymentPlan = (user_id, payment_plan) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'POST',
            url: API_BASE_URL + `/es/app/api/v1/payment_plans`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            data: { user_id, payment_plan },
            json: true,
        }).then((res) => {
            //catch error
            let plan = { data: { user_id, payment_plan: res.data.data.attributes || [] } };
            return dispatchAction(dispatch, plan, ADD_PAYMENT_PLAN);
        });
    };
};

export const deletePaymentPlan = (payment_plan_id, user_id) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'DELETE',
            url: API_BASE_URL + `/es/app/api/v1/payment_plans/${payment_plan_id}`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        }).then((res) => {
            if (res.data.success) {
                dispatchAction(dispatch, { data: { user_id, payment_plan_id } }, DELETE_PAYMENT_PLAN);
            }
            return res;
        });
    };
};
