import * as XLSX from 'xlsx';

export const exportToExcel = async (excelData, fileName, { page, selectedTab }) => {
    let data = excelData;

    if (page === 'comissions') data = data.filter((c) => (selectedTab === 'all' ? c : c.status === selectedTab));
    if (page === 'scheduled_payments') data = data.filter((c) => (selectedTab === 'all' ? c : c.state === selectedTab));

    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(book, sheet, 'Hoja 1');

    XLSX.writeFile(book, fileName + '.xlsx');
};
