import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Page, Heading, MainWrapper} from '../../components/Body';
import {Task, Subtask} from '../../components/Button';
import {FormFlashMessage} from '../../components/Alert';
import ModalDocuments from './ModalDocuments';
import ModalId from './ModalId';
import { faFolderOpen, faExclamationCircle, faFolder, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import {fileFilter} from '../../utils/validations/files';
import {
  uploadOfficialIdentityFile,
  requestFiles,
  requestRegisterDocumentStatus,
  updateCurp,
} from '../../actions/candidateActions';

import {
  getUserMembershipData,
} from '../../actions/subscriptionActions';

import { useHistory } from "react-router-dom";
import { LoadingText } from '../../components/Spinners/style';

const CandidateRegister = (props) => {
  const [modalId, setModalId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [formData, setFormData] = useState({});
  const [filesLoading, setFilesLoading] = useState(false);
  const [taskStatus, setTaskStatus] = useState(false);
  let history = useHistory();

  const toggleModal = (id) => {
    !!id ? setModalId(id) : setModalId(null);
  }

  const uploadFiles = (data) => {
    let fileToUpload = data.nextId;
    let curp = data.modalState && data.modalState.curp;
    let obj = {
      data: {
        user: {
          ine_front: fileToUpload === "ine_front" && data[fileToUpload].image,
          ine_back: fileToUpload === "ine_back" && data[fileToUpload].image,
          promissory_note: fileToUpload === "note" && data[fileToUpload].image,
          contract: fileToUpload === "contract" && data[fileToUpload].image,
          curp,
          passport: fileToUpload === "passport" && data[fileToUpload].image,
        },
      },
      credentials: {
        email: props.user.data.attributes.email,
      }
    }
    props.uploadOfficialIdentityFile(obj).then(res => {
      props.requestRegisterDocumentStatus()
    })
    return true;
  }

  const handleRegisterDocumentsFilesLoad = () => {
    setFilesLoading(true);
    if (props.user.isLoggedIn) {
      props.requestFiles().then(res => {
        setFilesLoading(false);
      })
    }
  }

  const handleUpdateCurp = (curp) => {
    props.updateCurp(curp);
  }
  
  const handleFileUploadChange = (file, id, modalState) => {
    return fileFilter(file)
    .then((file) => {
      let image = {
        name: file[0].name,
        size: file[0].size,
        type: file[0].type,
        reset: 0,
      }

      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      const onloader = function(e){
        let data = {
          ...formData,
          modalState,
          nextId: id,
          hasData: true,
          [id]: {
            ...formData[id],
            image: {
              ...image,
              raw: file[0],
              file: e.target.result
            }
          }
        }
        setFormData(data)
        uploadFiles(data)

      };
      reader.onload = onloader.bind(this);
      return file;
    })
    .catch(err => {
    });
  }

  const isMembershipPaid = () => {
    return props.membership &&
    props.membership.state === "active" ||
    props.membership?.subscriptions &&
      props.membership?.subscriptions.some(
        sub => (sub.state === "paid")
      )

  }

  const getGeneralTasksStatusMessage = () => {
    if (Object.keys(props.document_status).length) {
      let count = Object.entries(props.document_status).filter((key, value) => !key[1].valid).length
      if (count === 0) {
        return `Completado! Por favor espera al correo de confirmación.`
      }
      return `${count} Formularios a completar`
    } else {
      return "Cargando..."
    }          
  }

    const getSubscriptionTaskStatusMessage = () => {
    if (props.membership?.id) {
      if (isMembershipPaid()) {
        return `Completado!`
      }
      return `1 Acción a completar`
    } else {
      return "Cargando..."
    }          
  }

  const getTaskStatus = (id) => {
    if(taskStatus) {
      return taskStatus[id] && taskStatus[id].status;
    }
  }

  const getSubtaskIcon = (id) => {
    switch(getTaskStatus(id)) {
      case "success":
        return faCheck
      case "pending":
        return faExclamationCircle
      case "error":
        return faExclamationCircle
      default:
        return faSpinner
    }
  }

  const redirectToSubscriptionPage = () => {
    history.push("/candidate/subscription");
  }

  const fillSubtaskStatus = () => {
    let entries = Object.entries(props.document_status);
    if(entries.length) {
      let updatedStatus = {};
      entries.map(([key, value]) => {
        updatedStatus[key] = value;
        if(value.valid) {
          updatedStatus[key].status = "success"
        } else {
          updatedStatus[key]['status'] = "pending"
        }
      })
      if (Object.entries(updatedStatus).some(([key, value]) => value.status === "pending")) {
        if( !updatedStatus.general){
          updatedStatus.general = {}  
        }
        updatedStatus['general']['status'] = "pending";
      } else {
        if( !updatedStatus.general){
          updatedStatus.general = {}  
        }
        updatedStatus['general']['status'] = "success";
      } 
      setTaskStatus({...taskStatus, ...updatedStatus})
    }
  }

  const fillMembershipStatus = () => {
    setTaskStatus({...taskStatus, membership: {
        ...props.membership,
        status: isMembershipPaid() ? "success" : "pending"
      }
    })
  }

  useEffect(() => {
    setIsModalOpen(!isModalOpen);
    if (modalId && modalId.length) {
      handleRegisterDocumentsFilesLoad()
    }
  }, [modalId])

  useEffect(() => {
    props.requestRegisterDocumentStatus()
    props.getUserMembershipData()
  }, [])

  useEffect(() => {
    fillSubtaskStatus();
  }, [props.document_status])

  useEffect(() => {
    fillMembershipStatus();
  }, [props.membership])


  return (
    <Page>
      <FormFlashMessage
        closeTime={0}
        heading="Bienvenido a Unique Brother!"
        footer="Equipo Unique Brother"
      >
        Gracias por formar parte   nuestro equipo.
        <br/>
        Tan pronto hayas finalizado de llenar los datos solicitados en esta sección, estaremos revisando su solicitud, y tan pronto tengamos una respuesta, se la haremos saber por medio
        de un correo electrónico a la dirección de <b>{props.user.data.attributes && props.user.data.attributes.email}.</b>
        <br/>
        <br/>
        Gracias por su paciencia,
      </FormFlashMessage>
      <Heading title="Termina tu registro!"/>
      <MainWrapper 
        title="Proceso de activación"
        subtitle="Completa los siguientes campos para habilitar tu cuenta y recibir una línea de crédito."
      >
        <Task
          title="Activación de cuenta"
          subtitle="Por favor ingresa la información necesaria para activar tu cuenta."
          openIcon={faFolderOpen}
          closedIcon={faFolder}
          isOpen={taskStatus.general && !(taskStatus.general.status === "success")}
          status={getTaskStatus("general")}
          statusMessage={getGeneralTasksStatusMessage()}
        >
          <Subtask
            icon={getSubtaskIcon("documents")}
            id={"documents"}
            title={"Documentos a firmar"}
            status={getTaskStatus("documents")}
            onClick={toggleModal}
          >
            Por favor imprimir los documenos en este apartado, firmarlos y subir el escaneado de dichos documentos.
          </Subtask>
          <Subtask
            icon={getSubtaskIcon("id")}
            id={"id"}
            title={"Identificación Oficial"}
            status={getTaskStatus("id")}
            onClick={toggleModal}
          >
            Por favor subir una Identificación Oficial, INE o pasaporte, al igual que tu CURP.
          </Subtask>
        </Task>

        <Task
          title="Pago de Membresía"
          subtitle="Por favor paga tu subscripción para activar tu cuenta."
          openIcon={faFolderOpen}
          closedIcon={faFolder}
          isOpen={taskStatus.membership && !(taskStatus.membership.status === "success")}
          status={getTaskStatus("membership")}
          statusMessage={getSubscriptionTaskStatusMessage()}
        >
          <Subtask
            icon={getSubtaskIcon("membership")}
            id={"membership"}
            title={"Activa tu Membresía"}
            status={getTaskStatus("membership")}
            onClick={redirectToSubscriptionPage}
          >
            Para activar tu cuenta, es necesario iniciar tu subscripción Unique Brother. Puedes hacer click aquí para ir al apartado.
          </Subtask>
        </Task>
      </MainWrapper>
      { modalId === "documents" && 
        <ModalDocuments 
          show={isModalOpen} 
          isLoading={filesLoading} 
          toggleModal={toggleModal}
          handleFileUploadChange={handleFileUploadChange} 
          formData={formData}
          data={props.documents}
        />
      }
      { modalId === "id" && 
        <ModalId
          show={isModalOpen}
          isLoading={filesLoading} 
          toggleModal={toggleModal}
          handleFileUploadChange={handleFileUploadChange} 
          formData={formData}
          data={props.documents}
          curp={props.curp}
          updateCurp={handleUpdateCurp}
        />
      }
    </Page>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  documents: state.candidate.documents,
  curp: state.candidate.curp,
  document_status: state.candidate.document_status,
  membership: state.subscription.membership,
})

const mapDispatchToProps = {
  uploadOfficialIdentityFile,
  requestFiles,
  updateCurp,
  requestRegisterDocumentStatus,
  getUserMembershipData,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateRegister);
