import {
  POS_SEARCH_VARIANT,
  POS_SELECT_VARIANT,
  POS_UNSELECT_VARIANT,
  POS_VARIANT_LOADED,
} from '../../actions/types';

import reducerHandler from '../reducerHandler';

const initialState = {
  data: [],
  isLoading: false,
  loaded: false,
  error: null,
  selectedVariantId: null,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case POS_SEARCH_VARIANT.loading:
    case POS_SEARCH_VARIANT.success:
    case POS_SEARCH_VARIANT.error:
      return reducerHandler(state, action, POS_SEARCH_VARIANT)
    case POS_SELECT_VARIANT:
      return {
        ...state,
        isLoading: true,
        loaded: false,
        selectedVariantId: action.payload
      }
    case POS_UNSELECT_VARIANT:
      return {
        ...state,
        isLoading: false,
        loaded: false,
        selectedVariantId: null 
      }
    case POS_VARIANT_LOADED.success:
      return {
        ...state,
        isLoading: false,
        loaded: true,
      }
    default:
      return state;
  }
}
