import Styled from 'styled-components';
import { mobileScreenSize } from "utils/screen";
import { Slider, styled } from '@mui/material'

export const CustomSlider = styled(Slider)(
  ({theme}) => ({
  '& .MuiSlider-markLabel': {
    color: "white",
  }

  }));

export const PaymentInputContainer = Styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  flex-flow: column;
  margin-top: 15px;
`;

export const PageTitle = Styled.div`
  font-weight: 600;
  color: ${props => props.theme.text.color.light};
  height: 60px;
`; 

export const CancelMembershipContainer = Styled.div`
  margin-bottom: 15px;
  width: 100%;
  text-align: end;

  @media (max-width: ${mobileScreenSize}px) {
  }
`;

export const PageSubtitle = Styled.div`

`;
export const PlansContent = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 45px;
`;

export const ButtonContainer = Styled.div`
  display: flex;
  width: 50%;
  margin: auto;
  @media (max-width: ${mobileScreenSize}px) {
    width: 100%;
  }
`;

export const PageSubtitleContainer = Styled.div`
  display: flex;
  flex-direction: column;
  background-color: #cff4fc;
  color: #055160;
  border-color: #b6effb;
  border-radius: 5px;
  padding: 15px;
`;

export const ActiveItemSubscription = Styled.div`
  text-align: center;
  padding: 6px;
  background-color: #D4AF37;
  color: white;
  border-bottom: 1px solid #C8B273;
  text-weight: 600;
  font-size: 18px;
`;

export const SmallTitle = Styled.div`
  font-size: 14px;
  margin-top: 32px;
  margin-bottom: 16px;
  padding: 3px;
  color: #bdd1f8;
`;

export const MembershipConfigurationWrapper = Styled.div`
  margin: auto;
  width: 50%;
  padding-top: 15px;
  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const MembershipConfiguration = Styled.div`
  border 1px solid #8a99b5;
  border-radius: 5px;
  @media (max-width: ${mobileScreenSize}px) {
    overflow: auto;
  }
`;

export const RenewalSection = Styled.div`
  padding: 16px 24px 24px;
  padding-right: 25px;
  border-bottom 1px solid #8a99b5;
`;

export const SectionTitle = Styled.div`
  font-size: 24px;
  padding: 3px;
  color: white;
`;

export const SectionText = Styled.div`
  font: 1.25rem;
  margin-top: 14px;
  margin-bottom: 14px;
`;

export const SectionSwitch = Styled.div`
  float: right;
  display: flex;
`;
