import { GET_STATEMENT_ACCOUNT } from '../actions/types';

const initialState = {
    list: [],
    meta: {},
    links: {},
};

export default function comissionsRecordsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATEMENT_ACCOUNT:
            return {
                ...state,
                list: action.payload.Data,
            };

        default:
            return state;
    }
}
