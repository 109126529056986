import React from 'react';
import {LineChart} from 'components/Charts';
import { Title } from 'components/Texts';
import MonthlyChange from './monthly_change';
import MonthlyTotals from './monthly_totals';

import {
  MainContainer,
  ChartContainer,
  RankingsContainer,
  RowContainer,
  DashboardItem,
  ChartBody,
  MonthlyChangesContainer,
  MonthlyTotalsContainer,
} from './style';

const DashboardIndex = (props) => {
  return (
    <MainContainer>
      <RowContainer>
        <ChartContainer>
          <DashboardItem>
            <Title>
              Registro mensual
            </Title>
            <ChartBody>
              <LineChart/>
            </ChartBody>
          </DashboardItem>
        </ChartContainer>
        <RankingsContainer>
          <DashboardItem>
            <Title>
              Top vendedores 
            </Title>

          </DashboardItem>
        </RankingsContainer>
      </RowContainer>
      <RowContainer>
        <MonthlyChangesContainer>
          <DashboardItem>
            <MonthlyChange/>
          </DashboardItem>
        </MonthlyChangesContainer>

        <MonthlyTotalsContainer>
          <DashboardItem>
            <MonthlyTotals/>
          </DashboardItem>
        </MonthlyTotalsContainer>
      </RowContainer>
      <RowContainer>
        <Title style={{width: "100%", paddingLeft: 30,}}>
          Cortes mensuales 
        </Title>

        <DashboardItem>
          <Title>
            Abierto (Noviembre) 
          </Title>

          <div>
            <LineChart/>
          </div>
          <Title>
            Anteriores 
          </Title>
          <div>
            <LineChart/>
          </div>


        </DashboardItem>
      </RowContainer>
    </MainContainer>
  )
}

export default DashboardIndex;
