import axios from 'axios';

import { API_BASE_URL, GET_COMISSIONS } from './types';

export const getComissions = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + `/es/app/api/v1/comissions`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: params,
            json: true,
        })
            .then((response) => {
                console.log(response.data);
                dispatch({
                    type: GET_COMISSIONS,
                    payload: response.data,
                });

                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const updateComissions = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'PATCH',
            url: API_BASE_URL + `/es/app/api/v1/comissions/massive_update`,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: params,
            json: true,
        })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return error;
            });
    };
};
