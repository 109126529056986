import axios from 'axios';

export const requestBuilder = async function (method, url, params, dispatch, type) {
    dispatch({ type: type.loading });
    let axiosConfig = getAxiosConfig(method, url, params);

    return axios(axiosConfig)
        .then((res) => {
            dispatch({
                type: type.success,
                payload: res.data.data,
            });
            return res.data.data;
        })
        .catch((err) => {
            dispatch({
                type: type.error,
                payload: err,
            });
            return err.response;
        });
};

export const simpleRequestBuilder = async function (method, url, params) {
    let axiosConfig = getAxiosConfig(method, url, params);
    return axios(axiosConfig)
        .then((res) => {
            return res.data.data;
        })
        .catch((err) => {
            return {
                error: err,
                status: err.response.status,
            };
        });
};

function getAxiosConfig(method, url, params) {
    let token = JSON.parse(sessionStorage.getItem('sessionToken'));
    let order_token = JSON.parse(sessionStorage.getItem('orderToken'));

    let axiosConfig = {
        method: method,
        url: url,
        headers: {
            Authorization: `${token.token_type} ${token.access_token}`,
        },
    };

    if (order_token) {
        axiosConfig.headers['X-Spree-Order-Token'] = order_token.token;
    }

    if (method.toLowerCase() === 'get') {
        axiosConfig.params = params;
    } else {
        axiosConfig.data = params;
    }

    return axiosConfig;
}
