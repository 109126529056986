import Styled, {css} from 'styled-components';
import Icon from '@mui/material/Icon';

export const PagedCarouselContainer = Styled.div`
  margin: auto;
  justify-content: center;
  align-content: center;
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;
`;

export const NextPageButtonContainer = Styled.div`
  z-index: 1;
  width: 60px;
  height: 100%;
  display: flex;
  align-content: center;
  margin-left: -24px;
  button {
    display: ${props => props.show ? "block" : "none"};
    margin: 0;
    background-color: white;
    height: 64px;
    width: 64px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 19%);
  }
`;

export const BackPageButtonContainer = Styled.div`
  z-index: 1;
  width: 60px;
  height: 100%;
  display: flex;
  align-content: center;
  margin-right: -24px;
  button {
    display: ${props => props.show ? "block" : "none"};
    margin: 0;
    background-color: white;
    height: 64px;
    width: 64px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 19%);
  }
`;

export const PresentationContainer = Styled.div`
  width: 835px;
  overflow: hidden;
  position: relative;
  padding-top: 30px;
`;

export const PresentationChildren = Styled.div`
  width: 100%;
  display: flex;
  transform: translateX(${props => props.translateX}%);
  transition: all 250ms linear;
  scrollbar-width: none;
  overflow-style: none;
`;

export const CarouselPageIcon = Styled(Icon)`
  color: ${props => props.active ? "rgb(25, 118, 210)" : "rgba(0,0,0,.1)"};
  width: 0.5rem;
  font-size: 6px;
`;

export const PageIconsContainer = Styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
