import React from 'react';
import { faEllipsisH, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { DropdownButton } from '../../../components/Button';
import { DropdownMenu, DropdownItem } from '../../../components/Dropdown';
import { faCheck, faDotCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ColoredDatum } from '../../../components/Table';
import { DropdownMenuLi } from './style';
import Swal from 'sweetalert2';

import { PaymentMethodInformation, PaymentMethodCardDetails, PaymentMethodType, PaymentMethodNumberDetails, PaymentMethodNumbers, PaymentMethodExpires, PaymentMethodActions, PaymentMethodTypeIcon, PaymentMethodStates } from './style';
import { Radio } from '@material-ui/core';

const RegisteredPaymentMethod = (props) => {
    const statusObj = {
        primary: {
            type: 'primary',
            text: 'Primaria',
            icon: faCheck,
        },
        secondary: {
            type: 'secondary',
            text: 'Secundaria',
            icon: faDotCircle,
        },
        pending: {
            type: 'warning',
            text: 'Por verificar',
            icon: faInfoCircle,
        },
        charge: {
            type: 'primary',
            text: 'Tarjeta de cobro',
            icon: faCheck,
        },
        deposit: {
            type: 'primary',
            text: 'Tarjeta de Pagos',
            icon: faCheck,
        },
    };

    const getCardState = () => {
        if (props.attributes.default) {
            return 'primary';
        } else {
            return 'secondary';
        }
    };

    const handleSetDefaultCard = () => {
        props.updateDefaultCreditCard(props.id).then((res) => {
            Swal.fire('Tarjeta actualizada!', 'Se ha seleccionado una nueva tarjeta predeterminada', 'success');
        });
    };

    const handleOptionClick = () => {
        if (props.selectable && !!props.onOptionSelect) {
            props.onOptionSelect(props.id);
        }
    };
    let selectedStatus = statusObj[getCardState()];

    return (
        <PaymentMethodInformation onClick={handleOptionClick} selectable={props.selectable}>
            <PaymentMethodCardDetails>
                <PaymentMethodType>
                    <PaymentMethodTypeIcon icon={faCreditCard} />
                </PaymentMethodType>
                <PaymentMethodNumberDetails>
                    <PaymentMethodNumbers>
                        {props.attributes.cc_type} * {props.attributes.last_digits}
                    </PaymentMethodNumbers>
                    <PaymentMethodExpires>
                        Expira {props.attributes.month}/{props.attributes.year}
                    </PaymentMethodExpires>
                </PaymentMethodNumberDetails>

                <PaymentMethodStates>
                    {props.states ? (
                        props.states.map((state) => {
                            return (
                                <ColoredDatum variant={statusObj[state].type} icon={statusObj[state].icon} style={{ width: '150px' }} wrapperStyle={{ width: '170px' }}>
                                    {statusObj[state].text}
                                </ColoredDatum>
                            );
                        })
                    ) : (
                        <ColoredDatum variant={selectedStatus.type} icon={selectedStatus.icon} style={{ width: '120px' }}>
                            {selectedStatus.text}
                        </ColoredDatum>
                    )}
                </PaymentMethodStates>
            </PaymentMethodCardDetails>
            {props.selectable ? (
                <Radio name="registered-payment-radio" checked={props.isChecked} />
            ) : (
                <PaymentMethodActions>
                    <DropdownButton title="Perfil" icon={faEllipsisH}>
                        <DropdownMenuLi>
                            <DropdownMenu>
                                <DropdownItem key="1" onClick={handleSetDefaultCard}>
                                    Establecer predeterminada
                                </DropdownItem>
                                <DropdownItem id={props.id} key="2" hoverColor={'#f8d6d9'} onClick={props.onDeletePaymentMethod}>
                                    Eliminar
                                </DropdownItem>
                            </DropdownMenu>
                        </DropdownMenuLi>
                    </DropdownButton>
                </PaymentMethodActions>
            )}
        </PaymentMethodInformation>
    );
};

export default RegisteredPaymentMethod;
