import {
  GET_CUBIT_ORDERS,
  GET_CUBIT_LINE_ITEMS,
  GET_CUBIT_ITEMS_CLIENTS,
} from '../actions/types';

const initialState = {
    orders: {
      list: [],
      meta: {},
      links: {},
    },
    line_items: {
      list: [],
      meta: {},
      links: {},
    },
    items_clients: {
      list: [],
      meta: {},
      links: {},
    }
}

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUBIT_ORDERS:
      return {
        ...state,
        orders: {
                list: action.payload.data,
                meta: action.payload.meta,
                links: action.payload.links,
            },
      }
    case GET_CUBIT_LINE_ITEMS:
      return {
            ...state,
              line_items: {
                    list: action.payload.data,
                    meta: action.payload.meta,
                    links: action.payload.links,
                },
        }
    case GET_CUBIT_ITEMS_CLIENTS:
      return {
            ...state,
             items_clients: {
                    list: action.payload.data,
                    meta: action.payload.meta,
                    links: action.payload.links,
                },
        }
    default:
      return state
  }
}
