import React, {useState, useEffect} from 'react';
import { BasicInput, BasicTextArea } from '../../../../components/Form';

import {
  PaymentDetailsSectionWrapper,
  PaymentAmountFieldsContainer
} from './style';

const CashPaymentDetailsForm = (props) => {

  const handleInputChange = (e) => {
    let val = e.target.value;
    let id = e.target.id;

    props.updateCashForm({[id]: val})
  }

  return (
    <PaymentDetailsSectionWrapper>
      <PaymentAmountFieldsContainer>
        <BasicInput
          title="Cantidad a pagar"
          id="amount"
          style={{paddingRight: "25px"}}
          value={props.cashForm.amount}
          onChange={handleInputChange}
        />
      </PaymentAmountFieldsContainer>
      <BasicInput
        title="Crédito"
        value={props.cashForm.credit}
        id="credit"
        onChange={handleInputChange}
      />
      <BasicInput
        title="Referencia de pago"
        value={props.cashForm.ref}
        id="ref"
        placeholder="ABC123"
        onChange={handleInputChange}
      />
      <BasicTextArea 
        title="Descripción o motivo (opcional)"
        placeholder="Escribe una descripción del motivo de pago"
        id="description"
        value={props.cashForm.description}
        onChange={handleInputChange}
      />
    </PaymentDetailsSectionWrapper>
  )
}

export default CashPaymentDetailsForm;
