import constants from './constants';

const theme = {
  bg: {
    border: "#bdbdbd",
    buttonDefault: "#5469d4",
    buttonDefaultHover: "#6578d8",
    cancel: '#9fb3ca',
    danger: "#dc3545",
    dangerRow: "rgba(236,94,105,0.4)",
    default: "#192231",
    defaultClear: "#24344D",
    disabled: "#4e576f",
    error: "#f8d7da",
    errorHover: "#c24241",
    errorImg: "linear-gradient(to left, #f6aaa9, #fad4d4)",
    hoverMenuItem: "#1c2636",
    info: "rgba(88, 147, 223, 0.4)",
    modal: "#222e4c",
    pink: "#f8d7da",
    pending:"rgb(234,151,21)",
    pendingHover: "#BB7911",
    sidebar: "#131c29",
    success: "green",
    alertSuccess: "#d4edda",
    alertDanger: "#f8d7da",
    alertSecondary: "#e2e3e5",
    successRow: "rgba(46,197,211,0.4)",
    warning: "rgba(241,194,5,0.25)",
  },
  form: {
    color: {
      inputBorder: "1px solid #324462",
      inputBorderError: "#f5c6cb",
      inputBorderHover: "1px solid #465671",
      disabledInputBorder: "#6A6A6A",
      disabledBorder: "#2f4060",
      disabledTextColor: "#313131",
    },
  },
  color: {
    lightShadow: "rgba(0,0,0,0.1)",
    modalBorder: "rgba(255, 255, 255, 0.12)",
    alertSuccessBorder: "#c3e6cb",
    alertSecondaryBorder: "#d6d8db",
  },
  text: {
    font: "Calibri",
    color: {
      alertSuccess: "#155724",
      alertDanger: "#721c24",
      alertSecondary: "#383d41",
      default: "rgba(225,235,245,.7)",
      light: "#bdd1f8",
      panel: "#8a99b5",
      label: "#bdd1f8",
      hover: "rgba(225,235,245,1)",
      dim: "rgba(225,235,245,.45)",
      danger: "#721c24",
      disabled: "white",
      error: "#721c24",
      input: "#d6d6d6",
      success: "white",
      successGreen: "green",
      value: "white",
      info: "#white",
      disabled: "rgba(225,235,245,.9)",
      pending: "white",
      formTitle: "white",//"#007bff",
    },
    size: "14px",
  },
  cons: {
    ...constants,
  }
}

export default theme;
