import Styled from "styled-components";
import { mobileScreenSize } from "utils/screen";

export const HeaderMessage = Styled.div`
  color: white;
`

export const DistributorCodeContainer = Styled.div`
  height: 100%;
  color: white;
  font-size: 18px;
  display: flex;
  align-content: center;
  align-items: center;
  b {
    padding-right: 8px;
  }

  @media (max-width: ${mobileScreenSize}px) {
    font-size: 14px;
    align-items: left;
    align-content: left;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 8px;
  }
`;
