import React from 'react';
import { StyledInformation, NoHeightContainer } from './style';

function InformationAlert(props) {
    if (props.show) {
        return (
            <StyledInformation onClose={props.onClose} dismissible>
                {props.children}
            </StyledInformation>
        );
    }
    return <NoHeightContainer></NoHeightContainer>;
}

export default InformationAlert;
