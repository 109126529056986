import React from "react";
import MUIIconBtn from '@mui/material/IconButton'

function IconButton({children,
  size="large",
  color="primary",
  variant="outlined",
  onClick=() => console.log("click")
}) {
  return (
    <MUIIconBtn
      onClick={onClick}
      aria-label="Search"
      size={size}
      color={color}
      variant={variant}
      sx={[
        {'&:hover': { bgcolor: "hsl(205, 31%, 45%, 40%)" }},
        { bgcolor: "hsl(205, 31%, 45%, 15%)" }
      ]}
    >
      {children}
    </MUIIconBtn>

  );
}

export default IconButton;
