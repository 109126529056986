import axios from 'axios';

import { API_BASE_URL, GET_COMMISSION_RECORDS, GET_COMMISSION_PAYMENTS_LEVEL_1, GET_COMMISSION_PAYMENTS_LEVEL_2 } from './types';

export const getComissionRecords = (params) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));

        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/commission_records',
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            params: params,
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_COMMISSION_RECORDS,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getComissionsPayments = (comissionId) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/commission_payments?commission_record_id=' + comissionId,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_COMMISSION_PAYMENTS_LEVEL_1,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getComissionsIntegrations = (comissionPaymentId) => {
    return (dispatch) => {
        let token = JSON.parse(sessionStorage.getItem('sessionToken'));
        return axios({
            method: 'GET',
            url: API_BASE_URL + '/es/app/api/v1/commission_integrations?commission_payment_id=' + comissionPaymentId,
            headers: {
                Authorization: `${token.token_type} ${token.access_token}`,
            },
            json: true,
        })
            .then((response) => {
                dispatch({
                    type: GET_COMMISSION_PAYMENTS_LEVEL_2,
                    payload: response.data,
                });
                return response.data;
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const clearComissionsIntegrations = () => {
    return (dispatch) => {
        dispatch({
            type: GET_COMMISSION_PAYMENTS_LEVEL_2,
            payload: null,
        });
    };
};
